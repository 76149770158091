import React, { FunctionComponent } from 'react'
import styled, { useTheme } from 'styled-components'
import * as GQL from 'generated/graphql'
import { addDays, format, formatDistanceStrict, isBefore, parseISO } from 'date-fns'

import Loader from 'components/Loader'
import HistoryRow from 'plasmic/HistoryRow'
import MonitorHistory from 'plasmic/MonitorHistory'

const HeaderVerb = styled.div`
  color: ${props => props.color};
`

const filteredVerbSlugs = [
  'stopped-depletion',
  'stopped-depleting',
  'started-depletion',
  'started-depleting',
  'refilled',
  'ordered',
  'ordered-automatic',
  'ordered-manually',
  'cancelled-order',
  'delivered-order',
  'delivered',
]

type VerbKey =
  | 'stopped-depletion'
  | 'stopped-depleting'
  | 'started-depletion'
  | 'started-depleting'
  | 'refilled'
  | 'ordered'
  | 'ordered-automatic'
  | 'ordered-manually'
  | 'cancelled-order'
  | 'delivered-order'
  | 'delivered'

const verbDetails: { [key in VerbKey]: { color: string; text: string } } = {
  'stopped-depletion': { color: 'red', text: 'Empty' },
  'stopped-depleting': { color: 'red', text: 'Empty' },
  'started-depletion': { color: 'lightBlue', text: 'Started depleting' },
  'started-depleting': { color: 'lightBlue', text: 'Started depleting' },
  refilled: { color: 'green', text: 'Refilled' },
  ordered: { color: 'yellow', text: 'Ordered' },
  'ordered-manually': { color: 'yellow', text: 'Ordered manually' },
  'ordered-automatic': { color: 'yellow', text: 'Ordered automatic' },
  'cancelled-order': { color: 'red', text: 'Cancelled order' },
  'delivered-order': { color: 'lightBlue', text: 'Order delivered' },
  delivered: { color: 'lightBlue', text: 'Delivered' },
}

const slugToInternalVerb = (slug: string) => {
  const verbs = {
    'stopped-depletion': 'empty',
    'stopped-depleting': 'empty',
    'started-depletion': 'startedDepleting',
    'started-depleting': 'startedDepleting',
    refilled: 'refilled',
    ordered: 'ordered',
    'ordered-manually': 'ordered',
    'ordered-automatic': 'triggeredOrder',
    'cancelled-order': 'cancelled',
    'delivered-order': 'delivered',
    delivered: 'delivered',
  } as { [key: string]: 'empty' | 'startedDepleting' | 'refilled' | 'ordered' | 'delivered' | 'cancelled' | 'triggeredOrder' | undefined }
  return verbs[slug]
}

const VerbNameElement: React.FC<{ slug: string; theme: { colors: { [key: string]: string } } }> = ({ slug, theme }) => {
  const verbKey = slug as VerbKey

  const verb = verbDetails[verbKey]

  if (!verb) return <div>Unknown action</div>

  const color = theme.colors[verb.color]
  const text = verb.text

  return <HeaderVerb color={color}>{text}</HeaderVerb>
}

interface Props {
  customer: GQL.CustomerNode
  sides?: GQL.Maybe<GQL.CylinderGroupSideNode>[]
}

const CylinderGroupHistory: FunctionComponent<Props> = props => {
  const [moreLoading, setMoreLoading] = React.useState(false)
  const theme = useTheme()
  const { data, fetchMore } = GQL.useAllCustomerNotificationsNew({
    variables: {
      offset: 0,
      customerId: props.customer.id!,
      verbSlug: filteredVerbSlugs,
    },
  })

  const notifications = data?.allCustomerNotificationsNew?.edges.map(e => e?.node) || []
  const pageInfo = data?.allCustomerNotificationsNew?.pageInfo
  const grouped = notifications || []

  const leftSide = props.sides && props.sides.length >= 2 ? atob(props.sides[0]!.id!).split(':')[1] : undefined
  const rightSide = props.sides && props.sides.length >= 2 ? atob(props.sides[1]!.id!).split(':')[1] : undefined

  let timeTemp = new Date()

  return (
    <MonitorHistory
      btnLoadMore={{
        notVisible: !pageInfo?.hasNextPage,
        btnLoadMore: {
          ...(moreLoading && { children: <Loader color='white' /> }),
          onClick: async () => {
            if (moreLoading) return
            setMoreLoading(true)
            await fetchMore({
              variables: { offset: notifications.length },
              updateQuery: (previous, { fetchMoreResult }) => {
                return {
                  ...fetchMoreResult,
                  allCustomerNotificationsNew: {
                    ...(fetchMoreResult.allCustomerNotificationsNew as GQL.NotificationNewNodeConnection),
                    edges: [...(previous?.allCustomerNotificationsNew?.edges || []), ...(fetchMoreResult?.allCustomerNotificationsNew?.edges || [])],
                  },
                }
              },
            }).then(() => {
              setMoreLoading(false)
            })
          },
        },
      }}
      rows={grouped.map((n, i) => {
        const sideId = n?.objectText?.match(/[\d]+/g)?.at(0)
        let addTimeRow = null
        if (i === 0) {
          timeTemp = parseISO(n?.eventOccurredAt)
        } else {
          if (isBefore(parseISO(n?.eventOccurredAt), addDays(timeTemp, -3))) addTimeRow = formatDistanceStrict(parseISO(n?.eventOccurredAt), timeTemp)
          timeTemp = parseISO(n?.eventOccurredAt)
        }

        return (
          <React.Fragment key={'historyRow' + i}>
            {addTimeRow && <HistoryRow collapseRow daysBetween={addTimeRow} />}
            <HistoryRow
              side={sideId === leftSide ? 'left' : sideId === rightSide ? 'right' : 'none'}
              event={slugToInternalVerb(n?.verb?.slug!)}
              date={format(parseISO(n?.eventOccurredAt), 'dd.MM')}
              infoRight={<VerbNameElement slug={n?.verb?.slug!} theme={theme} />}
              timeRight={format(parseISO(n?.eventOccurredAt), 'HH:mm')}
              infoLeft={<VerbNameElement slug={n?.verb?.slug!} theme={theme} />}
              timeLeft={format(parseISO(n?.eventOccurredAt), 'HH:mm')}
            />
          </React.Fragment>
        )
      })}
    />
  )
}

export default CylinderGroupHistory
