import React, { useState } from 'react'
import { useIntl } from 'react-intl'
import ReactModal from 'react-modal'
import { useTheme } from 'styled-components'
import { Range } from 'react-date-range'
import { format } from 'date-fns'

import * as GQL from 'generated/graphql'
import ModalDeliveryWindow from 'plasmic/ModalDeliveryWindow'
import { displayToast } from 'util/toasts'
import { getModalStyle } from 'components/Modal/Modal'
import DatePickerCalendar from 'components/DatePicker/DatePickerCalendar'
import { getClosedDays } from 'modules/customers/util'

interface ChangeDeliveryWindowModalProps {
  isOpen: boolean
  onRequestClose: () => void
  cylinderGroupOrder: GQL.CylinderGroupOrderNode
}

const ChangeDeliveryWindowModal: React.FC<ChangeDeliveryWindowModalProps> = ({ isOpen, cylinderGroupOrder, onRequestClose }) => {
  const t = useIntl().formatMessage
  const [deliveryRange, setDeliveryRange] = useState<Range[]>([
    {
      startDate: new Date(cylinderGroupOrder.firstPossibleDelivery),
      endDate: new Date(cylinderGroupOrder.lastPossibleDelivery),
      key: 'selection',
    },
  ])

  const theme = useTheme()

  const [patchCylinderGroupOrder] = GQL.usePatchCylinderGroupOrder({
    update(cache, { data }) {
      cache.writeFragment({
        id: cache.identify({ id: data?.patchCylinderGroupOrder?.cylinderGroupOrder?.id, __typename: 'CylinderGroupOrderNode' }),
        fragment: GQL.CylinderGroupOrderInfo,
        fragmentName: 'CylinderGroupOrderInfo',
        data: data?.patchCylinderGroupOrder?.cylinderGroupOrder,
      })
    },
    onCompleted: response => {
      if (response.patchCylinderGroupOrder?.ok) {
        displayToast(t({ id: 'orders.delivery-window.change.success' }), 'success')
      } else {
        displayToast(t({ id: 'orders.delivery-window.change.error' }))
      }
    },
    onError: () => {
      displayToast(t({ id: 'orders.delivery-window.change.error' }))
    },
  })

  function changeDeliveryWindow() {
    patchCylinderGroupOrder({
      variables: {
        id: cylinderGroupOrder.id,
        input: {
          lastPossibleDelivery: deliveryRange[0].endDate && format(deliveryRange[0].endDate, 'yyyy-MM-dd'),
          firstPossibleDelivery: deliveryRange[0].startDate && format(deliveryRange[0].startDate, 'yyyy-MM-dd'),
        },
      },
    })

    onRequestClose()
  }

  return (
    <ReactModal isOpen={isOpen} onRequestClose={onRequestClose} style={getModalStyle({ height: 'auto', overflowY: 'auto' }, {}, false, theme, true)}>
      <ModalDeliveryWindow
        btnSave={{ onClick: changeDeliveryWindow }}
        calendar={
          <DatePickerCalendar selectedRange={deliveryRange} setSelectedRange={setDeliveryRange} closedDays={getClosedDays(cylinderGroupOrder.cylinderGroup)} />
        }
      />
    </ReactModal>
  )
}

export default ChangeDeliveryWindowModal
