import { useIntl } from 'react-intl'
import { useMemo } from 'react'

import * as GQL from 'generated/graphql'
import { enumToStringFormatted } from 'util/utils'
import { getCylinderGroupSideProps, getThresholdProps, isMonitored, isReporting, isSideOff } from '../util'
import { SIDE_REMAINING_UNIT, SIDE_UNIT_PROPS } from 'modules/customers/consts'
import SideComponent from 'plasmic/SideComponent'
import Select from 'plasmic/Select'
import { displayToast } from 'util/toasts'
import { differenceInMinutes, formatDistanceToNow } from 'date-fns'
import { getRemainingGas } from 'modules/hardware/util'

interface Props {
  cylinderGroup: GQL.CylinderGroupNode
  updateCylinderGroup: React.Dispatch<React.SetStateAction<GQL.CylinderGroupNode>>
  editMode: boolean
  side: GQL.CylinderGroupSideNode
  isLeftSide: boolean
}

export default function CylinderGroupSide({ cylinderGroup, updateCylinderGroup, editMode, side, isLeftSide }: Props) {
  const RECENT_TRANSMISSION_MINUTES = 5
  const intl = useIntl()
  const t = intl.formatMessage

  const activeCycle = useMemo(() => {
    return side.activeCycle
  }, [side])

  const { remainingAmount, remainingPercent } = useMemo(() => {
    const remainingGas = getRemainingGas(activeCycle)

    return { remainingAmount: remainingGas.amount ? remainingGas.amount : 0, remainingPercent: remainingGas.percentage ? remainingGas.percentage : 0 }
  }, [activeCycle])

  const lastTransmissionTimeAgo = useMemo(() => {
    if (!isMonitored(side)) return
    if (!side.activeCycle || (!side.activeCycle.last && !side.activeCycle.updatedAt)) return ''
    if (Math.abs(differenceInMinutes(new Date(), new Date(side.activeCycle.last || side.activeCycle.updatedAt))) < RECENT_TRANSMISSION_MINUTES)
      return 'Just now'
    return formatDistanceToNow(new Date(side.activeCycle.last || side.activeCycle.updatedAt), { addSuffix: true })
  }, [side])

  const bulkTankFormat = useMemo(() => {
    return cylinderGroup.bulkTankFormat
  }, [cylinderGroup])

  const isBulk = useMemo(() => {
    return !!cylinderGroup.isBulk
  }, [cylinderGroup])

  const isSensorOff = useMemo(() => {
    return isSideOff(side)
  }, [side])

  const statusOptions = useMemo(() => {
    if (isSensorOff) {
      return []
    }
    const options = activeCycle?.state
      ? [{ value: activeCycle?.state, label: activeCycle?.state ? enumToStringFormatted(activeCycle?.state) : '' }]
      : [{ value: GQL.CycleState.Unknown, label: enumToStringFormatted(GQL.CycleState.Unknown) }]

    if (isMonitored(side)) {
      return options
    }

    if (activeCycle?.state !== GQL.CycleState.Full) {
      options.push({ value: GQL.CycleState.Full, label: enumToStringFormatted(GQL.CycleState.Full) })
    }
    if (activeCycle?.state !== GQL.CycleState.Depleting) {
      options.push({ value: GQL.CycleState.Depleting, label: enumToStringFormatted(GQL.CycleState.Depleting) })
    }
    if (activeCycle?.state !== GQL.CycleState.Empty) {
      options.push({ value: GQL.CycleState.Empty, label: enumToStringFormatted(GQL.CycleState.Empty) })
    }
    return options
  }, [activeCycle, isSensorOff, side])

  const getMonitorDataTitle = (unit: SIDE_REMAINING_UNIT) => {
    if (!activeCycle) {
      return t({ id: 'common.no-data' })
    } else if (isMonitored(side)) {
      return `${t({ id: 'common.remaining' })} ${unit.property}`
    }
    return t({ id: 'sensors.no-sensor' })
  }

  const getRemainingProps = (unit: SIDE_REMAINING_UNIT) => {
    const value = side.cylinderCount * (remainingAmount || 0) * (isBulk && unit.value === GQL.DistributorRefillThresholdUnit.Liter ? 1 : unit.factor)
    const absoluteValue = value > 0 ? value.toFixed(2) : '0'
    const absolutePercent = remainingPercent && remainingPercent > 0 ? remainingPercent : 0

    const suffix = isBulk
      ? `of ${1 * bulkTankFormat?.capacity} ${SIDE_UNIT_PROPS.liters.unit}`
      : `of ${side.cylinderCount} x ${cylinderGroup?.product?.weight || 0} ${SIDE_UNIT_PROPS.kilograms.unit}`

    return {
      title: getMonitorDataTitle(unit),
      noSensor: !isMonitored(side),
      noActiveCycle: !activeCycle,
      value: parseInt(unit.property === SIDE_UNIT_PROPS.percent.property ? absolutePercent.toString() : absoluteValue).toString(),
      suffix: unit.property === SIDE_UNIT_PROPS.percent.property ? suffix : null,
      unit: unit.unit,
    }
  }

  const cylinderProps = useMemo(() => {
    return getCylinderGroupSideProps(side)
  }, [side])

  const handleEditCount = (value: number) => {
    const updatedSides = cylinderGroup?.cylinderSides?.map(cylinderSide => {
      if (cylinderSide?.id === side?.id) {
        return { ...side, cylinderCount: Math.max(1, cylinderSide.cylinderCount + value) }
      }
      return cylinderSide
    })

    updateCylinderGroup(prev => ({ ...prev, cylinderSides: updatedSides }))
  }

  const handleEditStatus = (status: GQL.CycleState) => {
    if (isSensorOff) {
      displayToast('Sensor on that side is marked as off. Turn it on before changing side state.')
      return
    }
    const updatedSides = cylinderGroup?.cylinderSides?.map(cylinderSide => {
      const activeCycle = cylinderSide?.activeCycle
      if (cylinderSide?.id === side?.id) {
        return { ...side, activeCycle: { ...activeCycle, state: status } as GQL.CycleNode }
      }
      return cylinderSide
    })

    updateCylinderGroup(prev => ({ ...prev, cylinderSides: updatedSides }))
  }

  return (
    <SideComponent
      editSetup={editMode}
      bulk={isBulk}
      side={isLeftSide ? 'left' : 'right'}
      parallel={
        isBulk ||
        cylinderGroup.cylinderMode === GQL.CylinderGroupMode.Parallel ||
        cylinderGroup.cylinderMode === GQL.CylinderGroupMode.Insitu ||
        cylinderGroup.cylinderSides?.length === 1
      }
      countPicker={{
        subtract: { onClick: () => handleEditCount(-1) },
        add: { onClick: () => handleEditCount(1) },
        count: side.cylinderCount,
      }}
      status={{
        placeholder: t({ id: 'common.no-status' }),
        value: isSensorOff ? t({ id: 'common.no-status' }) : activeCycle?.state || GQL.CycleState.Unknown,
        onChange: (status: GQL.CycleState) => {
          handleEditStatus(status)
        },
        children: statusOptions.map((statusOption, index) => <Select.Option key={index} value={statusOption.value} children={statusOption.label} />),
      }}
      remainingPercentage={{ ...getRemainingProps({ ...SIDE_UNIT_PROPS.percent }) }}
      remainingLiters={{ ...getRemainingProps({ ...SIDE_UNIT_PROPS.liters }), ...getThresholdProps({ ...SIDE_UNIT_PROPS.liters }, cylinderGroup.threshold) }}
      remainingGasWeight={{
        ...getRemainingProps({ ...SIDE_UNIT_PROPS.kilograms }),
        ...getThresholdProps({ ...SIDE_UNIT_PROPS.kilograms }, cylinderGroup.threshold),
      }}
      cylinders={{
        cylinderGroup: cylinderGroup,
        side: {
          ...cylinderProps,
        },
        threshold: cylinderGroup.threshold,
        activeCycle: activeCycle,
        showLastTransmission: cylinderProps.monitored,
        labelLastTransmission: lastTransmissionTimeAgo,
        lastTransmission: {
          notReporting: !isReporting(side),
        },
      }}
      progressBar={{
        side: {
          ...cylinderProps,
        },
        threshold: cylinderGroup.threshold,
      }}
    />
  )
}
