import * as GQL from 'generated/graphql'

export type PaymentStatus = 'paid' | 'refunded' | 'pending' | 'cancelled' | 'failed' | 'overdue' | 'noInvoice' | 'error'

export const getOrderPaymentStatus = (cgo: GQL.CylinderGroupOrderNode): PaymentStatus => {
  if (!cgo.paymentSetting?.provider || !cgo.paymentSetting?.method) {
    return 'noInvoice'
  }
  if (cgo.paymentStatus === GQL.CylinderGroupOrderPaymentStates.NotPaid) {
    const paymentDueDate = new Date(cgo.paymentDue)
    const now = new Date()
    if (now > paymentDueDate) {
      return 'overdue'
    }
  } else if (cgo.paymentStatus === GQL.CylinderGroupOrderPaymentStates.Cancelled) {
    return 'cancelled'
  } else if (cgo.paymentStatus === GQL.CylinderGroupOrderPaymentStates.Paid) {
    return 'paid'
  } else if (cgo.paymentStatus === GQL.CylinderGroupOrderPaymentStates.Refunded) {
    return 'refunded'
  } else if (cgo.paymentStatus === GQL.CylinderGroupOrderPaymentStates.Error) {
    return 'error'
  }

  return 'pending'
}

export const getOrderMethodForOrder = (order: GQL.CylinderGroupOrderNode) => {
  if (order.automatic) {
    return GQL.CustomerOrderMethod.Auto
  } else if (order.orderedByType === 'clients.customercontact') {
    return GQL.CustomerOrderMethod.App
  }
  return GQL.CustomerOrderMethod.Phone
}

export const getAlerts = (cylinderGroupOrder: GQL.CylinderGroupOrderNode): ('contact' | 'payment' | 'deliveryWindow' | 'freeze' | 'freezeout')[] => {
  const alerts: ('contact' | 'payment' | 'deliveryWindow' | 'freeze' | 'freezeout')[] = []
  const isFreezing = !cylinderGroupOrder.cylinderGroup.cylinderSides
    ?.filter(side => side?.activeCycle !== null)
    ?.every(side => side?.activeCycle?.isFreezing === false)
  const activeContacts = cylinderGroupOrder.cylinderGroup.customer.contacts
    ? cylinderGroupOrder.cylinderGroup.customer.contacts.filter(contact => !contact?.isDeleted)
    : undefined

  if (!activeContacts || activeContacts.length === 0) {
    alerts.push('contact')
  }

  if (
    cylinderGroupOrder.paymentStatus === GQL.CylinderGroupOrderPaymentStates.NotPaid &&
    !cylinderGroupOrder.paymentPaid &&
    new Date(cylinderGroupOrder.paymentDue) < new Date()
  ) {
    alerts.push('payment')
  }

  if (!cylinderGroupOrder.deliveredAt && new Date(cylinderGroupOrder.lastPossibleDelivery) < new Date()) {
    alerts.push('deliveryWindow')
  }

  if (isFreezing) {
    alerts.push('freezeout')
  } else if (cylinderGroupOrder.cylinderGroup.customer.temperatureGroup?.isFrozen) {
    alerts.push('freeze')
  }

  return alerts
}

export const getCylinderGroupAlerts = (cylinderGroup: GQL.CylinderGroupNode) => {
  const alerts: ('contact' | 'freeze' | 'freezeout')[] = []
  const isFreezing = !cylinderGroup.cylinderSides?.filter(side => side?.activeCycle !== null)?.every(side => side?.activeCycle?.isFreezing === false)
  const activeContacts = cylinderGroup.customer.contacts ? cylinderGroup.customer.contacts.filter(contact => !contact?.isDeleted) : undefined

  if (!activeContacts || activeContacts.length === 0) {
    alerts.push('contact')
  }
  if (isFreezing) {
    alerts.push('freezeout')
  } else if (cylinderGroup.customer.temperatureGroup?.isFrozen) {
    alerts.push('freeze')
  }

  return alerts
}

export const isWaitingForPayment = (cylinderGroupOrder: GQL.CylinderGroupOrderNode) => {
  return (
    cylinderGroupOrder.paymentSetting?.method === GQL.PaymentMethods.InAdvance &&
    cylinderGroupOrder.paymentStatus === GQL.CylinderGroupOrderPaymentStates.NotPaid
  )
}
