import React from 'react'
import * as GQL from 'generated/graphql'
import ModalConfirm from 'plasmic/ModalConfirm'
import Modal from 'components/Modal/Modal'
import { useIntl } from 'react-intl'
import { displayToast } from 'util/toasts'

interface Props {
  isOpen: boolean
  onClose: () => void
  geofence: GQL.GeoFenceNode | null
}

const ModalDeleteGeofence: React.FC<Props> = props => {
  const intl = useIntl()
  const t = intl.formatMessage

  const [deleteGeofence, { loading: loadingDeleteGeofence }] = GQL.useDeleteGeofence({
    refetchQueries: ['AllGeofences'],
    onCompleted: data => {
      if (!data.deleteGeofence?.ok) return displayToast(t({ id: 'settings.geofences.delete.error' }), 'error')
      displayToast(t({ id: 'settings.geofences.delete.success' }), 'success')
      props.onClose()
    },
    onError: () => {
      displayToast(t({ id: 'settings.geofences.delete.error' }), 'error')
    },
  })

  const handleSubmit = () => {
    if (loadingDeleteGeofence) return
    if (!props.geofence) return displayToast(t({ id: 'settings.geofences.delete.error.no-geofence' }), 'error')
    deleteGeofence({ variables: { id: props.geofence.id } })
  }

  return (
    <Modal isOpen={props.isOpen} onRequestClose={props.onClose} stripped>
      <ModalConfirm
        title={t({ id: 'settings.geofences.delete' }, { geofence: props.geofence?.name })}
        description={t({ id: 'settings.geofences.delete.description' }, { geofence: props.geofence?.name })}
        btnConfirm={{
          label: t({ id: 'common.confirm' }),
          onClick: () => handleSubmit(),
        }}
        btnCancel={{
          label: t({ id: 'common.cancel' }),
          onClick: () => props.onClose(),
        }}
        btnClose={{
          onClick: () => props.onClose(),
        }}
      />
    </Modal>
  )
}

export default ModalDeleteGeofence
