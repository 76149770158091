import React, { useCallback, useRef, useState } from 'react'
import { useIntl } from 'react-intl'

import * as GQL from 'generated/graphql'
import { formatProductType } from '../util'
import { useAppContext, useClickOutside } from 'util/hooks'
import CornerLoader from 'components/Loader/CornerLoader'
import ProductModal from '../components/ProductModal'
import ChangeProductStatusModal from '../components/ChangeProductStatusModal'
import ChangeProductVisibilityModal from '../components/ChangeProductVisibilityModal'
import { formatPrice, formatTaxRate } from 'modules/orders/util'

import SettingsContentWrapper from 'plasmic/SettingsContentWrapper'
import ProductCatalogProduct from 'plasmic/ProductCatalogProduct'
import ActionDropdownLine from '../../../plasmic/ActionDropdownLine'
import LabelStatusChip from 'plasmic/LabelStatusChip'
import ProductsSettings from 'plasmic/ProductsSettings'
import ButtonFill from 'plasmic/ButtonFill'
import ValueChip from 'plasmic/ValueChip'

const SettingsProducts: React.FunctionComponent = () => {
  const [actionsOpen, setActionsOpen] = React.useState('')
  const [createProductOpen, setCreateProductOpen] = useState(false)
  const [editProduct, setEditProduct] = useState<GQL.ProductNode | undefined>()
  const [changeProductStatus, setChangeProductStatus] = useState<GQL.ProductNode>()
  const [changeProductVisibility, setChangeProductVisibility] = useState<GQL.ProductNode>()
  const { appContext } = useAppContext()

  const ref = useRef(null)
  const intl = useIntl()
  const t = intl.formatMessage

  useClickOutside(ref, () => setActionsOpen(''), false, true)

  const { loading, data } = GQL.useAllProducts({ variables: { service: false } })

  const availableProducts = data?.availableProducts?.edges.map(product => product?.node as GQL.ProductNode)
  const unavailableProducts = data?.unavailableProducts?.edges.map(product => product?.node as GQL.ProductNode)

  const contextDistributor = appContext.distributor

  const getProductStatus = useCallback(
    (product: GQL.ProductNode) => {
      if (product.addAutomatically) return 'autoAdd'
      if (!product.visibleToCustomer) return t({ id: 'common.hidden' }).toLowerCase()
      return t({ id: 'common.visible' }).toLowerCase()
    },
    [t]
  )

  return (
    <SettingsContentWrapper
      settingsTitle={t({ id: 'settings.products' })}
      headerButtons={<ButtonFill rounded color={'blue'} label={t({ id: 'settings.products.create' })} onClick={() => setCreateProductOpen(true)} />}
      content={
        <>
          <ProductsSettings
            productsActive={
              availableProducts && availableProducts.length > 0 ? (
                availableProducts?.map(product => (
                  <ProductCatalogProduct
                    key={product.id}
                    bulk={product.storageType === GQL.ProductStorageType.Bulk}
                    data-testid='product'
                    title={product.displayName}
                    descriptionPreview={product.description}
                    description={product.description || '-'}
                    labelId={t({ id: 'settings.products.product-id' })}
                    productId={product.customId || '-'}
                    gasWeight={parseFloat(product.weight) + ' kg'}
                    productType={formatProductType(product.type)}
                    height={product.height ? `${product.height} mm` : '-'}
                    regulator={product.regulator || '-'}
                    btnMoreActions={{
                      open: actionsOpen === product.id,
                      ref: actionsOpen === product.id ? ref : null,
                      onClick: () => setActionsOpen(product.id === actionsOpen ? '' : product.id),
                      actionDropdown: (
                        <>
                          <ActionDropdownLine children={t({ id: 'settings.products.edit' })} onClick={() => setEditProduct(product)} />
                          <ActionDropdownLine
                            children={t({ id: 'settings.products.change.status.inactive' })}
                            onClick={() => setChangeProductStatus(product)}
                          />
                          <ActionDropdownLine
                            children={t({
                              id: product.visibleToCustomer
                                ? t({ id: 'settings.products.change.visibility.hidden' })
                                : t({ id: 'settings.products.change.visibility.visible' }),
                            })}
                            onClick={() => setChangeProductVisibility(product)}
                          />
                        </>
                      ),
                    }}
                    fullWeight={product.fullWeight ? `${product.fullWeight} kg` : '-'}
                    tareWeight={product.tareWeight ? `${product.tareWeight} kg` : '-'}
                    diameter={product.tareWeight ? `${product.tareWeight} mm` : '-'}
                    image={product?.image?.image ? product.image.image : undefined}
                    noImage={!product?.image}
                    productStatus={{
                      props: {
                        status: getProductStatus(product),
                      },
                    }}
                    depots={
                      product.inDepots && product.inDepots.length > 0
                        ? product.inDepots.map(depot => <ValueChip key={depot?.id} title={depot?.name} />)
                        : 'All depots'
                    }
                    exchangeProduct={product.storageType !== GQL.ProductStorageType.Bulk && (product.exchangeProduct || false)}
                    price={formatPrice(
                      product.productPrices?.find(productPrice => productPrice?.category.default === true)?.price || 0,
                      contextDistributor?.defaultCurrency || 'USD',
                      product.storageType === GQL.ProductStorageType.Bulk ? 'Liter' : undefined
                    )}
                    basePrice={formatPrice(
                      product.productPrices?.find(productPrice => productPrice?.category.default === true)?.price || 0,
                      contextDistributor?.defaultCurrency || 'USD',
                      product.storageType === GQL.ProductStorageType.Bulk ? 'Liter' : undefined
                    )}
                    basePriceTax={`${formatTaxRate(product.taxRate, contextDistributor)}`}
                    cylinderDepositPriceTax={`${formatTaxRate(product.depositTaxRate, contextDistributor)}`}
                    cylinderDepositPrice={formatPrice(
                      product.productDepositPrices?.find(productDepositPrice => productDepositPrice?.category.default === true)?.price || 0,
                      contextDistributor?.defaultCurrency || 'USD',
                      product.storageType === GQL.ProductStorageType.Bulk ? 'Liter' : undefined
                    )}
                  />
                ))
              ) : (
                <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                  <LabelStatusChip title={t({ id: 'common.no-products' })} icon={'info'} />
                </div>
              )
            }
            productsInactive={
              unavailableProducts && unavailableProducts.length > 0 ? (
                unavailableProducts?.map(product => (
                  <ProductCatalogProduct
                    data-testid='inactive-product'
                    key={product.id}
                    bulk={product.storageType === GQL.ProductStorageType.Bulk}
                    title={product.displayName}
                    descriptionPreview={product.description}
                    description={product.description || '-'}
                    productId={product.customId || '-'}
                    labelId={t({ id: 'settings.products.product-id' })}
                    gasWeight={parseFloat(product.weight) + ' kg'}
                    productType={product.type.toLowerCase().capitalizeFirst()}
                    height={product.height || '-'}
                    regulator={product.regulator || '-'}
                    btnMoreActions={{
                      props: {
                        ref: actionsOpen === product.id ? ref : null,
                        open: actionsOpen === product.id,
                        onClick: () => setActionsOpen(product.id === actionsOpen ? '' : product.id),
                        actionDropdown: (
                          <>
                            <ActionDropdownLine children={t({ id: 'settings.products.edit' })} onClick={() => setEditProduct(product)} />
                            <ActionDropdownLine
                              children={t({ id: 'settings.products.change.status.active' })}
                              onClick={() => setChangeProductStatus(product)}
                            />
                            <ActionDropdownLine
                              children={t({
                                id: product.visibleToCustomer
                                  ? t({ id: 'settings.products.change.visibility.hidden' })
                                  : t({ id: 'settings.products.change.visibility.visible' }),
                              })}
                              onClick={() => setChangeProductVisibility(product)}
                            />
                          </>
                        ),
                      },
                    }}
                    depots={
                      product.inDepots && product.inDepots.length > 0
                        ? product.inDepots.map(depot => <ValueChip key={depot?.id} title={depot?.name} />)
                        : 'All depots'
                    }
                    fullWeight={product.fullWeight || '-'}
                    tareWeight={product.tareWeight || '-'}
                    diameter={product.diameter || '-'}
                    image={product?.image?.image ? product.image.image : undefined}
                    noImage={!product?.image}
                    productStatus={{
                      props: {
                        status: GQL.ProductStatus.Inactive.toLowerCase(),
                      },
                    }}
                    exchangeProduct={product.storageType !== GQL.ProductStorageType.Bulk && (product.exchangeProduct || false)}
                    price={formatPrice(
                      product.productPrices?.find(productPrice => productPrice?.category.default === true)?.price || 0,
                      contextDistributor?.defaultCurrency || 'USD',
                      product.storageType === GQL.ProductStorageType.Bulk ? 'Liter' : undefined
                    )}
                    basePrice={formatPrice(
                      product.productPrices?.find(productPrice => productPrice?.category.default === true)?.price || 0,
                      contextDistributor?.defaultCurrency || 'USD',
                      product.storageType === GQL.ProductStorageType.Bulk ? 'Liter' : undefined
                    )}
                    basePriceTax={`${parseFloat(product.taxRate)} %`}
                    cylinderDepositPriceTax={`${parseFloat(product.depositTaxRate)} %`}
                    cylinderDepositPrice={formatPrice(
                      product.productDepositPrices?.find(productDepositPrice => productDepositPrice?.category.default === true)?.price || 0,
                      contextDistributor?.defaultCurrency || 'USD',
                      product.storageType === GQL.ProductStorageType.Bulk ? 'Liter' : undefined
                    )}
                  />
                ))
              ) : (
                <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                  <LabelStatusChip title={t({ id: 'common.no-products' })} icon={'info'} />
                </div>
              )
            }
            labelInactiveProducts={t({ id: 'common.inactive' })}
          />
          {createProductOpen && <ProductModal open={createProductOpen} onClose={() => setCreateProductOpen(false)} />}
          {editProduct && <ProductModal open={!!editProduct} onClose={() => setEditProduct(undefined)} product={editProduct} />}
          {changeProductStatus && (
            <ChangeProductStatusModal open={!!changeProductStatus} onClose={() => setChangeProductStatus(undefined)} product={changeProductStatus} />
          )}
          {changeProductVisibility && (
            <ChangeProductVisibilityModal
              open={!!changeProductVisibility}
              onClose={() => setChangeProductVisibility(undefined)}
              product={changeProductVisibility}
            />
          )}
          {loading && <CornerLoader size={50} />}
        </>
      }
    />
  )
}

export default SettingsProducts
