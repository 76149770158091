// This is a skeleton starter React component generated by Plasmic.
// This file is owned by you, feel free to edit as you see fit.
import * as React from 'react'
import QRCode from 'react-qr-code'
import { HTMLElementRefOf } from '@plasmicapp/react-web'
import { PlasmicCustomerAppSettings, DefaultCustomerAppSettingsProps } from './plasmic/solace_components/PlasmicCustomerAppSettings'
import AppContext from 'context/AppContext'
import { isLocal, getUrl } from 'util/env'
import { displayToast } from 'util/toasts'

import * as GQL from 'generated/graphql'
import { useAppContext } from 'util/hooks'
import { useIntl } from 'react-intl'
// Your component props start with props for variants and slots you defined
// in Plasmic, but you can add more here, like event handlers that you can
// attach to named nodes in your component.
//
// If you don't want to expose certain variants or slots as a prop, you can use
// Omit to hide them:
//
// interface CustomerAppSettingsProps extends Omit<DefaultCustomerAppSettingsProps, "hideProps1"|"hideProp2"> {
//   // etc.
// }
//
// You can also stop extending from DefaultCustomerAppSettingsProps altogether and have
// total control over the props for your component.
export interface CustomerAppSettingsProps extends DefaultCustomerAppSettingsProps {}

type InputField = 'allowPublicAccess' | 'allowAnonymousOrders' | 'requireVerifiedCustomerLocations' | 'automaticallyApproveSelfSignups'

function CustomerAppSettings_(props: CustomerAppSettingsProps, ref: HTMLElementRefOf<'div'>) {
  // Use PlasmicCustomerAppSettings to render this component as it was
  // designed in Plasmic, by activating the appropriate variants,
  // attaching the appropriate event handlers, etc.  You
  // can also install whatever React hooks you need here to manage state or
  // fetch data.
  //
  // Props you can pass into PlasmicCustomerAppSettings are:
  // 1. Variants you want to activate,
  // 2. Contents for slots you want to fill,
  // 3. Overrides for any named node in the component to attach behavior and data,
  // 4. Props to set on the root node.
  //
  // By default, we are just piping all CustomerAppSettingsProps here, but feel free
  // to do whatever works for you.

  const intl = useIntl()
  const t = intl.formatMessage
  const { appContext, setAppContext } = useAppContext()
  const distributor = appContext.distributor
  const qrRef = React.useRef<HTMLDivElement>(null)
  const [showProductCatalogBeforeLogin, setShowProductCatalogBeforeLogin] = React.useState(distributor?.allowPublicAccess || false)
  const [switchRequireZip, setSwitchRequireZip] = React.useState(distributor?.requireVerifiedCustomerLocations || false)
  const [switchAllowAnonymousOrders, setSwitchAllowAnonymousOrders] = React.useState(distributor?.allowAnonymousOrders || false)
  const [switchAutomaticallyApproveSelfSignups, setSwitchAutomaticallyApproveSelfSignups] = React.useState(
    distributor?.automaticallyApproveSelfSignups || false
  )

  const [patchDistributor, { loading: loadingPatchDistributor }] = GQL.usePatchDistributor({
    onCompleted: data => {
      if (!data.patchDistributor?.distributor) {
        return displayToast(t({ id: 'common.error-occured' }), 'error')
      }
      const distributor = data.patchDistributor?.distributor as GQL.DistributorNode
      setAppContext(context => ({
        ...context,
        distributor: distributor,
      }))
      displayToast(t({ id: 'settings.update-success' }), 'success')
      setShowProductCatalogBeforeLogin(distributor.allowPublicAccess)
      setSwitchRequireZip(distributor.requireVerifiedCustomerLocations)
      setSwitchAutomaticallyApproveSelfSignups(distributor.automaticallyApproveSelfSignups)
      setSwitchAllowAnonymousOrders(distributor.allowAnonymousOrders)
    },
    onError: error => {
      displayToast(error.message, 'error')
    },
  })

  const baseAppUrl = React.useMemo(() => {
    let protocol = 'https://'
    if (isLocal()) protocol = 'http://'
    if (!distributor || !distributor.appHost) {
      if (isLocal()) return `${protocol}smartcylinders.customer.systems.localhost`
      return `${protocol}smartcylinders.customer.${getUrl()}`
    }
    return `${protocol}${distributor?.appHost}`
  }, [distributor?.appHost])

  const handleDownloadQRCode = React.useCallback(() => {
    if (qrRef.current) {
      const svg = qrRef.current.querySelector('svg')
      if (!svg) {
        return displayToast('Failed to select the QR code to download', 'error')
      }
      const svgData = new XMLSerializer().serializeToString(svg)
      const svgBlob = new Blob([svgData], { type: 'image/svg+xml;charset=utf-8' })
      const svgUrl = URL.createObjectURL(svgBlob)
      const downloadLink = document.createElement('a')
      downloadLink.href = svgUrl
      downloadLink.download = 'qr-code.svg'
      document.body.appendChild(downloadLink)
      downloadLink.click()
      document.body.removeChild(downloadLink)
    }
  }, [qrRef.current])

  const handleSwitchChange = (value: boolean, setState: React.Dispatch<React.SetStateAction<boolean>>, inputField: InputField) => {
    if (!distributor || loadingPatchDistributor || value === distributor[inputField]) return
    setState(value)
    patchDistributor({
      variables: {
        id: distributor.id,
        input: {
          [inputField]: value,
        },
      },
    })
  }

  return (
    <PlasmicCustomerAppSettings
      root={{ ref }}
      {...props}
      inputUrl={{
        undefinedInput: {
          value: baseAppUrl,
          disabled: true,
        },
      }}
      btnResetUrl={{
        onClick: () => console.log('Not implemented'),
      }}
      qrCodeWrapper={{
        ref: qrRef,
      }}
      qrCode={<QRCode value={baseAppUrl} level='Q' />}
      btnDownloadQrSvg={{
        onClick: () => handleDownloadQRCode(),
      }}
      switchAllowGuestCheckout={{
        isChecked: switchAllowAnonymousOrders,
        name: 'allowGuestCheckout',
        onChange: event => handleSwitchChange(event, setSwitchAllowAnonymousOrders, 'allowAnonymousOrders'),
      }}
      switchAutoApproveSelfSignups={{
        isChecked: switchAutomaticallyApproveSelfSignups,
        name: 'autoApproveSelfSignups',
        onChange: event => handleSwitchChange(event, setSwitchAutomaticallyApproveSelfSignups, 'automaticallyApproveSelfSignups'),
      }}
      switchShowProductCatalogBeforeLogin={{
        isChecked: showProductCatalogBeforeLogin,
        name: 'showProductCatalogBeforeLogin',
        onChange: event => handleSwitchChange(event, setShowProductCatalogBeforeLogin, 'allowPublicAccess'),
      }}
      switchRequireZip={{
        isChecked: switchRequireZip,
        name: 'requireZip',
        onChange: event => handleSwitchChange(event, setSwitchRequireZip, 'requireVerifiedCustomerLocations'),
      }}
    />
  )
}

const CustomerAppSettings = React.forwardRef(CustomerAppSettings_)
export default CustomerAppSettings
