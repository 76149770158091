// @ts-nocheck
/* eslint-disable */
/* tslint:disable */
/* prettier-ignore-start */
import React from "react";
import { classNames } from "@plasmicapp/react-web";

export type BulkFootsvgIconProps = React.ComponentProps<"svg"> & {
  title?: string;
};

export function BulkFootsvgIcon(props: BulkFootsvgIconProps) {
  const { className, style, title, ...restProps } = props;
  return (
    <svg
      xmlns={"http://www.w3.org/2000/svg"}
      version={"1.1"}
      x={"0"}
      y={"0"}
      viewBox={"0 0 27.577 9.736"}
      xmlSpace={"preserve"}
      height={"1em"}
      className={classNames("plasmic-default__svg", className)}
      style={style}
      {...restProps}
    >
      {title && <title>{title}</title>}

      <path
        fill={"currentColor"}
        d={
          "M0 0h27.577v4.799a4.937 4.937 0 01-4.937 4.937H4.937A4.937 4.937 0 010 4.799V0z"
        }
      ></path>
    </svg>
  );
}

export default BulkFootsvgIcon;
/* prettier-ignore-end */
