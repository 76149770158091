// @ts-nocheck
/* eslint-disable */
/* tslint:disable */
/* prettier-ignore-start */
import React from "react";
import { classNames } from "@plasmicapp/react-web";

export type GeofencesvgIconProps = React.ComponentProps<"svg"> & {
  title?: string;
};

export function GeofencesvgIcon(props: GeofencesvgIconProps) {
  const { className, style, title, ...restProps } = props;
  return (
    <svg
      xmlns={"http://www.w3.org/2000/svg"}
      version={"1.1"}
      x={"0"}
      y={"0"}
      viewBox={"0 0 227 227"}
      xmlSpace={"preserve"}
      height={"1em"}
      style={{
        fill: "currentcolor",

        ...(style || {}),
      }}
      className={classNames("plasmic-default__svg", className)}
      {...restProps}
    >
      {title && <title>{title}</title>}

      <path
        d={
          "M113.5.075c-15.131 0-27.441 12.311-27.441 27.442s12.31 27.441 27.441 27.441 27.442-12.31 27.442-27.441S128.631.075 113.5.075zm0 171.967c-15.131 0-27.441 12.311-27.441 27.441s12.31 27.441 27.441 27.441 27.442-12.311 27.442-27.441-12.311-27.441-27.442-27.441z"
        }
      ></path>

      <circle cx={"27.516"} cy={"113.5"} r={"27.441"}></circle>

      <circle cx={"199.484"} cy={"113.5"} r={"27.441"}></circle>

      <path
        d={
          "M154.802 47.369a78.797 78.797 0 0124.819 24.819 7.994 7.994 0 0011.026 2.534 8 8 0 002.534-11.026c-7.53-12.024-17.865-22.359-29.888-29.888a8 8 0 10-8.491 13.561zM63.674 33.808c-12.023 7.53-22.358 17.865-29.887 29.888a8 8 0 002.535 11.026 7.995 7.995 0 0011.026-2.534 78.797 78.797 0 0124.819-24.819 8 8 0 002.534-11.026 8 8 0 00-11.027-2.535zm8.492 145.835a78.797 78.797 0 01-24.819-24.819 8 8 0 00-11.026-2.534 8 8 0 00-2.534 11.026c7.529 12.023 17.865 22.358 29.888 29.888a7.993 7.993 0 0011.026-2.534 8.001 8.001 0 00-2.535-11.027zm118.482-27.354a8.001 8.001 0 00-11.026 2.534 78.807 78.807 0 01-24.819 24.819 8 8 0 008.492 13.56c12.022-7.529 22.357-17.864 29.888-29.888a8 8 0 00-2.535-11.025z"
        }
      ></path>
    </svg>
  );
}

export default GeofencesvgIcon;
/* prettier-ignore-end */
