import React, { useEffect, useMemo, useState } from 'react'
import Select from 'react-select'

import * as GQL from 'generated/graphql'
import ModalAdjustStock from 'plasmic/ModalAdjustStock'
import { Control, Option, reactSelectStyles } from 'plasmic/StyledReactSelect'
import Modal from 'components/Modal/Modal'
import { displayToast } from 'util/toasts'
import { AdjustStock } from '../types'
import { formatInventoryAdjustmentReason } from '../utils'
import Loader from 'components/Loader'
import { SelectOption } from 'types/props'

interface Props {
  isOpen: boolean
  onClose: () => void
  adjustStockInfo: AdjustStock
}

const AdjustStockModal: React.FC<Props> = props => {
  const SELECT_OPTIONS_SIZE = 150
  const { product, inventory, stockState } = props.adjustStockInfo
  const [newQuantity, setNewQuantity] = useState('0')
  const [description, setDescription] = useState('')
  const [selectedInventoryId, setSelectedInventoryId] = useState<string | null>(inventory?.id || null)
  const [selectedInventory, setSelectedInventory] = useState<GQL.InventoryNode | null>(inventory || null)
  const [selectedReason, setSelectedReason] = useState(GQL.InventoryAdjustmentReason.ManualCorrection)
  const { data: dataInventories } = GQL.useAllInventories({
    variables: { first: SELECT_OPTIONS_SIZE, productId: product.id, forDepot: true },
  })
  const inventories = useMemo(() => dataInventories?.allInventories?.edges.map(edge => edge?.node as GQL.InventoryNode) || [], [dataInventories])

  const currentQuantity = useMemo(() => {
    if (props.adjustStockInfo.currentQuantity === null || props.adjustStockInfo.currentQuantity === undefined) return selectedInventory?.totalAmountOfCylinders
    return props.adjustStockInfo.currentQuantity
  }, [props.adjustStockInfo?.currentQuantity, selectedInventory])

  const [patchInventory, { loading: patchInventoryLoading }] = GQL.usePatchInventory({
    onCompleted: data => {
      if (!data.patchInventory?.ok) {
        return displayToast('Failed to update stock', 'error')
      }
      displayToast('Stock updated successfully', 'success')
      return props.onClose()
    },
    onError: error => {
      displayToast('Failed to update stock', 'error')
    },
  })

  const handleOnSubmit = () => {
    if (patchInventoryLoading || !selectedInventory) return
    patchInventory({
      variables: {
        inventoryId: selectedInventory.id,
        input: {
          stock: {
            state: stockState,
            amount: parseInt(newQuantity),
          },
          total: props.adjustStockInfo.adjustTotal,
          reason: selectedReason,
          description: description,
        },
      },
    })
  }

  useEffect(() => {
    if (inventories.length === 0 || selectedInventory?.id === selectedInventoryId) return
    if (!selectedInventoryId) {
      return setSelectedInventoryId(inventories[0].id)
    }
    setSelectedInventory(inventories.find(inventory => inventory.id === selectedInventoryId) || null)
  }, [inventories, selectedInventory, selectedInventoryId])

  return (
    <Modal isOpen={props.isOpen} onRequestClose={props.onClose} stripped>
      <ModalAdjustStock
        noImage={!product.image?.image}
        productImage={product.image?.image || undefined}
        productTitle={product.displayName}
        selectDepotDiv={
          <Select
            options={inventories.map(
              inventory =>
                ({
                  value: inventory.id,
                  label: inventory.belongsTo?.__typename === 'CylinderGroupNode' ? inventory.belongsTo.customer.name : inventory.belongsTo?.name,
                }) as SelectOption<GQL.InventoryAdjustmentReason>
            )}
            value={
              {
                value: selectedInventory?.id || '',
                label:
                  selectedInventory?.belongsTo?.__typename === 'CylinderGroupNode'
                    ? selectedInventory.belongsTo.customer.name
                    : selectedInventory?.belongsTo?.name || 'Select a depot...',
              } as SelectOption<GQL.InventoryAdjustmentReason>
            }
            onChange={(event: any) => setSelectedInventoryId(event.value)}
            components={{ Option, Control }}
            styles={reactSelectStyles}
            isSearchable={true}
            isMulti={false}
          />
        }
        selectReasonDiv={
          <Select
            options={Object.values(GQL.InventoryAdjustmentReason).map(
              value =>
                ({
                  value: value,
                  label: formatInventoryAdjustmentReason(value),
                }) as SelectOption<GQL.InventoryAdjustmentReason>
            )}
            value={
              {
                value: selectedReason || '',
                label: formatInventoryAdjustmentReason(selectedReason) || 'Select a reason...',
              } as SelectOption<GQL.InventoryAdjustmentReason>
            }
            onChange={(event: any) => setSelectedReason(event.value)}
            components={{ Option, Control }}
            styles={reactSelectStyles}
            isSearchable={true}
            isMulti={false}
          />
        }
        inputNewQuantity={{
          undefinedInput: {
            value: newQuantity,
            name: 'newQuantity',
            onChange: (event: React.ChangeEvent<HTMLInputElement>) => setNewQuantity(event.target.value),
          },
        }}
        inputDescription={{
          props: { value: description, name: 'description', onChange: (event: React.ChangeEvent<HTMLTextAreaElement>) => setDescription(event.target.value) },
        }}
        inputCurrentQuantity={{ undefinedInput: { value: currentQuantity?.toString() } }}
        btnClose={{ onClick: props.onClose }}
        btnCancel={{ onClick: props.onClose }}
        btnSave={{ onClick: handleOnSubmit, label: patchInventoryLoading ? <Loader size={32} color='white' /> : 'Adjust stock' }}
      />
    </Modal>
  )
}

export { AdjustStockModal }
