import React, { useMemo } from 'react'
import * as GQL from 'generated/graphql'
import SettingChangesBar from 'plasmic/SettingChangesBar'
import { CoordinatePair, PolygonPath } from '../types'
import { displayToast } from 'util/toasts'
import { useIntl } from 'react-intl'
import { closePolygon } from '../util'

enum ChangeStatus {
  CHANGED = 'changed',
  PUBLISHED = 'published',
}

interface Props {
  polygons: { [key: string]: PolygonPath }
  geofences: GQL.GeoFenceNode[]
  discardChanges: (geofences: GQL.GeoFenceNode[]) => void
}

const GeofenceChangesBar: React.FC<Props> = props => {
  const intl = useIntl()
  const t = intl.formatMessage

  const [bulkPatchGeofences, { loading: loadingPatchGeofence }] = GQL.useBulkPatchGeofences({
    refetchQueries: ['AllGeofences'],
    onCompleted: data => {
      if (!data.bulkPatchGeofences?.ok) return displayToast(t({ id: 'settings.geofences.patch.error' }), 'error')
      displayToast(t({ id: 'settings.geofences.patch.success' }), 'success')
    },
    onError: () => {
      displayToast(t({ id: 'settings.geofences.patch.error' }), 'error')
    },
  })

  const differences = useMemo(() => {
    if (Object.keys(props.polygons).length === 0 || props.geofences.length === 0) return null
    const output: { [key: string]: CoordinatePair[] } = {}
    for (const geofence of props.geofences) {
      const polygon = props.polygons[geofence.id]
      if (!polygon) continue
      const geofencePath: CoordinatePair[] = geofence.polygon.coordinates[0].map((coordinate: number[]) => ({ lng: coordinate[0], lat: coordinate[1] }))
      if (polygon.path.filter(a => !geofencePath?.some(b => a.lat === b.lat && a.lng === b.lng)).length > 0) {
        output[geofence.id] = polygon.path
      }
    }
    return output
  }, [props.polygons, props.geofences])

  const handleSubmit = () => {
    if (!differences || Object.keys(differences).length === 0 || loadingPatchGeofence) return
    const polygon = closePolygon(differences)
    if (!polygon) return
    bulkPatchGeofences({
      variables: {
        input: Object.entries(polygon).map(([key, value]) => ({
          id: key,
          polygon: `SRID=4326;POLYGON((${value.map(coordinates => `${coordinates.lng} ${coordinates.lat}`)}))`,
        })),
      },
    })
  }

  return (
    <SettingChangesBar
      status={differences !== null ? (Object.keys(differences).length > 0 ? ChangeStatus.CHANGED : ChangeStatus.PUBLISHED) : undefined}
      btnDiscardChanges={{ onClick: () => props.discardChanges(props.geofences) }}
      btnPublishChanges={{ loading: loadingPatchGeofence, disabled: loadingPatchGeofence, onClick: () => handleSubmit() }}
    />
  )
}

export default GeofenceChangesBar
