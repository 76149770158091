import React, { createContext, useEffect, useState } from 'react'

export enum CustomerTableColumn {
  CUSTOMER_ID = 'customerId',
  ADDRESS = 'address',
  CYLINDER_SETUP = 'cylinderSetup',
  ESTIMATED_EMPTY = 'estimatedEmpty',
  TAGS = 'tags',
  CHART = 'chart',
  PAYMENT_OPTIONS = 'paymentOptions',
  PRICING_LIST = 'pricingList',
  CUSTOMER_TYPE = 'customerType',
  ORDER_METHOD = 'orderMethod',
}

export enum AllOrdersTableColumn {
  CUSTOMER_ID = 'customerId',
  PRODUCT = 'product',
  ADDRESS = 'address',
  PRICE = 'price',
  PAYMENT = 'payment',
  STATUS = 'status',
  COMMENT = 'comment',
  ORDERED_BY = 'orderedBy',
  ORDER_ID = 'orderId',
  DATE_ORDERED = 'dateOrdered',
  DATE_DELIVERED = 'dateDelivered',
  VIEW_IMAGES = 'viewImages',
  DOWNLOAD_ORDER = 'downloadOrder',
}

const VISIBLE_CUSTOMER_COLUMNS_LOCALSTORAGE_KEY = 'visibleCustomerColumns'
const VISIBLE_ORDERS_ALL_COLUMNS_LOCALSTORAGE_KEY = 'visibleAllOrdersColumns'

interface TableContext {
  visibleCustomerTableColumns: CustomerTableColumn[]
  setVisibleCustomerTableColumns: (newVisibleCustomerTableColumns: CustomerTableColumn[]) => void
  visibleOrdersAllTableColumns: AllOrdersTableColumn[]
  setVisibleOrdersAllTableColumns: (newVisibleOrdersAllTableColumns: AllOrdersTableColumn[]) => void
}

const TableContext = createContext<TableContext>({
  visibleCustomerTableColumns: [],
  setVisibleCustomerTableColumns: () => {},
  visibleOrdersAllTableColumns: [],
  setVisibleOrdersAllTableColumns: () => {},
})

const checkVisibleColumns = <T extends string>(columns: T[], enumValues: Record<string, T>): T[] => {
  return columns.filter(column => Object.values(enumValues).includes(column))
}

export const generateColumnFilterClass = (cell: string, array: string[]): string => {
  return `col-transition ${!array.includes(cell) ? 'col-hidden' : ''}`
}

export const TableContextProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [visibleCustomerTableColumns, setVisibleCustomerTableColumns] = useState<CustomerTableColumn[]>(
    checkVisibleColumns(
      JSON.parse(localStorage.getItem(VISIBLE_CUSTOMER_COLUMNS_LOCALSTORAGE_KEY) || JSON.stringify(Object.values(CustomerTableColumn))),
      CustomerTableColumn
    )
  )
  const [visibleOrdersAllTableColumns, setVisibleOrdersAllTableColumns] = useState<AllOrdersTableColumn[]>(
    checkVisibleColumns(
      JSON.parse(localStorage.getItem(VISIBLE_ORDERS_ALL_COLUMNS_LOCALSTORAGE_KEY) || JSON.stringify(Object.values(AllOrdersTableColumn))),
      AllOrdersTableColumn
    ) as AllOrdersTableColumn[]
  )

  useEffect(() => {
    if (visibleCustomerTableColumns) {
      localStorage.setItem(VISIBLE_CUSTOMER_COLUMNS_LOCALSTORAGE_KEY, JSON.stringify(checkVisibleColumns(visibleCustomerTableColumns, CustomerTableColumn)))
    }
  }, [visibleCustomerTableColumns])

  useEffect(() => {
    if (visibleOrdersAllTableColumns) {
      localStorage.setItem(VISIBLE_ORDERS_ALL_COLUMNS_LOCALSTORAGE_KEY, JSON.stringify(checkVisibleColumns(visibleOrdersAllTableColumns, AllOrdersTableColumn)))
    }
  }, [visibleOrdersAllTableColumns])

  const contextValue: TableContext = {
    visibleCustomerTableColumns,
    setVisibleCustomerTableColumns,
    visibleOrdersAllTableColumns,
    setVisibleOrdersAllTableColumns,
  }

  return <TableContext.Provider value={contextValue}>{children}</TableContext.Provider>
}

export default TableContext
