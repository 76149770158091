import { components, OptionProps, ControlProps, MenuListProps, IndicatorsContainerProps } from 'react-select'
import OptimizeOnsvgIcon from './plasmic/solace_components/icons/PlasmicIcon__OptimizeOnsvg'
import PlussvgIcon from './plasmic/solace_components/icons/PlasmicIcon__Plussvg'
import checkedCirclesvg from './plasmic/solace_components/images/checkedCirclesvg.svg'
import alertsvg from './plasmic/solace_components/images/alertsvg.svg'

interface CustomMenuListProps {
  onClickBtnNewCustomer?: () => void
  form?: string
}

// export const Control = ({ children, ...props }: ControlProps<any>) => {
//   const { selectProps } = props

//   return (
//     <div
//       style={{
//         display: 'flex',
//         justifyContent: 'space-between',
//         alignItems: 'center',
//         position: 'relative',
//       }}
//     >
//       <components.Control {...props}>{children}</components.Control>
//       {selectProps.value && selectProps.value.suggested && (
//         <div
//           style={{
//             position: 'absolute',
//             right: '50px',
//             display: 'flex',
//             alignItems: 'center',
//             columnGap: '6px',
//             padding: '0px 10px 0px 10px',
//             backgroundColor: '#9300E9',
//             color: '#fff',
//             fontSize: '14px',
//             borderRadius: '20px',
//             height: '28px',
//           }}
//         >
//           <OptimizeOnsvgIcon width='14px' color='#fff' />
//           Suggested
//         </div>
//       )}
//     </div>
//   )
// }

export const Control = ({ children, ...props }: ControlProps<any>) => {
  const { selectProps } = props

  return (
    <div
      style={{
        display: 'flex',
        //alignContent: "stretch",
        justifyContent: 'space-between',
        alignItems: 'center',
        position: 'relative',
        height: '100%',
      }}
    >
      <components.Control {...props}>
        {/* @ts-ignore */}
        {selectProps.imagePath && selectProps.showImage && (
          <div style={{ backgroundColor: '#fff', width: '56px', height: '56px', borderRadius: '10px', overflow: 'hidden' }}>
            {/* @ts-ignore */}
            <img alt='' src={selectProps.imagePath} width={'100%'} height={'100%'} />
          </div>
        )}
        {children}
      </components.Control>
      {selectProps.value && selectProps.value.suggested && (
        <div
          style={{
            position: 'absolute',
            right: '50px',
            display: 'flex',
            alignItems: 'center',
            columnGap: '6px',

            padding: '0px 10px 0px 10px',
            backgroundColor: '#9300E9',
            color: '#fff',
            fontSize: '14px',
            borderRadius: '20px',
            height: '28px',
          }}
        >
          <OptimizeOnsvgIcon width='14px' color='#fff' />
          Suggested
        </div>
      )}
    </div>
  )
}

export const Option = (props: OptionProps<any>) => {
  const { suggested, highlightedText } = props.data
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        backgroundColor: '#181d36',
      }}
    >
      <components.Option {...props} />
      {!!highlightedText && (
        <div
          style={{
            position: 'absolute',
            right: '10px',
            display: 'flex',
            alignItems: 'center',
            columnGap: '6px',
            padding: '0px 10px 0px 10px',
            backgroundColor: '#9300E9',
            color: '#fff',
            fontSize: '14px',
            borderRadius: '20px',
            height: '28px',
          }}
        >
          {highlightedText}
        </div>
      )}
      {suggested === true && (
        <div
          style={{
            position: 'absolute',
            right: '10px',
            display: 'flex',
            alignItems: 'center',
            columnGap: '6px',
            padding: '0px 10px 0px 10px',
            backgroundColor: '#9300E9',
            color: '#fff',
            fontSize: '14px',
            borderRadius: '20px',
            height: '28px',
          }}
        >
          <OptimizeOnsvgIcon width='14px' color='#fff' />
          Suggested
        </div>
      )}
    </div>
  )
}
export const MenuList = (props: MenuListProps<any> & CustomMenuListProps) => {
  return (
    <components.MenuList {...props}>
      {props.children}
      {props.onClickBtnNewCustomer && (
        <div
          id='select_btnNewCustomer'
          style={{
            position: 'sticky',
            bottom: 0,
            padding: '16px',
            display: 'flex',
            columnGap: '6px',
            alignItems: 'center',
            backgroundColor: '#181d36',
            color: '#2C97DE',
            cursor: 'pointer',
            borderTop: '1px solid rgba(225,225,225,0.1)',
          }}
          onClick={props.onClickBtnNewCustomer}
          onMouseEnter={e => {
            e.currentTarget.style.backgroundColor = '#1D2945'
          }}
          onMouseLeave={e => {
            e.currentTarget.style.backgroundColor = '#181d36'
          }}
        >
          <PlussvgIcon color='#2C97DE' /> New customer
        </div>
      )}
    </components.MenuList>
  )
}
export const reactSelectStyles = {
  option: (defaultStyles: any, state: any) => ({
    ...defaultStyles,
    color: state.isSelected ? '#fff' : '#ffffff66',
    backgroundColor: state.isSelected ? 'rgba(225,225,225,0.05)' : 'rgba(225,225,225,0)',
    '&:hover': {
      backgroundColor: 'rgba(225,225,225,0.05)',
    },
  }),
  control: (defaultStyles: any, state: any) => ({
    ...defaultStyles,
    width: '100%',
    backgroundColor: '#181d36',
    padding: '5px',
    boxShadow: 'none',
    borderColor: state.isFocused ? '#2C97DE' : '#ffffff1a',
    '&:hover': {
      backgroundColor: '#111830',
      borderColor: state.isFocused ? '#2C97DE' : '#ffffff1a',
    },
  }),
  menu: (defaultStyles: any) => ({
    ...defaultStyles,
    zIndex: 10,
  }),
  menuList: (defaultStyles: any) => ({
    ...defaultStyles,
    padding: 0, // kill the white space around first and last option
  }),
  singleValue: (defaultStyles: any, state: any) => ({
    ...defaultStyles,
    color: '#fff',
  }),
  input: (defaultStyles: any) => ({
    ...defaultStyles,
    color: '#fff',
  }),
  loadingMessage: (defaultStyles: any) => ({
    ...defaultStyles,
    backgroundColor: '#181d36',
  }),
  noOptionsMessage: (defaultStyles: any) => ({
    ...defaultStyles,
    backgroundColor: '#181d36',
  }),
  multiValue: (defaultStyles: any) => ({
    ...defaultStyles,
    backgroundColor: '#2c97de',
    borderRadius: '4px',
  }),
  multiValueLabel: (defaultStyles: any) => ({
    ...defaultStyles,
    color: 'white',
    fontWeight: '400',
    fontSize: '14px',
  }),
  multiValueRemove: (defaultStyles: any) => ({
    ...defaultStyles,
    padding: '0px 6px 0px 6px',
    backgroundColor: 'transparent',
    borderRadius: '4px',
    '&:hover': {
      backgroundColor: 'white',
      color: '#2c97de',
    },
  }),
}

interface CustomIndicatorsContainerProps extends IndicatorsContainerProps<any> {
  validator?: boolean
}

export const IndicatorsContainer = ({ children, validator = true, ...props }: CustomIndicatorsContainerProps) => {
  const { selectProps } = props

  const isValid = () => {
    if (!props.options || (props.options && props.options.length === 0) || !selectProps.value) {
      return false
    }

    const optionsType = typeof props.options[0].value
    if (!optionsType) {
      return false
    }

    if (selectProps.value.value && typeof selectProps.value.value === optionsType) {
      return true
    }

    return false
  }

  return (
    <div>
      <components.IndicatorsContainer {...props}>
        {selectProps.value?.suggested ? (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              columnGap: '6px',
              padding: '0px 10px 0px 10px',
              backgroundColor: '#9300E9',
              color: '#fff',
              fontSize: '14px',
              borderRadius: '20px',
              height: '28px',
            }}
          >
            <OptimizeOnsvgIcon width='14px' color='#fff' />
            Suggested
          </div>
        ) : null}

        {children}

        {validator ? <img alt='' width='20px' style={{ marginRight: '5px' }} src={isValid() ? checkedCirclesvg : alertsvg} /> : null}
      </components.IndicatorsContainer>
    </div>
  )
}

export const reactSelectStylesMobile = {
  option: (defaultStyles: any, state: any) => ({
    ...defaultStyles,
    color: state.isSelected ? '#fff' : '#ffffff66',
    backgroundColor: state.isSelected ? 'rgba(225,225,225,0.05)' : 'rgba(225,225,225,0)',
    '&:hover': {
      backgroundColor: 'rgba(225,225,225,0.05)',
    },
  }),

  control: (defaultStyles: any) => ({
    ...defaultStyles,
    width: '100%',
    backgroundColor: 'transparent',
    padding: '5px',
    boxShadow: 'none',
    border: 'none',
  }),
  menuList: (defaultStyles: any) => ({
    ...defaultStyles,
    padding: 0, // kill the white space around first and last option
    width: '100vw',
    left: '-52px;',
  }),
  singleValue: (defaultStyles: any) => ({
    ...defaultStyles,
    color: '#fff',
  }),
  input: (defaultStyles: any) => ({
    ...defaultStyles,
    color: '#fff',
  }),
  noOptionsMessage: (defaultStyles: any) => ({
    ...defaultStyles,
    backgroundColor: '#181d36',
  }),
}

export const reactSelectStylesImgSelect = {
  container: (defaultStyles: any, state: any) => ({
    ...defaultStyles,
    height: '100%',
  }),
  option: (defaultStyles: any, state: any) => ({
    ...defaultStyles,
    color: state.isSelected ? '#fff' : '#ffffff66',
    backgroundColor: state.isSelected ? 'rgba(225,225,225,0.05)' : 'rgba(225,225,225,0)',
    '&:hover': {
      backgroundColor: 'rgba(225,225,225,0.05)',
    },
  }),

  control: (defaultStyles: any, state: any) => ({
    ...defaultStyles,
    width: '100%',
    backgroundColor: '#181d36',
    borderRadius: '16px',
    height: '100%',
    padding: '5px',
    boxShadow: 'none',
    borderColor: state.isFocused ? '#2C97DE' : '#ffffff1a',
    '&:hover': {
      backgroundColor: '#111830',
      borderColor: state.isFocused ? '#2C97DE' : '#ffffff1a',
    },
  }),
  menu: (defaultStyles: any) => ({
    ...defaultStyles,
    zIndex: 10,
  }),
  menuList: (defaultStyles: any) => ({
    ...defaultStyles,
    padding: 0, // kill the white space around first and last option
  }),
  singleValue: (defaultStyles: any, state: any) => ({
    ...defaultStyles,
    color: '#fff',
  }),
  input: (defaultStyles: any) => ({
    ...defaultStyles,
    color: '#fff',
  }),
  noOptionsMessage: (defaultStyles: any) => ({
    ...defaultStyles,
    backgroundColor: '#181d36',
  }),
}

export const reactSelectStylesImgSelectCell = {
  container: (defaultStyles: any, state: any) => ({
    ...defaultStyles,
    height: '100%',
  }),
  option: (defaultStyles: any, state: { isSelected: any }) => ({
    ...defaultStyles,
    color: state.isSelected ? '#fff' : '#ffffff66',
    backgroundColor: state.isSelected ? 'rgba(225,225,225,0.00)' : 'rgba(225,225,225,0)',
    '&:hover': {
      backgroundColor: 'rgba(225,225,225,0.05)',
    },
  }),

  control: (defaultStyles: any, state: { isFocused: any }) => ({
    ...defaultStyles,
    width: '100%',
    backgroundColor: 'transparent',
    borderRadius: '0px',
    height: '100%',
    padding: '5px',
    boxShadow: 'none',
    borderColor: state.isFocused ? '#2C97DE' : 'transparent',
    '&:hover': {
      backgroundColor: 'transparent',
      borderColor: state.isFocused ? '#2C97DE' : 'transparent',
    },
  }),
  menu: (defaultStyles: any) => ({
    ...defaultStyles,
    zIndex: 10,
  }),
  menuList: (defaultStyles: any) => ({
    ...defaultStyles,
    padding: 0, // kill the white space around first and last option
  }),
  singleValue: (defaultStyles: any, state: any) => ({
    ...defaultStyles,
    color: '#fff',
  }),
  input: (defaultStyles: any) => ({
    ...defaultStyles,
    color: '#fff',
  }),
  noOptionsMessage: (defaultStyles: any) => ({
    ...defaultStyles,
    backgroundColor: '#181d36',
  }),
}
