/* eslint-disable react/jsx-pascal-case */
import React, { useState } from 'react'
import { useIntl } from 'react-intl'
import ReactModal from 'react-modal'
import Select from 'react-select'

import * as GQL from 'generated/graphql'
import { displayToast } from 'util/toasts'
import { useAppContext } from 'util/hooks'
import Loader from 'components/Loader'
import { Control, MenuList, Option, reactSelectStyles, IndicatorsContainer } from 'plasmic/StyledReactSelect'
import { modalStyle } from 'components/Modal/PlasmicModal'
import ModalNewDriver from 'plasmic/ModalNewDriver'
import { SelectOption } from 'types/props'

const PAGE_SIZE = 15

const VehicleModal: React.FC<any> = ({ isOpen, onRequestClose, back }) => {
  const {
    appContext: { depot },
  } = useAppContext()
  const [user, setUser] = useState<SelectOption<string>>({
    value: '',
    label: '',
  })
  const [defaultVehicle, setDefaultVehicle] = useState<SelectOption<string>>({
    value: '',
    label: '',
  })
  const [defaultDepot, setDefaultDepot] = useState<SelectOption<string>>({
    value: depot?.id || '',
    label: depot?.name || '',
  })

  const { data: dataVehicles, loading: loadingVehicles } = GQL.useAllVehicles({ variables: { first: PAGE_SIZE } })
  const { data: dataDepots, loading: loadingDepots } = GQL.useAllDepots()
  const { data: dataUsers, loading: loadingUsers } = GQL.useUsers({
    variables: { isDriver: false },
  })
  const vehicles = dataVehicles?.allVehicles?.edges.map(edge => (edge?.node as GQL.VehicleNode) || [])
  const users = dataUsers?.allUsers?.edges.map(edge => (edge?.node as GQL.UserNode) || []).filter(user => !user.isDeleted)

  const intl = useIntl()
  const t = intl.formatMessage

  const [setUserAsDriverMutation, { loading }] = GQL.useSetUserAsDriverMutation({
    onCompleted: () => {
      displayToast(t({ id: 'deliveries.drivers-vehicles-new-driver.success' }), 'success')
      onRequestClose()
    },
    onError: error => {
      error.message && displayToast(error.message)
      displayToast(t({ id: 'common.error-occurred' }))
    },
    update(cache, { data }) {
      cache.modify({
        fields: {
          allDrivers(driversRefs, { readField }) {
            const fragRef = cache.writeFragment({
              fragment: GQL.DriverInfo,
              fragmentName: 'DriverInfo',
              data: data?.setUserAsDriver?.user?.driver,
            })

            if (driversRefs.edges.some((ref: any) => readField('id', ref.node) === data?.setUserAsDriver?.user?.driver?.id)) {
              return driversRefs
            }

            return {
              ...driversRefs,
              edges: [{ node: fragRef, __typename: 'DriverNodeEdge' }, ...driversRefs.edges],
            }
          },
        },
      })
    },
  })

  const handleSubmit = () => {
    if (user.value && defaultVehicle.value && defaultDepot.value) {
      setUserAsDriverMutation({
        variables: {
          user: user.value,
          vehicle: defaultVehicle.value,
          depot: defaultDepot.value,
        },
      })
    } else {
      displayToast('All fields must be selected!')
    }
  }

  return (
    <ReactModal id='addDriver' isOpen={isOpen} onRequestClose={onRequestClose} style={modalStyle()}>
      <ModalNewDriver
        titleHeader={{ onClick: () => back() }}
        existingUser={true}
        btnClose={{ onClick: onRequestClose }}
        inputUserDiv={
          <Select
            placeholder={loadingUsers ? <Loader color='white' /> : t({ id: 'common.select-user' })}
            components={{ Option, Control, MenuList, IndicatorsContainer }}
            styles={reactSelectStyles}
            isMulti={false}
            onChange={event => setUser(event)}
            value={
              {
                value: user.value,
                label: user.label || t({ id: 'common.select-user' }),
              } as SelectOption<string>
            }
            options={users?.map(
              user =>
                ({
                  value: user.id,
                  label: user.fullName,
                }) as SelectOption<string>
            )}
          />
        }
        inputVehicleDiv={
          <Select
            placeholder={loadingVehicles ? <Loader color='white' /> : t({ id: 'common.select-vehicle' })}
            components={{ Option, Control, MenuList, IndicatorsContainer }}
            styles={reactSelectStyles}
            isMulti={false}
            onChange={event => setDefaultVehicle(event)}
            value={
              {
                value: defaultVehicle.value,
                label: defaultVehicle.label || t({ id: 'common.select-vehicle' }),
              } as SelectOption<string>
            }
            options={vehicles?.map(
              vehicle =>
                ({
                  value: vehicle?.id,
                  label: vehicle?.name,
                }) as SelectOption<string>
            )}
          />
        }
        inputDepotDiv={
          <Select
            placeholder={loadingDepots ? <Loader color='white' /> : t({ id: 'common.select-depot' })}
            components={{ Option, Control, MenuList, IndicatorsContainer }}
            styles={reactSelectStyles}
            isMulti={false}
            onChange={event => setDefaultDepot(event)}
            value={
              {
                value: defaultDepot.value,
                label: defaultDepot.label || t({ id: 'common.select-depot' }),
              } as SelectOption<string>
            }
            options={dataDepots?.allDepots?.edges.map(
              depot =>
                ({
                  value: depot?.node?.id,
                  label: depot?.node?.name,
                }) as SelectOption<string>
            )}
          />
        }
        btnCreateDriver={{
          loading: loading,
          onClick: () => handleSubmit(),
          color: user.value && defaultVehicle.value && defaultDepot.value ? 'green' : 'gray',
        }}
      />
    </ReactModal>
  )
}

export default VehicleModal
