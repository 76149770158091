import React from 'react'
import styled from 'styled-components'
import { TileSelectData } from './Vehicles.types'
import * as GQL from 'generated/graphql'
import ScIcon from 'components/Icon/ScIcon'

interface LayoutProps {}

const Layout = styled.div<LayoutProps>`
  display: grid;
  grid-gap: 4px;
  background: #1c213b;
  border-radius: 6px;
  padding: 5px;
  padding-left: 1em;
  color: #696d80;
  &:hover {
    background-color: rgba(0, 0, 0, 0.212);
  }
`

const VehicleItem = styled.div`
  text-align: left;
  display: flex;
  align-items: center;
  padding: 0.5em;
  color: white;
  font-size: 1rem;
`

const DepotItem = styled.div`
  text-align: left;
  display: flex;
  align-items: center;
  padding: 0.5em;
  color: #838694;
  font-size: 1rem;
`

const VehicleIcon = styled.span`
  margin-right: 0.5rem;
  width: 24px;
  color: #9093a1;
`

const vehicleIconLookup = {
  TRUCK: 'sc-vehicle-truck',
  VAN: 'sc-vehicle-van',
  PICKUP: 'sc-vehicle-pickup',
  BULK: 'sc-vehicle-bulk',
}
interface VehiclePanelProps {
  vehicle?: GQL.VehicleNode
  depot?: GQL.DepotNode
  selectedTile?: TileSelectData | null
}

const VehiclePanel: React.FC<VehiclePanelProps> = ({ vehicle, depot }) => {
  return (
    <Layout>
      <VehicleItem title={vehicle?.registrationNumber ?? ''}>
        <VehicleIcon>{vehicle && !vehicle.deleted && vehicle.active ? <ScIcon size='12px' icon={vehicleIconLookup[vehicle.vehicleType]} /> : null}</VehicleIcon>
        {vehicle && !vehicle.deleted && vehicle.active ? vehicle?.name : null}
      </VehicleItem>
      <DepotItem>{depot?.name}</DepotItem>
    </Layout>
  )
}

export default VehiclePanel
