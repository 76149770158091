// @ts-nocheck
/* eslint-disable */
/* tslint:disable */
/* prettier-ignore-start */
import React from "react";
import { classNames } from "@plasmicapp/react-web";

export type DownloadArrowsvgIconProps = React.ComponentProps<"svg"> & {
  title?: string;
};

export function DownloadArrowsvgIcon(props: DownloadArrowsvgIconProps) {
  const { className, style, title, ...restProps } = props;
  return (
    <svg
      xmlns={"http://www.w3.org/2000/svg"}
      version={"1.1"}
      x={"0"}
      y={"0"}
      viewBox={"0 0 410 410"}
      xmlSpace={"preserve"}
      height={"1em"}
      className={classNames("plasmic-default__svg", className)}
      style={style}
      {...restProps}
    >
      {title && <title>{title}</title>}

      <g fill={"currentColor"}>
        <path
          d={
            "M192.628 287.368c3.406 3.406 7.887 5.133 12.367 5.133s8.961-1.703 12.367-5.133l70-70c6.836-6.836 6.836-17.922 0-24.758-6.836-6.836-17.922-6.836-24.758 0l-40.133 40.133V18.333c0-9.66-7.84-17.5-17.5-17.5s-17.5 7.84-17.5 17.5v214.41l-40.133-40.133c-6.836-6.836-17.922-6.836-24.758 0-6.836 6.836-6.836 17.922 0 24.758l70 70h.048z"
          }
        ></path>

        <path
          d={
            "M391.668 257.499c-9.66 0-17.5 7.84-17.5 17.5v70c0 16.078-13.09 29.168-29.168 29.168H65c-16.078 0-29.168-13.09-29.168-29.168v-70c0-9.66-7.84-17.5-17.5-17.5s-17.5 7.84-17.5 17.5v70c0 35.398 28.77 64.168 64.168 64.168h280c35.398 0 64.168-28.77 64.168-64.168v-70c0-9.661-7.84-17.5-17.5-17.5z"
          }
        ></path>
      </g>
    </svg>
  );
}

export default DownloadArrowsvgIcon;
/* prettier-ignore-end */
