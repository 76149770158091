import * as GQL from 'generated/graphql'
import { displayToast } from 'util/toasts'
import styled from 'styled-components'

const SelectContainer = styled.div`
  margin-left: 6px;
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: flex-start;
  align-items: stretch;
  border-radius: 10px;
  padding: 6px;
  box-sizing: border-box;
  background: #181d36;
  gap: 6px;
`

interface ISelectProps {
  selected: boolean
}

const Select = styled.div<ISelectProps>`
  flex-grow: 1;
  font-size: 16px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  border-radius: 8px;
  cursor: pointer;
  ${props =>
    props.selected &&
    `
    background-color: #00d4a6;
    color: #fff;
  `}
  :hover {
    background-color: ${props => (props.selected ? 'rgba(0,212,166, 0.8)' : 'rgb(255 255 255 / 10%)')};
  }
`

interface ISubscriptionProps {
  contact: GQL.CustomerContactNode
  verbs: GQL.NotificationVerbNode[]
  channels: GQL.ChannelNode[]
}

function SubscriptionsThresholdToggles({ contact, verbs, channels }: ISubscriptionProps) {
  const thresholdVerb = verbs.find(verb => verb.slug === 'has-reached')
  const percentages = [10, 20, 30, 40, 50]

  const smsChannel = channels.find(channel => channel.method === 'sms')
  const emailChannel = channels.find(channel => channel.method === 'email')

  const isThresholdToggled = (percentage: number, channel: GQL.ChannelNode) => {
    return (
      contact.notificationSubscriptions?.some(subscription => {
        if (!subscription?.notifyVerb?.id || !subscription?.notifyChannel?.id || !subscription?.isActive) {
          return false
        }

        const matchesVerbAndChannel = subscription.notifyVerb.id === thresholdVerb?.id && subscription.notifyChannel.id === channel.id

        // Parse dynamicRules from JSON string to object
        let dynamicRules
        try {
          dynamicRules = subscription.dynamicRules ? JSON.parse(subscription.dynamicRules) : null
        } catch (error) {
          console.error('Error parsing dynamicRules:', error)
          return false
        }

        const matchesThreshold = dynamicRules?.conditions?.some(
          (condition: { key: string; value: string }) => condition.key === 'threshold' && parseInt(condition.value) === percentage
        )

        return matchesVerbAndChannel && matchesThreshold
      }) ?? false
    )
  }

  const [createSubscription, createSubscriptionResult] = GQL.useCreateNotificationSubscription({
    onError: err => displayToast(err.message, 'error'),
    refetchQueries: ['AllCustomerContacts'],
  })

  const [patchSubscription, patchSubscriptionResult] = GQL.usePatchNotificationSubscription({
    onError: err => displayToast(err.message, 'error'),
    refetchQueries: ['AllCustomerContacts'],
  })

  const isLoading = createSubscriptionResult.loading || patchSubscriptionResult.loading

  const toggleSubscription = (percentage: number, channel: GQL.ChannelNode) => {
    if (!channel || !thresholdVerb || isLoading) return // Early return

    const dynamicRule = {
      conditions: [
        {
          key: 'threshold',
          operation: 'lte',
          value: percentage,
        },
      ],
    }

    const existingSubscription =
      contact.notificationSubscriptions?.find(subscription => {
        if (!subscription?.notifyVerb?.id || !subscription?.notifyChannel?.id) {
          return null
        }

        const matchesVerbAndChannel = subscription.notifyVerb.id === thresholdVerb.id && subscription.notifyChannel.id === channel.id

        // Parse dynamicRules from JSON string to object
        let dynamicRules
        try {
          dynamicRules = subscription.dynamicRules ? JSON.parse(subscription.dynamicRules) : null
        } catch (error) {
          return null
        }

        const matchesThreshold = dynamicRules?.conditions?.some(
          (condition: { key: string; value: string }) => condition.key === 'threshold' && parseInt(condition.value) === percentage
        )

        return matchesVerbAndChannel && matchesThreshold
      }) ?? null

    if (!existingSubscription) {
      createSubscription({
        variables: {
          input: {
            contactId: contact.id,
            notifyVerbId: thresholdVerb.id,
            notifyChannelId: channel.id,
            isActive: true,
            dynamicRules: JSON.stringify(dynamicRule),
          },
        },
      })
    } else {
      patchSubscription({
        variables: {
          id: existingSubscription.id,
          input: {
            isActive: !existingSubscription.isActive,
            dynamicRules: JSON.stringify(dynamicRule),
          },
        },
      })
    }
  }

  return (
    <>
      <div style={{ display: 'flex', flexDirection: 'column', gap: '1em' }}>
        {['SMS', 'Email'].map((method, index) => {
          const channel = method === 'SMS' ? smsChannel : emailChannel
          return (
            <div key={index} style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', width: '100%' }}>
              <div style={{ minWidth: '40px', textAlign: 'left', fontSize: '12px' }}>{method}</div>
              <SelectContainer>
                {percentages.map(percentage => (
                  <Select
                    key={percentage}
                    selected={(channel && isThresholdToggled(percentage, channel)) || false}
                    onClick={() => {
                      channel && toggleSubscription(percentage, channel)
                    }}
                  >
                    {percentage}%
                  </Select>
                ))}
              </SelectContainer>
            </div>
          )
        })}
      </div>
    </>
  )
}

export default SubscriptionsThresholdToggles
