import { useMemo, useState } from 'react'
import { isBoolean } from 'lodash'
import Card from 'plasmic/Card'
import CriticalCustomer from 'plasmic/CriticalCustomer'
import OrderStatus from 'plasmic/OrderStatus'
import OverviewStatus from 'plasmic/OverviewStatus'
import { Link, useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import { format } from 'date-fns'
import { addDays, isSameDay } from 'date-fns'
import * as GQL from 'generated/graphql'
import FlatCalendar from 'components/Calendar/FlatCalendar'
import { useAppContext } from 'util/hooks'
import EstimatedEmpty from 'components/Table/Cells/EstimatedEmpty'
import FullLoader from 'components/Loader/FullLoader'
import { FullLoaderWrapper } from 'components/Loader/LoaderWrappers'
import SensorStatus from 'plasmic/SensorStatus'
import SolaceVersion from 'plasmic/SolaceVersion'
import DriverAvailable from 'plasmic/DriverAvailable'
import WorkdayButton from 'plasmic/WorkdayButton'
import { Map } from './components/Map'
import { getVersion } from 'util/env'
import OrderMethodBar from 'plasmic/OrderMethodBar'
import BorderTag from 'plasmic/BorderTag'
import Placeholder from 'plasmic/Placeholder'
import EmptyStateButton from 'plasmic/EmptyStateButton'
import { displayToast } from 'util/toasts'
import clearCache from 'util/misc'
import Margin from 'components/Margin'
/* Tutorial temporarily disabled
import Tour from 'components/Tour/Tour'
import steps from './OverviewTourSteps'*/
import CacheConfigs from 'util/cacheConfig'
import { useIntl } from 'react-intl'
import PlanningBar from 'plasmic/PlanningBar'
import Loader from 'components/Loader'
import MapInsight from 'plasmic/MapInsight'
import darkModeTheme from 'components/GoogleMap/theme.json'
import lightModeTheme from 'components/GoogleMap/light_theme.json'

const Wrapper = styled.div`
  height: 100%;
  overflow: auto;
  margin-top: 0.5rem;
`

const OrderMethodWrapper = styled.div<{ automatic: number; app: number; total: number }>`
  width: 100%;
  height: 100%;
  .orderBarAutomatic {
    width: ${props => (props.automatic / props.total) * 100 + '%'};
    > div:nth-child(2) {
      border-top-right-radius: ${props => (props.automatic === props.total ? '20px' : '0px')};
      border-bottom-right-radius: ${props => (props.automatic === props.total ? '20px' : '0px')};
    }
  }
  .orderBarApp {
    width: ${props => (props.app / props.total) * 100 + '%'};
    > div:nth-child(2) {
      border-radius: ${props => (props.app === props.total ? '10px' : '0px')};
      border-top-right-radius: ${props => (props.app + props.automatic === props.total ? '20px' : '0px')};
      border-bottom-right-radius: ${props => (props.app + props.automatic === props.total ? '20px' : '0px')};
      border-bottom-left-radius: ${props => (props.automatic === 0 ? '20px' : '0px')};
      border-top-left-radius: ${props => (props.automatic === 0 ? '20px' : '0px')};
    }
  }
  .orderBarManual {
    width: ${props => ((props.total - (props.app + props.automatic)) / props.total) * 100 + '%'};
    > div:nth-child(2) {
      border-top-left-radius: ${props => (props.app === 0 && props.automatic === 0 ? '20px' : '0px')};
      border-bottom-left-radius: ${props => (props.app === 0 && props.automatic === 0 ? '20px' : '0px')};
    }
  }
`

const MapWrapper = styled.div`
  width: 100%;
  max-height: 81vh;
  height: 900px;
`

const LIGHT_MODE_KEY = 'insight-map-light-mode'

const Overview = () => {
  const { appContext } = useAppContext()
  const navigate = useNavigate()
  const [focal] = useState(new Date())
  const intl = useIntl()
  const t = intl.formatMessage

  const localStorageLightMode = localStorage.getItem(LIGHT_MODE_KEY)
  const [lightMode, setLightMode] = useState(localStorageLightMode && isBoolean(JSON.parse(localStorageLightMode)) ? JSON.parse(localStorageLightMode) : true)

  const { data: dataRoutes, loading: loadingRoutes } = GQL.useAllRoutes({
    ...CacheConfigs.ACCURATE_ONCE,
    variables: {
      dateGte: format(focal, 'yyyy-MM-dd'),
      dateLte: format(addDays(focal, 6), 'yyyy-MM-dd'),
      first: 50,
    },
  })

  const { data: dataRoutingOrdersStats, loading: routingOrdersStatsLoading } = GQL.useRoutingOrdersStats()

  const { loading: loadingOrdersSummary, data: ordersSummary } = GQL.useCylinderGroupOrdersSummaryCancellable(
    {},
    [appContext.depot],
    CacheConfigs.ACCURATE_ONCE.fetchPolicy
  )
  const { loading: loadingCriticallyLowCustomers, data: criticallyLowCustomersData } = GQL.useCriticallyLowCustomersCancellable(
    {
      after: null,
      first: 5,
      orderBy: '-estimatedEmpty',
    },
    [appContext.depot],
    CacheConfigs.ACCURATE_ONCE.fetchPolicy
  )

  const { data: dataDrivers, loading: loadingDrivers } = GQL.useAllDriversInsight(CacheConfigs.ACCURATE_ONCE)

  const { data: dataVersions } = GQL.useGetAppVersions()

  const { data: dataStats, loading: loadingStats } = GQL.useDistributorStatsQuery({
    ...CacheConfigs.ACCURATE_ONCE,
    onError: () => {
      displayToast(t({ id: 'pdf.error' }), 'error', {
        autoClose: false,
        closeOnClick: true,
      })
    },
  })

  const onDateClick = (day: Date) => {
    navigate('/deliveries/routes', { state: { selectedDay: day } })
  }

  const criticallyLowCustomers = useMemo(() => {
    return (
      criticallyLowCustomersData?.criticallyLowCustomers?.edges?.filter(e => {
        const timeZero = new Date(0)
        const temp =
          e?.node?.cylinderSides?.reduce(
            (acc, e) => new Date(Math.max(new Date(e?.activeCycle?.estimatedEmpty).getTime(), new Date(acc).getTime())),
            timeZero
          ) || new Date('1, Jan 3000')
        return temp < addDays(focal, 4) && temp > timeZero //not sure if second part is needed but just to make sure
      }) || []
    )
  }, [criticallyLowCustomersData?.criticallyLowCustomers?.edges, focal])

  const criticallyLowCylinderGroups = criticallyLowCustomers?.map(edge => edge?.node as GQL.CylinderGroupNode) || []

  const readyToRefillCylinderGroupsCount = useMemo(() => {
    return ordersSummary?.readyToRefillCylinderGroups?.totalCount || 0
  }, [ordersSummary])

  const awaitingDeliveryCylinderGroupsCount = useMemo(() => {
    return ordersSummary?.awaitingDeliveryCylinderGroupOrders?.totalCount || 0
  }, [ordersSummary])

  const pendingRefillCylinderGroupsCount = useMemo(() => {
    return ordersSummary?.pendingRefillCylinderGroupOrders?.totalCount || 0
  }, [ordersSummary])

  const pausedCylinderGroupOrdersCount = useMemo(() => {
    return ordersSummary?.pausedCylinderGroupOrders?.totalCount || 0
  }, [ordersSummary])

  const unmanagableOrdersCount = useMemo(() => {
    return dataRoutingOrdersStats?.routingOrdersStats?.totalUnmanagable || 0
  }, [dataRoutingOrdersStats])

  const unplannedOrdersCount = useMemo(() => {
    return dataRoutingOrdersStats?.routingOrdersStats?.totalUnplanned || 0
  }, [dataRoutingOrdersStats])

  const plannedOrdersCount = useMemo(() => {
    return dataRoutingOrdersStats?.routingOrdersStats?.totalPlanned || 0
  }, [dataRoutingOrdersStats])

  const numTotalOrders = useMemo(() => {
    return unplannedOrdersCount + plannedOrdersCount + unmanagableOrdersCount
  }, [plannedOrdersCount, unmanagableOrdersCount, unplannedOrdersCount])

  const allRoutes = useMemo(() => {
    return dataRoutes?.allRoutes?.edges.map(edge => edge?.node as GQL.RouteInfo) || []
  }, [dataRoutes])

  const stopsToday = useMemo(() => {
    return allRoutes.filter(e => isSameDay(new Date(e.date), new Date())).reduce((acc: any, e) => (acc || 0) + (e.stops?.length || 0), -1)
  }, [allRoutes])

  const stopsInSevenDays = allRoutes.length

  const orderMethodsStats =
    dataStats?.distributorStats?.allCustomersByOrderMethod?.reduce((map: any, obj) => {
      if (obj && obj.orderMethod) {
        map[obj.orderMethod] = obj?.count
      }
      return map
    }, {}) || {}

  const drivers = useMemo(() => {
    return (
      dataDrivers?.allDrivers?.edges
        .filter(d => d?.node?.active && !d?.node?.deleted)
        .map(edge => edge?.node as GQL.DriverNode)
        .sort((a, b) => ('' + a.user.fullName).localeCompare('' + b.user.fullName)) || []
    )
  }, [dataDrivers])

  /* To enable old tutorial, add the following after <Wrapper>:
  <Tour key='123' steps={steps} name='insight_overview' /> */

  return (
    <Wrapper>
      <OverviewStatus
        col1={
          <>
            <Card
              content={
                <>
                  {((loadingDrivers && !dataDrivers) ||
                    (loadingOrdersSummary && !ordersSummary) ||
                    (loadingStats && !dataStats) ||
                    (loadingCriticallyLowCustomers && !criticallyLowCustomersData) ||
                    (loadingRoutes && !dataRoutes)) && (
                    <FullLoaderWrapper className='Helo'>
                      <FullLoader color='white' size={120} />
                    </FullLoaderWrapper>
                  )}
                  <OrderStatus
                    readyToRefillCustomers={readyToRefillCylinderGroupsCount.toString()}
                    ordersPlacedCustomers={pendingRefillCylinderGroupsCount.toString()}
                    ordersPausedCustomers={pausedCylinderGroupOrdersCount.toString()}
                    awaitingDeliveryCustomers={awaitingDeliveryCylinderGroupsCount.toString()}
                  />
                </>
              }
              linkTitle={<Link to='/orders'>See all orders</Link>}
              title={'Orders'}
            />
            <Card
              content={
                criticallyLowCylinderGroups.length < 1 ? (
                  <EmptyStateButton text='No critically low customers' icon='check' />
                ) : (
                  <>
                    {criticallyLowCylinderGroups.map(group => (
                      <CriticalCustomer
                        key={group.id + 'OverviewCriticalCust'}
                        onClick={() => navigate('?customer=' + group.customer.id)}
                        customer={group.customer.name}
                        cellEstimatedEmpty={<EstimatedEmpty cylinderGroup={group} displayThreshold={false} />}
                      />
                    ))}
                  </>
                )
              }
              linkTitle={<Link to='/customers'>See all customers</Link>}
              title={'Critically low customers'}
            />
            <Card
              content={
                routingOrdersStatsLoading ? (
                  <Loader size={32} color='gray8' margin='0 auto' />
                ) : (
                  <PlanningBar
                    totalOrders={numTotalOrders}
                    fillPlanned={{
                      style: { width: `${(plannedOrdersCount / numTotalOrders) * 100}%` },
                    }}
                    fillUnplanned={{
                      style: { width: `${(unplannedOrdersCount / numTotalOrders) * 100}%` },
                    }}
                    fillUnmanageable={{
                      style: { width: `${(unmanagableOrdersCount / numTotalOrders) * 100}%` },
                    }}
                  />
                )
              }
              linkTitle={<Link to='/deliveries'>See all deliveries</Link>}
              title={'Planning'}
            />
            <Card
              content={
                stopsInSevenDays < 1 ? (
                  <Placeholder type='routes' btn={{ props: { onClick: () => navigate('/deliveries') } }} />
                ) : (
                  <div style={{ paddingTop: '35px', width: '100%' }}>
                    <FlatCalendar routes={allRoutes} numDaysToShow={7} width='100%' noArrows overview onSelectedDateChanged={onDateClick} />
                  </div>
                )
              }
              linkTitle={<Link to='/deliveries/routes'>See all routes</Link>}
              title={'Next deliveries'}
            />
          </>
        }
        col2={
          <>
            <Card
              content={
                <SensorStatus
                  connected={dataStats?.distributorStats?.sensorsInService?.toString() || '0'}
                  notResponding={dataStats?.distributorStats?.sensorsNotReporting?.toString() || '0'}
                />
              }
              title='Sensor status'
              linkTitle={<Link to='/deliveries/routes'></Link>}
            />
            <Card
              content={
                <OrderMethodWrapper
                  automatic={orderMethodsStats?.auto || 0}
                  app={orderMethodsStats?.app || 0}
                  total={(orderMethodsStats?.auto || 0) + (orderMethodsStats?.app || 0) + (orderMethodsStats?.phone || 0)}
                >
                  <OrderMethodBar
                    automatic={`(${orderMethodsStats?.auto?.toString() || '0'})`}
                    app={`(${orderMethodsStats?.app?.toString() || '0'})`}
                    manual={`(${orderMethodsStats?.phone?.toString() || '0'})`}
                  />
                </OrderMethodWrapper>
              }
              title='Order Method'
              linkTitle={<Link to='/customers'>See all customers</Link>}
            />

            <Card
              content={
                drivers.length < 1 ? (
                  <Placeholder type='drivers' btn={{ props: { onClick: () => navigate('/deliveries/vehicles') } }} />
                ) : (
                  drivers.map(driver => (
                    <DriverAvailable
                      key={'overviewDrivercard' + driver.id}
                      name={driver.user.fullName}
                      workdays={
                        <>
                          <WorkdayButton style={{ cursor: 'inherit' }} slim={true} inactive={!driver?.standardWorkhours?.some(e => e?.dow === 1)} day={'M'} />
                          <WorkdayButton style={{ cursor: 'inherit' }} slim={true} inactive={!driver?.standardWorkhours?.some(e => e?.dow === 2)} day={'T'} />
                          <WorkdayButton style={{ cursor: 'inherit' }} slim={true} inactive={!driver?.standardWorkhours?.some(e => e?.dow === 3)} day={'W'} />
                          <WorkdayButton style={{ cursor: 'inherit' }} slim={true} inactive={!driver?.standardWorkhours?.some(e => e?.dow === 4)} day={'T'} />
                          <WorkdayButton style={{ cursor: 'inherit' }} slim={true} inactive={!driver?.standardWorkhours?.some(e => e?.dow === 5)} day={'F'} />
                          <WorkdayButton style={{ cursor: 'inherit' }} slim={true} inactive={!driver?.standardWorkhours?.some(e => e?.dow === 6)} day={'S'} />
                          <WorkdayButton style={{ cursor: 'inherit' }} slim={true} inactive={!driver?.standardWorkhours?.some(e => e?.dow === 0)} day={'S'} />
                        </>
                      }
                    />
                  ))
                )
              }
              title='Drivers available'
              linkTitle={<Link to='/deliveries/vehicles'>See all drivers</Link>}
            />
            <Card
              content={
                <SolaceVersion
                  version={getVersion() || 'local'}
                  suffix={null}
                  tags={
                    dataVersions?.getLatestAppVersion?.version ? (
                      getVersion() === dataVersions?.getLatestAppVersion?.version || getVersion() === '1337' ? (
                        <BorderTag color='green' text='Latest Version' />
                      ) : (
                        <div style={{ cursor: 'pointer' }} onClick={() => clearCache().then(() => window.location.reload())}>
                          <BorderTag color='red' text={`Update to ${dataVersions?.getLatestAppVersion?.version}`} />
                        </div>
                      )
                    ) : null
                  }
                />
              }
              title='Solace version'
              linkTitle={<div onClick={() => clearCache().then(() => window.location.reload())}>Update to latest</div>}
            />
          </>
        }
        col3={
          <Card
            content={
              <MapInsight
                map={{
                  render: () => (
                    <MapWrapper>
                      <Map
                        options={{ gestureHandling: 'cooperative' }}
                        routes={allRoutes}
                        loading={loadingRoutes}
                        theme={!lightMode ? darkModeTheme : lightModeTheme}
                      />
                    </MapWrapper>
                  ),
                }}
                toggleLight={{
                  isChecked: lightMode,
                  onChange: checked => {
                    setLightMode(checked)
                    localStorage.setItem(LIGHT_MODE_KEY, JSON.stringify(checked))
                  },
                }}
              />
            }
            title={
              <div style={{ display: 'flex', alignItems: 'end' }}>
                <div>Today's deliveries </div>
                <div style={{ color: 'gray', fontSize: '0.8em' }}>({stopsToday < 0 ? '0' : stopsToday.toString()})</div>
              </div>
            }
            linkTitle={<Link to='/deliveries/routes'>See all deliveries</Link>}
          />
        }
      />

      {/* <OverviewOther
        tutorialsCard={{
          linkTitle: <div onClick={() => window.open('https://www.smartcylinders.com/resource-center', '_blank')}>See all tutorials</div>,
          content:
            rssData.length > 0 ? (
              rssData?.map((tut, i) => (
                <TutorialPost
                  key={'tutorialpost' + i}
                  onClick={() => window.open(tut.link, '_blank')}
                  title={tut.title}
                  description={tut.description}
                  thumbnail={tut.thumbnail?.url}
                  pubDate={'Published: ' + format(new Date(tut.pubDate), 'dd. MMMM yyyy')}
                />
              ))
            ) : (
              <div style={{color:'white', opacity: '0.5', padding: "2rem"}}>
                There was a problem fetching tutorials here but you can visit{' '}
                <a style={{textDecoration:'underline'}} href={'https://www.smartcylinders.com/resource-center'} target='_blank' rel='noreferrer'>
                   https://www.smartcylinders.com/resource-center
                </a> to read them (password: solace)
              </div>
            ),
        }}
        changelogCard={{
          linkTitle: <Link to='/settings/changelog'>See full changelog</Link>,

          content: dataVersions?.getAppVersions?.edges.map((version, i) => (
            <ChangelogPost
              key={'changelogpost' + i}
              date={format(new Date(version?.node?.createdAt || '0'), 'dd. MMMM, yyyy')}
              text={<ReactMarkdownStyled remarkPlugins={[remarkGfm]}>{version?.node?.info || ''}</ReactMarkdownStyled>}
              tags={<BorderTag text={'ver ' + version?.node?.version} color='blue' />}
            />
          )),
        }}
      /> */}
      <Margin top='50px' />
    </Wrapper>
  )
}

export default Overview
