import React, { useState } from 'react'
import { useIntl } from 'react-intl'

import * as GQL from 'generated/graphql'
import Modal from 'components/Modal/Modal'
import ModalProductConfigurator from 'plasmic/ModalProductConfigurator'
import ModalConfirm from '../../../plasmic/ModalConfirm'
import ModalStickyContainer from 'plasmic/ModalStickyContainer'
import { MandatoryField, MandatoryFieldObject } from 'plasmic/MandatoryFieldsPanel'

interface ProductModalProps {
  product?: GQL.ProductNode
  refetchQueries?: string[]
  open: boolean
  onClose: () => void
}

const ProductModal: React.FC<ProductModalProps> = props => {
  const intl = useIntl()
  const t = intl.formatMessage

  const [productType, setProductType] = useState<GQL.ProductStorageType>(GQL.ProductStorageType.Cylinder)
  const [isConfirmModal, setIsConfirmModal] = useState(false)
  const [triggerSave, setTriggerSave] = useState(false)

  const [mandatoryFields, setMandatoryFields] = useState<MandatoryFieldObject[]>([
    {
      visible: true,
      key: MandatoryField.GAS_TYPE,
      field: t({ id: 'common.cylinder-gas-type' }),
      valid: false,
      id: 'select-gas',
    },
    {
      visible: true,
      key: MandatoryField.GAS_WEIGHT,
      field: t({ id: 'common.cylinder-gas-weight' }),
      valid: false,
      id: 'inputGasWeight',
    },
    {
      visible: true,
      key: MandatoryField.CUSTOMER_APP_VISIBILITY,
      field: t({ id: 'common.visibility-in-the-customerapp' }),
      valid: false,
      id: 'select-visibility',
    },
  ])

  return (
    <>
      <Modal
        isOpen={props.open}
        onRequestClose={() => setIsConfirmModal(true)}
        stripped
        overlayStyle={{ alignItems: 'flex-start', overflow: 'auto', padding: '2rem 0rem' }}
      >
        <ModalStickyContainer
          mandatoryFieldsPanel={{
            mandatoryFieldsObject: mandatoryFields,
            btnSubmit: {
              onClick: () => setTriggerSave(true),
            },
          }}
          modalDiv={
            <ModalProductConfigurator
              triggerSave={triggerSave}
              setTriggerSave={value => setTriggerSave(value)}
              product={props.product}
              onClose={() => setIsConfirmModal(true)}
              refetchQueries={props.refetchQueries}
              mandatoryFields={mandatoryFields}
              setMandatoryFields={setMandatoryFields}
              productType={productType}
              setProductType={setProductType}
              onSubmit={() => {
                setIsConfirmModal(false)
                props.onClose()
              }}
            />
          }
        />
      </Modal>
      {isConfirmModal && (
        <Modal
          isOpen={isConfirmModal}
          onRequestClose={() => {
            setIsConfirmModal(false)
          }}
          stripped
          contentStyle={{
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
          }}
          overlayStyle={{ alignItems: 'flex-start', overflow: 'auto', padding: '2rem 0rem' }}
        >
          <ModalConfirm
            title={t({ id: 'common.discard-changes' }) + '?'}
            description={t({ id: 'settings.products.change.confirm' })}
            btnConfirm={{
              label: t({ id: 'common.discard-changes' }),
              onClick: () => {
                setIsConfirmModal(false)
                props.onClose()
              },
            }}
            btnCancel={{
              label: t({ id: 'common.save-changes' }),
              onClick: () => {
                setTriggerSave(true)
                setIsConfirmModal(false)
              },
            }}
            btnClose={{
              onClick: () => setIsConfirmModal(false),
            }}
            btnColor={'red'}
          />
        </Modal>
      )}
    </>
  )
}

export default ProductModal
