import React, { useMemo, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { format, parseISO } from 'date-fns'
import { useIntl } from 'react-intl'
import * as GQL from 'generated/graphql'
import { displayToast } from 'util/toasts'
import CacheConfigs from 'util/cacheConfig'
import { getErrorMessageIdFromOptimiseError } from '../util'
import RoutesComponent from 'plasmic/RoutesComponent'
import Loader from 'components/Loader'
import ScrollIndicator from 'plasmic/ScrollIndicator'
import Modal from 'components/Modal/Modal'
import TotalDeliveries from '../tables/TotalDeliveries'
import UnplannedDeliveries from '../tables/UnplannedDeliveries'
import UnmanagableDeliveries from '../tables/UnmanagableDeliveries'
import PlannedDeliveries from '../tables/PlannedDeliveries'
import PlannedRoutes from '../tables/PlannedRoutes'
import RouteTable from 'plasmic/RouteTable'
import Tooltip from 'components/Tooltip'

const ROUTE_PAGE_SIZE = 10
const ACTIVE_STATUSES = [
  GQL.RouteStatus.Waiting,
  GQL.RouteStatus.Started,
  GQL.RouteStatus.Planned,
  GQL.RouteStatus.DispatchedButUpdated,
  GQL.RouteStatus.Dispatched,
]
const COMPLETED_STATUSES = [GQL.RouteStatus.Cancelled, GQL.RouteStatus.Completed]
enum VisibleModal {
  ERRORS,
  PLANNED_ROUTES,
  PLANNED,
  UNMANAGABLE,
  UNPLANNED,
  TOTAL,
}

const DRIVERS_PAGE_SIZE = 15

interface Props {}
const Routes: React.FC<Props> = () => {
  const [loading, setLoading] = useState(false)
  const [visibleModal, setVisibleModal] = useState<VisibleModal | undefined>()
  const [loadingFetchMoreRoutes, setLoadingFetchMoreRoutes] = useState(false)
  const [statusIn, setStatusIn] = useState<GQL.RouteStatus[]>(ACTIVE_STATUSES)
  const location = useLocation()
  const [selectedDate, setSelectedDate] = useState(format(location?.state?.selectedDay || new Date(), 'yyyy-MM-dd'))

  const intl = useIntl()
  const t = intl.formatMessage

  const { data: dataRoutingOrdersStats, loading: routingOrdersStatsLoading } = GQL.useRoutingOrdersStats({
    ...CacheConfigs.ACCURATE_ONCE,
  })

  const {
    data: dataRoutes,
    loading: allRoutesLoading,
    fetchMore: fetchMoreRoutes,
  } = GQL.useAllScheduledRoutes({
    ...CacheConfigs.ACCURATE_FREQUENT,
    variables: {
      statusIn: statusIn.map(status => {
        if (status === GQL.RouteStatus.DispatchedButUpdated) {
          return status.toLowerCase().replace(/_/g, '-')
        }
        return status.toLowerCase()
      }),
      date: selectedDate,
      first: ROUTE_PAGE_SIZE,
    },
  })
  const { data: dataDrivers, loading: allDriversLoading } = GQL.useAllDrivers({ variables: { first: DRIVERS_PAGE_SIZE } })
  const [planRoutes, { loading: planRoutesLoading }] = GQL.usePlanRoutesMutation({
    refetchQueries: ['AllScheduledRoutes', 'RoutingStats', 'RoutingOrdersStats'],
    update(cache) {
      cache.modify({
        fields: {
          allRoutes(_, { DELETE }) {
            return DELETE
          },
          allScheduledRoutes(_, { DELETE }) {
            return DELETE
          },
          allCylinderGroupOrders(_, { DELETE }) {
            return DELETE
          },
          allUnassignedRoutes(_, { DELETE }) {
            return DELETE
          },
        },
      })
    },
    onCompleted: result => {
      if (result.planRoutes?.error) {
        displayToast(result.planRoutes?.error)
      } else {
        displayToast(t({ id: 'routing.reoptimize-success' }), 'success')
      }
    },
    onError: error => {
      displayToast(t({ id: getErrorMessageIdFromOptimiseError(error.message) }))
    },
  })
  const [patchCylinderGroupOrder, { loading: patchCylinderGroupOrderLoading }] = GQL.usePatchCylinderGroupOrder({
    onCompleted: () => {
      planRoutes({
        variables: {
          days: 7,
          tz: Intl.DateTimeFormat().resolvedOptions().timeZone,
        },
      })
    },
    onError: error => {
      displayToast(t({ id: getErrorMessageIdFromOptimiseError(error.message) }))
    },
  })

  const upcomingRoutesCount = useMemo(() => {
    return dataRoutingOrdersStats?.routingOrdersStats?.totalUpcomingRoutes || 0
  }, [dataRoutingOrdersStats])

  const unmanagableOrdersCount = useMemo(() => {
    return dataRoutingOrdersStats?.routingOrdersStats?.totalUnmanagable || 0
  }, [dataRoutingOrdersStats])

  const unplannedOrdersCount = useMemo(() => {
    return dataRoutingOrdersStats?.routingOrdersStats?.totalUnplanned || 0
  }, [dataRoutingOrdersStats])

  const plannedOrdersCount = useMemo(() => {
    return dataRoutingOrdersStats?.routingOrdersStats?.totalPlanned || 0
  }, [dataRoutingOrdersStats])

  const numTotalOrders = useMemo(() => {
    return unplannedOrdersCount + plannedOrdersCount + unmanagableOrdersCount
  }, [plannedOrdersCount, unmanagableOrdersCount, unplannedOrdersCount])

  const routes = useMemo(() => {
    return (dataRoutes?.allScheduledRoutes?.edges || []).map(route => route?.node as GQL.RouteNode)
  }, [dataRoutes])

  const routesPageInfo = useMemo(() => {
    return dataRoutes?.allScheduledRoutes?.pageInfo
  }, [dataRoutes])

  const allDrivers = useMemo(() => {
    return dataDrivers?.allDrivers?.edges.map(edge => edge?.node as GQL.DriverNode) || []
  }, [dataDrivers])

  const isLoading = useMemo(() => {
    return allRoutesLoading || allDriversLoading || loading || patchCylinderGroupOrderLoading || planRoutesLoading || routingOrdersStatsLoading
  }, [allRoutesLoading, allDriversLoading, loading, patchCylinderGroupOrderLoading, planRoutesLoading, routingOrdersStatsLoading])

  const handleFlatCalendarDrop = (day: Date, item: GQL.StopNode) => {
    if (!item.order?.id) return
    const formattedDay = format(day, 'yyyy-MM-dd')
    patchCylinderGroupOrder({
      variables: {
        id: item.order.id,
        input: {
          firstPossibleDelivery: formattedDay,
          lastPossibleDelivery: formattedDay,
        },
      },
    })
  }

  return (
    <RoutesComponent
      selectedDateLabel={intl.formatDate(parseISO(selectedDate), {
        weekday: 'long',
        day: 'numeric',
        month: 'long',
      })}
      mapRoutes={{
        props: {
          routes: routes,
        },
      }}
      routeDatePicker={{
        render: (props: any, Component: any) => {
          return <Component {...props} selectedDate={selectedDate} setSelectedDate={setSelectedDate} onDrop={handleFlatCalendarDrop} />
        },
      }}
      tabActive={{
        onClick: () => setStatusIn(ACTIVE_STATUSES),
        selected: statusIn.includes(GQL.RouteStatus.Planned) && !statusIn.includes(GQL.RouteStatus.Completed),
      }}
      tabCompleted={{
        onClick: () => setStatusIn(COMPLETED_STATUSES),
        selected: !statusIn.includes(GQL.RouteStatus.Planned) && statusIn.includes(GQL.RouteStatus.Completed),
      }}
      routeTables={
        <>
          {isLoading ? (
            <Loader size={32} color='gray8' margin='0 auto' />
          ) : (
            routes.map(route => (
              <RouteTable key={route?.stops?.map(stop => stop?.id).join('-')} drivers={allDrivers} route={route} routes={routes} setLoading={setLoading} />
            ))
          )}
          {!isLoading && routesPageInfo?.hasNextPage && routes.length > 0 && (
            <ScrollIndicator
              tableRow
              loaded={routes.length}
              total={dataRoutes?.allScheduledRoutes?.totalCount || routes.length}
              btnLoadMore={{
                ...(loadingFetchMoreRoutes && { children: <Loader color='white' /> }),
                onClick: () => {
                  if (routesPageInfo?.endCursor && routesPageInfo?.hasNextPage) {
                    setLoadingFetchMoreRoutes(true)
                    fetchMoreRoutes({
                      variables: {
                        statusIn: statusIn.map(status => {
                          if (status === GQL.RouteStatus.DispatchedButUpdated) {
                            return status.toLowerCase().replace(/_/g, '-')
                          }
                          return status.toLowerCase()
                        }),
                        date: selectedDate,
                        first: ROUTE_PAGE_SIZE,
                        after: routesPageInfo?.endCursor,
                      },
                      updateQuery(prev, { fetchMoreResult }) {
                        if (!fetchMoreResult) return prev
                        return {
                          ...fetchMoreResult,
                          allScheduledRoutes: {
                            ...fetchMoreResult?.allScheduledRoutes,
                            edges: [
                              ...(prev?.allScheduledRoutes?.edges ? prev.allScheduledRoutes.edges : []),
                              ...(fetchMoreResult?.allScheduledRoutes?.edges ? fetchMoreResult.allScheduledRoutes.edges : []),
                            ],
                          },
                        } as GQL.AllScheduledRoutes
                      },
                    }).finally(() => setLoadingFetchMoreRoutes(false))
                  } else {
                    displayToast('Something went wrong while fetching more.')
                  }
                },
              }}
            />
          )}
        </>
      }
      planningBar={{
        text: {
          onClick: () => setVisibleModal(VisibleModal.TOTAL),
        },
        totalOrders: numTotalOrders.toString(),
        fillPlanned: {
          onClick: () => setVisibleModal(VisibleModal.PLANNED),
          style: { width: `${(plannedOrdersCount / numTotalOrders) * 100}%` },
        },
        fillUnplanned: {
          onClick: () => setVisibleModal(VisibleModal.UNPLANNED),
          style: { width: `${(unplannedOrdersCount / numTotalOrders) * 100}%` },
        },
        fillUnmanageable: { onClick: () => setVisibleModal(VisibleModal.UNMANAGABLE), style: { width: `${(unmanagableOrdersCount / numTotalOrders) * 100}%` } },
      }}
      planningBarInfoRowPlanned={{
        root: {
          render: (props: any, Component: any) => {
            return (
              <Tooltip content={t({ id: 'tooltips.deliveries.dates.planned_orders' })} delay={0} arrow={false}>
                <Component {...props} />
              </Tooltip>
            )
          },
        },
        value: plannedOrdersCount.toString(),
        onClick: () => setVisibleModal(VisibleModal.PLANNED),
      }}
      planningBarInfoRowUnplanned={{
        root: {
          render: (props: any, Component: any) => {
            return (
              <Tooltip content={t({ id: 'tooltips.deliveries.dates.unplanned_orders' })} delay={0} arrow={false}>
                <Component {...props} />
              </Tooltip>
            )
          },
        },
        value: unplannedOrdersCount.toString(),
        onClick: () => setVisibleModal(VisibleModal.UNPLANNED),
      }}
      planningBarInfoRowUnmanageable={{
        root: {
          render: (props: any, Component: any) => {
            return (
              <Tooltip content={t({ id: 'tooltips.deliveries.dates.unmanagable_orders' })} delay={0} arrow={false}>
                <Component {...props} />
              </Tooltip>
            )
          },
        },
        value: unmanagableOrdersCount.toString(),
        onClick: () => setVisibleModal(VisibleModal.UNMANAGABLE),
      }}
      btnUpcomingRoutes={{
        root: {
          render: (props: any, Component: any) => (
            <Tooltip content={t({ id: 'tooltips.deliveries.dates.planned_routes' })} delay={0} arrow={false}>
              <Component {...props} />
            </Tooltip>
          ),
        },
        numberValue: upcomingRoutesCount.toString(),
        onClick: () => setVisibleModal(VisibleModal.PLANNED_ROUTES),
      }}
      btnReoptimize={{
        root: {
          render: (props: any, Component: any) => (
            <Tooltip content={t({ id: 'tooltips.deliveries.dates.reoptimize' })} delay={0} arrow={false}>
              <Component {...props} />
            </Tooltip>
          ),
        },
        loading: planRoutesLoading,
        disabled: planRoutesLoading,
        onClick: () =>
          planRoutes({
            variables: {
              days: 15,
              tz: Intl.DateTimeFormat().resolvedOptions().timeZone,
            },
          }),
      }}
      root={{
        wrapChildren: (children: React.ReactNode) => (
          <>
            {children}
            {visibleModal === VisibleModal.PLANNED_ROUTES && (
              <Modal
                stripped
                overlayStyle={{ zIndex: 1002 }}
                contentStyle={{ width: '95%', maxHeight: '90%', overflowY: 'auto', backgroundColor: '#242c48' }}
                isOpen={visibleModal === VisibleModal.PLANNED_ROUTES}
                onRequestClose={() => setVisibleModal(undefined)}
              >
                <PlannedRoutes drivers={allDrivers} />
              </Modal>
            )}
            {visibleModal === VisibleModal.TOTAL && (
              <Modal
                stripped
                overlayStyle={{ zIndex: 1002 }}
                contentStyle={{ width: '95%', maxHeight: '90%', overflowY: 'auto', backgroundColor: '#242c48' }}
                isOpen={visibleModal === VisibleModal.TOTAL}
                onRequestClose={() => setVisibleModal(undefined)}
              >
                <TotalDeliveries />
              </Modal>
            )}
            {visibleModal === VisibleModal.UNPLANNED && (
              <Modal
                stripped
                overlayStyle={{ zIndex: 1002 }}
                contentStyle={{ width: '95%', maxHeight: '90%', overflowY: 'auto', backgroundColor: '#242c48' }}
                isOpen={visibleModal === VisibleModal.UNPLANNED}
                onRequestClose={() => setVisibleModal(undefined)}
              >
                <UnplannedDeliveries />
              </Modal>
            )}
            {visibleModal === VisibleModal.UNMANAGABLE && (
              <Modal
                stripped
                overlayStyle={{ zIndex: 1002 }}
                contentStyle={{ width: '95%', maxHeight: '90%', overflowY: 'auto', backgroundColor: '#242c48' }}
                isOpen={visibleModal === VisibleModal.UNMANAGABLE}
                onRequestClose={() => setVisibleModal(undefined)}
              >
                <UnmanagableDeliveries />
              </Modal>
            )}
            {visibleModal === VisibleModal.PLANNED && (
              <Modal
                stripped
                overlayStyle={{ zIndex: 1002 }}
                contentStyle={{ width: '95%', maxHeight: '90%', overflowY: 'auto', backgroundColor: '#242c48' }}
                isOpen={visibleModal === VisibleModal.PLANNED}
                onRequestClose={() => setVisibleModal(undefined)}
              >
                <PlannedDeliveries />
              </Modal>
            )}
          </>
        ),
      }}
    />
  )
}
export default Routes
