import React, { useEffect, useMemo } from 'react'
import { GoogleMap, GoogleMapProps, Marker } from '@react-google-maps/api'
// import mapTheme from 'components/GoogleMap/theme.json'
import * as GQL from 'generated/graphql'
import { isSameDay } from 'date-fns'
import styled from 'styled-components'

interface IMarker {
  longitude: number
  latitude: number
  icon: string
  title: string
}

interface IMapProps extends GoogleMapProps {
  routes: GQL.RouteInfo[]
  loading: boolean
  theme: google.maps.MapTypeStyle[]
}

const NoStops = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  text-align: center;
  align-items: center;
  justify-content: center;
  display: flex;
  /* backdrop-filter: blur(3px); */
  z-index: 1;
  background-color: rgba(24, 29, 54, 0.39);
  pointer-events: none;
  p {
    background-color: rgb(24, 29, 54);
    padding: 3rem;
    border-radius: 8px;
  }
`

const containerStyle = {
  width: '100%',
  height: '100%',
}

export const Map: React.FC<IMapProps> = ({ routes, loading, theme }) => {
  const [savedMap, saveMap] = React.useState<any>(null)
  const markers = useMemo(() => {
    return (
      ([
        ...routes
          .filter(e => isSameDay(new Date(e.date), new Date()))
          .flatMap(route =>
            route.stops
              ?.filter(s => !!s?.customer?.address.latitude)
              .map(stop => ({
                longitude: parseFloat(stop?.customer?.address.longitude.toString()),
                latitude: parseFloat(stop?.customer?.address.latitude.toString()),
                title: stop?.customer?.name,
                icon: 'data:image/svg+xml;utf-8, <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 40 48.3"><style type="text/css">.st0{fill:%23191E2F;} .st2{font-family:\'ProximaNova-Semibold\';} .st3{font-size:16px;} </style><path fill="%232B97DE" d="M40,20C40,9,31,0,20,0S0,9,0,20c0,5.3,2.1,10.1,5.4,13.6l0,0L20,48.3l14.6-14.6l0,0C37.9,30.1,40,25.3,40,20z" /><circle fill="%23191E2F" cx="20" cy="20" r="16" /><text fill="%232B97DE" text-anchor="middle" transform="matrix(1 0 0 1 20.1445 24.1496)" className="st2 st3" style="font-size: 16px;">S</text></svg>',
              }))
          ),
      ] as IMarker[]) || []
    )
  }, [routes])
  // const markers = []  as IMarker[]

  useEffect(() => {
    if (savedMap && markers && markers.length > 0) {
      const bounds = new google.maps.LatLngBounds()
      markers?.forEach(marker =>
        bounds.extend({
          lat: marker.latitude,
          lng: marker.longitude,
        })
      )
      savedMap.fitBounds(bounds)
      savedMap.panToBounds(bounds)
    }
  }, [markers, savedMap])

  return (
    <>
      {(!markers || markers.length < 1) && !loading && (
        <NoStops>
          <p>No deliveries today</p>
        </NoStops>
      )}
      <GoogleMap
        onLoad={map => {
          saveMap(map)
          map.setCenter({ lat: 30.595901893270668, lng: -39.87550592883013 })
        }}
        mapContainerStyle={containerStyle}
        zoom={2}
        options={{
          fullscreenControl: false,
          backgroundColor: 'rgb(20, 25, 38)',
          mapTypeControl: false,
          zoomControl: false,
          streetViewControl: false,
          styles: theme,
          gestureHandling: 'cooperative',
        }}
      >
        {markers?.map((marker, i) => (
          <Marker
            key={i}
            position={{
              lat: marker.latitude,
              lng: marker.longitude,
            }}
            title={marker.title}
            icon={{ url: marker.icon, scaledSize: new google.maps.Size(30, 50) }}
          />
        ))}
      </GoogleMap>
    </>
  )
}
