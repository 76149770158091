import React, { useMemo } from 'react'
import styled from 'styled-components'
import { FormattedMessage } from 'react-intl'
import { useNavigate } from 'react-router-dom'
import { useModal } from 'react-modal-hook'

import Icon from 'components/Icon'
import Truck from 'components/icons/Truck'
import SimpleClock from 'components/icons/SimpleClock'
import MapMarker from 'components/icons/MapMarker'
import ChangeVehicleModal from '../components/ChangeVehicleModal'
import ChangeWorkhoursModal from '../components/ChangeWorkhoursModal'
import ChangeDepotModal from '../components/ChangeDepotModal'
import Tooltip from 'components/Tooltip'
import Loader from 'components/Loader'
import * as GQL from 'generated/graphql'
import { useAppContext } from 'util/hooks'
import { displayToast } from 'util/toasts'
import TutorialTooltip from 'components/Tooltip/TutorialTooltip'
import { getDateOnly } from '../util'
import { format } from 'date-fns'
import { getZeroBasedWeekday } from 'util/date-fns/custom'

const OptionsIcon = styled(Icon)`
  position: absolute;
  right: 0;
  top: 0;
  color: rgba(255, 255, 255, 0.4);
  font-size: 1em;
  padding-right: 10px;
  padding-left: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
  visibility: hidden;
  cursor: pointer;
  :hover {
    color: rgba(255, 255, 255, 0.8);
  }
`
const RoutesIcon = styled(Icon)`
  position: absolute;
  left: 0;
  top: 5px;
  color: rgba(255, 255, 255, 0.4);
  font-size: 0.8em;
  padding-right: 10px;
  padding-left: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
  visibility: hidden;
  cursor: pointer;
  :hover {
    color: rgba(255, 255, 255, 0.8);
  }
`

const HoverIcon = styled(Icon)`
  visibility: hidden;
  cursor: pointer;
  color: ${props => props.theme.colors.green};
`

interface LayoutProps {
  isWorking: boolean
  isVacated: boolean
  readOnly: boolean
}

const Layout = styled.div<LayoutProps>`
  display: flex;
  justify-content: ${props => (props.isWorking ? 'space-between' : 'center')};
  align-items: center;
  height: 97px;

  color: ${props => (props.isWorking && !props.isVacated ? props.theme.colors.green : props.theme.colors.red)};

  ${props =>
    !props.isWorking &&
    !props.readOnly &&
    `
    background: repeating-linear-gradient(
      -45deg,
      ${props.theme.colors.primaryDark},
      ${props.theme.colors.primaryDark} 5px,
      #1e243f 5px,
      #1e243f 10px
    );
  `}
  font-size: 22px;
  opacity: 0.65;
  :hover {
    opacity: 1;
  }
  :hover ${OptionsIcon}, :hover ${HoverIcon}, :hover ${RoutesIcon} {
    visibility: visible;
  }
  ${props => (props.readOnly ? 'background-color: #12152b;' : 'background-color: #283053;')}
  border-radius: 6px;
  user-select: none;
  flex-direction: column;
`

const BottomIconWrapper = styled.div`
  min-height: 25px;
  display: flex;
  justify-content: space-evenly;
  width: 100%;
  svg {
    padding: 3px;
  }
`

const TruckIcon = styled(Truck)`
  cursor: pointer;
  width: 15px;
  height: 15px;
  fill: ${props => props.theme.colors.lightBlue};
`

const StyledIcon = styled(Icon)<{ color?: string }>`
  cursor: pointer;
  color: ${props => props.color};
`

interface DriverDatesPanelTileProps {
  route?: GQL.RouteNode
  loading: boolean
  driver: GQL.DriverNode
  date: Date
  onClick: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void
  handleMenu: (e: React.MouseEvent<HTMLElement>, cb: (action: string) => void) => void
}

const DriverDatesPanelTile = ({ route, date, loading, driver, handleMenu, onClick }: DriverDatesPanelTileProps) => {
  const navigate = useNavigate()
  const { appContext } = useAppContext()

  const currentDepot = appContext.depot?.id ?? null

  const [showVehicleModal, hideVehicleModal] = useModal(
    () => <ChangeVehicleModal isOpen={true} onRequestClose={hideVehicleModal} driver={driver} date={date} />,
    [route, date]
  )
  const [showWorkhoursModal, hideWorkhoursModal] = useModal(
    () => <ChangeWorkhoursModal isOpen={true} onRequestClose={hideWorkhoursModal} driver={driver} date={date} />,
    [route, date]
  )
  const [showDepotModal, hideDepotModal] = useModal(
    () => <ChangeDepotModal isOpen={true} onRequestClose={hideDepotModal} driver={driver} date={date} />,
    [route, date]
  )

  const hasRoute = !!route
  const readOnly = useMemo(() => {
    return !!(getDateOnly(date) < getDateOnly(new Date()) || (driver.defaultDepot?.id !== currentDepot && currentDepot))
  }, [driver, date, currentDepot])

  const driverWorkingHours = useMemo(() => {
    return driver.standardWorkhours?.find(sw => sw?.dow === getZeroBasedWeekday(date))
  }, [driver, date])

  const dateException = useMemo(() => {
    return driver.exceptions?.find(exception => exception?.onDate === format(date, 'yyyy-MM-dd'))
  }, [driver, date])

  const dateVehicle = useMemo(() => {
    return dateException && dateException.defaultVehicle ? dateException.defaultVehicle : driver?.defaultVehicle || undefined
  }, [driver?.defaultVehicle, dateException])

  const dateDepot = useMemo(() => {
    return dateException && dateException.defaultDepot ? dateException.defaultDepot : driver?.defaultDepot || undefined
  }, [driver?.defaultDepot, dateException])

  const isWorkingDateNormally = useMemo(() => {
    return driverWorkingHours?.dow !== undefined ? driverWorkingHours?.dow === getZeroBasedWeekday(date) : false
  }, [driverWorkingHours, date])

  const isVacated = useMemo(() => {
    return !!dateException?.vacated
  }, [dateException?.vacated])

  const hasCustomWorkingHours = useMemo(() => {
    return (
      !isVacated &&
      !!dateException &&
      dateException.startTime &&
      dateException.endTime &&
      (dateException?.startTime !== driverWorkingHours?.startTime || dateException?.endTime !== driverWorkingHours?.endTime)
    )
  }, [dateException, driverWorkingHours?.endTime, driverWorkingHours?.startTime, isVacated])

  const hasCustomVehicle = useMemo(() => {
    return dateException && dateException.defaultVehicle ? dateException.defaultVehicle?.id !== driver?.defaultVehicle?.id : false
  }, [driver?.defaultVehicle?.id, dateException])

  const hasCustomDepot = useMemo(() => {
    return dateException && dateException.defaultDepot ? dateException.defaultDepot?.id !== driver?.defaultDepot?.id : false
  }, [driver?.defaultDepot?.id, dateException])

  function getCustomMessage(type: string) {
    switch (type) {
      case 'vehicle':
        return `${driver.defaultVehicle?.name.capitalizeFirst()} vehicle is usually used for this driver. ${dateVehicle?.name.capitalizeFirst()} will be used this day.`
      case 'depot':
        return `${driver.defaultDepot?.name?.capitalizeFirst()} depot is usually used for this driver. ${dateDepot?.name} will be used this day.`
      case 'workhours':
        if (isWorkingDateNormally) {
          return `${driver.user.fullName} is usually working from ${driverWorkingHours?.startTime} to ${driverWorkingHours?.endTime}. On this day its spanning from ${dateException?.startTime} to ${dateException?.endTime}`
        }
        return `${driver.user.fullName} is usually not working on this day, but on this day his working hours are spanning from ${dateException?.startTime} to ${dateException?.endTime}`
      case 'notWorkingDayRoute':
        if (!driverWorkingHours?.endTime && !driverWorkingHours?.startTime) {
          return (
            'Driver is usually not working on this day, but has a route assigned from ' +
            format(route?.startTime, 'HH:mm') +
            ' to ' +
            format(route?.endTime, 'HH:mm')
          )
        }
        return (
          'Route is usually from ' +
          driverWorkingHours?.startTime +
          ' to ' +
          driverWorkingHours?.endTime +
          '. This route is spanning from ' +
          route?.driverStartTime +
          ' to ' +
          route?.driverEndTime
        )
    }
  }

  const click = (event: any) => {
    onClick(event)
  }

  const notAllowed = () => {
    displayToast("Can't change routes in the past", 'warning')
  }
  const hasAssignedRoute = () => {
    displayToast('This driver has assigned route! Please first delete assigned route or select other driver for that route.', 'warning')
  }

  const handleMenuCb = (action: string) => {
    const actions = {
      vehicle: showVehicleModal,
      workhours: showWorkhoursModal,
      depot: showDepotModal,
    } as { [key: string]: () => void }

    actions[action]()
  }

  const Icon = () => {
    if (loading) {
      return <Loader color='primaryLighter' />
    } else if (hasRoute) {
      return (
        <TutorialTooltip content={<FormattedMessage id='tooltips.deliveries.dates.day.assigned' />} placement='bottom'>
          <Tooltip content='Has assigned route'>
            <StyledIcon icon={'check'} color='rgb(0,212,166)' onClick={readOnly ? notAllowed : hasAssignedRoute} />
          </Tooltip>
        </TutorialTooltip>
      )
    } else if (isVacated) {
      return (
        <TutorialTooltip content={<FormattedMessage id='tooltips.deliveries.dates.day.times' />} placement='bottom'>
          <StyledIcon icon={'times'} onClick={readOnly ? notAllowed : click} />
        </TutorialTooltip>
      )
    } else if (!isWorkingDateNormally) {
      return <div></div>
    }
    return (
      <TutorialTooltip content={<FormattedMessage id='tooltips.deliveries.dates.day.tick' />} placement='bottom'>
        <Tooltip
          content={
            <>
              Working hours: {driverWorkingHours?.startTime} - {driverWorkingHours?.endTime}
            </>
          }
          delay={0}
          arrow={false}
        >
          <StyledIcon icon={'check'} onClick={readOnly ? notAllowed : click} color='#2c97de' />
        </Tooltip>
      </TutorialTooltip>
    )
  }

  return (
    <Layout isWorking={isWorkingDateNormally} isVacated={isVacated} readOnly={readOnly}>
      <div style={{ display: 'flex', width: '100%', height: '27px', position: 'relative' }}>
        {!readOnly && <OptionsIcon icon='ellipsis-v' onClick={e => handleMenu(e, handleMenuCb)} />}
        {hasRoute && (
          <TutorialTooltip content='Click to see the route.'>
            <RoutesIcon
              icon='route'
              onClick={() => {
                navigate('/deliveries/routes', { state: { selectedDay: date } })
              }}
            />
          </TutorialTooltip>
        )}
      </div>
      <Icon />
      {!isVacated ? (
        <BottomIconWrapper>
          {hasCustomVehicle && (
            <Tooltip content={getCustomMessage('vehicle')} delay={0} arrow={false}>
              <span>
                <TruckIcon onClick={readOnly ? notAllowed : showVehicleModal} />
              </span>
            </Tooltip>
          )}
          {hasRoute && !isWorkingDateNormally && (
            <Tooltip content={getCustomMessage('notWorkingDayRoute')} delay={0} arrow={false}>
              <span>
                <SimpleClock style={{ cursor: 'pointer' }} width='15px' height='15px' fill={'#9300e9'} onClick={readOnly ? notAllowed : showWorkhoursModal} />
              </span>
            </Tooltip>
          )}
          {!hasRoute && hasCustomWorkingHours && (
            <Tooltip content={getCustomMessage('workhours')} delay={0} arrow={false}>
              <span>
                <SimpleClock style={{ cursor: 'pointer' }} width='15px' height='15px' fill={'#9300e9'} onClick={readOnly ? notAllowed : showWorkhoursModal} />
              </span>
            </Tooltip>
          )}
          {hasCustomDepot && (
            <Tooltip content={getCustomMessage('depot')} delay={0} arrow={false}>
              <span>
                <MapMarker style={{ cursor: 'pointer' }} width='15px' height='15px' fill={'#00d4a6'} onClick={readOnly ? notAllowed : showDepotModal} />
              </span>
            </Tooltip>
          )}
        </BottomIconWrapper>
      ) : (
        <div style={{ height: '26px' }}></div>
      )}
    </Layout>
  )
}

export default DriverDatesPanelTile
