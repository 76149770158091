import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { Range, Calendar } from 'react-date-range'
import { useIntl } from 'react-intl'
import Select from 'react-select'
import { addDays, differenceInCalendarDays, format, parseISO, subDays } from 'date-fns'
import { useApolloClient } from '@apollo/client'

import * as GQL from 'generated/graphql'
import * as p from '@plasmicapp/react-web'
import { useAppContext, useClickOutside } from 'util/hooks'
import { displayToast } from 'util/toasts'
import { getClosedDays, getLatestEstimatedEmptyDate, isPaymentMethodActive, isPaymentProviderActive } from '../util'
import DatePickerCalendar from 'components/DatePicker/DatePickerCalendar'
import Loader from 'components/Loader'
import { formatPrice, formatTaxRate, updateCylinderGroupOrderCacheOnPatch } from 'modules/orders/util'
import PhoneInput from 'components/Input/PhoneInput'
import { EMAIL_REGEX } from 'modules/users/consts'
import { paymentEnabled } from 'modules/distributors/utils'
import { grapheneIdToInt } from 'util/parsing'
import { enumToStringFormatted, stripePaymentsEnabled } from 'util/utils'
import { Control, MenuList, Option, reactSelectStyles } from 'plasmic/StyledReactSelect'
import CreateOrderModuleExtended from 'plasmic/CreateOrderModuleExtended'
import OrderProductRowExtended from 'plasmic/OrderProductRowExtended'
import ActionDropdownLine from 'plasmic/ActionDropdownLine'
import NoteElement from 'plasmic/NoteElement'
import AlertMessage from 'plasmic/AlertMessage'
import OrderInfoExtended from 'plasmic/OrderInfoExtended'
import cylinderPlaceholderFillsvg from 'plasmic/plasmic/solace_components/images/cylinderPlaceholderFillsvg.svg'

const getSuggestedSide = (cylinderGroup?: GQL.CylinderGroupNode) => {
  if (cylinderGroup && cylinderGroup.cylinderSides) {
    const suggestedSide = cylinderGroup.cylinderSides.reduce((a, b) => (a?.estimatedEmpty < b?.estimatedEmpty ? a : b), null)
    if (suggestedSide) {
      return suggestedSide.cylinderCount
    }
  }
  return null
}

interface OrderProduct {
  id?: string
  product: GQL.ProductNode
  quantity: number
  exchange: boolean
  basePrice: number
  depositFee: number
  subtotal: number
  tax: number
  total: number
}

interface OrderSummary {
  products: OrderProduct[]
  notes?: string[]
}

export interface SelectedProduct {
  value: GQL.ProductNode | null
  label: string
  quantity: number
  exchange: boolean
}

interface CreateOrderProps {
  cylinderGroup?: GQL.CylinderGroupNode
  orders: GQL.CylinderGroupOrderNode[]
  activeOrders: GQL.CylinderGroupOrderNode[]
  onClose: () => void
  asModal?: boolean
  order?: GQL.CylinderGroupOrderNode
  selectCustomerDiv?: p.Flex<'div'>
}

interface SelectedPaymentProvider {
  value: GQL.PaymentProviders | null | undefined
  label: string
}

interface SelectedPaymentMethod {
  value: GQL.PaymentMethods | null | undefined
  label: string
}

export const paymentProviderLabel = (provider: GQL.PaymentProviders | null) => {
  if (provider === GQL.PaymentProviders.Visma) {
    return 'Visma'
  } else if (provider === GQL.PaymentProviders.Stripe) {
    return 'Stripe'
  }
  return '-'
}

export const paymentMethodLabel = (method: GQL.PaymentMethods | null) => {
  if (method === GQL.PaymentMethods.InAdvance) {
    return 'In Advance'
  } else if (method === GQL.PaymentMethods.OnDelivery) {
    return 'On Delivery'
  } else if (method === GQL.PaymentMethods.Credit) {
    return 'Credit'
  }
  return '-'
}

export default function CreateOrder({ cylinderGroup, orders, activeOrders, onClose, order, selectCustomerDiv, asModal }: CreateOrderProps) {
  const intl = useIntl()
  const t = intl.formatMessage

  const { appContext } = useAppContext()

  const ref = useRef(null)
  const paymentDueCalendarRef = useRef(null)
  const client = useApolloClient()

  const [externalReference, setExternalReference] = useState(order?.externalReference || '')
  const [invoiceReceiver, setInvoiceReceiver] = useState<GQL.CustomerContactNode | undefined | null>(
    cylinderGroup?.customer?.contacts?.find(contact => contact?.invoiceReceiver && !contact.isDeleted)
  )
  const [draftInvoiceReceiver, setDraftInvoiceReceiver] = useState({
    name: '',
    phoneNumber: '',
    email: '',
    position: '',
    phoneNumberInvalid: true,
  })

  const [showDueDateCalendar, setShowDueDateCalendar] = useState(false)
  const [dueDate, setDueDate] = useState(new Date(new Date().setDate(new Date().getDate() + (appContext.distributor?.defaultPaymentDueDays || 14))))

  const defaultProduct = useMemo(
    () =>
      cylinderGroup?.product && cylinderGroup.product.status === GQL.ProductStatus.Active && cylinderGroup.product.isBulk === cylinderGroup.isBulk
        ? cylinderGroup.product
        : null,
    [cylinderGroup?.isBulk, cylinderGroup?.product]
  )
  const defaultQuantity = useMemo(() => getSuggestedSide(cylinderGroup), [cylinderGroup])

  const [orderProductActionOpen, setOrderProductActionOpen] = useState<number>()
  const [orderNoteActionOpen, setOrderNoteActionOpen] = useState<number>()

  const latestEstimatedEmptyDate = useMemo(() => {
    return getLatestEstimatedEmptyDate(cylinderGroup)
  }, [cylinderGroup])

  const [noteContent, setNoteContent] = useState('')
  const [selectedRange, setSelectedRange] = useState<Range[]>([
    latestEstimatedEmptyDate
      ? {
          startDate: new Date(),
          endDate: subDays(latestEstimatedEmptyDate, differenceInCalendarDays(latestEstimatedEmptyDate, new Date()) * 0.2),
          key: 'selection',
        }
      : {
          startDate: new Date(),
          endDate: addDays(new Date(), 3),
          key: 'selection',
        },
  ])

  const [selectedProduct, setSelectedProduct] = useState<SelectedProduct>({
    value: defaultProduct,
    label: defaultProduct ? defaultProduct.displayName || `${defaultProduct.weight} kg of ${defaultProduct.type}` : t({ id: 'orders.create.select-item' }),
    quantity: defaultQuantity || 1,
    exchange: false,
  })

  const customerPaymentProviders = useMemo(() => {
    return cylinderGroup?.customer.paymentProviders
  }, [cylinderGroup])

  const customerPaymentMethods = useMemo(() => {
    return cylinderGroup?.customer.paymentMethods
  }, [cylinderGroup])

  const isPaymentEnabled = useMemo(() => {
    return paymentEnabled(appContext)
  }, [appContext])

  const defaultOrderPaymentSetting = useMemo(() => {
    if (!cylinderGroup || !cylinderGroup.customer || !cylinderGroup.customer.defaultOrderPaymentSetting) {
      return null
    }
    return cylinderGroup.customer.defaultOrderPaymentSetting
  }, [cylinderGroup])

  const [selectedPaymentProvider, setSelectedPaymentProvider] = useState<SelectedPaymentProvider>({
    value: null,
    label: '',
  })

  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState<SelectedPaymentMethod>({
    value: null,
    label: '',
  })

  const suggestedPaymentProvider = useMemo(() => {
    if (
      defaultOrderPaymentSetting &&
      defaultOrderPaymentSetting.provider &&
      isPaymentProviderActive(defaultOrderPaymentSetting.provider, appContext.distributor)
    ) {
      return defaultOrderPaymentSetting.provider
    } else if (customerPaymentProviders && customerPaymentProviders.length === 1) {
      return customerPaymentProviders[0]
    }
    return null
  }, [defaultOrderPaymentSetting, customerPaymentProviders, appContext.distributor])

  const suggestedMethodProvider = useMemo(() => {
    if (
      defaultOrderPaymentSetting &&
      defaultOrderPaymentSetting.method &&
      defaultOrderPaymentSetting.provider &&
      isPaymentMethodActive(defaultOrderPaymentSetting.method, defaultOrderPaymentSetting.provider, appContext.distributor)
    ) {
      return defaultOrderPaymentSetting.method
    } else if (customerPaymentMethods?.stripe && customerPaymentMethods.stripe.length === 1) {
      return customerPaymentMethods.stripe[0]
    } else if (customerPaymentMethods?.visma && customerPaymentMethods.visma.length === 1) {
      return customerPaymentMethods.visma[0]
    }

    return null
  }, [defaultOrderPaymentSetting, customerPaymentMethods, appContext.distributor])

  const paymentMethodOptions = useMemo(() => {
    const distributorPaymentMethodOptions = []
    const paymentSetting = appContext.distributor?.paymentSetting

    if (customerPaymentMethods?.stripe && selectedPaymentProvider.value === GQL.PaymentProviders.Stripe) {
      if (paymentSetting?.stripe?.paymentAlternatives?.inAdvance) {
        distributorPaymentMethodOptions.push(GQL.PaymentMethods.InAdvance)
      }
      if (paymentSetting?.stripe?.paymentAlternatives?.onDelivery) {
        distributorPaymentMethodOptions.push(GQL.PaymentMethods.OnDelivery)
      }
      if (paymentSetting?.stripe?.paymentAlternatives?.credit) {
        distributorPaymentMethodOptions.push(GQL.PaymentMethods.Credit)
      }
    } else if (customerPaymentMethods?.visma && selectedPaymentProvider.value === GQL.PaymentProviders.Visma) {
      if (paymentSetting?.visma?.paymentAlternatives?.credit) {
        distributorPaymentMethodOptions.push(GQL.PaymentMethods.Credit)
      }
    }
    return distributorPaymentMethodOptions
  }, [customerPaymentMethods, selectedPaymentProvider, appContext.distributor?.paymentSetting])

  const handlePaymentProviderChange = (event: any) => {
    // Reset the second select when the first select changes
    setSelectedPaymentMethod({
      value: null,
      label: '',
    })
    setSelectedPaymentProvider(event)
  }

  const [orderSummary, setOrderSummary] = useState<OrderSummary | undefined>()

  const { data: dataAllProducts, loading: loadingAllProducts } = GQL.useAllProducts({ variables: { bulk: cylinderGroup?.isBulk } })

  const availableProducts = useMemo(() => dataAllProducts?.availableProducts?.edges.map(edge => edge?.node as GQL.ProductNode) || [], [dataAllProducts])

  const automaticallyAddedProducts = useMemo(
    () => dataAllProducts?.automaticallyAddedProducts?.edges.map(edge => edge?.node as GQL.ProductNode) || [],
    [dataAllProducts]
  )

  const customerPricingCategory = useMemo(
    () => cylinderGroup?.customer.priceCategory || appContext.distributor?.priceCategories?.find(priceCategory => priceCategory?.default === true),
    [cylinderGroup, appContext.distributor]
  )

  const handleAutomaticProducts = useCallback(() => {
    setOrderSummary(prev =>
      !prev
        ? undefined
        : {
            ...prev,
            products: [
              ...(prev?.products || []),
              ...automaticallyAddedProducts
                .filter(service => !prev?.products.some(item => item.id === service.id))
                .map(service => {
                  const price = parseFloat(
                    service.productPrices?.find(productPrice => productPrice?.category.id === customerPricingCategory?.id)?.price ||
                      service.productPrices?.find(productPrice => productPrice?.category.default === true)?.price ||
                      0
                  )
                  const tax = (price * service.taxRate) / 100
                  return {
                    product: service,
                    quantity: 1,
                    exchange: false,
                    basePrice: price,
                    depositFee: 0,
                    subtotal: price,
                    tax: tax,
                    total: price + tax,
                  } as OrderProduct
                }),
            ],
          }
    )
  }, [automaticallyAddedProducts, setOrderSummary, customerPricingCategory])

  useEffect(() => {
    handleAutomaticProducts()
  }, [handleAutomaticProducts])

  useEffect(() => {
    setExternalReference(order?.externalReference || '')
  }, [order?.externalReference])

  useEffect(() => {
    setInvoiceReceiver(cylinderGroup?.customer?.contacts?.find(contact => contact?.invoiceReceiver && !contact.isDeleted))
    if (!!order) {
      // edit mode
      setOrderSummary({ notes: [], products: [] })
    } else {
      // create mode
      setSelectedPaymentProvider({
        value: suggestedPaymentProvider ? (suggestedPaymentProvider as GQL.PaymentProviders) : null,
        label: suggestedPaymentProvider ? enumToStringFormatted(suggestedPaymentProvider) : '',
      })
      setSelectedPaymentMethod({
        value: suggestedMethodProvider ? (suggestedMethodProvider as GQL.PaymentMethods) : null,
        label: suggestedMethodProvider ? enumToStringFormatted(suggestedMethodProvider) : '',
      })
    }
  }, [cylinderGroup, cylinderGroup?.customer, order, suggestedPaymentProvider, suggestedMethodProvider])

  const handleOnClose = () => {
    if (!!order) {
      setOrderSummary(undefined)
      setSelectedRange([
        latestEstimatedEmptyDate
          ? {
              startDate: new Date(),
              endDate: subDays(latestEstimatedEmptyDate, differenceInCalendarDays(latestEstimatedEmptyDate, new Date()) * 0.2),
              key: 'selection',
            }
          : {
              startDate: new Date(),
              endDate: addDays(new Date(), 3),
              key: 'selection',
            },
      ])
    }
    onClose()
  }

  const [createCustomerContact] = GQL.useCreateCustomerContact({
    onCompleted: response => {
      if (!!response.createCustomerContact?.warning) {
        displayToast(t({ id: 'customers.contact.create.success-with-warning' }), 'warning')
      } else {
        displayToast(t({ id: 'customers.contact.create.success' }), 'success')
      }
      setInvoiceReceiver(response.createCustomerContact?.customerContact as GQL.CustomerContactNode)
      setDraftInvoiceReceiver({
        name: '',
        phoneNumber: '',
        email: '',
        position: '',
        phoneNumberInvalid: true,
      })
    },
    onError: err => {
      displayToast(err.message, 'error')
    },
    update: (cache, { data }) => {
      cache.modify({
        fields: {
          allCustomerContacts(existingContacts) {
            return {
              ...existingContacts,
              edges: [...existingContacts.edges, { node: data?.createCustomerContact?.customerContact }],
            }
          },
        },
      })
    },
  })

  const [createCylinderGroupOrder, { loading: createOrderLoading }] = GQL.useCreateCylinderGroupOrderMutation({
    refetchQueries: ['AllActivePendingRefill', 'AllActiveReadyToRefill', 'AllActiveCylinderGroupOrders'],
    onCompleted: () => {
      displayToast(t({ id: 'customers.toasts.success-order-creation' }), 'success')
      setSelectedRange([
        latestEstimatedEmptyDate
          ? {
              startDate: new Date(),
              endDate: subDays(latestEstimatedEmptyDate, differenceInCalendarDays(latestEstimatedEmptyDate, new Date()) * 0.2),
              key: 'selection',
            }
          : {
              startDate: new Date(),
              endDate: addDays(new Date(), 3),
              key: 'selection',
            },
      ])
      setOrderSummary(undefined)
      handleOnClose()
      handleAutomaticProducts()
    },
    onError: () => {
      displayToast(t({ id: 'customers.toasts.error-order-creation' }))
    },
  })

  const [patchCylinderGroupOrder, { loading: patchOrderLoading }] = GQL.usePatchCylinderGroupOrder({
    onCompleted: () => {
      displayToast(t({ id: 'customers.toasts.success-order-editing' }), 'success')
      setSelectedRange([
        latestEstimatedEmptyDate
          ? {
              startDate: new Date(),
              endDate: subDays(latestEstimatedEmptyDate, differenceInCalendarDays(latestEstimatedEmptyDate, new Date()) * 0.2),
              key: 'selection',
            }
          : {
              startDate: new Date(),
              endDate: addDays(new Date(), 3),
              key: 'selection',
            },
      ])
      setOrderSummary(undefined)
      handleOnClose()
    },
    onError: () => {
      displayToast(t({ id: 'customers.toasts.error-order-editing' }))
    },
    update(_, { data }) {
      if (!data?.patchCylinderGroupOrder?.ok || !data.patchCylinderGroupOrder.cylinderGroupOrder) {
        displayToast(t({ id: 'orders.patch-order.error.generic' }), 'error')
        return
      }
      updateCylinderGroupOrderCacheOnPatch(data.patchCylinderGroupOrder.cylinderGroupOrder as GQL.CylinderGroupOrderNode, client)
    },
  })

  const customerOrderInterval = useMemo(() => {
    if (orders.length === 0) {
      return []
    }

    return orders
      ?.filter(e => e?.state === GQL.CylinderGroupOrderStates.Delivered)
      ?.map((order, i) => {
        if (i === 0) return differenceInCalendarDays(new Date(), parseISO(order?.createdAt))
        const prevOrder = parseISO(orders[i - 1]?.createdAt)
        const currOrder = parseISO(orders[i]?.createdAt)
        return differenceInCalendarDays(prevOrder, currOrder)
      })
      .filter(e => e)
  }, [orders])

  const cardHiddenElements = useMemo(() => {
    if (!selectedProduct || !selectedProduct.value) {
      return ['priceSummary', 'depositCheckbox']
    } else if (!selectedProduct.value.exchangeProduct || selectedProduct.value?.productPrices?.length === 0) {
      return ['depositCheckbox']
    }
    return []
  }, [selectedProduct])

  const productPricing = useMemo(() => {
    if (!customerPricingCategory || !selectedProduct.value?.productPrices || selectedProduct.value?.productPrices?.length === 0 || !selectedProduct.quantity) {
      return { subtotal: 0, depositFee: 0, total: 0, tax: 0, basePrice: 0 }
    } else {
      const productPrice =
        selectedProduct.value.productPrices.find(productPrice => productPrice?.category.id === customerPricingCategory.id)?.price ||
        selectedProduct.value.productPrices.find(productPrice => productPrice?.category.default === true)?.price ||
        0

      const depositFee =
        selectedProduct.exchange && selectedProduct.value.productDepositPrices
          ? selectedProduct.value.productDepositPrices.find(productDepositPrice => productDepositPrice?.category.id === customerPricingCategory.id)?.price ||
            selectedProduct.value.productDepositPrices.find(productDepositPrice => productDepositPrice?.category.default === true)?.price ||
            0
          : 0
      const subtotal = productPrice * selectedProduct.quantity + depositFee * selectedProduct.quantity
      const itemTax = (productPrice * selectedProduct.quantity * selectedProduct.value.taxRate) / 100
      const depositTax = (depositFee * selectedProduct.quantity * selectedProduct.value.depositTaxRate) / 100

      return {
        basePrice: productPrice,
        subtotal: subtotal,
        depositFee: depositFee,
        tax: depositTax + itemTax,
        total: subtotal + depositTax + itemTax,
      }
    }
  }, [selectedProduct, customerPricingCategory])

  const orderSummaryPricing = useMemo(() => {
    const initialSum = { depositFee: 0, subtotal: 0, total: 0, tax: 0 }
    if (!orderSummary?.products) {
      return initialSum
    }
    const summary = orderSummary.products.reduce((accumulator, product) => {
      accumulator.depositFee += product.depositFee
      accumulator.subtotal += product.subtotal
      accumulator.tax += product.tax
      accumulator.total += product.total
      return accumulator
    }, initialSum)

    return summary
  }, [orderSummary])

  const orderSummaryPickups = useMemo(() => {
    const initialSum = { toDeliver: 0, toPickup: 0 }
    if (!orderSummary?.products) {
      return initialSum
    }
    const summary = orderSummary.products.reduce((accumulator, product) => {
      if (!Object.values(GQL.GasTypeEnum).includes(product.product.type as unknown as GQL.GasTypeEnum)) {
        return accumulator
      } else if (!product.exchange) {
        accumulator.toPickup += product.quantity
      }
      accumulator.toDeliver += product.quantity
      return accumulator
    }, initialSum)

    return summary
  }, [orderSummary])

  useClickOutside(
    ref,
    () => {
      setOrderProductActionOpen(undefined)
      setOrderNoteActionOpen(undefined)
    },
    false,
    true
  )

  useClickOutside(
    paymentDueCalendarRef,
    () => {
      setShowDueDateCalendar(false)
    },
    false,
    true
  )

  const handleSetDate = (date: Date) => {
    setDueDate(date)
    setShowDueDateCalendar(false)
  }

  const addOrderNote = () => {
    if (!noteContent) {
      return displayToast(t({ id: 'orders.create.notes.error-length' }))
    } else if (orderSummary?.notes?.includes(noteContent)) {
      return displayToast(t({ id: 'orders.create.notes.error-exists' }))
    }
    const newNotes = [...(orderSummary?.notes || []), noteContent]
    setOrderSummary({ ...orderSummary, products: orderSummary?.products || [], notes: newNotes })
    setNoteContent('')
  }

  const addToOrder = () => {
    if (!selectedProduct.value) {
      displayToast(t({ id: 'orders.create.no-selected-item' }))
      return
    }
    if (!selectedProduct.quantity) {
      displayToast(t({ id: 'orders.create.no-quantity' }))
      return
    }

    const currentOrderProducts = orderSummary?.products || []
    const similarExists = !!order
      ? -1
      : currentOrderProducts.findIndex(
          orderProduct => orderProduct.product.id === selectedProduct.value?.id && orderProduct.exchange === selectedProduct.exchange
        )
    if (similarExists === -1) {
      currentOrderProducts.push({
        product: selectedProduct.value,
        quantity: selectedProduct.quantity,
        exchange: selectedProduct.exchange,
        subtotal: productPricing.subtotal,
        total: productPricing.total,
        depositFee: productPricing.depositFee,
        tax: productPricing.tax,
        basePrice: productPricing.basePrice,
      })
    } else {
      currentOrderProducts[similarExists] = {
        product: currentOrderProducts[similarExists].product,
        quantity: currentOrderProducts[similarExists].quantity + selectedProduct.quantity,
        exchange: currentOrderProducts[similarExists].exchange,
        subtotal: currentOrderProducts[similarExists].subtotal + productPricing.subtotal,
        total: currentOrderProducts[similarExists].total + productPricing.total,
        depositFee: currentOrderProducts[similarExists].depositFee + productPricing.depositFee,
        tax: currentOrderProducts[similarExists].tax + productPricing.tax,
        basePrice: productPricing.basePrice,
      }
    }
    setOrderSummary({ ...orderSummary, products: currentOrderProducts })
    setSelectedProduct({
      value: defaultProduct,
      label: defaultProduct
        ? defaultProduct.displayName || defaultProduct.name || `${defaultProduct.weight} kg of ${defaultProduct.type}`
        : t({ id: 'orders.create.select-item' }),
      quantity: defaultQuantity || 1,
      exchange: defaultProduct?.exchangeProduct || false,
    })
    displayToast(t({ id: 'orders.create.select-item.success' }), 'success')
  }

  const handleCreateOrder = () => {
    if (!orderSummary || !orderSummary.products) {
      displayToast(t({ id: 'customers.toasts.error-select-product' }))
      return
    }

    if (!cylinderGroup) {
      displayToast(t({ id: 'customers.toasts.error-select-customer' }))
      return
    }

    if (!selectedRange[0].startDate || !selectedRange[0].endDate) {
      displayToast(t({ id: 'customers.toasts.error-order-creation-delivery-date' }))
      return
    }

    if (orderSummary.products.find(order => order.quantity <= 0 || !order.quantity)) {
      displayToast(t({ id: 'customers.toasts.error-order-quantity' }))
      return
    }

    if (orderSummary.products.find(order => !order.product)) {
      displayToast(t({ id: 'customers.toasts.error-select-product' }))
      return
    }

    const input: GQL.CreateCylinderGroupOrderInput = {
      cylinderGroup: cylinderGroup.id,
      orderProducts: orderSummary.products.map(orderProduct => ({
        product: orderProduct.product.id,
        quantity: orderProduct.quantity,
        exchangeCylindersFee: orderProduct.exchange,
      })),
      orderedBy: appContext.distributor?.id,
      lastPossibleDeliveryDate: selectedRange[0].endDate && format(selectedRange[0].endDate, 'yyyy-MM-dd'),
      firstPossibleDeliveryDate: selectedRange[0].startDate && format(selectedRange[0].startDate, 'yyyy-MM-dd'),
      notes: orderSummary.notes,
      paymentDue: dueDate,
      externalReference: externalReference,
    }

    if (selectedPaymentProvider.value && selectedPaymentMethod.value) {
      input.paymentInput = {
        provider: selectedPaymentProvider.value,
        method: selectedPaymentMethod.value,
      }
    }

    createCylinderGroupOrder({
      variables: {
        input,
      },
    })
  }

  const handleUpdateOrder = (order: GQL.CylinderGroupOrderNode) => {
    if (!orderSummary || !orderSummary.products) {
      displayToast(t({ id: 'customers.toasts.error-select-product' }))
      return
    }

    if (!selectedRange[0].startDate || !selectedRange[0].endDate) {
      displayToast(t({ id: 'customers.toasts.error-order-creation-delivery-date' }))
      return
    }

    if (orderSummary.products.find(order => order.quantity <= 0 || !order.quantity)) {
      displayToast(t({ id: 'customers.toasts.error-order-quantity' }))
      return
    }

    if (orderSummary.products.find(order => !order.product)) {
      displayToast(t({ id: 'customers.toasts.error-select-product' }))
      return
    }

    patchCylinderGroupOrder({
      variables: {
        id: order.id,
        input: {
          orderProducts: orderSummary.products.map(orderProduct => ({
            id: orderProduct.id,
            productId: orderProduct.product.id,
            quantity: orderProduct.quantity,
            exchangeCylindersFee: orderProduct.exchange,
          })),
          lastPossibleDelivery: selectedRange[0].endDate && format(selectedRange[0].endDate, 'yyyy-MM-dd'),
          firstPossibleDelivery: selectedRange[0].startDate && format(selectedRange[0].startDate, 'yyyy-MM-dd'),
          notes: orderSummary.notes,
          paymentDue: dueDate,
          externalReference: externalReference,
        },
      },
    })
  }

  const handleCreateCustomerContact = () => {
    if (!cylinderGroup || !cylinderGroup.customer) {
      displayToast(
        isPaymentEnabled
          ? t({ id: 'customers.orders.create.invoice-receiver.no-customer-selected' })
          : t({ id: 'customers.orders.create.receipt-receiver.no-customer-selected' })
      )
    } else if (!draftInvoiceReceiver.name || draftInvoiceReceiver.name.length < 3) {
      displayToast(
        isPaymentEnabled
          ? t({ id: 'customers.orders.create.contact-person.invoice-receiver.invalid-name' })
          : t({ id: 'customers.orders.create.contact-person.receipt-receiver.invalid-name' })
      )
    } else if (!draftInvoiceReceiver.email || !EMAIL_REGEX.test(draftInvoiceReceiver.email)) {
      displayToast(
        isPaymentEnabled
          ? t({ id: 'customers.orders.create.contact-person.invoice-receiver.invalid-email' })
          : t({ id: 'customers.orders.create.contact-person.receipt-receiver.invalid-email' })
      )
    } else if (draftInvoiceReceiver.phoneNumberInvalid) {
      displayToast(
        isPaymentEnabled
          ? t({ id: 'customers.orders.create.contact-person.invoice-receiver.invalid-phone' })
          : t({ id: 'customers.orders.create.contact-person.receipt-receiver.invalid-phone' })
      )
    } else {
      createCustomerContact({
        variables: {
          input: {
            customer: cylinderGroup.customer.id,
            name: draftInvoiceReceiver.name,
            phone: draftInvoiceReceiver.phoneNumber,
            email: draftInvoiceReceiver.email,
            position: draftInvoiceReceiver.position,
            invoiceReceiver: true,
          },
        },
      })
    }
  }

  const handleOnSubmit = () => {
    if (!cylinderGroup || !cylinderGroup.customer) {
      displayToast(t({ id: 'customers.orders.create.no-customer-selected' }))
    } else if (!invoiceReceiver && stripePaymentsEnabled(appContext)) {
      displayToast(
        paymentEnabled(appContext) ? t({ id: 'customers.orders.create.no-invoice-receiver' }) : t({ id: 'customers.orders.create.no-receipt-receiver' })
      )
    } else if (!!order) return handleUpdateOrder(order)
    else {
      handleCreateOrder()
    }
  }

  const cardTitle = useMemo(() => {
    if (!!order) {
      if (cylinderGroup?.isBulk) {
        return t({ id: 'orders.edit-booking' }, { orderId: grapheneIdToInt(order.id) })
      }
      return t({ id: 'orders.edit-order' }, { orderId: grapheneIdToInt(order.id) })
    }
    if (cylinderGroup?.isBulk) {
      return t({ id: 'orders.create-booking' })
    }
    return t({ id: 'orders.create-order' })
  }, [cylinderGroup, order, t])

  useEffect(() => {
    if (!order || !!orderSummary) return
    setOrderSummary({
      products:
        order.products?.map(orderProduct => {
          const productPrice = orderProduct?.price || 0
          const depositFee = orderProduct?.exchangeCylindersFee && orderProduct?.cylinderDepositPrice ? parseFloat(orderProduct.cylinderDepositPrice) : 0
          const subtotal = productPrice * (orderProduct?.quantity || 0) + depositFee * (orderProduct?.quantity || 0)
          const itemTax = (productPrice * (orderProduct?.quantity || 0) * (orderProduct?.taxRate || 0)) / 100
          const depositTax = (depositFee * (orderProduct?.quantity || 0) * (orderProduct?.depositTaxRate || 0)) / 100

          return {
            id: orderProduct?.id,
            product: orderProduct?.product,
            quantity: orderProduct?.quantity,
            exchange: orderProduct?.exchangeCylindersFee,
            basePrice: productPrice,
            subtotal: subtotal,
            depositFee: depositFee,
            tax: depositTax + itemTax,
            total: subtotal + depositTax + itemTax,
          } as OrderProduct
        }) || [],
      notes: order.notes?.map(note => note?.content || '') || [],
    })
  }, [order, orderSummary, setOrderSummary, customerPricingCategory])

  useEffect(() => {
    setSelectedProduct({
      value: defaultProduct,
      label: defaultProduct ? defaultProduct.displayName || `${defaultProduct.weight} kg of ${defaultProduct.type}` : t({ id: 'orders.create.select-item' }),
      quantity: defaultQuantity || 1,
      exchange: false,
    })
  }, [defaultProduct, defaultQuantity, t])

  useEffect(() => {
    setSelectedRange([
      latestEstimatedEmptyDate
        ? {
            startDate: new Date(),
            endDate: subDays(latestEstimatedEmptyDate, differenceInCalendarDays(latestEstimatedEmptyDate, new Date()) * 0.2),
            key: 'selection',
          }
        : {
            startDate: new Date(),
            endDate: addDays(new Date(), 3),
            key: 'selection',
          },
    ])
  }, [latestEstimatedEmptyDate])

  useEffect(() => {
    if (!order) return
    setSelectedRange([
      {
        startDate: new Date(order.firstPossibleDelivery),
        endDate: new Date(order.lastPossibleDelivery),
        key: 'selection',
      },
    ])
    setDueDate(new Date(order.paymentDue))
  }, [order])

  return (
    <CreateOrderModuleExtended
      editOrder={!!order}
      selectCustomerDiv={selectCustomerDiv}
      asModal={asModal}
      hideReciever={!cylinderGroup || !cylinderGroup.customer}
      labelSummary={!!cylinderGroup?.isBulk || !!selectedProduct.value?.isBulk ? 'Booking summary' : 'Order summary'}
      title={cardTitle}
      hasActiveOrder={!order && !!activeOrders && activeOrders.length > 0}
      activeOrders={
        <>
          <AlertMessage message={t({ id: 'orders.create-order.alert-message.active-order-found' })} />
          {activeOrders?.map(order => (
            <OrderInfoExtended
              key={order?.id!}
              closed
              vertical
              border={'orange'}
              hasOrderNote={order.notes ? order.notes.length > 0 : false}
              orderNotes={order.notes?.map(note => <NoteElement key={note?.id} content={note?.content} />)}
              deliveryComment={order.comment}
              order={order as GQL.CylinderGroupOrderNode}
            />
          ))}
        </>
      }
      createOrderSelectProductsParent={{
        props: {
          createOrderBookingBulk: {
            props: {
              hideInputs: !selectedProduct.value,
              cylinderGroup: cylinderGroup,
              products: availableProducts,
              selectedProduct: selectedProduct,
              setSelectedProduct: setSelectedProduct,
              estimatedPrice: formatPrice(productPricing.subtotal, appContext.distributor?.defaultCurrency || 'USD'),
              estimatedTotal: formatPrice(productPricing.total, appContext.distributor?.defaultCurrency || 'USD'),
              tax: formatPrice(productPricing.tax, appContext.distributor?.defaultCurrency || 'USD'),
              btnAddToOrder: {
                onClick: () => addToOrder(),
              },
            },
          },
          booking: selectedProduct?.value?.isGasType ? cylinderGroup?.isBulk : false,
          hide: cardHiddenElements,
          selectProductDiv: (
            <Select
              placeholder={loadingAllProducts ? <Loader color='white' /> : t({ id: 'orders.create.select-item' })}
              components={{ Option, Control, MenuList }}
              styles={reactSelectStyles}
              isMulti={false}
              onChange={event => {
                if (event.value.isBulk && !cylinderGroup) {
                  displayToast(t({ id: 'orders.create-booking.alert-message.product-select.no-customer' }))
                } else {
                  setSelectedProduct(event)
                }
              }}
              value={
                {
                  value: selectedProduct.value || '',
                  label: selectedProduct.label,
                  quantity: selectedProduct.quantity,
                } as SelectedProduct
              }
              options={availableProducts?.map(
                product =>
                  ({
                    value: product,
                    label: product.displayName,
                    quantity: 1,
                    exchange: false,
                  }) as SelectedProduct
              )}
            />
          ),
          inputQuantity: {
            undefinedInput: {
              type: 'number',
              name: 'quantity',
              min: 1,
              value: selectedProduct.quantity || '',
              onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
                setSelectedProduct({ ...selectedProduct, quantity: Math.abs(parseInt(e.target.value)) })
              },
            },
          },
          checkboxExchangeCylinders: {
            isChecked: selectedProduct.exchange,
            onChange: (selected: boolean) => setSelectedProduct({ ...selectedProduct, exchange: selected }),
          },
          addSubtotal: formatPrice(productPricing.subtotal, appContext.distributor?.defaultCurrency || 'USD'),
          addDeposit: formatPrice(productPricing.depositFee, appContext.distributor?.defaultCurrency || 'USD'),
          addTax: formatPrice(productPricing.tax, appContext.distributor?.defaultCurrency || 'USD'),
          addTotal: formatPrice(productPricing.total, appContext.distributor?.defaultCurrency || 'USD'),
          btnAddToOrder: {
            onClick: () => addToOrder(),
          },
        },
      }}
      createOrderSummary={{
        props: {
          'data-testid': 'order-summary-card',
          noProducts: !orderSummary?.products || orderSummary?.products.length === 0,
          labelTotal: cylinderGroup?.isBulk ? 'Estimated total' : 'Total',
          bulk: !!cylinderGroup?.isBulk,
          productRows: orderSummary?.products.map((orderProduct, index) => (
            <OrderProductRowExtended
              key={index + 'order-product'}
              actions
              service={!Object.values(GQL.GasTypeEnum).includes(orderProduct.product.type as unknown as GQL.GasTypeEnum)}
              product={orderProduct.product.displayName}
              productImage={orderProduct.product.image?.image || cylinderPlaceholderFillsvg}
              quantity={`${cylinderGroup?.isBulk && !!orderProduct.product.isGasType ? `${orderProduct.quantity} liters` : orderProduct.quantity}`}
              deposit={formatPrice(orderProduct.depositFee, appContext.distributor?.defaultCurrency || 'USD')}
              price={formatPrice(orderProduct.basePrice, appContext.distributor?.defaultCurrency || 'USD')}
              total={formatPrice(orderProduct.total, appContext.distributor?.defaultCurrency || 'USD')}
              priceTax={`+${formatTaxRate(orderProduct.product.taxRate, appContext.distributor)}`}
              depositTax={`+${formatTaxRate(orderProduct.product.depositTaxRate, appContext.distributor)}`}
              moreActions={{
                open: !orderProduct.product.addAutomatically && orderProductActionOpen === index,
                ref: orderProductActionOpen === index ? ref : null,
                onClick: () => setOrderProductActionOpen(index === orderProductActionOpen ? undefined : index),
                actionDropdown: (
                  <>
                    <ActionDropdownLine
                      children={t({ id: 'common.remove-item' })}
                      onClick={() => {
                        if (orderProduct.product.addAutomatically) return
                        const orderProducts = orderSummary.products
                        orderSummary.products.splice(index, 1)
                        setOrderSummary({ ...orderSummary, products: orderProducts })
                      }}
                    />
                  </>
                ),
              }}
            />
          )),
          tax: formatPrice(orderSummaryPricing.tax, appContext.distributor?.defaultCurrency || 'USD'),
          subtotal: formatPrice(orderSummaryPricing.subtotal, appContext.distributor?.defaultCurrency || 'USD'),
          total: formatPrice(orderSummaryPricing.total, appContext.distributor?.defaultCurrency || 'USD'),
          orderDate: format(new Date(), 'dd.MM.yyyy'),
          orderedBy: appContext.user?.fullName,
          productsToDeliver: cylinderGroup?.isBulk ? `${orderSummaryPickups.toDeliver} liters` : orderSummaryPickups.toDeliver || '-',
          productsToPickUp: orderSummaryPickups.toPickup || '-',
        },
      }}
      inputExternalReference={{
        undefinedInput: {
          value: externalReference,
          name: 'external-reference',
          onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
            setExternalReference(e.target.value)
          },
        },
      }}
      selectPaymentProviderDiv={
        <Select
          placeholder={t({ id: 'orders.create.select-item' })}
          components={{ Option, Control, MenuList }}
          isDisabled={!!order}
          styles={reactSelectStyles}
          isMulti={false}
          onChange={event => handlePaymentProviderChange(event)}
          value={selectedPaymentProvider.value ? selectedPaymentProvider : t({ id: 'orders.create.select-item' })}
          options={customerPaymentProviders?.map(paymentProvider => ({
            value: paymentProvider,
            label: paymentProvider ? enumToStringFormatted(paymentProvider) : '',
          }))}
        />
      }
      paymentMethodWrapper={selectedPaymentProvider.value ? undefined : { render: () => null }}
      selectPaymentMethodDiv={
        <Select
          placeholder={t({ id: 'orders.create.select-item' })}
          components={{ Option, Control, MenuList }}
          styles={reactSelectStyles}
          isMulti={false}
          isDisabled={!!order}
          onChange={event => setSelectedPaymentMethod(event)}
          value={selectedPaymentMethod}
          options={paymentMethodOptions.map(paymentMethod => ({
            value: paymentMethod,
            label: paymentMethod ? enumToStringFormatted(paymentMethod) : '',
          }))}
        />
      }
      inputPaymentDueDate={{
        undefinedInput: {
          onFocus: () => setShowDueDateCalendar(true),
          value: dueDate,
        },
      }}
      datePickerDueDateDiv={{
        props: {
          ref: paymentDueCalendarRef,
          style: { display: showDueDateCalendar ? 'block' : 'none' },
          children: (
            <div>
              <Calendar minDate={new Date()} onChange={date => handleSetDate(date)} date={dueDate} />
            </div>
          ),
        },
      }}
      btnDueDateAdd={{
        onClick: () => {
          setDueDate(prev => new Date(prev.getTime() + 24 * 60 * 60 * 1000))
        },
      }}
      btnDueDateSubtract={{
        onClick: () => {
          const pastDate = new Date(dueDate.getTime())
          pastDate.setDate(pastDate.getDate() - 1)
          const today = new Date()

          if (today.getTime() <= pastDate.getTime()) {
            setDueDate(pastDate)
          }
        },
      }}
      paymentDueDateDays={differenceInCalendarDays(dueDate, new Date()).toString()}
      averageOrderInterval={
        customerOrderInterval && customerOrderInterval?.length > 0
          ? Math.round((customerOrderInterval.reduce((a, b) => a + b) / customerOrderInterval.length + Number.EPSILON) * 10) / 10 + ' days'
          : t({ id: 'common.no-delivered-orders' })
      }
      btnViewLastOrder={{
        onClick: () => (orders.length === 0 ? displayToast(t({ id: 'common.no-data-to-show' })) : window.open(`/order/pdf/${orders[0].id}`, '_blank')),
      }}
      inputDeliveryNote={{
        undefinedInput: {
          value: noteContent,
          onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
            setNoteContent(e.target.value)
          },
        },
      }}
      btnCreateDeliveryNote={{
        onClick: () => addOrderNote(),
        style: { display: noteContent ? 'flex' : 'none' },
      }}
      deliveryNotes={
        !orderSummary?.notes || orderSummary.notes.length === 0 ? (
          <></>
        ) : (
          orderSummary?.notes?.map((note, index) => (
            <NoteElement
              key={note}
              content={note}
              btnMoreActions={{
                open: orderNoteActionOpen === index,
                ref: orderNoteActionOpen === index ? ref : null,
                onClick: () => setOrderNoteActionOpen(index === orderNoteActionOpen ? undefined : index),
                actionDropdown: (
                  <>
                    <ActionDropdownLine
                      children={t({ id: 'common.remove-item' })}
                      onClick={() => {
                        const notes = orderSummary.notes || []
                        notes.splice(index, 1)
                        setOrderSummary({ ...orderSummary, notes: notes })
                      }}
                    />
                  </>
                ),
              }}
            />
          ))
        )
      }
      calendar={
        <DatePickerCalendar
          emptyDate={latestEstimatedEmptyDate}
          selectedRange={selectedRange}
          setSelectedRange={setSelectedRange}
          closedDays={getClosedDays(cylinderGroup)}
        />
      }
      invoiceReceiverCard={{
        name: invoiceReceiver && invoiceReceiver.name,
        phoneNumber: invoiceReceiver && invoiceReceiver.phone,
        email: invoiceReceiver && invoiceReceiver.email,
        position: invoiceReceiver && invoiceReceiver.position,
        noContact: !invoiceReceiver,
        modalContact: {
          inputName: {
            alert: draftInvoiceReceiver.name.length < 3,
            value: draftInvoiceReceiver.name,
            fieldStatus: true,
            onChange: (e: React.ChangeEvent<HTMLInputElement>) => setDraftInvoiceReceiver({ ...draftInvoiceReceiver, name: e.target.value }),
          },
          inputPhone: {
            initialValue: '',
            props: {
              alert: draftInvoiceReceiver.phoneNumberInvalid,
              divPhoneNumber: (
                <PhoneInput
                  defaultValue={draftInvoiceReceiver.phoneNumber}
                  error={false}
                  onPhoneNumberChange={(...all) => {
                    const value = all[3].replace(/\s|-/g, '')
                    if (!all[0]) {
                      setDraftInvoiceReceiver({ ...draftInvoiceReceiver, phoneNumber: value, phoneNumberInvalid: true })
                    } else {
                      setDraftInvoiceReceiver({ ...draftInvoiceReceiver, phoneNumber: value, phoneNumberInvalid: false })
                    }
                  }}
                  onSelectFlag={(...all) => {
                    if (!all[3]) {
                      setDraftInvoiceReceiver({ ...draftInvoiceReceiver, phoneNumber: all[2].replace(/\s|-/g, ''), phoneNumberInvalid: true })
                    } else {
                      setDraftInvoiceReceiver({ ...draftInvoiceReceiver, phoneNumber: all[2].replace(/\s|-/g, ''), phoneNumberInvalid: false })
                    }
                  }}
                  height='40px'
                />
              ),
            },
          },
          inputEmail: {
            alert: !EMAIL_REGEX.test(draftInvoiceReceiver.email),
            value: draftInvoiceReceiver.email,
            fieldStatus: true,
            onChange: (e: React.ChangeEvent<HTMLInputElement>) => setDraftInvoiceReceiver({ ...draftInvoiceReceiver, email: e.target.value }),
          },
          inputPosition: {
            alert: false,
            value: draftInvoiceReceiver.position,
            fieldStatus: true,
            onChange: (e: React.ChangeEvent<HTMLInputElement>) => setDraftInvoiceReceiver({ ...draftInvoiceReceiver, position: e.target.value }),
          },
          btnSave: {
            onClick: () => handleCreateCustomerContact(),
          },
        },
      }}
      alertMessages={
        <>
          {!!order && Math.abs(differenceInCalendarDays(new Date(order.paymentDue), dueDate)) > 0 && (
            <AlertMessage message={t({ id: 'orders.create-order.alert-message.due-date-changed' })} />
          )}
          {!!order &&
            (new Date(order.firstPossibleDelivery).toDateString() !== new Date(selectedRange[0]?.startDate || '').toDateString() ||
              new Date(order.lastPossibleDelivery).toDateString() !== new Date(selectedRange[0]?.endDate || '').toDateString()) && (
              <AlertMessage message={t({ id: 'orders.create-order.alert-message.delivery-date-changed' })} />
            )}
          {!!order &&
            (orderSummary?.products.length !== order.products?.length ||
              !orderSummary?.products.every(
                (orderProduct, index) =>
                  !!order?.products &&
                  orderProduct.product.id === order?.products[index]?.product?.id &&
                  orderProduct.quantity === order?.products[index]?.quantity &&
                  orderProduct.exchange === order?.products[index]?.exchangeCylindersFee
              )) && <AlertMessage message={t({ id: 'orders.create-order.alert-message.order-summary-change' })} />}
          {!invoiceReceiver && cylinderGroup && <AlertMessage message={t({ id: 'orders.create-order.alert-message.missing-contact-person' })} />}
        </>
      }
      btnClose={{
        onClick: handleOnClose,
      }}
      btnConfirmOrder={{
        label: !!cylinderGroup?.isBulk ? 'Save booking' : 'Confirm order',
        loading: createOrderLoading || patchOrderLoading,
        onClick: handleOnSubmit,
      }}
    />
  )
}
