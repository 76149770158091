import React, { useMemo, useState } from 'react'
import Modal, { ModalProps } from 'components/Modal/Modal'
import { format } from 'date-fns'
import Select from 'react-select'
import styled from 'styled-components'
import { useIntl } from 'react-intl'

import { Control, Option, reactSelectStyles } from 'plasmic/StyledReactSelect'
import * as GQL from 'generated/graphql'
import { useDebounce } from 'util/hooks'
import ButtonFill from 'plasmic/ButtonFill'
import { displayToast } from 'util/toasts'

const PAGE_SIZE = 15
const Wrapper = styled.div`
  margin: 1rem 0;
  display: flex;
  flex-direction: column;

  h4 {
    margin: 10px 0;
  }

  button {
    margin-top: 30px;
  }
`
interface IChangeDepotModalProps extends ModalProps {
  date: Date
  driver: GQL.DriverNode
}

const ChangeDepotModal: React.FC<IChangeDepotModalProps> = ({ date, driver, onRequestClose, ...props }) => {
  const dateException = driver?.exceptions?.find(exception => exception?.onDate === format(date, 'yyyy-MM-dd'))
  const dayDepot = dateException && dateException.defaultDepot ? dateException.defaultDepot : driver?.defaultDepot || undefined

  const [search, setSearch] = useState('')
  const [selectedDepot, setSelectedDepot] = useState<GQL.DepotNode | undefined>(dayDepot)

  const debouncedSearch = useDebounce(search, 500)
  const intl = useIntl()
  const t = intl.formatMessage

  const { data: dataDepots, loading: depotsLoading } = GQL.useAllDepots({
    variables: {
      first: PAGE_SIZE,
      q: debouncedSearch,
    },
  })

  const depots = useMemo(() => {
    return (dataDepots?.allDepots?.edges.map(edge => edge?.node) as GQL.DepotNode[]) || []
  }, [dataDepots])

  const [createDriverException] = GQL.useCreateDriverExceptions({
    refetchQueries: ['AllDriversDeliveriesDatesPage'],
    onCompleted: response => {
      if (response.createDriverExceptions?.driverExceptions) {
        displayToast(t({ id: 'deliveries.dates.depot.exception.update.success' }), 'success')
      } else {
        displayToast(t({ id: 'deliveries.dates.depot.exception.update.error' }))
      }
    },
    onError: () => {
      displayToast(t({ id: 'deliveries.dates.depot.exception.update.error' }))
    },
  })

  const [patchDriverException] = GQL.usePatchDriverExceptions({
    refetchQueries: ['AllDriversDeliveriesDatesPage'],
    onCompleted: response => {
      if (response.patchDriverExceptions?.driverExceptions) {
        displayToast(t({ id: 'deliveries.dates.depot.exception.update.success' }), 'success')
      } else {
        displayToast(t({ id: 'deliveries.dates.depot.exception.update.error' }))
      }
    },
    onError: () => {
      displayToast(t({ id: 'deliveries.dates.depot.exception.update.error' }))
    },
  })

  function onSubmit() {
    if (!selectedDepot) {
      return displayToast(t({ id: 'deliveries.dates.depot.exception.update.error.missing-vehicle' }))
    }

    if (!!dateException) {
      patchDriverException({
        variables: {
          id: dateException.id,
          input: {
            defaultDepot: selectedDepot.id,
            vacated: false,
          },
        },
      })
    } else {
      createDriverException({
        variables: {
          input: {
            defaultDepot: selectedDepot.id,
            driver: driver.id,
            onDate: format(date, 'yyyy-MM-dd'),
            vacated: false,
          },
        },
      })
    }
    onRequestClose()
  }

  return (
    <Modal
      title={format(date, 'EEEE, dd. LLLL') + ' (' + driver.user.fullName + ')'}
      onRequestClose={onRequestClose}
      {...props}
      contentStyle={{ width: 500, backgroundColor: '#242c48' }}
    >
      <Wrapper>
        <h4>{t({ id: 'common.select-depot' })}</h4>
        <Select
          placeholder={'Search for a depot...'}
          value={{ value: selectedDepot, label: selectedDepot?.name || '' }}
          isMulti={false}
          options={depots.map(depot => ({
            value: depot,
            label: depot.name || '',
            highlightedText: depot.id === driver?.defaultDepot?.id ? 'Default' : '',
          }))}
          isLoading={depotsLoading}
          onInputChange={value => setSearch(value)}
          isSearchable
          onChange={event => {
            setSelectedDepot(event?.value)
          }}
          components={{ Option, Control }}
          styles={reactSelectStyles}
        />
        <ButtonFill rounded color={'blue'} label={t({ id: 'common.save' })} onClick={() => onSubmit()} />
      </Wrapper>
    </Modal>
  )
}

export default ChangeDepotModal
