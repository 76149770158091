import React, { useMemo, useState } from 'react'
import { useIntl } from 'react-intl'
import styled from 'styled-components'
import { format } from 'date-fns'
import { time } from 'lib/time'

import Modal, { ModalProps } from 'components/Modal/Modal'
import TimeRangeSelect from 'components/TimeSelect/TimeRangeSelect'
import * as GQL from 'generated/graphql'
import ButtonFill from 'plasmic/ButtonFill'
import { displayToast } from 'util/toasts'
import { getZeroBasedWeekday } from 'util/date-fns/custom'

const Wrapper = styled.div`
  margin: 1rem 0;
  display: flex;
  flex-direction: column;

  h4 {
    margin: 10px 0;
  }

  button {
    margin-top: 30px;
  }
`

interface IChangeWorkhoursModalProps extends ModalProps {
  date: Date
  driver: GQL.DriverNode
}

const ChangeWorkhoursModal: React.FC<IChangeWorkhoursModalProps> = ({ date, driver, onRequestClose, ...props }) => {
  const dateException = driver?.exceptions?.find(exception => exception?.onDate === format(date, 'yyyy-MM-dd'))

  const driverWorkingHours = useMemo(() => {
    return driver.standardWorkhours?.find(sw => sw?.dow === getZeroBasedWeekday(date))
  }, [driver, date])

  const [selectedStartTime, setSelectedStartTime] = useState(
    dateException && dateException.startTime ? time(dateException.startTime) : time(driverWorkingHours?.startTime)
  )
  const [selectedEndTime, setSelectedEndTime] = useState(
    dateException && dateException.endTime ? time(dateException.endTime) : time(driverWorkingHours?.endTime)
  )

  const intl = useIntl()
  const t = intl.formatMessage

  const [createDriverException] = GQL.useCreateDriverExceptions({
    refetchQueries: ['AllDriversDeliveriesDatesPage'],
    onCompleted: response => {
      if (response.createDriverExceptions?.driverExceptions) {
        displayToast(t({ id: 'deliveries.dates.workhours.exception.update.success' }), 'success')
      } else {
        displayToast(t({ id: 'deliveries.dates.workhours.exception.update.error' }))
      }
    },
    onError: () => {
      displayToast(t({ id: 'deliveries.dates.workhours.exception.update.error' }))
    },
  })

  const [patchDriverException] = GQL.usePatchDriverExceptions({
    refetchQueries: ['AllDriversDeliveriesDatesPage'],
    onCompleted: response => {
      if (response.patchDriverExceptions?.driverExceptions) {
        displayToast(t({ id: 'deliveries.dates.workhours.exception.update.success' }), 'success')
      } else {
        displayToast(t({ id: 'deliveries.dates.workhours.exception.update.error' }))
      }
    },
    onError: () => {
      displayToast(t({ id: 'deliveries.dates.workhours.exception.update.error' }))
    },
  })

  function onSubmit() {
    if (!selectedStartTime || !selectedEndTime) {
      return displayToast(t({ id: 'deliveries.dates.workhours.exception.update.error.missing-time' }))
    } else if (!selectedStartTime.isBefore(selectedEndTime)) {
      return displayToast(t({ id: 'deliveries.dates.workhours.exception.update.error.time-invalid' }))
    }

    if (!!dateException) {
      patchDriverException({
        variables: {
          id: dateException.id,
          input: {
            startTime: selectedStartTime.format(),
            endTime: selectedEndTime.format(),
            vacated: false,
          },
        },
      })
    } else {
      createDriverException({
        variables: {
          input: {
            startTime: selectedStartTime.format(),
            endTime: selectedEndTime.format(),
            driver: driver.id,
            onDate: format(date, 'yyyy-MM-dd'),
            vacated: false,
          },
        },
      })
    }
    onRequestClose()
  }

  return (
    <Modal
      title={format(date, 'EEEE, dd. LLLL') + ' (' + driver?.user?.fullName + ')'}
      onRequestClose={onRequestClose}
      {...props}
      contentStyle={{ width: 500, backgroundColor: '#242c48' }}
    >
      <Wrapper>
        <h4>{t({ id: 'activities.working-hours' })}</h4>
        <div
          style={{
            position: 'absolute',
            right: '10px',
            display: 'flex',
            alignItems: 'center',
            columnGap: '6px',
            padding: '0px 10px 0px 10px',
            backgroundColor: '#9300E9',
            color: '#fff',
            fontSize: '14px',
            borderRadius: '20px',
            height: '28px',
          }}
        >
          Default ({driverWorkingHours?.startTime} - {driverWorkingHours?.endTime})
        </div>
        <TimeRangeSelect
          startValue={selectedStartTime}
          endValue={selectedEndTime}
          onChange={(start, end) => {
            setSelectedStartTime(start)
            setSelectedEndTime(end)
          }}
        />
        <ButtonFill rounded color={'blue'} label={t({ id: 'common.save' })} onClick={() => onSubmit()} />
      </Wrapper>
    </Modal>
  )
}

export default ChangeWorkhoursModal
