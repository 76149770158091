// This is a skeleton starter React component generated by Plasmic.
// This file is owned by you, feel free to edit as you see fit.
import * as React from 'react'
import { PlasmicGeofenceRow, DefaultGeofenceRowProps } from './plasmic/solace_components/PlasmicGeofenceRow'
import { HTMLElementRefOf } from '@plasmicapp/react-web'

import * as p from '@plasmicapp/react-web'
import ButtonSquare from './ButtonSquare'
import Toggle from './Toggle'
import * as GQL from 'generated/graphql'
import { displayToast } from 'util/toasts'
import { omit } from 'lodash'
import { useIntl } from 'react-intl'
import { useDebounce } from 'util/hooks'
// Your component props start with props for variants and slots you defined
// in Plasmic, but you can add more here, like event handlers that you can
// attach to named nodes in your component.
//
// If you don't want to expose certain variants or slots as a prop, you can use
// Omit to hide them:
//
// interface GeofenceRowProps extends Omit<DefaultGeofenceRowProps, "hideProps1"|"hideProp2"> {
//   // etc.
// }
//
// You can also stop extending from DefaultGeofenceRowProps altogether and have
// total control over the props for your component.
export interface GeofenceRowProps extends DefaultGeofenceRowProps {
  btnDelete?: p.Flex<typeof ButtonSquare>
  btnEdit?: p.Flex<typeof ButtonSquare>
  btnLock?: p.Flex<typeof ButtonSquare>
  toggleActive?: p.Flex<typeof Toggle>
  inputColor?: p.Flex<'div'>
  geofence: GQL.GeoFenceNode
  onClick?: () => void
}

function GeofenceRow_(props: GeofenceRowProps, ref: HTMLElementRefOf<'div'>) {
  // Use PlasmicGeofenceRow to render this component as it was
  // designed in Plasmic, by activating the appropriate variants,
  // attaching the appropriate event handlers, etc.  You
  // can also install whatever React hooks you need here to manage state or
  // fetch data.
  //
  // Props you can pass into PlasmicGeofenceRow are:
  // 1. Variants you want to activate,
  // 2. Contents for slots you want to fill,
  // 3. Overrides for any named node in the component to attach behavior and data,
  // 4. Props to set on the root node.
  //
  // By default, we are just piping all GeofenceRowProps here, but feel free
  // to do whatever works for you.
  const intl = useIntl()
  const t = intl.formatMessage
  const [color, setColor] = React.useState(props.geofence.color)
  const debouncedColor = useDebounce(color, 500)

  const [patchGeofence, { loading: loadingPatchGeofence }] = GQL.usePatchGeofence({
    onCompleted: data => {
      if (!data.patchGeofence?.ok) return displayToast(t({ id: 'settings.geofences.patch.error' }), 'error')
      displayToast(t({ id: 'settings.geofences.patch.success' }), 'success')
    },
    onError: () => {
      displayToast(t({ id: 'settings.geofences.patch.error' }), 'error')
    },
    update: (cache, { data }) => {
      cache.writeFragment({
        id: cache.identify({ id: data?.patchGeofence?.geofence?.id, __typename: 'GeoFenceNode' }),
        fragment: GQL.GeofenceInfo,
        fragmentName: 'GeofenceInfo',
        data: data?.patchGeofence?.geofence,
      })
    },
  })

  const handleLocked = React.useCallback(
    (value: boolean) => {
      if (loadingPatchGeofence) return
      patchGeofence({ variables: { id: props.geofence.id, input: { locked: value } } })
    },
    [props.geofence.id, loadingPatchGeofence]
  )

  const handleActive = React.useCallback(
    (value: boolean) => {
      if (loadingPatchGeofence) return
      patchGeofence({ variables: { id: props.geofence.id, input: { enabled: value } } })
    },
    [props.geofence.id, loadingPatchGeofence]
  )

  React.useEffect(() => {
    if (loadingPatchGeofence || debouncedColor === props.geofence.color) return
    patchGeofence({ variables: { id: props.geofence.id, input: { color: debouncedColor } } })
  }, [debouncedColor, props.geofence.id, props.geofence.color])

  return (
    <PlasmicGeofenceRow
      root={{ ref }}
      {...omit(props, 'geofence')}
      btnLock={{ props: { onClick: () => handleLocked(!props.geofence.locked), icon: props.geofence.locked ? 'locked' : 'unlocked' } }}
      toggleActive={{ on: props.geofence.enabled, onClick: () => handleActive(!props.geofence.enabled) }}
      inputColor={<input type='color' className='color-picker' value={color} onChange={e => setColor(e.target.value)}></input>}
    />
  )
}

const GeofenceRow = React.forwardRef(GeofenceRow_)
export default GeofenceRow
