import React from 'react'

interface Props {
  title: string
  message: string
  logoSrc: string
  colorPrimary: string
  loginLink: string
  unsubscribeLink: string | false
  footerInfo: React.ReactNode
}

const EmailPreview: React.FC<Props> = ({ title, message, logoSrc, colorPrimary, loginLink, unsubscribeLink, footerInfo }) => (
  <div style={{ width: '100%', borderRadius: '16px', overflow: 'hidden', fontFamily: 'Open Sans, Helvetica Neue, Arial, sans-serif' }}>
    <table
      align='center'
      role='presentation'
      cellSpacing='0'
      cellPadding='0'
      border={0}
      width='100%'
      style={{ maxWidth: '37.5em', backgroundColor: '#ffffff', border: '1px solid #f0f0f0', padding: '35px' }}
    >
      <tr style={{ width: '100%' }}>
        <td>
          <img
            alt='Logo'
            src={logoSrc}
            height='50'
            style={{
              display: 'block',
              outline: 'none',
              border: 'none',
              textDecoration: 'none',
              margin: 'auto',
            }}
          />
          <table align='center' border={0} cellPadding='0' cellSpacing='0' role='presentation' width='100%' style={{ marginTop: '30px' }}>
            <tbody style={{ width: '100%' }}>
              <tr style={{ width: '100%' }}>
                <td style={{ width: '100%' }}>
                  <h1
                    style={{
                      marginLeft: '0px',
                      marginRight: '0px',
                      textAlign: 'center',
                      color: '#000',
                      fontSize: '20px',
                      fontWeight: '500',
                      lineHeight: '24px',
                      marginBottom: '0',
                      marginTop: '10px',
                    }}
                  >
                    {title}
                  </h1>
                  <p
                    style={{
                      fontSize: '18px',
                      lineHeight: 1.4,
                      margin: '16px 0',
                      color: '#484848',
                      padding: '24px',
                      backgroundColor: '#f2f3f3',
                      borderRadius: '6px',
                      textAlign: 'center',
                    }}
                  >
                    {message}
                  </p>
                  <table style={{ textAlign: 'center' }} align='center' border={0} cellPadding='0' cellSpacing='0' role='presentation' width='100%'>
                    <tbody>
                      <tr>
                        <td>
                          <a href={loginLink} rel='noreferrer' target='_blank'>
                            <span
                              style={{
                                backgroundColor: `${colorPrimary}`,
                                borderRadius: '10px',
                                color: '#fff',
                                fontSize: '15px',
                                textDecoration: 'none',
                                textAlign: 'center',
                                display: 'inline-block',
                                width: '210px',
                                padding: '14px 7px',
                                maxWidth: '100%',
                                lineHeight: '120%',
                                textTransform: 'none',
                              }}
                            >
                              Go to login
                            </span>
                          </a>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
            </tbody>
          </table>
          <hr style={{ width: '100%', border: 'none', borderTop: '1px solid #eaeaea', borderColor: '#dfe1e4', margin: '26px 0 26px' }} />
          {unsubscribeLink && (
            <table style={{ textAlign: 'center' }} align='center' border={0} cellPadding='0' cellSpacing='0' role='presentation' width='100%'>
              <tbody>
                <tr>
                  <td>
                    <a href={unsubscribeLink} rel='noreferrer' target='_blank'>
                      <span
                        style={{
                          backgroundColor: '#efefef',
                          borderRadius: '4px',
                          color: '#999999',
                          fontSize: '10px',
                          textDecoration: 'none',
                          textAlign: 'center',
                          display: 'inline-block',
                          width: '100px',
                          padding: '14px 7px',
                          maxWidth: '100%',
                          lineHeight: '30%',
                          textTransform: 'none',
                        }}
                      >
                        Unsubscribe
                      </span>
                    </a>
                  </td>
                </tr>
              </tbody>
            </table>
          )}
          <p
            style={{
              textAlign: 'center',
              lineHeight: '26px',
              margin: '16px 0',
              fontWeight: '300',
              color: '#b4becc',
              textDecoration: 'none',
              fontSize: '14px',
            }}
          >
            {footerInfo}
          </p>
        </td>
      </tr>
    </table>
  </div>
)
export default EmailPreview
