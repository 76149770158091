import * as React from 'react'
import { useIntl } from 'react-intl'
import Select from 'react-select'

import * as GQL from '../generated/graphql'
import { useAppContext } from 'util/hooks'
import { displayToast } from 'util/toasts'
import { CurrencyInput } from 'components/Input/CurrencyInput'
import { Control, Option, reactSelectStyles } from './StyledReactSelect'

import { PlasmicModalService, DefaultModalServiceProps } from './plasmic/solace_components/PlasmicModalService'
import { HTMLElementRefOf } from '@plasmicapp/react-web'
import { PERCENT_REGEX } from 'modules/settings/util'
import { SelectOption } from 'types/props'

export interface ModalServiceProps extends DefaultModalServiceProps {
  service?: GQL.ProductNode
  onClose: () => void
}

interface ServiceData {
  name: string
  description: string
  customId: string
  basePrice: string
  taxRate: string
  visibleToCustomer: SelectOption<boolean>
  addAutomatically: boolean
}

function ModalService_(props: ModalServiceProps, ref: HTMLElementRefOf<'div'>) {
  const intl = useIntl()
  const t = intl.formatMessage

  const { appContext } = useAppContext()

  const [createProduct, { loading: createLoading }] = GQL.useCreateProduct({
    refetchQueries: ['AllProducts'],
    onCompleted: data => {
      if (data.createProduct?.ok) {
        displayToast(t({ id: 'settings.services.create.toasts.success' }), 'success')
        return props.onClose()
      }
      if (data.createProduct?.error?.includes('Service with that custom ID exists.')) {
        displayToast(t({ id: 'settings.services.create-update.error.exists' }))
      } else {
        displayToast(t({ id: 'settings.services.create.toasts.error' }))
      }
    },
    onError: error => {
      if (error.message.includes('Service with that custom ID exists.')) {
        displayToast(t({ id: 'settings.services.create-update.error.exists' }))
      } else {
        displayToast(t({ id: 'settings.services.create.toasts.error' }))
      }
    },
  })

  const [patchProduct, { loading: patchLoading }] = GQL.usePatchProduct({
    refetchQueries: ['AllProducts'],
    onCompleted: data => {
      if (data.patchProduct?.ok) {
        displayToast(t({ id: 'settings.services.edit.toasts.success' }), 'success')
        return props.onClose()
      }
      if (data.patchProduct?.error?.includes('Service with that custom ID exists.')) {
        displayToast(t({ id: 'settings.services.create-update.error.exists' }))
      } else {
        displayToast(t({ id: 'settings.services.edit.toasts.error' }), 'error')
      }
    },
    onError: error => {
      if (error.message.includes('Service with that custom ID exists.')) {
        displayToast(t({ id: 'settings.services.create-update.error.exists' }))
      } else {
        displayToast(t({ id: 'settings.services.edit.toasts.error' }), 'error')
      }
    },
  })

  const [service, setService] = React.useState<ServiceData>({
    name: props.service?.name ? props.service.name : '',
    description: props.service?.description ? props.service.description : '',
    customId: props.service?.customId ? props.service.customId : '',
    basePrice: props.service?.productPrices ? props.service.productPrices.find(productPrice => productPrice?.category.default === true)?.price : '',
    visibleToCustomer: {
      value: props.service ? props.service.visibleToCustomer : false,
      label: props.service ? (props.service.visibleToCustomer ? t({ id: 'common.invisible' }) : t({ id: 'common.visible' })) : t({ id: 'common.invisible' }),
    },
    taxRate: props.service?.taxRate ? props.service.taxRate : appContext.distributor?.defaultTax,
    addAutomatically: props.service?.addAutomatically ? props.service.addAutomatically : false,
  })

  const handleSubmit = () => {
    if (service.name.length < 3) {
      displayToast(t({ id: 'settings.services.errors.invalid-service-name' }))
      return
    }

    if (props.service && props.service.id) {
      patchProduct({
        variables: {
          id: props.service.id,
          input: {
            name: service.name,
            description: service.description,
            customId: service.customId,
            visibleToCustomer: service.visibleToCustomer.value,
            basePrice: !service.basePrice ? null : parseFloat(service.basePrice),
            taxRate: service.taxRate,
            addAutomatically: service.addAutomatically,
          },
        },
      })
    } else {
      createProduct({
        variables: {
          input: {
            name: service.name,
            description: service.description,
            customId: service.customId,
            visibleToCustomer: service.visibleToCustomer.value,
            storageType: null,
            type: GQL.ProductType.Other.toUpperCase(),
            exchangeProduct: false,
            basePrice: service.basePrice ? service.basePrice : null,
            weight: 0,
            taxRate: service.taxRate,
            addAutomatically: service.addAutomatically,
          },
        },
      })
    }
  }

  return (
    <PlasmicModalService
      root={{ ref }}
      title={props.service ? t({ id: 'settings.services.edit' }) : t({ id: 'settings.services.create' })}
      inputServiceTitle={{
        undefinedInput: {
          name: 'title',
          value: service.name,
          onChange: (e: React.ChangeEvent<HTMLInputElement>) =>
            setService({
              ...service,
              name: e.target.value,
            }),
        },
      }}
      inputDescription={{
        undefinedInput: {
          name: 'description',
          value: service.description,
          onChange: (e: React.ChangeEvent<HTMLInputElement>) =>
            setService({
              ...service,
              description: e.target.value,
            }),
        },
      }}
      inputServiceId={{
        undefinedInput: {
          name: 'serviceId',
          value: service.customId,
          onChange: (e: React.ChangeEvent<HTMLInputElement>) =>
            setService({
              ...service,
              customId: e.target.value,
            }),
        },
      }}
      inputBasePriceDiv={
        <CurrencyInput
          classNames='inputPriceFieldInline'
          currency={appContext?.distributor?.defaultCurrency || 'USD'}
          value={service.basePrice}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setService({
              ...service,
              basePrice: event.target.value || '',
            })
          }}
          onBlur={() => {
            if (!service.basePrice) {
              setService({
                ...service,
                basePrice: props.service?.productPrices ? props.service.productPrices.find(productPrice => productPrice?.category.default === true)?.price : '',
              })
            }
          }}
        />
      }
      selectVisibilityDiv={
        <Select
          value={service.visibleToCustomer}
          isMulti={false}
          options={[
            { value: true, label: t({ id: 'common.visible' }) },
            { value: false, label: t({ id: 'common.invisible' }) },
          ]}
          onChange={event => {
            setService({ ...service, visibleToCustomer: event! })
          }}
          components={{ Option, Control }}
          styles={reactSelectStyles}
        />
      }
      inputTax={{
        undefinedInput: {
          value: service.taxRate,
          onChange: (e: React.ChangeEvent<HTMLInputElement>) => setService({ ...service, taxRate: e.target.value }),
          onBlur: () => {
            if (!PERCENT_REGEX.test(service.taxRate)) {
              setService({ ...service, taxRate: props.service?.taxRate ? props.service.taxRate : appContext.distributor?.defaultTax })
            }
          },
        },
      }}
      toggleAddToOrders={{
        props: {
          name: 'addAutomatically',
          isChecked: service.addAutomatically,
          onChange: event => setService({ ...service, addAutomatically: event }),
        },
      }}
      btnClose={{ onClick: props.onClose }}
      btnCreateProduct={{
        label: props.service ? t({ id: 'settings.services.save' }) : t({ id: 'settings.services.create' }),
        loading: createLoading || patchLoading,
        onClick: handleSubmit,
      }}
    />
  )
}

const ModalService = React.forwardRef(ModalService_)
export default ModalService
