import { format, parseISO, startOfYear, getDay } from 'date-fns'

const nameFormat = {
  J: 'LLLLL',
  Jan: 'LLL',
  January: 'LLLL',
}

export const getMonthNameFromNumber = (month: number, frmt: 'J' | 'Jan' | 'January' = 'Jan') => {
  let curDate = startOfYear(new Date())
  curDate.setMonth(month - 1)
  return format(curDate, nameFormat[frmt])
}

export const parseISONullSafe = (argument?: string, defaultReturn?: Date): Date | undefined => {
  return argument ? parseISO(argument) : defaultReturn
}

export function getZeroBasedWeekday(date: Date): 0 | 2 | 1 | 3 | 4 | 5 | 6 {
  const day = getDay(date)
  return day === 0 ? 6 : ((day - 1) as 0 | 2 | 1 | 3 | 4 | 5 | 6)
}
