import React, { useMemo, useState } from 'react'
import styled from 'styled-components'
import { startOfWeek, endOfWeek } from 'date-fns'
import DriverVehiclesPanel from './DriverVehiclesPanel'
import DriverDatesPanel from './DriverDatesPanel'
import CornerLoader from 'components/Loader/CornerLoader'
import * as GQL from 'generated/graphql'
/* Tutorials temporarily disabled
import Tour from 'components/Tour/Tour'
import steps from './DatesTourSteps'*/

const Content = styled.div`
  display: grid;
  height: calc(100% - 37px);
  grid-template-rows: auto 80px;
  height: calc(100% - 37px);
`

const Layout = styled.div`
  display: grid;
  grid-template-columns: 500px 40px 1fr;
  grid-template-rows: 1fr;
  grid-template-areas: 'drivervehicles separator dates' 'bottombar bottombar bottombar';
  height: 100%;
`

const VerticalBar = styled.div`
  justify-self: center;
  width: 2px;
  height: calc(100% + 1rem);
  margin-top: -1rem;
  background: #111830;
  content: '';
`

const DRIVERS_PAGE_SIZE = 15

interface DatesProps {}

const Dates: React.FC<DatesProps> = () => {
  const [startDate, setStartDate] = useState<Date>(startOfWeek(new Date(), { weekStartsOn: 1 }))
  const [loadingFetchMore, setLoadingFetchMore] = useState(false)

  const {
    data: dataDrivers,
    loading,
    fetchMore: fetchMoreDrivers,
  } = GQL.useAllDriversDeliveriesDatesPage({
    variables: {
      first: DRIVERS_PAGE_SIZE,
    },
  })

  const driversPageInfo = useMemo(() => {
    return dataDrivers?.allDriversDeliveriesDatesPage?.pageInfo
  }, [dataDrivers])

  const endDate = endOfWeek(startDate, { weekStartsOn: 1 })

  const drivers = useMemo(() => {
    return (dataDrivers?.allDriversDeliveriesDatesPage?.edges?.map(edge => edge?.node) || []) as GQL.DriverNode[]
  }, [dataDrivers?.allDriversDeliveriesDatesPage?.edges])

  return (
    <Content>
      <Layout>
        {loadingFetchMore && <CornerLoader topAdjust='-80px' size={50} />}
        <DriverVehiclesPanel
          drivers={drivers}
          totalDrivers={dataDrivers?.allDriversDeliveriesDatesPage?.totalCount || drivers.length}
          fetchMore={() => {
            if (driversPageInfo?.endCursor && driversPageInfo?.hasNextPage) {
              setLoadingFetchMore(true)
              fetchMoreDrivers({
                variables: {
                  first: DRIVERS_PAGE_SIZE,
                  after: driversPageInfo?.endCursor,
                },
                updateQuery(prev, { fetchMoreResult }) {
                  if (!fetchMoreResult) return prev
                  return {
                    ...fetchMoreResult,
                    allDriversDeliveriesDatesPage: {
                      ...fetchMoreResult?.allDriversDeliveriesDatesPage,
                      edges: [
                        ...(prev?.allDriversDeliveriesDatesPage?.edges ? prev.allDriversDeliveriesDatesPage.edges : []),
                        ...(fetchMoreResult?.allDriversDeliveriesDatesPage?.edges ? fetchMoreResult.allDriversDeliveriesDatesPage.edges : []),
                      ],
                    },
                  } as GQL.AllDriversDeliveriesDatesPage
                },
              }).then(() => setLoadingFetchMore(false))
            }
          }}
          loadingFetchMore={loadingFetchMore || loading}
        />
        <VerticalBar />
        <DriverDatesPanel drivers={drivers} startDate={startDate} setStartDate={setStartDate} endDate={endDate} />
      </Layout>
    </Content>
  )

  /* To re-enable tutorials, add the following after </Layout>:
  {!loadingDrivers && !loading && drivers.length < 1 && <Tour steps={steps} />} */
}
export default Dates
