import React, { useState } from 'react'
import { useIntl } from 'react-intl'
import * as GQL from 'generated/graphql'
import ModalGeofence from 'plasmic/ModalGeofence'
import Modal from 'components/Modal/Modal'
import { displayToast } from 'util/toasts'

interface Props {
  isOpen: boolean
  onClose: () => void
  geofence: GQL.GeoFenceNode | null
}

const ModalEditGeofence: React.FC<Props> = props => {
  const intl = useIntl()
  const t = intl.formatMessage

  const [name, setName] = useState(props.geofence?.name || '')

  const [patchGeofence, { loading: loadingPatchGeofence }] = GQL.usePatchGeofence({
    onCompleted: data => {
      if (!data.patchGeofence?.ok) return displayToast(t({ id: 'settings.geofences.patch.error' }), 'error')
      displayToast(t({ id: 'settings.geofences.patch.success' }), 'success')
      props.onClose()
    },
    onError: () => {
      displayToast(t({ id: 'settings.geofences.patch.error' }), 'error')
    },
    update: (cache, { data }) => {
      cache.writeFragment({
        id: cache.identify({ id: data?.patchGeofence?.geofence?.id, __typename: 'GeoFenceNode' }),
        fragment: GQL.GeofenceInfo,
        fragmentName: 'GeofenceInfo',
        data: data?.patchGeofence?.geofence,
      })
    },
  })

  const handleSubmit = () => {
    if (loadingPatchGeofence) return
    if (!props.geofence) return displayToast(t({ id: 'settings.geofences.edit.error.no-geofence' }), 'error')
    if (!name) return displayToast(t({ id: 'settings.geofences.error.name.empty' }), 'warning')
    patchGeofence({ variables: { id: props.geofence.id, input: { name: name } } })
  }

  return (
    <Modal isOpen={props.isOpen} onRequestClose={props.onClose} stripped>
      <ModalGeofence
        title={t({ id: 'settings.geofences.edit' }, { geofence: props.geofence?.name })}
        hideSearch
        selectTabs={{ render: () => null }}
        inputAreaName={{ props: { fieldValue: name, onChange: (event: React.ChangeEvent<HTMLInputElement>) => setName(event.target.value) } }}
        btnCreateGeofence={{ props: { label: t({ id: 'settings.geofences.edit.button' }), onClick: () => handleSubmit() } }}
        btnClose={{ props: { onClick: props.onClose } }}
      />
    </Modal>
  )
}

export default ModalEditGeofence
