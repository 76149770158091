import React, { SetStateAction, useCallback, useMemo, useState } from 'react'
import { useIntl } from 'react-intl'
import styled from 'styled-components'

import * as GQL from 'generated/graphql'
import { CustomerNode, CycleState, CylinderGroupNode } from 'generated/graphql'
import { SimplifiedCalculation } from 'services/sensorCalculation'
import {
  ADDRESS_SORT_KEY,
  CYLINDER_SETUP_SORT_KEY,
  ESTIMATED_EMPTY_SORT_KEY,
  ORDER_METHOD_COLORS,
  ORDER_METHOD_SORT_KEY,
  TAGS_SORT_KEY,
  ORDER_METHOD_TRANSLATIONS,
  STOCK_SORT_KEY,
  CUSTOMER_ID_SORT_KEY,
} from './consts'
import { extractDetailsForSimplifiedCalculation } from './util'
import Button from 'components/Button'
import Tooltip from 'components/Tooltip'
import Loader from 'components/Loader'
import EstimatedEmpty from 'components/Table/Cells/EstimatedEmpty'
import CylinderGroupCylinderSetupCell from 'components/Table/Cells/CylinderGroupCylinderSetupCell'
import CellAddress from 'plasmic/CellAddress'
import CellChart from 'plasmic/CellChart'
import CellCustomer from 'plasmic/CellCustomer'
import CellCylinderSetup from 'plasmic/CellCylinderSetup'
import CellEstimatedEmpty from 'plasmic/CellEstimatedEmpty'
import CellCustomerID from 'plasmic/CellCustomerId'
import CellOrderMethod from 'plasmic/CellOrderMethod'
import FlexTable from 'plasmic/FlexTable'
import TableHeadTitle from 'plasmic/TableHeadTitle'
import TableRow from 'plasmic/TableRow'
import LabelStatusChip from 'plasmic/LabelStatusChip'
import ScrollIndicator from 'plasmic/ScrollIndicator'
import { ReactComponent as Lightning } from 'assets/lightning.svg'
import CellTags from './components/CellTags'
import { getCylinderGroupAlerts } from 'modules/orders/ActiveOrdersTable/util'
import { useTableContext } from 'util/hooks'
import { CustomerTableColumn, generateColumnFilterClass } from 'context/TableContext'
import CellPaymentOptions from 'plasmic/CellPaymentOptions'
import CellPricingList from 'plasmic/CellPricingList'
import CellCustomerType from 'plasmic/CellCustomerType'
import { SelectOption } from 'types/props'

export const StyledOrderMethodButton = styled(Button)`
  font-weight: 500;
  height: 100%;
  width: 100%;
  text-transform: none;
  font-family: 'Proxima Nova', sans-serif;
  font-size: 14px;
  border-radius: 0;
  -webkit-font-smoothing: antialised;
`

export const LightningIcon = styled(Lightning)`
  width: 15px;
  height: 15px;
  fill: #ffffff;
  position: absolute;
  right: 20px;
`

const LoaderContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 10em;
`

const NoDataContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 10em;
`

interface Props {
  label: string
  cylinderGroups: CylinderGroupNode[]
  totalCount: number
  loading: boolean
  loadingMore: boolean
  onSortClick: (sortKey: string) => void
  getSortIcon: (sortKey: string) => 'upActive' | 'downActive' | undefined
  onRowClick: (customer: CustomerNode) => void
  onOrderMethodClick: (target: any, cylinderGroup: CylinderGroupNode) => void
  onCustomerTagsChange: (newSelectedTagValues: string[], cylinderGroup: CylinderGroupNode) => void
  onLoadMoreClick: () => void
  onDistributorTagCreation: any
  sensorsCalculations?: { [key: string]: SimplifiedCalculation }
  visibleColumns?: CustomerTableColumn[]
  distributorTagOptions?: SelectOption<string>[]
  noSorting?: boolean
  selectedIds: Set<string>
  setSelectedIds: React.Dispatch<React.SetStateAction<Set<string>>>
}

const CustomersTable: React.FC<Props> = props => {
  const intl = useIntl()
  const t = intl.formatMessage

  const [collapsed, setCollapsed] = useState(false)

  const { visibleCustomerTableColumns: contextVisibleColumns } = useTableContext()
  const allCustomerIds = useMemo(() => props.cylinderGroups.map(cylinderGroup => cylinderGroup.customer.id), [props.cylinderGroups])

  const visibleColumns = useMemo(() => {
    if (!!props.visibleColumns && Array.isArray(props.visibleColumns)) {
      return contextVisibleColumns.filter(column => props.visibleColumns?.includes(column))
    }
    return contextVisibleColumns
  }, [contextVisibleColumns, props.visibleColumns])

  const handleHeadTitleClick = () => setCollapsed(collapsed => !collapsed)

  const handleTagOptionsCreate = (newTagOptions: SetStateAction<SelectOption<string>[]>) => {
    // Get the previous tagOptions
    const prevTagOptions = props.distributorTagOptions

    // Extract the values of the previous options
    const prevOptionValues = prevTagOptions?.map(option => option.value)

    // Extract the values of the new options by calling the function
    const newOptionValues = Array.isArray(newTagOptions) ? newTagOptions.map(option => option.value) : []

    // Find the values that are new (present in newOptionValues but not in prevOptionValues)
    const newValues = newOptionValues.filter(value => !prevOptionValues?.includes(value))

    props.onDistributorTagCreation(newValues)
  }

  const allSelected = useMemo(() => {
    if (props.selectedIds.size === 0) return false
    return allCustomerIds.every(a => props.selectedIds.has(a))
  }, [allCustomerIds, props.selectedIds])

  const handleRowCheckbox = useCallback(
    (id: string) =>
      props.setSelectedIds(prev => {
        if (prev.has(id)) return new Set([...prev].filter(_id => _id !== id))
        return new Set([...prev, id])
      }),
    [props]
  )

  const handleAllCheckboxes = useCallback(
    () =>
      props.setSelectedIds(prev => {
        if (allSelected) return new Set([...prev].filter(id => !allCustomerIds.includes(id)))
        return new Set([...prev, ...allCustomerIds])
      }),
    [allCustomerIds, allSelected, props]
  )

  const getCustomerPaymentOptions = (customer: GQL.CustomerNode) => {
    const options: ('stripe' | 'visma')[] = []
    if (customer.paymentMethods?.visma && customer.paymentMethods.visma.length > 0) options.push('visma')
    if (customer.paymentMethods?.stripe && customer.paymentMethods.stripe.length > 0) options.push('stripe')
    return options
  }

  return (
    <FlexTable
      checkbox={{
        isChecked: allSelected,
        onChange: () => handleAllCheckboxes(),
        name: 'selectAllCheckbox',
      }}
      noSorting={props.noSorting ? true : false}
      collapsed={collapsed}
      children={<TableHeadTitle title={props.label} count={null} onClick={handleHeadTitleClick} />}
      headCustomerIdParent={{ className: generateColumnFilterClass(CustomerTableColumn.CUSTOMER_ID, visibleColumns) }}
      headAddressParent={{ className: generateColumnFilterClass(CustomerTableColumn.ADDRESS, visibleColumns) }}
      headCylinderSetupParent={{ className: generateColumnFilterClass(CustomerTableColumn.CYLINDER_SETUP, visibleColumns) }}
      headEstimatedEmptyParent={{ className: generateColumnFilterClass(CustomerTableColumn.ESTIMATED_EMPTY, visibleColumns) }}
      headOrderMethodParent={{ className: generateColumnFilterClass(CustomerTableColumn.ORDER_METHOD, visibleColumns) }}
      headChartParent={{ className: generateColumnFilterClass(CustomerTableColumn.CHART, visibleColumns) }}
      headTagsParent={{ className: generateColumnFilterClass(CustomerTableColumn.TAGS, visibleColumns) }}
      headPaymentOptionsParent={{ className: generateColumnFilterClass(CustomerTableColumn.PAYMENT_OPTIONS, visibleColumns) }}
      headPricingListParent={{ className: generateColumnFilterClass(CustomerTableColumn.PRICING_LIST, visibleColumns) }}
      headCustomerTypeParent={{ className: generateColumnFilterClass(CustomerTableColumn.CUSTOMER_TYPE, visibleColumns) }}
      headCustomerId={{ onClick: () => props.onSortClick(CUSTOMER_ID_SORT_KEY), sorting: props.getSortIcon(CUSTOMER_ID_SORT_KEY) }}
      headAddress={{ onClick: () => props.onSortClick(ADDRESS_SORT_KEY), sorting: props.getSortIcon(ADDRESS_SORT_KEY) }}
      headCylinderSetup={{ onClick: () => props.onSortClick(CYLINDER_SETUP_SORT_KEY), sorting: props.getSortIcon(CYLINDER_SETUP_SORT_KEY) }}
      headEstimatedEmpty={{ onClick: () => props.onSortClick(ESTIMATED_EMPTY_SORT_KEY), sorting: props.getSortIcon(ESTIMATED_EMPTY_SORT_KEY) }}
      headInventory={{ onClick: () => props.onSortClick(STOCK_SORT_KEY), sorting: props.getSortIcon(STOCK_SORT_KEY) }}
      headOrderMethod={{ onClick: () => props.onSortClick(ORDER_METHOD_SORT_KEY), sorting: props.getSortIcon(ORDER_METHOD_SORT_KEY) }}
      headTags={{ onClick: () => props.onSortClick(TAGS_SORT_KEY), sorting: props.getSortIcon(TAGS_SORT_KEY) }}
      headChart={{ hideSorting: true }}
      rows={
        props.loading ? (
          <LoaderContainer>
            <Loader size={64} color='grey' />
          </LoaderContainer>
        ) : props.cylinderGroups.length > 0 ? (
          <>
            {props.cylinderGroups.map(cylinderGroup => (
              <TableRow
                data-testid='customer'
                checkbox={{
                  isChecked: props.selectedIds.has(cylinderGroup.customer.id),
                  onChange: () => handleRowCheckbox(cylinderGroup.customer.id),
                  name: 'selectRowCheckbox',
                }}
                key={`CustomersTable-${props.label}-${cylinderGroup.id}`}
                sticky={
                  <CellCustomer
                    name={cylinderGroup.customer.name}
                    onClick={() => props.onRowClick(cylinderGroup.customer)}
                    alerts={getCylinderGroupAlerts(cylinderGroup).filter(alert => alert !== 'contact')}
                    temperature={cylinderGroup.customer.temperatureGroup?.temperature?.toString()}
                  />
                }
                scrollCells={
                  <>
                    <CellCustomerID
                      content={cylinderGroup.customer.customerIdentifier || '-'}
                      className={generateColumnFilterClass(CustomerTableColumn.CUSTOMER_ID, visibleColumns)}
                    />
                    <CellAddress
                      className={generateColumnFilterClass(CustomerTableColumn.ADDRESS, visibleColumns)}
                      googleMapsLink={
                        'https://www.google.com/maps/search/?api=1&query=' +
                        cylinderGroup.customer.address.latitude +
                        ',' +
                        cylinderGroup.customer.address.longitude
                      }
                      address={cylinderGroup.customer.address.firstLine}
                    />
                    <CellCylinderSetup
                      className={generateColumnFilterClass(CustomerTableColumn.CYLINDER_SETUP, visibleColumns)}
                      content={<CylinderGroupCylinderSetupCell key={cylinderGroup.id + 'cylinderSetup'} cylinderGroup={cylinderGroup} />}
                    />
                    <CellEstimatedEmpty
                      className={generateColumnFilterClass(CustomerTableColumn.ESTIMATED_EMPTY, visibleColumns)}
                      content={<EstimatedEmpty cylinderGroup={cylinderGroup} displayThreshold={false} />}
                    />
                    <CellTags
                      className={generateColumnFilterClass(CustomerTableColumn.TAGS, visibleColumns)}
                      tagOptions={props.distributorTagOptions}
                      customerTags={cylinderGroup.customer.tags}
                      onTagOptionsCreate={handleTagOptionsCreate}
                      onSelectedCustomerTagsChange={(newSelectedTagValues: string[]) => props.onCustomerTagsChange(newSelectedTagValues, cylinderGroup)}
                    />
                    {props.sensorsCalculations &&
                      (() => {
                        const detailsForSimplifiedCalculation = extractDetailsForSimplifiedCalculation(cylinderGroup)

                        let color = '#2C97DE'
                        let numbers: number[] = []

                        if (
                          detailsForSimplifiedCalculation.sensorSerialNumber &&
                          detailsForSimplifiedCalculation.sensorSerialNumber in props.sensorsCalculations
                        ) {
                          numbers = props.sensorsCalculations[detailsForSimplifiedCalculation.sensorSerialNumber].entries.map(entry => entry.value)
                        }

                        if (detailsForSimplifiedCalculation.state === CycleState.Full) {
                          color = '#00D4A6'
                        }

                        return (
                          <CellChart
                            className={generateColumnFilterClass(CustomerTableColumn.CHART, visibleColumns)}
                            customerId={cylinderGroup.customer.id}
                            svgWrapper={{ style: { color } }}
                            numbers={numbers}
                          />
                        )
                      })()}
                    <CellPaymentOptions
                      className={generateColumnFilterClass(CustomerTableColumn.PAYMENT_OPTIONS, visibleColumns)}
                      options={getCustomerPaymentOptions(cylinderGroup.customer)}
                    />
                    <CellPricingList
                      className={generateColumnFilterClass(CustomerTableColumn.PRICING_LIST, visibleColumns)}
                      content={(cylinderGroup.customer.priceCategory?.name || t({ id: 'common.default' })).capitalizeFirst()}
                    />
                    <CellCustomerType
                      className={generateColumnFilterClass(CustomerTableColumn.CUSTOMER_TYPE, visibleColumns)}
                      content={cylinderGroup.customer.customerDomainType?.capitalizeFirst()}
                    />
                    <CellOrderMethod
                      className={generateColumnFilterClass(CustomerTableColumn.ORDER_METHOD, visibleColumns)}
                      content={
                        <StyledOrderMethodButton
                          color={ORDER_METHOD_COLORS[cylinderGroup.customer.orderMethod || 'APP']}
                          onClick={event => props.onOrderMethodClick(event.currentTarget, cylinderGroup)}
                        >
                          <div style={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            {t({ id: ORDER_METHOD_TRANSLATIONS[cylinderGroup.customer.orderMethod || 'APP'] })}
                          </div>
                          {cylinderGroup.threshold && (
                            <Tooltip
                              content={
                                <span>
                                  Automatic trigger set to: "{cylinderGroup.threshold.name}"<br />
                                  Order will be placed when there is remaining {cylinderGroup.threshold.threshold}
                                  {cylinderGroup.threshold.unit}
                                </span>
                              }
                              delay={0}
                              arrow={false}
                              placement='top'
                            >
                              <LightningIcon />
                            </Tooltip>
                          )}
                        </StyledOrderMethodButton>
                      }
                    />
                  </>
                }
              />
            ))}
            <ScrollIndicator
              tableRow
              loaded={props.cylinderGroups.length}
              total={props.totalCount}
              btnLoadMore={{ ...(props.loadingMore && { children: <Loader color='white' /> }), onClick: props.onLoadMoreClick }}
            />
          </>
        ) : (
          <NoDataContainer>
            <LabelStatusChip title={t({ id: 'common.no-data-to-show' })} icon={'info'} />
          </NoDataContainer>
        )
      }
      visibleColumns={Object.values(CustomerTableColumn)}
    />
  )
}

export default CustomersTable
