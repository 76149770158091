// @ts-nocheck
/* eslint-disable */
/* tslint:disable */
/* prettier-ignore-start */
import React from "react";
import { classNames } from "@plasmicapp/react-web";

export type AnovaIconsvgIconProps = React.ComponentProps<"svg"> & {
  title?: string;
};

export function AnovaIconsvgIcon(props: AnovaIconsvgIconProps) {
  const { className, style, title, ...restProps } = props;
  return (
    <svg
      xmlns={"http://www.w3.org/2000/svg"}
      version={"1.1"}
      x={"0"}
      y={"0"}
      viewBox={"0 0 266 266"}
      xmlSpace={"preserve"}
      height={"1em"}
      style={{
        fill: "currentcolor",

        ...(style || {}),
      }}
      className={classNames("plasmic-default__svg", className)}
      {...restProps}
    >
      {title && <title>{title}</title>}

      <path
        d={
          "M93.66 6.434C39.689 23.191.495 73.518.495 133c0 23.292 6.018 45.176 16.571 64.195l67.862-177.42A37.659 37.659 0 0193.66 6.434zm20.345 51.233h-3.833l-33.5 87.5h31.167l13.333 35.167H63.208l-19.396 50.661c23.542 21.438 54.838 34.51 89.188 34.51 33.174 0 63.501-12.191 86.744-32.338h-38.74l-66.999-175.5zM133 .495c-.332 0-.661.01-.992.013l88.815 231.702c27.403-24.276 44.682-59.722 44.682-99.21C265.505 59.82 206.18.495 133 .495z"
        }
      ></path>
    </svg>
  );
}

export default AnovaIconsvgIcon;
/* prettier-ignore-end */
