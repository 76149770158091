import * as GQL from 'generated/graphql'
import { CoordinatePair } from './types'

export const createDynamicField = (name: string, key: string) => {
  const element = document.createElement('span')
  element.contentEditable = 'false'
  element.classList.add('dynamic-field')
  element.innerHTML = name
  element.setAttribute('data-dynamic-field', key)
  return element
}

export function updateSVG(svgString: string, color: string) {
  const updatedSVGString = svgString.replace(/currentColor/g, color)
  return new Blob([updatedSVGString], { type: 'image/svg+xml' })
}

export function bakeSvg(svgString: string, color: string) {
  if (svgString) {
    return URL.createObjectURL(updateSVG(svgString, color))
  }
  return ''
}

export const formatProductType = (type: GQL.ProductType) => {
  if (type === GQL.ProductType.Co2) return type.toUpperCase()
  return type.capitalizeFirst()
}

export const fetchProductPreviewJSON = async () => {
  const response = await fetch('https://solace-static.s3.eu-west-1.amazonaws.com/product_catalog_preview.json')
  const data = await response.json()

  if (data && data.svgPreviews) {
    return data.svgPreviews
  }

  return []
}

export const PERCENT_REGEX = /^\d{1,2}(\.\d{1,2})?$/

/**
 * Polygons used by `@react-google-maps/api` do not follow the closed-ring principle, meaning that the first and last coordinates are not the same.
 * This is however required by GIS Polygons. This function closes the given polygons, if they are not closed already.
 * @param differences A object literal where each key corresponds to a modified polygon. The value is a list containing the new polygon coordinates.
 * @returns A closed, GIS-compatible polygon, or `null` if no polygons are supplied.
 */
export const closePolygon = (differences: { [key: string]: CoordinatePair[] }): { [key: string]: CoordinatePair[] } | null => {
  for (const key of Object.keys(differences)) {
    const firstCoordinate = differences[key].at(0)
    const lastCoordinate = differences[key].at(-1)
    if (!firstCoordinate || !lastCoordinate) return null
    if (firstCoordinate.lat !== lastCoordinate.lat || firstCoordinate.lng !== lastCoordinate.lng) {
      differences[key].push({ lat: firstCoordinate.lat, lng: firstCoordinate.lng })
    }
  }
  return differences
}

/**
 * GIS Polygons follow the closed-ring principle, meaning that the first and last coordinates must be the same.
 * This is however incompatible with `@react-google-maps/api`. This function removes the duplicate ending coordinates from the given path, if it is provided.
 * @param path An array of coordinate pairs which make-up the polygon to be transformed.
 * @returns An open polygon, compatible with `@react-google-maps/api`, or null if the path is empty.
 */
export const openPolygon = (path: CoordinatePair[]) => {
  const firstCoordinate = path.at(0)
  const lastCoordinate = path.at(-1)
  if (!firstCoordinate || !lastCoordinate) return null
  if (firstCoordinate.lat === lastCoordinate.lat && firstCoordinate.lng === lastCoordinate.lng) {
    path.pop()
  }
  return path
}
