// This is a skeleton starter React component generated by Plasmic.
// This file is owned by you, feel free to edit as you see fit.
import * as React from 'react'
import { useCallback, useRef, useState } from 'react'
import _, { isBoolean } from 'lodash'
import { Polyline } from '@react-google-maps/api'

import * as GQL from 'generated/graphql'
import { PlasmicMapRoutes, DefaultMapRoutesProps } from './plasmic/solace_components/PlasmicMapRoutes'
import { HTMLElementRefOf } from '@plasmicapp/react-web'
import Map, { IMarker, IPolyline } from 'modules/localization/components/Map'
import iconDepot from 'assets/pin-depot.svg'
import darkModeTheme from 'components/GoogleMap/theme.json'
import lightModeTheme from 'components/GoogleMap/light_theme.json'

//const icons = [pinOne, pinTwo, pinThree, pinFour, pinFive, pinSix]
const colors = ['2B97DE', 'FDAB3D', 'DD2C74', '2CDD91', '7C7A82', '9442F4']
const directionsService = new google.maps.DirectionsService()

const LIGHT_MODE_KEY = 'route-map-light-mode'

interface IMapRoute {
  route: GQL.RouteNode
  i: number
}

const MapRoute = ({ route, i }: IMapRoute) => {
  const [polyline, setPolyline] = useState<IPolyline | null>(null)

  React.useEffect(() => {
    if (!route.depot) {
      return
    }
    directionsService.route(
      {
        origin: { lat: parseFloat(route.depot.address.latitude), lng: parseFloat(route.depot.address.longitude) },
        destination: {
          lat: parseFloat(route.depot.address.latitude),
          lng: parseFloat(route.depot.address.longitude),
        },
        waypoints: route.stops?.map(stop => ({
          location: new google.maps.LatLng({
            lat: stop?.latitude!,
            lng: stop?.longitude!,
          }),
        })),
        travelMode: google.maps.TravelMode.DRIVING,
      },
      (result, status) => {
        if (status === google.maps.DirectionsStatus.OK && result) {
          setPolyline({
            path: result.routes[0].overview_path,
            options: {
              strokeColor: '#' + colors[i % colors.length],
            },
          })
        }
      }
    )
  }, [route, i])

  if (polyline === null) {
    return null
  }
  return <Polyline path={polyline.path} options={polyline.options} />
}
export interface MapRoutesProps extends DefaultMapRoutesProps {
  mapContainer?: any
  routes: GQL.RouteNode[]
}

function MapRoutes_(props: MapRoutesProps, ref: HTMLElementRefOf<'div'>) {
  const localStorageLightMode = localStorage.getItem(LIGHT_MODE_KEY)
  const [lightMode, setLightMode] = useState(localStorageLightMode && isBoolean(JSON.parse(localStorageLightMode)) ? JSON.parse(localStorageLightMode) : true)

  const [mapHeightChanging, setMapHeightChanging] = useState(false)
  const [mapHeight, setMapHeight] = useState(300)
  const initialHeightRef = useRef(300)
  const startYRef = useRef(0)

  const handleMouseDown = useCallback(
    (e: React.MouseEvent) => {
      e.preventDefault()
      setMapHeightChanging(true)
      startYRef.current = e.clientY
      initialHeightRef.current = mapHeight

      const handleMouseMove = (e: MouseEvent) => {
        const newY = e.clientY
        const diffY = newY - startYRef.current
        setMapHeight(Math.max(200, initialHeightRef.current + diffY))
      }

      const handleMouseUp = () => {
        window.removeEventListener('mousemove', handleMouseMove)
        window.removeEventListener('mouseup', handleMouseUp)
      }

      window.addEventListener('mousemove', handleMouseMove)
      window.addEventListener('mouseup', handleMouseUp)
      setMapHeightChanging(false)
    },
    [mapHeight]
  )

  const encodeSVG = (color: string, orderInRoute: number | undefined) => {
    const svg = `<?xml version="1.0" encoding="utf-8"?>
    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
      viewBox="0 0 40 48.3" style="enable-background:new 0 0 40 48.3;" xml:space="preserve">
    <style type="text/css">
      .st1{fill:#111831;}
      .st2{fill:#FFFFFF;}
      .st3{font-family:'Arial';}
      .st4{
            font-size:16px;
            text-anchor: middle;
        }
    </style>
    <path fill="#${color}" d="M40,20C40,9,31,0,20,0S0,9,0,20c0,5.3,2.1,10.1,5.4,13.6l0,0L20,48.3l14.6-14.6l0,0C37.9,30.1,40,25.3,40,20z"
      />
    <circle class="st1" cx="20" cy="20" r="16"/>
    <text transform="matrix(1 0 0 1 19 25)" class="st2 st3 st4">${orderInRoute}</text>
    </svg>`

    return `data:image/svg+xml;utf-8,${encodeURIComponent(svg)}`
  }

  const markers = React.useMemo(() => {
    return [
      ...props.routes
        .filter(route => route.depot)
        .map<IMarker>(route => ({
          longitude: parseFloat(route.depot?.address.longitude),
          latitude: parseFloat(route.depot?.address.latitude),
          icon: iconDepot,
        })),
      ...props.routes.flatMap<IMarker>(
        (route: GQL.RouteNode, i) =>
          route.stops
            ?.filter(s => !!s?.customer?.address.latitude)
            .map(stop => ({
              longitude: parseFloat(stop?.customer?.address.longitude),
              latitude: parseFloat(stop?.customer?.address.latitude),
              icon: encodeSVG(colors[i % 6], stop?.orderInRoute),
            })) as IMarker[]
      ),
    ]
  }, [props.routes])

  return (
    <PlasmicMapRoutes
      root={{ ref }}
      {..._.omit(props, ['routes'])}
      map={{
        render: () => (
          <Map
            mapStyle={{ height: '100%', width: '100%' }}
            options={{ gestureHandling: mapHeightChanging ? 'cooperative' : null }}
            theme={!lightMode ? darkModeTheme : lightModeTheme}
            markers={markers}
          >
            {props.routes.map((r, i) => (
              <MapRoute key={r.id} route={r} i={i} />
            ))}
          </Map>
        ),
      }}
      scaleMapToggle={{
        onMouseDown: handleMouseDown,
      }}
      mapContainer={{
        style: {
          height: `${mapHeight}px`,
          maxHeight: 'calc(100vh - 180px)',
        },
      }}
      toggleLight={{
        isChecked: lightMode,
        onChange: checked => {
          setLightMode(checked)
          localStorage.setItem(LIGHT_MODE_KEY, JSON.stringify(checked))
        },
      }}
    />
  )
}

const MapRoutes = React.forwardRef(MapRoutes_)
export default MapRoutes
