import { useMemo, useRef, useState } from 'react'
import { useIntl } from 'react-intl'

import * as GQL from 'generated/graphql'
import { displayToast } from 'util/toasts'
import ContactCard from 'plasmic/ContactCard'
import ContentContactsPriv from 'plasmic/ContentContactsPriv'
import Loader from 'components/Loader/Loader'
import { CenteredLoader } from 'components/Loader/LoaderWrappers'
import ConfirmModal from 'components/Modal/ConfirmModal'
import { TutorialInfoIcon } from '../../../components/Tooltip/TutorialTooltip'
import CustomerContactModal from '../components/CustomerContactModal'

import 'react-intl-tel-input/dist/main.css'
import ActionDropdownLine from 'plasmic/ActionDropdownLine'
import { paymentEnabled } from 'modules/distributors/utils'
import { useAppContext, useClickOutside } from 'util/hooks'
import SubscriptionsToggles from './ContactsSubscriptionsToggles'
import SubscriptionsThresholdToggles from './ContactsSubscriptionsThresholdToggles'

interface IContactProps {
  contact: GQL.CustomerContactNode
  onCustomerContactEdit: (customer: GQL.CustomerContactNode) => void
}

const Contact = ({ contact, onCustomerContactEdit }: IContactProps) => {
  const [confirmDeleteModalOpen, setConfirmDeleteModalOpen] = useState<boolean>(false)
  const [moreActions, setMoreActions] = useState(false)

  const { data: verbData } = GQL.useAllNotificationVerbs({ variables: { subscribableForCustomers: true, first: 100 } })
  const verbs =
    verbData?.allNotificationVerbs?.edges.map(edge => edge?.node).filter((node): node is GQL.NotificationVerbNode => node !== null && node !== undefined) ?? []

  const toggleVerbs = verbs.filter(verb => verb.slug !== 'has-reached')
  const thresholdVerbs = verbs.filter(verb => verb.slug === 'has-reached')

  const { data: channelData } = GQL.useAllChannels()
  const channels = channelData?.allChannels?.edges.map(edge => edge?.node).filter((node): node is GQL.ChannelNode => node !== null && node !== undefined) ?? []

  const intl = useIntl()
  const t = intl.formatMessage
  const ref = useRef(null)

  const { appContext } = useAppContext()

  useClickOutside(
    ref,
    () => {
      setMoreActions(false)
    },
    false,
    true
  )

  const [patchContact] = GQL.usePatchCustomerContact({
    refetchQueries: ['AllCustomerContacts'],
    onCompleted: () => {
      displayToast('Successfully updated contact.', 'success')
    },
  })

  const deleteContact = () => {
    if (contact?.id) {
      patchContact({
        variables: {
          id: contact?.id,
          input: {
            isDeleted: true,
            phone: null,
            email: null,
          },
        },
      })
    }
  }

  return (
    <>
      <ConfirmModal
        isOpen={confirmDeleteModalOpen}
        onRequestClose={() => setConfirmDeleteModalOpen(false)}
        onConfirm={() => {
          setConfirmDeleteModalOpen(false)
          deleteContact()
        }}
        onAbort={() => {
          setConfirmDeleteModalOpen(false)
        }}
        title={t({ id: 'customers.contact.delete' })}
        description={`Are you sure you want to delete ${contact.name} ${contact.position ? contact.position : ''} as a contact of this customer?`}
      />
      <ContactCard
        data-testid='customer-contact'
        invoiceReceiver={contact.invoiceReceiver}
        name={contact.name}
        phoneNumber={contact.phone}
        email={contact.email}
        position={contact.position}
        noSensor={!contact.customer?.cylinderGroups?.some(cg => cg?.sensors && cg.sensors.length > 0)}
        toggles={
          <>{contact && verbs.length > 0 && channels.length > 0 ? <SubscriptionsToggles contact={contact} verbs={toggleVerbs} channels={channels} /> : null}</>
        }
        percentageTrigger={
          <>
            {contact && verbs.length > 0 && channels.length > 0 ? (
              <SubscriptionsThresholdToggles contact={contact} verbs={thresholdVerbs} channels={channels} />
            ) : null}
          </>
        }
        moreActions={{
          open: moreActions,
          ref: moreActions ? ref : null,
          onClick: () => setMoreActions(prev => !prev),
          actionDropdown: (
            <>
              <ActionDropdownLine
                children={t({ id: 'common.edit' })}
                onClick={() => {
                  onCustomerContactEdit(contact)
                }}
              />
              <ActionDropdownLine
                children={
                  paymentEnabled(appContext) ? t({ id: 'customers.contact.set-invoice-receiver' }) : t({ id: 'customers.contact.set-receipt-receiver' })
                }
                onClick={() => {
                  patchContact({
                    variables: {
                      id: contact.id,
                      input: {
                        invoiceReceiver: true,
                      },
                    },
                  })
                }}
              />
              <ActionDropdownLine
                children={t({ id: 'common.delete' })}
                onClick={() => {
                  setConfirmDeleteModalOpen(true)
                }}
              />
            </>
          ),
        }}
      />
    </>
  )
}

interface ContactsProps {
  cylinderGroup: GQL.CylinderGroupNode
}

const Contacts = ({ cylinderGroup }: ContactsProps) => {
  const intl = useIntl()
  const t = intl.formatMessage

  const [openCreateContactModal, setOpenCreateContactModal] = useState<boolean>(false)
  const [editCustomerContact, setEditCustomerContact] = useState<GQL.CustomerContactNode | undefined>(undefined)
  const { data, loading } = GQL.useAllCustomerContacts({
    notifyOnNetworkStatusChange: true,
    variables: {
      customer: cylinderGroup.customer.id,
    },
  })

  const contacts = useMemo(() => {
    return data?.allCustomerContacts?.edges.map(e => e?.node as GQL.CustomerContactNode) || []
  }, [data])

  return (
    <>
      {openCreateContactModal && <CustomerContactModal onClose={() => setOpenCreateContactModal(false)} customer={cylinderGroup.customer.id} />}
      {!!editCustomerContact && (
        <CustomerContactModal onClose={() => setEditCustomerContact(undefined)} customer={cylinderGroup.customer.id} contact={editCustomerContact} />
      )}
      <ContentContactsPriv
        helpText={<TutorialInfoIcon content={t({ id: 'tooltips.customers.drawer.tabs.contacts' })} />}
        cardsContent={
          <>
            {contacts.length > 0 ? (
              contacts.map(c => {
                return <Contact key={c.id} contact={c} onCustomerContactEdit={customer => setEditCustomerContact(customer)} />
              })
            ) : loading ? (
              <CenteredLoader>
                <Loader size={52} color='white' />
              </CenteredLoader>
            ) : (
              <div style={{ flexGrow: 1, display: 'flex', justifyContent: 'center' }}>No contacts</div>
            )}
          </>
        }
        buttonRoundIcon={{
          onClick: (e: any) => {
            e.preventDefault()
            e.stopPropagation()
            setOpenCreateContactModal(true)
          },
        }}
      />
    </>
  )
}
export default Contacts
