import React, { useState } from 'react'
import { useIntl } from 'react-intl'
import Select from 'react-select'
import * as GQL from 'generated/graphql'
import ModalGeofence from 'plasmic/ModalGeofence'
import Modal from 'components/Modal/Modal'
import { displayToast } from 'util/toasts'
import { CoordinatePair, GeofenceModalTab } from '../types'
import { Control, Option, reactSelectStyles } from 'plasmic/StyledReactSelect'

interface SelectOption {
  label: string
  value: string
}

interface Props {
  isOpen: boolean
  onClose: () => void
  mapCenter: CoordinatePair
}

const ModalCreateGeofence: React.FC<Props> = props => {
  const intl = useIntl()
  const t = intl.formatMessage

  const [tab, setTab] = useState<GeofenceModalTab>('search')
  const [name, setName] = useState('')
  const [results, setResults] = useState([])
  const [selectedOption, setSelectedOption] = useState<SelectOption | null>(null)
  let timeout: NodeJS.Timeout

  const [addGeofence, { loading: loadingAddGeofence }] = GQL.useAddGeofence({
    refetchQueries: ['AllGeofences'],
    onCompleted: data => {
      if (!data.addGeofence?.ok) return displayToast(t({ id: 'settings.geofences.add.error' }), 'error')
      displayToast(t({ id: 'settings.geofences.add.success' }), 'success')
      props.onClose()
    },
    onError: () => {
      displayToast(t({ id: 'settings.geofences.add.error' }), 'error')
    },
  })

  const handleSubmit = () => {
    if (loadingAddGeofence) return
    if (!name) return displayToast(t({ id: 'settings.geofences.error.name.empty' }), 'warning')
    if (tab === 'freeform') {
      addGeofence({
        variables: { input: { name: name, centerLatitude: props.mapCenter.lat, centerLongitude: props.mapCenter.lng } },
      })
    } else {
      if (!selectedOption) return displayToast(t({ id: 'settings.geofences.error.area.empty' }))
      addGeofence({
        variables: { input: { name: name, area: selectedOption.value } },
      })
    }
  }

  const handleSearch = (query: string) => {
    if (!query) {
      return
    }
    if (timeout) clearTimeout(timeout)
    timeout = setTimeout(() => {
      fetch(`https://nominatim.openstreetmap.org/search.php?q=${encodeURIComponent(query)}&polygon_geojson=1&format=json`)
        .then(resp => resp.json())
        .then(data => {
          const filterGeoJsonType = data
            .filter((item: any) => item.geojson.type === 'MultiPolygon' || item.geojson.type === 'Polygon')
            .map(
              (item: any) =>
                ({
                  label: item.display_name,
                  value: item.display_name,
                }) as SelectOption
            )
          setResults(filterGeoJsonType)
        })
    }, 1000)
  }

  const handleSelectChange = (selectedOption: any) => {
    setSelectedOption(selectedOption)
  }

  return (
    <Modal isOpen={props.isOpen} onRequestClose={props.onClose} stripped>
      <ModalGeofence
        title={t({ id: 'settings.geofences.add' })}
        tabSearch={{ active: tab === 'search', onClick: () => setTab('search') }}
        tabFreeform={{ active: tab === 'freeform', onClick: () => setTab('freeform') }}
        hideSearch={tab === 'freeform'}
        inputAreaName={{
          props: {
            fieldValue: name,
            alert: name.length === 0,
            onChange: (event: React.ChangeEvent<HTMLInputElement>) => setName(event.target.value),
          },
        }}
        inputSearch={
          <Select
            placeholder='Search for an area...'
            components={{ Option, Control }}
            styles={reactSelectStyles}
            options={results}
            onInputChange={inputValue => handleSearch(inputValue)}
            onChange={handleSelectChange}
          />
        }
        btnCreateGeofence={{ props: { label: t({ id: 'settings.geofences.add.button' }), onClick: () => handleSubmit() } }}
        btnClose={{ props: { onClick: props.onClose } }}
      />
    </Modal>
  )
}

export default ModalCreateGeofence
