// @ts-nocheck
/* eslint-disable */
/* tslint:disable */
/* prettier-ignore-start */
import React from "react";
import { classNames } from "@plasmicapp/react-web";

export type VismaLogoWhiteRgbsvgIconProps = React.ComponentProps<"svg"> & {
  title?: string;
};

export function VismaLogoWhiteRgbsvgIcon(props: VismaLogoWhiteRgbsvgIconProps) {
  const { className, style, title, ...restProps } = props;
  return (
    <svg
      xmlns={"http://www.w3.org/2000/svg"}
      viewBox={"0 0 111.72 20.81"}
      height={"1em"}
      className={classNames("plasmic-default__svg", className)}
      style={style}
      {...restProps}
    >
      {title && <title>{title}</title>}

      <path
        d={
          "M11.47 0c6.08.52 12.79 3.61 16.62 8 4.9 5.62 3.21 11.23-3.77 12.54S7.7 18.36 2.8 12.74c-3.75-4.3-3.64-8.59-.25-11L22 16.12zm95.91 20.5L105 10.69c-.35-1.16-.49-2.33-.74-3.49-.21 1.19-.3 2.36-.62 3.55l-2.47 9.75h-4.32L102.51.08h3.3l5.91 20.42zM42.92.08l2.44 9.8c.35 1.17.49 2.33.73 3.5.22-1.2.3-2.36.63-3.55L49.23.08h4.23L47.8 20.5h-3.31L38.59.08zm17.6 0V20.5h-4V.08zm22.82 0l2 12.05h.05L87 .08h5.42l2.13 20.42h-4.14l-1-14.71h-.05l-.65 5L87 20.5h-3.12l-2-9.78-.68-4.55v-.38l-.62 14.71h-4.34L78 .08zm-12.81 0A5 5 0 0069 2.28c-1.11 2.55 0 4.5 1 6.23.14.25.3.51.44.77 2.17 3.73 3 6.48 1.41 10.41-.12.27-.38.8-.38.8h-4.85a5.17 5.17 0 001.49-1.93c1.11-2.69-.2-5.16-1.48-7.54-1.53-2.87-3.12-5.84-1.44-9.9.16-.38.51-1 .51-1z"
        }
        fill={"currentColor"}
      ></path>
    </svg>
  );
}

export default VismaLogoWhiteRgbsvgIcon;
/* prettier-ignore-end */
