// @ts-nocheck
/* eslint-disable */
/* tslint:disable */
/* prettier-ignore-start */
import React from "react";
import { classNames } from "@plasmicapp/react-web";

export type BulkHeadsvgIconProps = React.ComponentProps<"svg"> & {
  title?: string;
};

export function BulkHeadsvgIcon(props: BulkHeadsvgIconProps) {
  const { className, style, title, ...restProps } = props;
  return (
    <svg
      xmlns={"http://www.w3.org/2000/svg"}
      version={"1.1"}
      x={"0"}
      y={"0"}
      viewBox={"0 0 57 24"}
      xmlSpace={"preserve"}
      height={"1em"}
      className={classNames("plasmic-default__svg", className)}
      style={style}
      {...restProps}
    >
      {title && <title>{title}</title>}

      <path
        fill={"currentColor"}
        d={
          "M54.407 5.526H52.52V2.385A2.385 2.385 0 0050.135 0H6.866a2.385 2.385 0 00-2.385 2.385v3.141H2.594A2.327 2.327 0 00.267 7.853v5.59a2.327 2.327 0 002.327 2.327h1.887V24h48.038v-8.231h1.887a2.327 2.327 0 002.327-2.327v-5.59a2.326 2.326 0 00-2.326-2.326z"
        }
      ></path>
    </svg>
  );
}

export default BulkHeadsvgIcon;
/* prettier-ignore-end */
