import React, { useState } from 'react'
import { useIntl } from 'react-intl'
import styled from 'styled-components'
import DriverPanel from './DriverPanel'
import VehiclePanel from './VehiclePanel'
import AddDriverAndOrUserModal from '../components/AddDriverAndOrUserModal'
import * as GQL from 'generated/graphql'
import ScrollIndicator from 'plasmic/ScrollIndicator'
import Loader from 'components/Loader'

interface LayoutProps {
  numberOfDrivers: number
}

const Layout = styled.div<LayoutProps>`
  display: grid;
  padding: 1rem;

  grid-template-columns: 235px 235px;
  grid-template-rows: repeat(${props => props.numberOfDrivers + 1}, 97px);
  grid-gap: 5px;
`

interface DriverVehiclesPanelProps {
  drivers: GQL.DriverNode[]
  totalDrivers: number
  fetchMore: () => void
  loadingFetchMore: boolean
}

const HeaderTile = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;

  padding: 12px;

  font-size: 12px;
  text-transform: uppercase;
  font-weight: bold;

  color: white;
  background-color: #1c213b;
  border-radius: 6px;
`

const DriverVehiclesPanel: React.FC<DriverVehiclesPanelProps> = ({ drivers, totalDrivers, fetchMore, loadingFetchMore }) => {
  const [createDriverModalOpen, setCreateDriverModalOpen] = useState(false)
  const intl = useIntl()
  const t = intl.formatMessage

  return (
    <Layout numberOfDrivers={drivers.length}>
      <AddDriverAndOrUserModal isOpen={createDriverModalOpen} onRequestClose={() => setCreateDriverModalOpen(false)} />
      <HeaderTile>{t({ id: 'common.drivers' })}</HeaderTile>
      <HeaderTile>{t({ id: 'common.vehicles' })}</HeaderTile>
      {drivers.map(driver => {
        return (
          <React.Fragment key={driver.id}>
            <DriverPanel driver={driver} />
            <VehiclePanel vehicle={driver?.defaultVehicle!} depot={driver?.defaultDepot!} />
          </React.Fragment>
        )
      })}
      <div style={{ gridColumn: '1/3' }}>
        <ScrollIndicator
          tableRow
          loaded={drivers.length.toString()}
          total={totalDrivers.toString()}
          btnLoadMore={{ ...(loadingFetchMore && { children: <Loader color='white' /> }), onClick: fetchMore }}
        />
      </div>
    </Layout>
  )
}

export default DriverVehiclesPanel
