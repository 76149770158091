import * as GQL from 'generated/graphql'
import { displayToast } from 'util/toasts'
import styled from 'styled-components'

interface ISelectProps {
  selected: boolean
}

const Select = styled.div<ISelectProps>`
  flex-grow: 1;
  font-size: 16px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  border-radius: 8px;
  cursor: pointer;
  ${props =>
    props.selected &&
    `
    background-color: #00d4a6;
    color: #fff;
  `}
  :hover {
    background-color: ${props => (props.selected ? 'rgba(0,212,166, 0.8)' : 'rgb(255 255 255 / 10%)')};
  }
`

interface ISubscriptionProps {
  subscriptions: GQL.NotificationSubscriptionNode[]
  verbs: GQL.NotificationVerbNode[]
  channels: GQL.ChannelNode[]
}

function UserThresholdSubscriptionsToggles({ subscriptions, verbs, channels }: ISubscriptionProps) {
  const thresholdVerb = verbs.find(verb => verb.slug === 'has-reached')
  const percentages = [10, 20, 30, 40, 50]
  const emailChannel = channels.find(channel => channel.method === 'email')

  const isThresholdToggled = (percentage: number, channel: GQL.ChannelNode) => {
    return (
      subscriptions?.some(subscription => {
        if (!subscription?.notifyVerb?.id || !subscription?.notifyChannel?.id || !subscription?.isActive) {
          return false
        }

        const matchesVerbAndChannel = subscription.notifyVerb.id === thresholdVerb?.id && subscription.notifyChannel.id === channel.id

        // Parse dynamicRules from JSON string to object
        let dynamicRules
        try {
          dynamicRules = subscription.dynamicRules ? JSON.parse(subscription.dynamicRules) : null
        } catch (error) {
          console.error('Error parsing dynamicRules:', error)
          return false
        }

        const matchesThreshold = dynamicRules?.conditions?.some(
          (condition: { key: string; value: string }) => condition.key === 'threshold' && parseInt(condition.value) === percentage
        )

        return matchesVerbAndChannel && matchesThreshold
      }) ?? false
    )
  }

  const [createSubscription, createSubscriptionResult] = GQL.useCreateNotificationSubscription({
    onError: err => displayToast(err.message, 'error'),
    refetchQueries: ['AllPersonalSubscriptions'],
  })

  const [patchSubscription, patchSubscriptionResult] = GQL.usePatchNotificationSubscription({
    onError: err => displayToast(err.message, 'error'),
    refetchQueries: ['AllPersonalSubscriptions'],
  })

  const isLoading = createSubscriptionResult.loading || patchSubscriptionResult.loading

  const toggleSubscription = (percentage: number, channel: GQL.ChannelNode) => {
    if (!channel || !thresholdVerb || isLoading) return // Early return

    const dynamicRule = {
      conditions: [
        {
          key: 'threshold',
          operation: 'lte',
          value: percentage,
        },
      ],
    }

    const existingSubscription =
      subscriptions?.find(subscription => {
        if (!subscription?.notifyVerb?.id || !subscription?.notifyChannel?.id) {
          return null
        }

        const matchesVerbAndChannel = subscription.notifyVerb.id === thresholdVerb.id && subscription.notifyChannel.id === channel.id

        // Parse dynamicRules from JSON string to object
        let dynamicRules
        try {
          dynamicRules = subscription.dynamicRules ? JSON.parse(subscription.dynamicRules) : null
        } catch (error) {
          return null
        }

        const matchesThreshold = dynamicRules?.conditions?.some(
          (condition: { key: string; value: string }) => condition.key === 'threshold' && parseInt(condition.value) === percentage
        )

        return matchesVerbAndChannel && matchesThreshold
      }) ?? null

    if (!existingSubscription) {
      createSubscription({
        variables: {
          input: {
            notifyVerbId: thresholdVerb.id,
            notifyChannelId: channel.id,
            isActive: true,
            dynamicRules: JSON.stringify(dynamicRule),
          },
        },
      })
    } else {
      patchSubscription({
        variables: {
          id: existingSubscription.id,
          input: {
            isActive: !existingSubscription.isActive,
            dynamicRules: JSON.stringify(dynamicRule),
          },
        },
      })
    }
  }

  return (
    <>
      {percentages.map(percentage => (
        <Select
          key={percentage}
          selected={(emailChannel && isThresholdToggled(percentage, emailChannel)) || false}
          onClick={() => {
            emailChannel && toggleSubscription(percentage, emailChannel)
          }}
        >
          {percentage}%
        </Select>
      ))}
    </>
  )
}

export default UserThresholdSubscriptionsToggles
