// This is a skeleton starter React component generated by Plasmic.
// This file is owned by you, feel free to edit as you see fit.
import * as React from 'react'
import { useMemo, useState } from 'react'
import Select from 'react-select'
import { useIntl } from 'react-intl'
import _ from 'lodash'

import * as GQL from 'generated/graphql'
import { PlasmicCreateOrderBookingBulk, DefaultCreateOrderBookingBulkProps } from './plasmic/solace_components/PlasmicCreateOrderBookingBulk'
import { HTMLElementRefOf } from '@plasmicapp/react-web'
import { Control, MenuList, Option, reactSelectStyles } from 'plasmic/StyledReactSelect'
import { SelectedProduct } from 'modules/customers/components/CreateOrder'
import { formatNumber } from 'modules/customers/util'

export interface CreateOrderBookingBulkProps extends DefaultCreateOrderBookingBulkProps {
  cylinderGroup?: GQL.CylinderGroupNode
  products: GQL.ProductNode[]
  selectedProduct: SelectedProduct
  setSelectedProduct: React.Dispatch<React.SetStateAction<SelectedProduct>>
  estimatedPrice: string
  tax: string
  estimatedTotal: string
}

function CreateOrderBookingBulk_(props: CreateOrderBookingBulkProps, ref: HTMLElementRefOf<'div'>) {
  const [isPercentageBasis, setIsPercentageBasis] = useState(true)

  const bulkTankFormat = useMemo(() => {
    return props?.cylinderGroup?.bulkTankFormat
  }, [props.cylinderGroup])

  const maxCapacityLiters: number = useMemo(() => {
    return bulkTankFormat?.capacity || 0
  }, [bulkTankFormat])

  const maxUsableCapacityPercent: number = useMemo(() => {
    return bulkTankFormat ? bulkTankFormat.usableCapacity : 100
  }, [bulkTankFormat])

  // TODO
  const averagePercentageBeforeRefill = useMemo(() => {
    return undefined
  }, [])

  const [estimatedPercentageBeforeRefill, setEstimatedPercentageBeforeRefill] = useState(averagePercentageBeforeRefill || 20)
  const [fillToPercent, setFillToPercent] = useState(maxUsableCapacityPercent)
  const [percentageToRefill, setPercentageToRefill] = useState(maxUsableCapacityPercent - estimatedPercentageBeforeRefill)
  const [actualAmount, setActualAmount] = useState((percentageToRefill / 100) * maxCapacityLiters)

  const intl = useIntl()
  const t = intl.formatMessage

  const handleActualAmountChange = (value: string) => {
    const actualAmount = value ? Math.abs(parseFloat(value)) : 0
    const validatedActualAmount = Math.min(Math.max(actualAmount, 0), (maxCapacityLiters * maxUsableCapacityPercent) / 100)
    setActualAmount(validatedActualAmount)

    const newFillToPercent = (validatedActualAmount / maxCapacityLiters) * 100
    setFillToPercent(newFillToPercent)
    setPercentageToRefill(newFillToPercent)
    setEstimatedPercentageBeforeRefill(0)
  }

  const handleFillToPercentChange = (value: string) => {
    const _fillToPercent = value ? Math.abs(parseFloat(value)) : 0
    const validatedFillToPercent = Math.min(Math.max(_fillToPercent, 0), maxUsableCapacityPercent)
    setFillToPercent(validatedFillToPercent)
    setEstimatedPercentageBeforeRefill(prev => {
      const newEstimatedPercent = Math.min(prev, validatedFillToPercent)
      handleCalculations(validatedFillToPercent, newEstimatedPercent)
      return newEstimatedPercent
    })
  }

  const handleEstimatedPercentageBeforeRefillChange = (value: string) => {
    const estimatedPercentageBeforeRefill = value ? Math.abs(parseFloat(value)) : 0
    const validatedEstimatedPercentageBeforeRefill = Math.min(Math.max(estimatedPercentageBeforeRefill, 0), maxUsableCapacityPercent)
    setEstimatedPercentageBeforeRefill(validatedEstimatedPercentageBeforeRefill)
    setFillToPercent(prev => {
      const newFillToPercent = Math.max(prev, validatedEstimatedPercentageBeforeRefill)
      handleCalculations(newFillToPercent, validatedEstimatedPercentageBeforeRefill)
      return newFillToPercent
    })
  }

  const handleCalculations = (fillPercent: number, leftPercent: number) => {
    const newRefillPercentage = fillPercent - leftPercent
    const newActualAmount = (newRefillPercentage / 100) * maxCapacityLiters

    if (newActualAmount > maxCapacityLiters) {
      setPercentageToRefill(100)
    } else {
      setPercentageToRefill(parseFloat(formatNumber(newRefillPercentage)))
    }

    setActualAmount(newActualAmount)
  }

  React.useEffect(() => {
    if (props?.setSelectedProduct) {
      props.setSelectedProduct(prev => ({ ...prev, quantity: actualAmount }))
    }
  }, [actualAmount])

  return (
    <PlasmicCreateOrderBookingBulk
      root={{ ref }}
      {..._.omit(props, ['products', 'selectedProduct', 'setSelectedProduct', 'cylinderGroup'])}
      basis={isPercentageBasis ? 'percentage' : 'actualAmount'}
      tabActualAmount={{
        selected: !isPercentageBasis,
        tabOnClick: () => setIsPercentageBasis(false),
      }}
      tabPercentage={{
        selected: isPercentageBasis,
        tabOnClick: () => setIsPercentageBasis(true),
      }}
      inputActualAmount={{
        type: 'number',
        fieldValue: actualAmount,
        onChange: (event: React.ChangeEvent<HTMLInputElement>) => handleActualAmountChange(event.target.value),
      }}
      sliderFillToPercent={{
        value: fillToPercent,
        onChange: value => {
          if (value !== undefined && value <= maxUsableCapacityPercent) {
            handleFillToPercentChange(value.toString())
          }
        },
        marks: maxUsableCapacityPercent ? [{ value: maxUsableCapacityPercent, label: 'Max fill level' }] : undefined,
      }}
      inputFillToPercent={{
        type: 'number',
        fieldValue: fillToPercent,
        onChange: (event: React.ChangeEvent<HTMLInputElement>) => handleFillToPercentChange(event.target.value),
      }}
      sliderEstimatedPercentLeftBeforeRefill={{
        value: estimatedPercentageBeforeRefill,
        onChange: value => {
          if (value !== undefined) {
            handleEstimatedPercentageBeforeRefillChange(value.toString())
          }
        },
        marks: averagePercentageBeforeRefill ? [{ value: averagePercentageBeforeRefill, label: 'Avg. percentage on refill' }] : undefined,
      }}
      inputEstimatedPercentLeftBeforeRefill={{
        type: 'number',
        fieldValue: estimatedPercentageBeforeRefill,
        onChange: (event: React.ChangeEvent<HTMLInputElement>) => handleEstimatedPercentageBeforeRefillChange(event.target.value),
      }}
      percentToRefill={`${percentageToRefill.toFixed(2)} %`}
      refillAmount={`${actualAmount.toFixed(2)} liters`}
    />
  )
}

const CreateOrderBookingBulk = React.forwardRef(CreateOrderBookingBulk_)
export default CreateOrderBookingBulk
