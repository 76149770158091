import React from 'react'
import styled from 'styled-components'
import * as GQL from 'generated/graphql'
import Tooltip from 'components/Tooltip/Tooltip'
import CopyToAll from 'plasmic/CopyToAll'
import { useNavigate } from 'react-router-dom'

interface LayoutProps {}

const Layout = styled.div<LayoutProps>`
  display: grid;
  grid-gap: 4px;
  background: #1c213b;
  border-radius: 6px;
  padding: 5px;
  color: #696d80;

  &:hover {
    background-color: rgba(0, 0, 0, 0.212);
  }
`

const DowGroup = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 40px;
  align-items: center;
  .copyToAllDriverPanel {
    display: none;
  }
  :hover .copyToAllDriverPanel {
    display: block;
  }
`

interface DowProps {
  selected: boolean
}
const Dow = styled.div<DowProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  width: 24px;
  height: 24px;
  text-align: center;

  ${props =>
    props.selected &&
    `
    border: solid 1px #9400e984;
    background: rgba(147,0,233,0.1);
    color: white;
  `}
`

interface DayProps {
  d: any
  i: number
}

const Day: React.FC<DayProps> = ({ d, i }) => {
  const dowsMonday = ['M', 'T', 'W', 'T', 'F', 'S', 'S']
  const dowsMondayLong = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday']
  return (
    <>
      <Tooltip content={dowsMondayLong[i] + ': ' + (d ? d.startTime + ' - ' + d.endTime : 'Not working')}>
        <Dow selected={!!d}>{dowsMonday[i]}</Dow>
      </Tooltip>
    </>
  )
}

interface DriverPanelProps {
  driver: GQL.DriverNode
}

const DriverPanel: React.FC<DriverPanelProps> = ({ driver }) => {
  const navigate = useNavigate()

  const user_dows = driver.standardWorkhours?.map(w => w) || []

  const dows = ['S', 'M', 'T', 'W', 'T', 'F', 'S']

  const dowResult = dows.map((d, i) => user_dows.find(ud => ud?.dow === i))

  return (
    <Layout>
      <DowGroup style={{ color: '#fff', fontSize: '18px' }}>
        {driver.user?.fullName}
        <CopyToAll tooltip={{ caption: 'Drivers and Vehicles' }} className='copyToAllDriverPanel' onClick={() => navigate('/deliveries/vehicles')} />
      </DowGroup>

      <DowGroup>
        {dowResult.map((d, i) => (
          <Day key={i} d={d} i={i} />
        ))}
      </DowGroup>
    </Layout>
  )
}

export default DriverPanel
