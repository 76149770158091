// This is a skeleton starter React component generated by Plasmic.
// This file is owned by you, feel free to edit as you see fit.
import * as React from 'react'
import { PlasmicMapWrapper, DefaultMapWrapperProps } from './plasmic/solace_components/PlasmicMapWrapper'
import { HTMLElementRefOf } from '@plasmicapp/react-web'
import * as p from '@plasmicapp/react-web'
import { omit } from 'lodash'

// Your component props start with props for variants and slots you defined
// in Plasmic, but you can add more here, like event handlers that you can
// attach to named nodes in your component.
//
// If you don't want to expose certain variants or slots as a prop, you can use
// Omit to hide them:
//
// interface MapWrapperProps extends Omit<DefaultMapWrapperProps, "hideProps1"|"hideProp2"> {
//   // etc.
// }
//
// You can also stop extending from DefaultMapWrapperProps altogether and have
// total control over the props for your component.
export interface MapWrapperProps extends DefaultMapWrapperProps {
  map?: p.Flex<'div'>
  lightMode?: boolean
  toggleLightMode?: (lightMode: boolean) => void
}

function MapWrapper_(props: MapWrapperProps, ref: HTMLElementRefOf<'div'>) {
  // Use PlasmicMapWrapper to render this component as it was
  // designed in Plasmic, by activating the appropriate variants,
  // attaching the appropriate event handlers, etc.  You
  // can also install whatever React hooks you need here to manage state or
  // fetch data.
  //
  // Props you can pass into PlasmicMapWrapper are:
  // 1. Variants you want to activate,
  // 2. Contents for slots you want to fill,
  // 3. Overrides for any named node in the component to attach behavior and data,
  // 4. Props to set on the root node.
  //
  // By default, we are just piping all MapWrapperProps here, but feel free
  // to do whatever works for you.

  return (
    <PlasmicMapWrapper
      root={{ ref }}
      {...omit(props, 'lightMode', 'toggleLightMode')}
      toggleLight={{
        isChecked: props.lightMode,
        onChange: props.toggleLightMode,
      }}
    />
  )
}

const MapWrapper = React.forwardRef(MapWrapper_)
export default MapWrapper
