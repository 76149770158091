// This is a skeleton starter React component generated by Plasmic.
// This file is owned by you, feel free to edit as you see fit.
import * as React from 'react'
import { PlasmicModalVerifyBulkDelivery, DefaultModalVerifyBulkDeliveryProps } from './plasmic/solace_components/PlasmicModalVerifyBulkDelivery'
import { HTMLElementRefOf } from '@plasmicapp/react-web'
import * as GQL from 'generated/graphql'
import * as p from '@plasmicapp/react-web'
import ButtonFill from './ButtonFill'
import CreateOrderSummary from './CreateOrderSummary'
import { VerifyBulkTankDelivery } from 'modules/orders/types'
import { omit } from 'lodash'
import OrderProductRowExtended from './OrderProductRowExtended'
import { formatPrice, formatTaxRate } from 'modules/orders/util'
import { useAppContext } from 'util/hooks'
import { onChange } from 'react-toastify/dist/core/store'
import { format } from 'date-fns'
import { OrderProductAdjustment } from 'modules/orders/ActiveOrdersTable/OrdersTable'

// Your component props start with props for variants and slots you defined
// in Plasmic, but you can add more here, like event handlers that you can
// attach to named nodes in your component.
//
// If you don't want to expose certain variants or slots as a prop, you can use
// Omit to hide them:
//
// interface ModalVerifyBulkDeliveryProps extends Omit<DefaultModalVerifyBulkDeliveryProps, "hideProps1"|"hideProp2"> {
//   // etc.
// }
//
// You can also stop extending from DefaultModalVerifyBulkDeliveryProps altogether and have
// total control over the props for your component.
export interface ModalVerifyBulkDeliveryProps extends DefaultModalVerifyBulkDeliveryProps {
  verifyBulkTankInfo: VerifyBulkTankDelivery | undefined
  createOrderSummary?: p.Flex<typeof CreateOrderSummary>
  onClose: () => void
}

export interface OrderProduct {
  id?: string
  product: GQL.ProductNode
  quantity: number
  exchange: boolean
  basePrice: number
  depositFee: number
  subtotal: number
  tax: number
  total: number
}

interface OrderSummary {
  products: OrderProduct[]
  notes?: string[]
}

function ModalVerifyBulkDelivery_(props: ModalVerifyBulkDeliveryProps, ref: HTMLElementRefOf<'div'>) {
  // Use PlasmicModalVerifyBulkDelivery to render this component as it was
  // designed in Plasmic, by activating the appropriate variants,
  // attaching the appropriate event handlers, etc.  You
  // can also install whatever React hooks you need here to manage state or
  // fetch data.
  //
  // Props you can pass into PlasmicModalVerifyBulkDelivery are:
  // 1. Variants you want to activate,
  // 2. Contents for slots you want to fill,
  // 3. Overrides for any named node in the component to attach behavior and data,
  // 4. Props to set on the root node.
  //
  // By default, we are just piping all ModalVerifyBulkDeliveryProps here, but feel free
  // to do whatever works for you.
  const { appContext } = useAppContext()
  const { distributor } = appContext
  const [adjustedAmounts, setAdjustedAmounts] = React.useState<OrderProductAdjustment>({})
  const [orderSummary, setOrderSummary] = React.useState<OrderSummary | undefined>()

  const handleAmountChange = (id: string, productId: string, amount: number) => {
    setAdjustedAmounts(prev => ({ ...prev, [id]: { quantity: amount, productId: productId } }))
  }

  React.useEffect(() => {
    if (!props.verifyBulkTankInfo?.order.products) return
    const amounts: OrderProductAdjustment = {}
    for (const orderProduct of props.verifyBulkTankInfo.order.products) {
      if (!orderProduct || !orderProduct.product || orderProduct.product.storageType !== GQL.ProductStorageType.Bulk) continue
      amounts[orderProduct.id] = { quantity: orderProduct.quantity, productId: orderProduct.product.id }
    }
    setAdjustedAmounts(amounts)
  }, [props.verifyBulkTankInfo?.order.products])

  const customerPricingCategory = React.useMemo(
    () =>
      props.verifyBulkTankInfo?.order.cylinderGroup?.customer.priceCategory ||
      appContext.distributor?.priceCategories?.find(priceCategory => priceCategory?.default === true),
    [props.verifyBulkTankInfo?.order.cylinderGroup, appContext.distributor]
  )

  React.useEffect(() => {
    if (!props.verifyBulkTankInfo?.order) return
    setOrderSummary({
      products:
        props.verifyBulkTankInfo?.order.products?.map(orderProduct => {
          const quantity = adjustedAmounts[orderProduct!.id]?.quantity || orderProduct?.quantity || 0
          const productPrice = orderProduct?.price || 0
          const depositFee = orderProduct?.exchangeCylindersFee && orderProduct?.cylinderDepositPrice ? parseFloat(orderProduct.cylinderDepositPrice) : 0
          const subtotal = productPrice * (quantity || 0) + depositFee * (quantity || 0)
          const itemTax = (productPrice * (quantity || 0) * (orderProduct?.taxRate || 0)) / 100
          const depositTax = (depositFee * (quantity || 0) * (orderProduct?.depositTaxRate || 0)) / 100

          return {
            id: orderProduct?.id,
            product: orderProduct?.product,
            quantity: quantity,
            exchange: orderProduct?.exchangeCylindersFee,
            basePrice: productPrice,
            subtotal: subtotal,
            depositFee: depositFee,
            tax: depositTax + itemTax,
            total: subtotal + depositTax + itemTax,
          } as OrderProduct
        }) || [],
      notes: props.verifyBulkTankInfo?.order.notes?.map(note => note?.content || '') || [],
    })
  }, [props.verifyBulkTankInfo?.order, orderSummary, setOrderSummary, customerPricingCategory, adjustedAmounts])

  const orderSummaryPricing = React.useMemo(() => {
    const initialSum = { depositFee: 0, subtotal: 0, total: 0, tax: 0 }
    if (!orderSummary?.products) {
      return initialSum
    }
    const summary = orderSummary.products.reduce((accumulator, product) => {
      accumulator.depositFee += product.depositFee
      accumulator.subtotal += product.subtotal
      accumulator.tax += product.tax
      accumulator.total += product.total
      return accumulator
    }, initialSum)

    return summary
  }, [orderSummary])

  const orderSummaryPickups = React.useMemo(() => {
    const initialSum = { toDeliver: 0, toPickup: 0 }
    if (!orderSummary?.products) {
      return initialSum
    }
    const summary = orderSummary.products.reduce((accumulator, product) => {
      const quantity = adjustedAmounts[product.quantity]?.quantity || product.quantity || 0
      if (!Object.values(GQL.GasTypeEnum).includes(product.product?.type as unknown as GQL.GasTypeEnum)) {
        return accumulator
      } else if (!product.exchange) {
        accumulator.toPickup += quantity
      }
      accumulator.toDeliver += quantity
      return accumulator
    }, initialSum)

    return summary
  }, [orderSummary, adjustedAmounts])

  const handleSubmit = () => {
    if (props.verifyBulkTankInfo?.callback) props.verifyBulkTankInfo.callback(adjustedAmounts)
    props.onClose()
  }

  return (
    <PlasmicModalVerifyBulkDelivery
      root={{ ref }}
      {...omit(props, 'verifyBulkTankInfo', 'onClose')}
      createOrderSummary={{
        productRows: props.verifyBulkTankInfo?.order.products?.map(orderProduct => (
          <OrderProductRowExtended
            key={orderProduct?.id}
            edit={orderProduct?.product?.storageType === GQL.ProductStorageType.Bulk}
            productImage={orderProduct?.product?.image?.image}
            product={orderProduct?.product?.displayName}
            price={formatPrice(orderProduct?.price || 0, distributor?.defaultCurrency || 'USD')}
            priceTax={`+${formatTaxRate(orderProduct?.taxRate, distributor)}`}
            deposit={formatPrice(orderProduct?.cylinderDepositPrice || 0, distributor?.defaultCurrency || 'USD')}
            depositTax={`+${formatTaxRate(orderProduct?.depositTaxRate)}`}
            total={formatPrice(orderProduct?.totalPrice || 0, distributor?.defaultCurrency || 'USD')}
            inputQuantity={{
              fieldValue: adjustedAmounts[orderProduct!.id]?.quantity || 0,
              onChange: (value: any) => handleAmountChange(orderProduct!.id || '', orderProduct?.product?.id || '', parseInt(value.target.value)),
            }}
            quantity={orderProduct?.quantity}
          />
        )),
        tax: formatPrice(orderSummaryPricing.tax, appContext.distributor?.defaultCurrency || 'USD'),
        subtotal: formatPrice(orderSummaryPricing.subtotal, appContext.distributor?.defaultCurrency || 'USD'),
        total: formatPrice(orderSummaryPricing.total, appContext.distributor?.defaultCurrency || 'USD'),
        orderDate: format(new Date(), 'dd.MM.yyyy'),
        orderedBy: appContext.user?.fullName,
        productsToDeliver: orderSummaryPickups.toDeliver || '-',
        productsToPickUp: orderSummaryPickups.toPickup || '-',
      }}
      btnClose={{ onClick: () => props.onClose() }}
      btnCancel={{ onClick: () => props.onClose() }}
      btnVerify={{ onClick: () => handleSubmit() }}
    />
  )
}

const ModalVerifyBulkDelivery = React.forwardRef(ModalVerifyBulkDelivery_)
export default ModalVerifyBulkDelivery
