import React from 'react'
import PlasmicCustomerNotifications, { DefaultCustomerNotificationsProps } from 'plasmic/plasmic/solace_components/PlasmicCustomerNotifications'
import PlasmicNotificationRow from 'plasmic/plasmic/solace_components/PlasmicNotificationRow'
import { omit } from 'lodash'
import * as GQL from 'generated/graphql'
import { IDrawerState } from '../pages/SettingsCustomerNotifications'
import CornerLoader from 'components/Loader/CornerLoader'

interface Props extends DefaultCustomerNotificationsProps {
  setDrawerState: (state: IDrawerState) => void
}

const CustomerNotifications: React.FC<Props> = props => {
  const { data: genericTemplateData, loading: loadingGenericTemplates } = GQL.useAllGenericNotificationTemplates()
  const genericTemplates = (genericTemplateData?.allGenericNotificationTemplates as GQL.GenericNotificationTemplateNode[]) || []

  const { data: customTemplateData, loading: loadingCustomTemplates } = GQL.useAllCustomNotificationTemplates({ variables: { first: 100 } })
  const customTemplates =
    customTemplateData?.allCustomNotificationTemplates?.edges
      .map(edge => edge?.node)
      .filter((node): node is GQL.CustomNotificationTemplateNode => node !== null && node !== undefined) ?? []

  const getCustomNotificationTemplate = (genericTemplate?: GQL.GenericNotificationTemplateNode) => {
    return customTemplates.find(template => template.overrides?.id === genericTemplate?.id)
  }

  return (
    <React.Fragment>
      {(loadingGenericTemplates || loadingCustomTemplates) && <CornerLoader size={50} />}
      <PlasmicCustomerNotifications
        notifications={genericTemplates.map(genericTemplate => (
          <PlasmicNotificationRow
            key={genericTemplate.verb?.id}
            title={genericTemplate.verb?.name}
            description={genericTemplate.verb?.description}
            checkbox={{}}
            btnEditMessage={{
              props: {
                onClick: () => {
                  if (genericTemplate.verb !== null) {
                    props.setDrawerState({
                      verb: genericTemplate.verb,
                      genericTemplate: genericTemplate,
                      customTemplate: getCustomNotificationTemplate(genericTemplate),
                    })
                  }
                },
              },
            }}
          />
        ))}
        {...omit(props, 'setDrawerState')}
      />
    </React.Fragment>
  )
}

export default CustomerNotifications
