// This is a skeleton starter React component generated by Plasmic.
// This file is owned by you, feel free to edit as you see fit.
import * as React from 'react'
import { useMemo, useState } from 'react'
import { add, format, parseISO, startOfWeek, sub } from 'date-fns'
import _ from 'lodash'
import { useIntl } from 'react-intl'
import { useDrop } from 'react-dnd'
import { HTMLElementRefOf } from '@plasmicapp/react-web'

import * as GQL from 'generated/graphql'
import { PlasmicRouteDatePicker, DefaultRouteDatePickerProps } from './plasmic/solace_components/PlasmicRouteDatePicker'
import RouteDatePickerItem from './RouteDatePickerItem'
import Loader from 'components/Loader'
import { STOP_TABLE_ROW_DRAG_TYPE } from './RouteTable'
import { useDelayedAction } from 'util/hooks'
import CacheConfigs from 'util/cacheConfig'

export interface RouteDatePickerProps extends DefaultRouteDatePickerProps {
  selectedDate: string
  setSelectedDate: React.Dispatch<React.SetStateAction<string>>
  onDrop: (day: Date, item: GQL.StopNode) => void
}

const DAYS_RANGE = 7

function RouteDatePicker_(props: RouteDatePickerProps, ref: HTMLElementRefOf<'div'>) {
  const [startingDate, setStartingDate] = useState(format(startOfWeek(new Date(props.selectedDate)), 'yyyy-MM-dd'))
  const { startAction, clearAction } = useDelayedAction(2000)

  const [{ isOver: isOverNextWeek, canDrop: canDropNextWeek }, dropNextWeek] = useDrop<any, any, any>({
    accept: [STOP_TABLE_ROW_DRAG_TYPE],
    collect: monitor => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
    hover: (_, monitor) => {
      if (monitor.isOver({ shallow: true }) && canDropNextWeek) {
        startAction(() => setStartingDate(format(add(new Date(startingDate), { days: DAYS_RANGE }), 'yyyy-MM-dd')))
      } else {
        clearAction()
      }
    },
    drop: clearAction,
  })

  const [{ isOver: isOverPreviousWeek, canDrop: canDropPreviousWeek }, dropPreviousWeek] = useDrop<any, any, any>({
    accept: [STOP_TABLE_ROW_DRAG_TYPE],
    collect: monitor => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
    hover: (_, monitor) => {
      if (monitor.isOver({ shallow: true }) && canDropNextWeek) {
        startAction(() => setStartingDate(format(add(new Date(startingDate), { days: -DAYS_RANGE }), 'yyyy-MM-dd')))
      } else {
        clearAction()
      }
    },
    drop: clearAction,
  })

  const intl = useIntl()

  const { data, loading } = GQL.useRoutingStats({
    variables: {
      startDate: startingDate,
      days: DAYS_RANGE,
    },
    ...CacheConfigs.ACCURATE_ONCE,
  })

  const weeklyRoutingStats = useMemo(() => {
    return (data?.routingStats || []) as GQL.DateRouteStats[]
  }, [data])

  const dates = useMemo(() => {
    return Array.from({ length: DAYS_RANGE }, (_, i) => {
      const date = new Date(startingDate)
      date.setDate(date.getDate() + i)
      return date
    })
  }, [startingDate])

  React.useEffect(() => {
    const isSameWeek = format(startOfWeek(parseISO(props.selectedDate)), 'yyyy-MM-dd') === format(startOfWeek(parseISO(startingDate)), 'yyyy-MM-dd')
    if (!isSameWeek) {
      props.setSelectedDate(startingDate)
    }
  }, [startingDate])

  React.useEffect(() => {
    return clearAction
  }, [])

  const getRouteAvgCapacity = (date: Date): number => {
    const dateStats = weeklyRoutingStats.find(s => s?.date === format(date, 'yyyy-MM-dd'))
    if (!dateStats) {
      return 0
    }
    const avgCapacity = dateStats.avgCapacity || 0
    const avgTimeEffort = dateStats.avgTimeEffort || 0
    return Math.min(Math.max((avgCapacity + avgTimeEffort) / 2, 0), 100)
  }

  return (
    <PlasmicRouteDatePicker
      root={{ ref }}
      {..._.omit(props, ['onDrop', 'selectedDate', 'setSelectedDate'])}
      dateItems={
        <>
          {dates.map((dateItem, i) => (
            <RouteDatePickerItem
              key={i}
              data-testid={(weeklyRoutingStats.find(s => s?.date === format(dateItem, 'yyyy-MM-dd'))?.routesCount || 0) > 0 ? 'route' : undefined}
              accept={[STOP_TABLE_ROW_DRAG_TYPE]}
              onItemDrop={item => props.onDrop(dateItem, item)}
              capacityDiv={loading && <Loader size={24} color='gray8' />}
              selected={props.selectedDate === format(dateItem, 'yyyy-MM-dd')}
              today={format(dateItem, 'yyyy-MM-dd') === format(new Date(), 'yyyy-MM-dd')}
              day={intl.formatDate(dateItem, { weekday: 'short' })}
              date={intl.formatDate(format(dateItem, 'yyyy-MM-dd'), { day: 'numeric', month: 'short' })}
              routesCount={weeklyRoutingStats.find(s => s?.date === format(dateItem, 'yyyy-MM-dd'))?.routesCount || 0}
              noRoutes={(weeklyRoutingStats.find(s => s?.date === format(dateItem, 'yyyy-MM-dd'))?.routesCount || 0) === 0}
              onClick={() => props.setSelectedDate(format(dateItem, 'yyyy-MM-dd'))}
              capacityFill={{
                style: {
                  height: `${getRouteAvgCapacity(dateItem)}%`,
                },
              }}
            />
          ))}
        </>
      }
      btnNextWeek={{
        dragOver: isOverNextWeek && canDropNextWeek,
        btnLoadMore: {
          ref: dropNextWeek,
          onClick: () => {
            setStartingDate(format(add(new Date(startingDate), { days: DAYS_RANGE }), 'yyyy-MM-dd'))
          },
        },
      }}
      btnPreviousweek={{
        dragOver: isOverPreviousWeek && canDropPreviousWeek,
        btnLoadMore: {
          ref: dropPreviousWeek,
          onClick: () => {
            setStartingDate(format(sub(new Date(startingDate), { days: DAYS_RANGE }), 'yyyy-MM-dd'))
          },
        },
      }}
    />
  )
}

const RouteDatePicker = React.forwardRef(RouteDatePicker_)

export default RouteDatePicker
