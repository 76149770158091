// This is a skeleton starter React component generated by Plasmic.
// This file is owned by you, feel free to edit as you see fit.
import * as React from 'react'
import { PlasmicSendingProgress, DefaultSendingProgressProps } from './plasmic/solace_components/PlasmicSendingProgress'
import { HTMLElementRefOf } from '@plasmicapp/react-web'
import * as GQL from 'generated/graphql'
import { omit } from 'lodash'
import { format } from 'date-fns'
import { displayToast } from 'util/toasts'
import { useIntl } from 'react-intl'

// Your component props start with props for variants and slots you defined
// in Plasmic, but you can add more here, like event handlers that you can
// attach to named nodes in your component.
//
// If you don't want to expose certain variants or slots as a prop, you can use
// Omit to hide them:
//
// interface SendingProgressProps extends Omit<DefaultSendingProgressProps, "hideProps1"|"hideProp2"> {
//   // etc.
// }
//
// You can also stop extending from DefaultSendingProgressProps altogether and have
// total control over the props for your component.
export interface SendingProgressProps extends DefaultSendingProgressProps {
  amountOfRecipients: number
  notificationId: string | null
  taskId: string | null
  onClose: () => void
}

enum LogLabel {
  SUCCESS = 'Message sent to',
  FAILED = 'Failed sending message to',
}

enum LogStatus {
  SUCCESS = 'Success',
  FAILED = 'Failed',
}

interface LogData {
  timestamp: string
  status: LogStatus
  label: LogLabel
  target: string
  reason?: string
}

function SendingProgress_(props: SendingProgressProps, ref: HTMLElementRefOf<'div'>) {
  // Use PlasmicSendingProgress to render this component as it was
  // designed in Plasmic, by activating the appropriate variants,
  // attaching the appropriate event handlers, etc.  You
  // can also install whatever React hooks you need here to manage state or
  // fetch data.
  //
  // Props you can pass into PlasmicSendingProgress are:
  // 1. Variants you want to activate,
  // 2. Contents for slots you want to fill,
  // 3. Overrides for any named node in the component to attach behavior and data,
  // 4. Props to set on the root node.
  //
  // By default, we are just piping all SendingProgressProps here, but feel free
  // to do whatever works for you.
  const intl = useIntl()
  const t = intl.formatMessage

  const [isLogOpen, setIsLogOpen] = React.useState(false)
  const [fetchMoreLoading, setFetchMoreLoading] = React.useState(false)
  const { data: dataMassCommunication, fetchMore: fetchMoreMassCommunication } = GQL.useMassCommunication({
    variables: { notificationId: props.notificationId!, taskId: props.taskId! },
    skip: !props.notificationId || !props.taskId,
    onError() {
      displayToast(t({ id: 'mass-communication.bulk-messaging.logs.fetch-error' }), 'error')
      props.onClose()
    },
  })
  const isTaskCompleted = React.useMemo(() => dataMassCommunication?.massCommunication?.taskCompleted || undefined, [dataMassCommunication])
  const outgoingLogs = React.useMemo(
    () => dataMassCommunication?.massCommunication?.logs?.edges.map(edge => edge?.node as GQL.OutgoingLogNode) || [],
    [dataMassCommunication]
  )
  const outgoingLogsPageInfo = React.useMemo(
    () => dataMassCommunication?.massCommunication?.logs?.pageInfo as GQL.PageInfo | undefined,
    [dataMassCommunication]
  )

  const totalMessages = React.useMemo(() => Math.max(props.amountOfRecipients, outgoingLogs.length), [props.amountOfRecipients, outgoingLogs])

  const totalFailed = React.useMemo(() => {
    return outgoingLogs.reduce((accumulated, log) => accumulated + Number(!!log.errorMessage), 0)
  }, [outgoingLogs])

  const totalSent = React.useMemo(() => outgoingLogs.length, [outgoingLogs])

  const logData = React.useMemo(() => {
    const getLogStatus = (log: GQL.OutgoingLogNode): LogStatus => {
      if (!!log.errorMessage) return LogStatus.FAILED
      return LogStatus.SUCCESS
    }
    const getLogLabel = (log: GQL.OutgoingLogNode): LogLabel => {
      if (!!log.errorMessage) return LogLabel.FAILED
      return LogLabel.SUCCESS
    }
    return outgoingLogs.map(
      log =>
        ({
          timestamp: format(log.successfulAt || log.createdAt, 'HH:mm:ss'),
          status: getLogStatus(log),
          label: getLogLabel(log),
          target: log.recipientStr,
          reason: log.errorMessage,
        }) as LogData
    )
  }, [outgoingLogs])

  const isTaskCompletedAndAllFetched = React.useMemo(() => {
    if (isTaskCompleted === undefined || !outgoingLogsPageInfo) return false
    return isTaskCompleted && !outgoingLogsPageInfo.hasNextPage
  }, [outgoingLogsPageInfo?.hasNextPage, isTaskCompleted])

  const handleFetchMore = React.useCallback(async () => {
    if (fetchMoreLoading || isTaskCompletedAndAllFetched || !outgoingLogsPageInfo || !props.notificationId || !props.taskId) return
    setFetchMoreLoading(true)
    await fetchMoreMassCommunication({
      variables: { after: outgoingLogsPageInfo?.endCursor, notificationId: props.notificationId, taskId: props.taskId },
      updateQuery: (previous, { fetchMoreResult }) => {
        if (!fetchMoreResult) return previous
        return {
          ...fetchMoreResult,
          massCommunication: {
            ...(fetchMoreResult.massCommunication as GQL.MassCommunicationResult),
            logs: {
              ...(fetchMoreResult.massCommunication?.logs as GQL.OutgoingLogNodeConnection),
              edges: [...(previous?.massCommunication?.logs?.edges || []), ...(fetchMoreResult?.massCommunication?.logs?.edges || [])],
            },
          },
        }
      },
    }).finally(() => {
      setFetchMoreLoading(false)
    })
  }, [
    fetchMoreMassCommunication,
    setFetchMoreLoading,
    fetchMoreLoading,
    isTaskCompletedAndAllFetched,
    outgoingLogsPageInfo?.endCursor,
    props.taskId,
    props.notificationId,
  ])

  React.useEffect(() => {
    if ((totalSent >= 1 && totalSent === totalMessages) || isTaskCompletedAndAllFetched || fetchMoreLoading) return
    handleFetchMore()
  }, [totalSent, totalMessages, isTaskCompletedAndAllFetched, fetchMoreLoading])

  return (
    <PlasmicSendingProgress
      root={{ ref }}
      {...omit(props, 'amountOfRecipients', 'notificationId', 'taskId', 'onClose')}
      totalSent={totalSent.toString()}
      totalMessages={totalMessages.toString()}
      totalFailed={totalFailed.toString()}
      // completed={totalSent >= 1 && totalSent === totalMessages}
      completed={isTaskCompletedAndAllFetched}
      open={isLogOpen}
      logData={logData}
      btnSeeLog={{
        onClick: () => setIsLogOpen(prev => !prev),
      }}
      btnClose={{
        onClick: () => props.onClose(),
      }}
      progressFill={{
        props: {
          style: {
            width: totalMessages >= 1 ? `${(totalSent / totalMessages) * 100}%` : '0%',
            transition: 'width .2s ease',
          },
        },
      }}
    />
  )
}

const SendingProgress = React.forwardRef(SendingProgress_)
export default SendingProgress
