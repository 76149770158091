/* eslint-disable react/jsx-pascal-case */
/* eslint-disable react/style-prop-object */
import React, { useEffect, useMemo, useRef, useState } from 'react'
import pick from 'lodash/pick'
import { useIntl } from 'react-intl'
import styled from 'styled-components'
import ReactSelect from 'react-select'
import Fuse from 'fuse.js'

import { useAppContext, useCustomerContext } from 'util/hooks'
import { default as CustomerInfoTabPlasmic } from 'plasmic/CustomerInfoTab'
import PhotoThumbnail from 'plasmic/PhotoThumbnail'
import * as GQL from 'generated/graphql'
import Loader from 'components/Loader'
import Select__Option from 'plasmic/Select__Option'
import { CenteredLoader } from 'components/Loader/LoaderWrappers'
import CreateTriggerModal from 'modules/settings/components/CreateTriggerModal'
import { displayToast } from 'util/toasts'
import OpeningHours, { ICustomerStandardOpeningHours } from './OpeningHours'
import AutoComplete from 'components/Input/AutoComplete'
import PhoneInput from 'components/Input/PhoneInput'
import { API_URL } from 'util/env'
import { getLoginToken } from 'util/auth'
import { TutorialInfoIcon } from '../../../components/Tooltip/TutorialTooltip'
import ImagesLightbox from './ImagesLightbox'
import ButtonRoundIcon from 'plasmic/ButtonRoundIcon'
import { Control, MenuList, Option, reactSelectStyles } from 'plasmic/StyledReactSelect'
import ChecksvgIcon from 'plasmic/plasmic/solace_components/icons/PlasmicIcon__Checksvg'
import LabelStatusChip from 'plasmic/LabelStatusChip'
import ProductCountRow from 'plasmic/ProductCountRow'
import { AddCylinderGroupInventoryModal, EditStock } from 'modules/inventory'
import { isValidAddress } from 'modules/localization/utils'
import { SelectOption } from 'types/props'

const StyledSelectOption = styled(Select__Option)`
  border-top: 1px dashed rgba(255, 255, 255, 0.212);
  background-color: rgba(255, 255, 255, 0.021);
`

export interface CylinderGroupImage {
  image: string
  id: string
  createdAt: string
  createdBy: string
}

function CustomerInfo({ cylinderGroup }: { cylinderGroup: GQL.CylinderGroupNode | undefined }) {
  const [changed, setChanged] = useState(false)
  const [isLightboxOpen, setIsLightboxOpen] = useState(false)
  const [activeImageIndex, setActiveImageIndex] = useState(0)
  const [createTriggerOpen, setCreateTriggerOpen] = useState(false)
  const [customerInfo, setCustomerInfo] = useState<GQL.CustomerNode | undefined>(cylinderGroup?.customer)
  const [editMode, setEditMode] = useState(false)
  const [images, setImages] = useState<CylinderGroupImage[]>([])
  const [imagesLoading, setImagesLoading] = useState(false)
  const [alwaysOpen, setAlwaysOpen] = React.useState<boolean>(cylinderGroup?.customer?.alwaysOpen || false)
  const [openingHours, setOpeningHours] = React.useState<ICustomerStandardOpeningHours[]>(
    cylinderGroup?.customer?.standardOpeningHours?.map(e => ({
      id: e?.id,
      weekday: e?.weekday,
      closesAt: e?.closesAt,
      opensAt: e?.opensAt,
      active: true,
    })) || []
  )
  const [formErrors, setFormErrors] = useState({
    isPhoneNumberInvalid: cylinderGroup?.customer?.phoneNumber || false,
    showErrors: false,
  })
  const [editStockInfo, setEditStockInfo] = useState<EditStock>()
  const [addInventoryModalOpen, setAddInventoryModalOpen] = useState(false)

  const intl = useIntl()
  const t = intl.formatMessage

  const { customersContext } = useCustomerContext()

  const token = getLoginToken()
  const { appContext } = useAppContext()

  const firstUpdate = useRef(true)
  const [stripePaymentAlternatives, setStripePaymentAlternatives] = useState<GQL.StripePaymentAlternativesInput>({
    inAdvance: !!customerInfo?.paymentSetting?.stripe?.paymentAlternatives?.inAdvance,
    onDelivery: !!customerInfo?.paymentSetting?.stripe?.paymentAlternatives?.onDelivery,
    credit: !!customerInfo?.paymentSetting?.stripe?.paymentAlternatives?.credit,
  })
  const [vismaPaymentAlternatives, setVismaPaymentAlternatives] = useState<GQL.VismaPaymentAlternativesInput>({
    credit: !!customerInfo?.paymentSetting?.visma?.paymentAlternatives?.credit,
  })
  const [customizePaymentOptions, setCustomizePaymentOptions] = useState(
    !!(customerInfo?.paymentSetting?.stripe?.paymentAlternatives || customerInfo?.paymentSetting?.visma?.paymentAlternatives)
  )

  const { data: productData, loading: loadingProducts } = GQL.useAllProducts({ variables: { service: false } })
  const { data: depotData } = GQL.useAllDepots()
  const { data: triggerData, loading: loadingTriggers } = GQL.useAllDistributorThresholds()
  const { data: inventoriesData, loading: loadingInventories } = GQL.useAllInventories({
    variables: { cylinderGroupId: cylinderGroup?.id },
    skip: !cylinderGroup || appContext.distributor?.inventoryAccess === GQL.InventoryAccess.NoAccess,
  })

  const allTriggers = useMemo(
    () => triggerData?.allDistributorRefillThresholds?.edges.map(edge => edge?.node as GQL.DistributorRefillThresholdNode) || [],
    [triggerData]
  )

  const products = useMemo(() => productData?.availableProducts?.edges.map(product => product?.node as GQL.ProductNode) || [], [productData])

  const inventories = useMemo(() => inventoriesData?.allInventories?.edges.map(edge => edge?.node as GQL.InventoryNode) || [], [inventoriesData])

  const [setCustomerPaymentSetting] = GQL.useSetCustomerPaymentSetting({
    onCompleted: response => {
      displayToast(t({ id: 'settings.update-success' }), 'success')
      setCustomerInfo(response.setCustomerPaymentSetting?.paymentSetting?.customer as GQL.CustomerNode)
    },
    onError: () => {
      displayToast(t({ id: 'edit-user.toasts.error' }), 'error')
    },
  })

  function setDefaultPaymentAlternatives() {
    setStripePaymentAlternatives({
      inAdvance: !!appContext.distributor?.paymentSetting?.stripe?.paymentAlternatives?.inAdvance,
      onDelivery: !!appContext.distributor?.paymentSetting?.stripe?.paymentAlternatives?.onDelivery,
      credit: !!appContext.distributor?.paymentSetting?.stripe?.paymentAlternatives?.credit,
    })

    setVismaPaymentAlternatives({
      credit: !!appContext.distributor?.paymentSetting?.visma?.paymentAlternatives?.credit,
    })
  }

  useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false
      return
    }

    let params: any = {
      variables: {
        id: customerInfo?.id || '',
        input: {},
      },
    }

    if (customizePaymentOptions) {
      if (appContext.distributor?.paymentSetting?.stripe) {
        params.variables.input['stripePaymentAlternatives'] = stripePaymentAlternatives
      }
      if (appContext.distributor?.paymentSetting?.visma) {
        params.variables.input['vismaPaymentAlternatives'] = vismaPaymentAlternatives
      }
    }

    setCustomerPaymentSetting(params)
  }, [
    stripePaymentAlternatives,
    vismaPaymentAlternatives,
    customizePaymentOptions,
    customerInfo?.id,
    setCustomerPaymentSetting,
    appContext.distributor?.paymentSetting?.stripe,
    appContext.distributor?.paymentSetting?.visma,
  ])

  const activeProviders = useMemo(() => {
    const providers = []
    if (
      appContext.distributor?.paymentSetting?.stripe &&
      (appContext.distributor.paymentSetting.stripe.paymentAlternatives?.credit ||
        appContext.distributor.paymentSetting.stripe.paymentAlternatives?.onDelivery ||
        appContext.distributor.paymentSetting.stripe.paymentAlternatives?.inAdvance)
    ) {
      providers.push(GQL.PaymentProviders.Stripe.toLowerCase())
    }
    if (appContext.distributor?.paymentSetting?.visma && appContext?.distributor?.paymentSetting?.visma?.paymentAlternatives?.credit) {
      providers.push(GQL.PaymentProviders.Visma.toLowerCase())
    }

    return providers
  }, [appContext.distributor])

  useEffect(() => {
    const fetchCylinderGroupImages = async () => {
      if (!cylinderGroup?.images) {
        setImages([])
        return
      }

      const images: CylinderGroupImage[] = []
      setImagesLoading(true)
      for (const image of cylinderGroup.images) {
        if (!image) {
          continue
        }

        const decodedIdParts = atob(image.id).split(':')

        if (decodedIdParts.length !== 2) {
          continue
        }

        const id = decodedIdParts[1]

        try {
          const resp = await fetch(`${API_URL}/cylinder_groups/${id}`, {
            headers: {
              authorization: token ? `Bearer ${token}` : '',
              'system-origin': 'Solace',
            },
          })
          const imageBlob = await resp.blob()
          images.push({
            id: image.id,
            createdAt: image.createdAt,
            createdBy: image.createdBy.fullName || 'Unknown',
            image: URL.createObjectURL(imageBlob),
          })
        } catch (err) {
          console.log(err)
        }
      }
      setImages(images)
      setImagesLoading(false)
    }

    fetchCylinderGroupImages()
  }, [cylinderGroup, token])

  const [patchCustomer, { loading: loadingPatchCustomer }] = GQL.usePatchCustomer({
    // TODO: update cache
    onError: error => {
      if (error.message.includes('phone_number')) {
        displayToast(t({ id: 'customers.toasts.error-bad-phone-number' }))
      } else if (error.message.includes('Customer with that name exists')) {
        displayToast(t({ id: 'customers.patch.error.duplicate.name' }))
      } else if (error.message.includes('Customer with that customer identifier exists')) {
        displayToast(t({ id: 'customers.create-patch-customer.error.identifier-exists' }))
      } else {
        displayToast(t({ id: 'customers.toasts.error-generic' }))
      }
    },
  })
  const [patchCylinderGroup, { loading: patchCylinderGroupLoading }] = GQL.usePatchCylinderGroup({
    // TODO: update cache
    onError: e => {
      displayToast(e.message, 'error')
      setOpeningHours(
        customer?.standardOpeningHours?.map(e => ({ id: e?.id, weekday: e?.weekday, closesAt: e?.closesAt, opensAt: e?.opensAt, active: true })) || []
      )
    },
  })

  const customer = cylinderGroup?.customer
  if (!cylinderGroup || !customer || !customerInfo || !depotData) return <Loader />

  const handlePatchCustomer = () => {
    patchCustomer({
      variables: {
        id: customerInfo.id,
        input: {
          name: customerInfo.name,
          customerIdentifier: customerInfo.customerIdentifier,
          address: {
            firstLine: customerInfo.address.firstLine,
            latitude: customerInfo.address.latitude,
            longitude: customerInfo.address.longitude,
          },
          phoneNumber: customerInfo.phoneNumber,
          customerDomainType: customerInfo.customerDomainType,
          depot: customerInfo.depot?.id,
          priceCategory: customerInfo?.priceCategory?.id,
        },
      },
      onCompleted: data => {
        setEditMode(false)
        displayToast('Customer updated successfully', 'success')
        setCustomerInfo((data?.patchCustomer?.customer as GQL.CustomerNode) || cylinderGroup.customer)
      },
    })
  }

  const doPatchCustomer = () => {
    if (loadingPatchCustomer) return
    if (!customerInfo.name || customerInfo.name === '') {
      displayToast('Customer name is required!', 'error')
      return
    }

    if (!isValidAddress(customerInfo.address)) {
      displayToast('Please select a valid address', 'error')
      return
    }
    let similarAddressCustomers = []
    let similarPhoneCustomers = []

    if (customerInfo.address) {
      const allCustomersAddress = customersContext.map(customer => customer.address)

      const addressFuse = new Fuse(allCustomersAddress, {
        includeScore: true,
      })
      similarAddressCustomers = addressFuse.search(customerInfo.address.firstLine).filter(result => result.hasOwnProperty('score') && result.score! < 0.05)
    }
    if (customerInfo.phoneNumber) {
      const allCustomersPhoneNumbers = customersContext.map(customer => customer.phoneNumber)

      const phoneFuse = new Fuse(allCustomersPhoneNumbers, {
        includeScore: true,
      })
      similarPhoneCustomers = phoneFuse.search(customerInfo.phoneNumber).filter(result => result.hasOwnProperty('score') && result.score! < 0.02)
    }

    // length > 1 ensures it skips this customer
    if (similarAddressCustomers.length > 1 && similarPhoneCustomers.length > 1) {
      displayToast(
        t({ id: 'customers.create-patch-customer.alert.address-and-phone-number-similarity' }),
        'confirm',
        { autoClose: false },
        true,
        () => setEditMode(false),
        () => handlePatchCustomer()
      )
      return
    } else if (similarAddressCustomers.length > 1) {
      displayToast(
        t({ id: 'customers.create-patch-customer.alert.address-similarity' }),
        'confirm',
        { autoClose: false },
        true,
        () => setEditMode(false),
        () => handlePatchCustomer()
      )
      return
    } else if (similarPhoneCustomers.length > 1) {
      displayToast(
        t({ id: 'customers.create-patch-customer.alert.phone-number-similarity' }),
        'confirm',
        { autoClose: false },
        true,
        () => setEditMode(false),
        () => handlePatchCustomer()
      )
      return
    }

    handlePatchCustomer()
  }

  const onAvailableDeliverySave = () => {
    if (loadingPatchCustomer) {
      return
    }
    patchCustomer({
      variables: {
        id: customer.id,
        input: {
          standardOpeningHoursAdd: openingHours
            .filter(openingHour => openingHour.active && !openingHour.id)
            .map((o: any) => pick(o, ['weekday', 'opensAt', 'closesAt'])),
          standardOpeningHoursUpdate: openingHours
            .filter(openingHour => openingHour.active && openingHour.id)
            .map((o: any) => pick(o, ['id', 'weekday', 'opensAt', 'closesAt'])),
          standardOpeningHoursRemove: openingHours.filter(openingHour => !openingHour.active && openingHour.id).map((o: any) => o.id),
          alwaysOpen: alwaysOpen,
        },
      },
      onCompleted: data => {
        displayToast(t({ id: 'customers.customer-modal.available-delivery.saved' }), 'success')
        setChanged(false)
        const newOpeningHours =
          data?.patchCustomer?.customer?.standardOpeningHours?.map(e => ({
            id: e?.id,
            weekday: e?.weekday,
            closesAt: e?.closesAt,
            opensAt: e?.opensAt,
            active: true,
          })) || []
        setOpeningHours(newOpeningHours)
      },
    })
  }

  return (
    <>
      <CreateTriggerModal open={createTriggerOpen} onClose={() => setCreateTriggerOpen(false)} />
      <ImagesLightbox
        customerName={customerInfo.name}
        isOpen={isLightboxOpen}
        onClose={() => setIsLightboxOpen(false)}
        images={images}
        activeImageIndex={activeImageIndex}
      />
      <CustomerInfoTabPlasmic
        customerAssets={appContext.distributor?.inventoryAccess !== GQL.InventoryAccess.NoAccess}
        noCylinderSetup={!cylinderGroup.cylinderSides || cylinderGroup.cylinderSides.length === 0}
        customerInfoInfo={{
          helpText: <TutorialInfoIcon content={t({ id: 'tooltips.customers.drawer.tabs.customer_info.info' })} />,
        }}
        customerInfoOrderMethod={{
          helpText: <TutorialInfoIcon content={t({ id: 'tooltips.customers.drawer.tabs.customer_info.order_method' })} />,
        }}
        customerInfoAutoTriggers={{
          helpText: <TutorialInfoIcon content={t({ id: 'tooltips.customers.drawer.tabs.customer_info.auto_triggers' })} />,
        }}
        customerInfoAvailable={{
          helpText: <TutorialInfoIcon content={t({ id: 'tooltips.customers.drawer.tabs.customer_info.available_delivery' })} />,
          headRight: changed && (
            <ButtonRoundIcon
              onClick={() => onAvailableDeliverySave()}
              title={t({ id: 'common.save-changes' })}
              color={'green'}
              icon={<ChecksvgIcon style={{ width: '10px' }} />}
            />
          ),
        }}
        paymentOptions={{
          props: {
            toggleDefault: {
              on: customizePaymentOptions,
              onClick: () => {
                if (!customizePaymentOptions) {
                  setDefaultPaymentAlternatives()
                }
                setCustomizePaymentOptions(!customizePaymentOptions)
              },
            },
            activeProviders: activeProviders,
            hideOptions: !customizePaymentOptions,
            checkStripeAdvance: {
              isChecked: stripePaymentAlternatives.inAdvance,
              isDisabled: !appContext.distributor?.paymentSetting?.stripe?.paymentAlternatives?.inAdvance,
              onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
                setStripePaymentAlternatives({
                  ...stripePaymentAlternatives,
                  inAdvance: !!e,
                })
              },
            },
            checkStripeDelivery: {
              isChecked: stripePaymentAlternatives.onDelivery,
              isDisabled: !appContext.distributor?.paymentSetting?.stripe?.paymentAlternatives?.onDelivery,
              onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
                setStripePaymentAlternatives({
                  ...stripePaymentAlternatives,
                  onDelivery: !!e,
                })
              },
            },
            checkStripeCredit: {
              isChecked: stripePaymentAlternatives.credit,
              isDisabled: !appContext.distributor?.paymentSetting?.stripe?.paymentAlternatives?.credit,
              onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
                setStripePaymentAlternatives({
                  ...stripePaymentAlternatives,
                  credit: !!e,
                })
              },
            },
            checkVismaCredit: {
              isChecked: vismaPaymentAlternatives.credit,
              isDisabled: !appContext.distributor?.paymentSetting?.visma?.paymentAlternatives?.credit,
              onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
                setVismaPaymentAlternatives({
                  ...vismaPaymentAlternatives,
                  credit: !!e,
                })
              },
            },
          },
        }}
        customerInfo={{
          save: editMode,
          btnEdit: {
            label: 'dsdas',
            ...(editMode && loadingPatchCustomer && { icon: <Loader color='white' size={35} /> }),
            onClick: () => {
              if (editMode) {
                doPatchCustomer()
              }
              setEditMode(!editMode)
            },
          },
          inputCustomerName: {
            alert: !customerInfo.name || customerInfo.name.length < 3,
            undefinedInput: {
              name: 'name',
              value: customerInfo.name,
              onChange: (e: React.ChangeEvent<HTMLInputElement>) => setCustomerInfo({ ...customerInfo, name: e.target.value }),
            },
          },
          inputId: {
            fieldStatus: !!customerInfo.customerIdentifier,
            undefinedInput: {
              name: 'customerIdentifier',
              value: customerInfo.customerIdentifier,
              onChange: (e: React.ChangeEvent<HTMLInputElement>) => setCustomerInfo({ ...customerInfo, customerIdentifier: e.target.value }),
            },
          },
          customerId: customer.customerIdentifier,
          customerName: customer.name,
          inputAddressWrapper: (
            <AutoComplete
              value={customerInfo.address ? customerInfo.address.firstLine : ''}
              placeholder={'Address'}
              name='address'
              onChange={e => setCustomerInfo({ ...customerInfo, address: { ...customerInfo.address, firstLine: e } as GQL.AddressNode })}
              onLatLngChange={({ latitude, longitude, formattedAddress }: { latitude: number; longitude: number; formattedAddress?: string }) => {
                setCustomerInfo({
                  ...customerInfo,
                  address: {
                    firstLine: formattedAddress || customerInfo.address?.firstLine || '',
                    latitude,
                    longitude,
                  } as GQL.AddressNode,
                })
              }}
              inputProps={{
                width: '100%',
                height: 'auto',
                padding: '5px 10px',
                borderColor: '#ffffff1a',
                error: !isValidAddress(customerInfo.address),
              }}
            />
          ),
          customerAddress: customer.address.firstLine,
          inputPhoneNumberWrapper: {
            props: {
              divPhoneNumber: (
                <PhoneInput
                  defaultValue={customerInfo.phoneNumber || ''}
                  height='48px'
                  name='phoneNumber'
                  error={false} // optional
                  onPhoneNumberChange={(...all) => {
                    setCustomerInfo({ ...customerInfo, phoneNumber: all[3].replace(/\s|-/g, '') })
                    if (!all[0]) {
                      setFormErrors({ ...formErrors, isPhoneNumberInvalid: true })
                    } else {
                      setFormErrors({ ...formErrors, isPhoneNumberInvalid: false })
                    }
                  }}
                  onSelectFlag={(...all) => {
                    setCustomerInfo({ ...customerInfo, phoneNumber: all[2].replace(/\s|-/g, '') })
                    if (!all[3]) {
                      setFormErrors({ ...formErrors, isPhoneNumberInvalid: true })
                    } else {
                      setFormErrors({ ...formErrors, isPhoneNumberInvalid: false })
                    }
                  }}
                />
              ),
              hideFieldStatus: !customerInfo.phoneNumber,
              alert: !customerInfo.phoneNumber || (customerInfo.phoneNumber && formErrors.isPhoneNumberInvalid),
              tooltip: <TutorialInfoIcon content={t({ id: 'tooltips.settings.user-form.phone-number' })} />,
            },
          },
          customerPhone: customer.phoneNumber || '',
          selectDepotDiv: (
            <ReactSelect
              placeholder={t({ id: 'common.select-depot' })}
              components={{ Option, Control, MenuList }}
              styles={reactSelectStyles}
              name='depot'
              isMulti={false}
              onChange={event => setCustomerInfo({ ...customerInfo, depot: event.value })}
              value={
                {
                  value: customerInfo.depot?.id,
                  label: customerInfo.depot?.name || t({ id: 'common.select-depot' }),
                } as SelectOption<string>
              }
              options={depotData.allDepots?.edges.map(depot => ({
                value: depot?.node,
                label: depot?.node?.name,
              }))}
            />
          ),
          customerDepot: customer.depot?.name,
          selectTypeDiv: (
            <ReactSelect
              placeholder={t({ id: 'common.select-customer-type' })}
              components={{ Option, Control, MenuList }}
              styles={reactSelectStyles}
              name='customerDomainType'
              isMulti={false}
              onChange={event => setCustomerInfo({ ...customerInfo, customerDomainType: event.value })}
              value={
                {
                  value: customerInfo.customerDomainType,
                  label: customerInfo.customerDomainType?.capitalizeFirst() || t({ id: 'common.select-customer-type' }),
                } as SelectOption<string>
              }
              options={Object.values(GQL.CustomerDomainType).map(customerType => ({
                value: customerType,
                label: customerType.capitalizeFirst(),
              }))}
            />
          ),
          customerType: customer.customerDomainType?.capitalizeFirst(),
          selectPriceListDiv: (
            <ReactSelect
              placeholder={t({ id: 'common.select-pricing-list' })}
              components={{ Option, Control, MenuList }}
              styles={reactSelectStyles}
              name='priceCategory'
              isMulti={false}
              onChange={event => setCustomerInfo({ ...customerInfo, priceCategory: event.value })}
              value={
                {
                  value: customerInfo.priceCategory?.id,
                  label: customerInfo.priceCategory?.name || t({ id: 'common.default' }),
                } as SelectOption<string>
              }
              options={appContext.distributor?.priceCategories?.map(pricingCategory => ({
                value: pricingCategory,
                label: pricingCategory?.name,
              }))}
            />
          ),
          pricingList: customer.priceCategory?.name || t({ id: 'common.default' }),
          photoThumbnails: (
            <>
              {images && images.length > 0 ? (
                <PhotoThumbnail
                  photo={images[0].image}
                  count={images.length}
                  onClick={() => {
                    setActiveImageIndex(0)
                    setIsLightboxOpen(true)
                  }}
                />
              ) : imagesLoading ? (
                <Loader size={32} color='gray8' />
              ) : (
                <LabelStatusChip icon={'info'} title={t({ id: 'common.no-photos' })} />
              )}
            </>
          ),
          photoCount: images.length.toString(),
          openLightbox: {
            onClick: () => {
              if (images.length > 0) {
                setIsLightboxOpen(true)
              }
            },
          },
          mapBox: (
            <div
              onClick={() =>
                window.open(`https://www.google.com/maps/search/?api=1&query=${customerInfo.address.latitude},${customerInfo.address.longitude}`, '_blank')
              }
              style={{
                cursor: 'pointer',
                width: '100%',
                height: '100%',
                maxWidth: '950px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                fontSize: '1.5rem',
                color: 'rgb(255, 143, 143)',
                backgroundImage: `url('https://maps.googleapis.com/maps/api/staticmap?size=800x300&zoom=15&scale=2&map_id=7c94979620d924a7&key=AIzaSyBtD0cwYlaVLkqfRT1MPc22j0j0vv51wag&markers=size:normal%7Ccolor:red%7C${customerInfo.address.latitude},${customerInfo.address.longitude}')`,
                backgroundSize: '100% auto',
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',
              }}
            >
              {!customer.address && 'This place has improper address selected!'}
            </div>
          ),
        }}
        tabSelectorOrderMethod={{
          selected: customer.orderMethod.toString().toLowerCase(),
          btnAutomatic: {
            onClick: () =>
              patchCustomer({
                variables: {
                  id: customer.id,
                  input: { orderMethod: GQL.CustomerOrderMethod.Auto },
                },
              }),
          },
          btnApp: {
            onClick: () =>
              patchCustomer({
                variables: {
                  id: customer.id,
                  input: { orderMethod: GQL.CustomerOrderMethod.App },
                },
              }),
          },
          btnPhone: {
            onClick: () =>
              patchCustomer({
                variables: {
                  id: customer.id,
                  input: { orderMethod: GQL.CustomerOrderMethod.Phone },
                },
              }),
          },
        }}
        selectAutomaticTrigger={{
          ...(patchCylinderGroupLoading && { icon: <Loader color={'yellow'} /> }),
          placeholder: loadingTriggers ? (
            <CenteredLoader>
              loading <Loader color={'white'} />
            </CenteredLoader>
          ) : (
            'None / Select Trigger'
          ),
          value: cylinderGroup.threshold?.id ? cylinderGroup.threshold?.id : undefined,
          onChange: (e: any) => {
            if (e === 'add') {
              setCreateTriggerOpen(true)
            } else {
              patchCylinderGroup({ variables: { id: cylinderGroup.id, input: { thresholdId: e } } })
            }
          },
          children: (
            <>
              {allTriggers.map(trigger => (
                <Select__Option key={trigger.id} value={trigger.id}>
                  {trigger.name}
                </Select__Option>
              ))}
              <StyledSelectOption
                onClick={(e: any) => {
                  e.preventDefault()
                  console.log('hello')
                }}
                value={'add'}
              >
                <div style={{ color: '#008cfff8' }}>+ add trigger</div>
              </StyledSelectOption>
            </>
          ),
        }}
        btnAddProduct={{
          props: {
            name: 'addProductInventory',
            onClick: () => setAddInventoryModalOpen(true),
          },
        }}
        atCustomerLocation={{
          accordionContent:
            loadingInventories && inventories.length < 1 ? (
              <CenteredLoader>
                <Loader color={'white'} />
              </CenteredLoader>
            ) : inventories.length < 1 ? (
              <LabelStatusChip icon='info' title='No products' style={{ margin: 'auto' }} />
            ) : (
              inventories.map(inventory =>
                inventory.product ? (
                  <ProductCountRow key={inventory.id} inventory={inventory} quantity={inventory.totalAmountOfCylinders} setEditStockInfo={setEditStockInfo} />
                ) : null
              )
            ),
        }}
        openingHoursComponent={
          <OpeningHours
            customer={customer}
            alwaysOpen={alwaysOpen}
            setAlwaysOpen={value => setAlwaysOpen(value)}
            openingHours={openingHours}
            setOpeningHours={value => setOpeningHours(value)}
            onChange={value => setChanged(value)}
          />
        }
      />
      {addInventoryModalOpen && (
        <AddCylinderGroupInventoryModal
          isOpen={addInventoryModalOpen}
          onClose={() => setAddInventoryModalOpen(false)}
          cylinderGroup={cylinderGroup}
          products={products}
          loadingProducts={loadingProducts}
        />
      )}
      {!!editStockInfo && (
        <AddCylinderGroupInventoryModal
          isOpen={!!editStockInfo}
          onClose={() => setEditStockInfo(undefined)}
          cylinderGroup={cylinderGroup}
          products={products}
          inventory={editStockInfo.inventory}
          loadingProducts={loadingProducts}
        />
      )}
    </>
  )
}

export default CustomerInfo
