import React from 'react'
import ModalVerifyBulkDelivery from 'plasmic/ModalVerifyBulkDelivery'
import { VerifyBulkTankDelivery } from '../types'
import Modal from 'components/Modal/Modal'

interface Props {
  isOpen: boolean
  onClose: () => void
  verifyBulkTankInfo: VerifyBulkTankDelivery | undefined
}

const VerifyBulkTankDeliveryModal: React.FC<Props> = props => {
  return (
    <Modal isOpen={props.isOpen} onRequestClose={props.onClose} overlayStyle={{ overflow: 'auto', alignItems: 'flex-start', padding: '2rem' }} stripped>
      <ModalVerifyBulkDelivery onClose={props.onClose} verifyBulkTankInfo={props.verifyBulkTankInfo} />
    </Modal>
  )
}

export default VerifyBulkTankDeliveryModal
