import React, { useMemo, useRef, useState } from 'react'
import styled from 'styled-components'
import { isSameDay, parseISO } from 'date-fns'

import * as GQL from 'generated/graphql'
import { Route } from '../tables/PlannedRoutes'
import Modal from 'components/Modal/Modal'
import CornerLoader from 'components/Loader/CornerLoader'
import format from 'date-fns/format'
import RouteRow from 'plasmic/RouteRow'
import { default as PlasmicDispatch } from 'plasmic/Dispatch'
import Text from 'components/Text'
import { displayToast } from 'util/toasts'
import CellPrint from 'plasmic/CellPrint'
import { Link } from 'react-router-dom'
import { routeReadyForDispatch } from '../util'
import RouteDispatch from '../components/RouteDispatch'
import { useAppContext, useClickOutside } from 'util/hooks'
import ActionDropdownLine from 'plasmic/ActionDropdownLine'
import ConfirmModal from 'components/Modal/ConfirmModal'
import Loader from 'components/Loader'
import ScrollIndicator from '../../../plasmic/ScrollIndicator'
import CacheConfigs from 'util/cacheConfig'
import { VerifyExchangeModal, VerifyExchangeStock } from 'modules/inventory'
import VerifyBulkTankDeliveryModal from 'modules/orders/components/VerifyBulkTankDeliveryModal'
import { VerifyBulkTankDelivery } from 'modules/orders/types'

const Wrapper = styled.div`
  max-width: 100%;
  padding: 1rem 4rem;
  min-width: 1190px;
  @media (max-width: 1280px) {
    padding: 0.5rem;
    padding-right: 1.5rem;
  }

  input[type='date']::-webkit-calendar-picker-indicator {
    /* background: transparent; */
    filter: invert(1);
    opacity: 0.7;
    bottom: 0;
    color: transparent;
    cursor: pointer;
    height: auto;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    padding: 8px;
    padding-left: 140px;

    width: 100%;
    display: flex;
  }
`

const ProgressContainer = styled.div<{ progress: number }>`
  border: 1px solid rgb(16, 126, 116);
  border-radius: 4px;
  display: flex;
  width: 100px;
  justify-content: flex-start;
  height: 30px;
  position: relative;

  &:before {
    content: '';
    width: ${props => props.progress}%;
    background: rgb(16, 126, 116);
    display: block;
    font-style: normal;
    padding: 0.5em;
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
    border-top-right-radius: ${props => (props.progress > 99 ? '3px' : '0px')};
    border-bottom-right-radius: ${props => (props.progress > 99 ? '3px' : '0px')};
    box-sizing: border-box;
    overflow: visible;
    white-space: nowrap;
    /* transform: scale(1.06) */
  }

  span {
    position: absolute;
    width: 100px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
  }
`

interface DispatchProps {}

const ROUTES_PER_PAGE = 50
const DRIVERS_PAGE_SIZE = 15

const Dispatch: React.FC<DispatchProps> = () => {
  const [moreOpen, setMoreOpen] = useState<null | string>(null)
  const [confirmOpen, setConfirmOpen] = useState(false)
  const [confirmData, setConfirmData] = useState<{ onConfirm: any; title: string; text: string } | null>(null)
  const [moreLoading, setMoreLoading] = useState(false)
  const [date, setDate] = useState({ dateFrom: '', dateTo: '' })
  const [verifyExchangeInfo, setVerifyExchangeInfo] = useState<VerifyExchangeStock>()
  const [verifyBulkTankInfo, setVerifyBulkTankInfo] = useState<VerifyBulkTankDelivery>()
  const { appContext } = useAppContext()

  const [route, setRoute] = useState<null | GQL.RouteInfo>(null)

  const moreRef = useRef(null)
  useClickOutside(moreRef, () => setMoreOpen(null), true, true)

  const { data: dataRoutes, loading: routesLoading } = GQL.useAllUpcomingRoutes({
    ...CacheConfigs.ACCURATE_ONCE,
    variables: {
      dateGte: format(new Date(), 'yyyy-MM-dd'),
      statusNotIn: [GQL.RouteStatus.Completed.toLowerCase(), GQL.RouteStatus.Cancelled.toLowerCase()],
      first: ROUTES_PER_PAGE,
      orderBy: '-date',
    },
  })
  const {
    data: dataPastRoutes,
    loading: dataPastRoutesLoading,
    fetchMore,
  } = GQL.useAllCompletedRoutes({
    ...CacheConfigs.ACCURATE_ONCE,
    variables: {
      statusIn: [GQL.RouteStatus.Completed.toLowerCase()],
      first: 10,
      offset: 0,
      dateGte: date.dateFrom ? date.dateFrom : null,
      dateLte: date.dateTo ? date.dateTo : null,
      orderBy: '-date',
    },
  })
  const { data: dataDrivers, loading: driversLoading } = GQL.useAllDrivers({ variables: { first: DRIVERS_PAGE_SIZE } })
  const [patchRoute, { loading: patchLoading }] = GQL.usePatchRoute({
    onError: ({ message }) => {
      displayToast(message, 'error')
    },
  })

  const [deleteRoute] = GQL.useDeleteRoute({
    update(cache, { data }) {
      cache.modify({
        fields: {
          allRoutes(connection, { readField }) {
            return {
              ...connection,
              edges: connection?.edges?.filter((edge: GQL.RouteNodeEdge) => readField('id', edge?.node!) !== data?.deleteRoute?.deletedId) || [], //data?.planRoutes?.routes?.map(r => ({ node: r!, __typename: 'RouteNodeEdge' }))
            }
          },
          allScheduledRoutes(connection, { readField }) {
            return {
              ...connection,
              edges: connection?.edges?.filter((edge: GQL.RouteNodeEdge) => readField('id', edge?.node!) !== data?.deleteRoute?.deletedId) || [], //data?.planRoutes?.routes?.map(r => ({ node: r!, __typename: 'RouteNodeEdge' }))
            }
          },
          allCylinderGroupOrders(_, { DELETE }) {
            return DELETE
          },
          allUnassignedRoutes(_, { DELETE }) {
            return DELETE
          },
        },
      })
    },
    onError: () => {
      displayToast('There was an error deleting the route!')
      setConfirmOpen(false)
      setConfirmData(null)
    },
    onCompleted: () => {
      setConfirmOpen(false)
      setConfirmData(null)
    },
  })

  const [exchangeStock, { loading: exchangeStockLoading }] = GQL.useExchangeStock({
    refetchQueries: ['AllProductInventories', 'AllInventoryAdjustments', 'CylinderGroupOrders'],
    onError: () => {
      displayToast('Could not verify the stock transfer')
    },
  })

  const [completeRouteAndStops] = GQL.useCompleteRouteAndStops({
    onError: ({ message }) => {
      displayToast(message)
    },
    refetchQueries: ['AllRoutes'],
    onCompleted: () => {
      displayToast('Route marked as completed!', 'success')
      setConfirmOpen(false)
      setConfirmData(null)
    },
  })

  const routes = useMemo(() => {
    return dataRoutes?.allUpcomingRoutes?.edges.map(edge => edge?.node as GQL.RouteNode) || []
  }, [dataRoutes])
  const pastRoutes = useMemo(() => {
    return dataPastRoutes?.allCompletedRoutes?.edges.map(edge => edge?.node as GQL.RouteNode) || []
  }, [dataPastRoutes])
  const drivers = useMemo(() => {
    return dataDrivers?.allDrivers?.edges.map(edge => edge?.node as GQL.DriverNode) || []
  }, [dataDrivers])

  const showRoute = (route: GQL.RouteInfo) => {
    setRoute(route)
  }

  const hideRouteModal = () => {
    setRoute(null)
  }

  const getExchangeInput = (
    fromInventory: Partial<GQL.InputAddInventory>,
    toInventory: Partial<GQL.InputAddInventory>,
    stop: GQL.StopNode,
    orderProduct: GQL.Maybe<GQL.CylinderGroupOrderProductNode>
  ) => {
    return {
      fromInventory: { ...fromInventory, productId: orderProduct?.product?.id || '' },
      toInventory: { ...toInventory, productId: orderProduct?.product?.id || '' },
      received: orderProduct?.quantity || 0,
      returned: orderProduct?.quantity || 0,
      fromState: GQL.InventoryStockState.Empty,
      toState: GQL.InventoryStockState.NotEmpty,
      transformState: true,
      reason: GQL.InventoryAdjustmentReason.Sale,
      orderProductId: orderProduct?.id || '',
      stopId: stop.id,
    } as GQL.InputExchangeStock
  }

  const getOrderExchanges = (route: GQL.RouteInfo, stop: GQL.StopNode) => {
    const fromInventory = { depotId: route?.depot?.id || '' }
    const toInventory = { cylinderGroupId: stop.order?.cylinderGroup?.id || '' }
    return stop.order?.products?.map(orderProduct => getExchangeInput(fromInventory, toInventory, stop, orderProduct)) || []
  }

  const handleMarkAsCompleted = (route: GQL.RouteInfo) => {
    if (exchangeStockLoading) return
    const stops = (route.stops?.filter(stop => !!stop?.order && stop?.order.products?.some(orderProduct => orderProduct?.product?.isGasType)) || []) as
      | GQL.StopNode[]
      | undefined
    if (!stops || appContext.distributor?.inventoryAccess === GQL.InventoryAccess.NoAccess) return completeRouteAndStops({ variables: { id: route.id } })

    exchangeStock({
      variables: { exchanges: stops?.map(stop => getOrderExchanges(route, stop)).flat() },
      onCompleted: data => {
        if (!data.exchangeStock?.ok) return displayToast('Could not verify the stock transfer')
        completeRouteAndStops({ variables: { id: route.id } })
      },
    })
  }

  const getCompletedRouteDateFormat = (dateRoute: string, dateCompleted: string) => {
    if (!isSameDay(new Date(dateRoute), new Date(dateCompleted))) return 'HH:mm, dd. MMM yyyy'
    return 'HH:mm'
  }

  return (
    <>
      <Wrapper>
        {(driversLoading || routesLoading || patchLoading || dataPastRoutesLoading) && <CornerLoader position='fixed' topAdjust='10' size={50} />}
        <ConfirmModal
          isOpen={confirmOpen}
          onRequestClose={() => {
            setConfirmOpen(false)
            setConfirmData(null)
          }}
          onConfirm={() => {
            confirmData?.onConfirm()
          }}
          onAbort={() => {
            setConfirmOpen(false)
            setConfirmData(null)
          }}
          title={confirmData?.title || ''}
          description={confirmData?.text || ''}
        />
        <PlasmicDispatch
          routes={
            <>
              {routes.map(route => {
                const routeReady = routeReadyForDispatch(route)
                return (
                  <RouteRow
                    key={route.id + 'dispatch'}
                    data-testid='upcoming-route'
                    btnMoreActions={{
                      ref: moreOpen === route.id ? moreRef : null,
                      btnMoreActions: {
                        onClick: () => setMoreOpen(oldState => (oldState === route.id ? null : route.id)),
                      },
                      open: moreOpen === route.id,
                      actionDropdown: (
                        <>
                          <ActionDropdownLine
                            onClick={() => {
                              setMoreOpen(null)
                              setConfirmData({
                                onConfirm: () => deleteRoute({ variables: { id: route.id } }),
                                title: 'Delete route',
                                text: 'Are you sure you want to delete this route?',
                              })
                              setConfirmOpen(true)
                            }}
                          >
                            Delete route
                          </ActionDropdownLine>
                          {route.status !== GQL.RouteStatus.Completed && route.status !== GQL.RouteStatus.Cancelled && (
                            <ActionDropdownLine
                              onClick={() => {
                                setMoreOpen(null)
                                setConfirmData({
                                  onConfirm: () => handleMarkAsCompleted(route),
                                  title: 'Mark route as completed',
                                  text: 'Are you sure you want mark this route as completed? All orders included in this route will change status to delivered.',
                                })
                                setConfirmOpen(true)
                              }}
                            >
                              Mark as completed
                            </ActionDropdownLine>
                          )}
                        </>
                      ),
                    }}
                    date={format(parseISO(route.date), 'dd. MMM')}
                    driver={
                      <div onClick={() => showRoute(route)} style={{ cursor: 'pointer' }}>
                        {route?.driver?.user.fullName}
                      </div>
                    }
                    depot={route.depot?.name || '-'}
                    length={
                      route.totalDrivingDistance! > 0 ? (
                        <Text fontSize='16px'>
                          <Text color='white'>{Math.round(route.totalDrivingDistance! / 1000)} </Text>
                        </Text>
                      ) : (
                        '-'
                      )
                    }
                    cellTime={
                      <ProgressContainer progress={route.driverTimePercentageEffort || 0}>
                        <span>{route.driverTimePercentageEffort || 0} %</span>
                      </ProgressContainer>
                    }
                    cellCapacity={
                      <ProgressContainer progress={route.percentageLoad || 0}>
                        <span>{route.percentageLoad || 0} %</span>
                      </ProgressContainer>
                    }
                    cellLocked={{
                      onClick: () =>
                        patchRoute({
                          variables: { id: route.id, input: { locked: !route.locked } },
                        }),
                    }}
                    locked={route.locked}
                    cellPrint={
                      <Link to={`/route/pdf/${route.id}`} target='_blank' style={{ width: '100%', height: '100%' }}>
                        <CellPrint style={{ width: '100%', height: '100%' }} />
                      </Link>
                    }
                    cellDispatch={<RouteDispatch route={route} routeIsReady={routeReady!} />}
                    state='completed'
                  />
                )
              })}
              {!routesLoading && <ScrollIndicator fullyLoaded={true} tableRow={true} loaded={routes.length.toString()} total={routes.length.toString()} />}
            </>
          }
          dateFrom={{
            undefinedInput: {
              max: date.dateTo !== '' ? date.dateTo : new Date().toISOString().split('T')[0],
              onFocus: (e: any) => {
                e.currentTarget.type = 'date'
              },
              onBlur: (e: any) => {
                if (date.dateFrom === '') e.currentTarget.type = 'text'
              },
              placeholder: 'select date',
              value: date.dateFrom,
              onChange: (e: any) => setDate({ ...date, dateFrom: e.target.value }),
            },
          }}
          dateTo={{
            undefinedInput: {
              max: new Date().toISOString().split('T')[0],
              min: date.dateFrom !== '' ? date.dateFrom : undefined,
              onFocus: (e: any) => {
                e.currentTarget.type = 'date'
              },
              onBlur: (e: any) => {
                setDate({ ...date, dateTo: e.target.value })
                if (date.dateTo === '') e.currentTarget.type = 'text'
              },
              placeholder: 'select date',
              value: date.dateTo,
              onChange: (e: any) => setDate({ ...date, dateTo: e.target.value }),
            },
          }}
          pastRoutes={
            <>
              {pastRoutes.map((route, i) => {
                return (
                  <RouteRow
                    past
                    data-testid='past-route'
                    key={route.id + 'dispatchPastRoutes' + i}
                    date={route.date ? format(parseISO(route.date), 'dd. MMM yyyy') : 'unknown'}
                    completed={
                      route.driverEndedRouteAt
                        ? format(parseISO(route.driverEndedRouteAt), getCompletedRouteDateFormat(route.date, route.driverEndedRouteAt))
                        : 'unknown'
                    }
                    driver={
                      <div onClick={() => showRoute(route)} style={{ cursor: 'pointer' }}>
                        {route?.driver?.user.fullName}
                      </div>
                    }
                    depot={route.depot?.name || '-'}
                    length={
                      (route.totalDrivingDistance || 0) > 0 ? (
                        <Text fontSize='16px'>
                          <Text color='white'>{Math.round(route.totalDrivingDistance! / 1000)} </Text>
                        </Text>
                      ) : (
                        '-'
                      )
                    }
                    cellLocked={{
                      onClick: () =>
                        patchRoute({
                          variables: { id: route.id, input: { locked: !route.locked } },
                        }),
                    }}
                    locked={route.locked}
                    cellPrint={
                      <Link to={`/route/pdf/${route.id}`} target='_blank' style={{ width: '100%', height: '100%' }}>
                        <CellPrint style={{ width: '100%', height: '100%' }} />
                      </Link>
                    }
                    state='completed'
                  />
                )
              })}
              {!dataPastRoutesLoading && (pastRoutes.length === 0 || !dataPastRoutes?.allCompletedRoutes?.pageInfo.hasNextPage) && (
                <ScrollIndicator fullyLoaded tableRow loaded={pastRoutes.length} total={pastRoutes.length} />
              )}
            </>
          }
          btnLoadMore={{
            notVisible: !dataPastRoutes?.allCompletedRoutes?.pageInfo?.hasNextPage,
            btnLoadMore: {
              ...(moreLoading && { children: <Loader color='white' /> }),
              onClick: async () => {
                if (moreLoading) return
                setMoreLoading(true)
                await fetchMore({
                  variables: { offset: pastRoutes.length },
                  updateQuery: (preiovus, { fetchMoreResult }) => {
                    return {
                      ...fetchMoreResult,
                      allCompletedRoutes: {
                        ...(fetchMoreResult.allCompletedRoutes as GQL.RouteNodeConnection),
                        edges: [...(preiovus?.allCompletedRoutes?.edges || []), ...(fetchMoreResult?.allCompletedRoutes?.edges || [])],
                      },
                    }
                  },
                }).then(() => {
                  setMoreLoading(false)
                })
              },
            },
          }}
        />
      </Wrapper>
      {!!verifyBulkTankInfo && (
        <VerifyBulkTankDeliveryModal isOpen={!!verifyBulkTankInfo} onClose={() => setVerifyBulkTankInfo(undefined)} verifyBulkTankInfo={verifyBulkTankInfo} />
      )}
      {!!verifyExchangeInfo && (
        <VerifyExchangeModal isOpen={!!verifyExchangeInfo} onClose={() => setVerifyExchangeInfo(undefined)} verifyExchangeInfo={verifyExchangeInfo} />
      )}
      {!!route && (
        <Modal
          onRequestClose={hideRouteModal}
          isOpen={true}
          stripped
          contentStyle={{ width: '95%', maxHeight: '90%', overflowY: 'auto' }}
          overlayStyle={{ zIndex: '999' }}
        >
          {/* using `routes.find` instead of `route` directly because `route` isn't getting updated from cache. */}
          <Route drivers={drivers} routes={routes} route={routes.find(r => r.id === route?.id) as GQL.RouteNode} />
        </Modal>
      )}
    </>
  )
}

export default Dispatch
