import React from 'react'

import Modal from 'components/Modal/Modal'
import ModalBulkEditInfo from 'plasmic/ModalBulkEditInfo'

interface Props {
  isOpen: boolean
  onClose: () => void
  customerIds: string[]
  setCustomerIds: React.Dispatch<React.SetStateAction<Set<string>>>
}

const BulkEditModal: React.FC<Props> = props => {
  return (
    <Modal
      isOpen={props.isOpen}
      onRequestClose={() => props.onClose()}
      overlayStyle={{ alignItems: 'flex-start', overflow: 'auto', padding: '2rem 0rem' }}
      stripped
    >
      <ModalBulkEditInfo customerIds={props.customerIds} setCustomerIds={props.setCustomerIds} onClose={props.onClose} />
    </Modal>
  )
}

export default BulkEditModal
