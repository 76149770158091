// This is a skeleton starter React component generated by Plasmic.
// This file is owned by you, feel free to edit as you see fit.
import * as React from 'react'
import { PlasmicInventoryHistory, DefaultInventoryHistoryProps } from './plasmic/solace_components/PlasmicInventoryHistory'
import { HTMLElementRefOf } from '@plasmicapp/react-web'
import styled from 'styled-components'
import Select from 'react-select'

import * as GQL from 'generated/graphql'
import InventoryHistoryRow from './InventoryHistoryRow'
import { Control, Option, reactSelectStylesImgSelect } from './StyledReactSelect'
import { CenteredLoader } from 'components/Loader/LoaderWrappers'
import Loader from 'components/Loader'
import { NoHits } from 'modules/orders/util'
import LabelStatusChip from './LabelStatusChip'
import { SelectOption } from 'types/props'

// Your component props start with props for variants and slots you defined
// in Plasmic, but you can add more here, like event handlers that you can
// attach to named nodes in your component.
//
// If you don't want to expose certain variants or slots as a prop, you can use
// Omit to hide them:
//
// interface InventoryHistoryProps extends Omit<DefaultInventoryHistoryProps, "hideProps1"|"hideProp2"> {
//   // etc.
// }
//
// You can also stop extending from DefaultInventoryHistoryProps altogether and have
// total control over the props for your component.
export interface InventoryHistoryProps extends DefaultInventoryHistoryProps {
  adjustments: GQL.InventoryAdjustmentNode[]
  adjustmentsTotalCount: number
  loadMoreAdjustments: () => void
  setSearchProduct: (search: string) => void
  selectedProductId: string | null
  setSelectedProductId: (productId: string | null) => void
  selectedInventoryId: string | null
  setSelectedInventoryId: (inventoryId: string | null) => void
  loadingProducts: boolean
  loadingInventories: boolean
  loadingAdjustments: boolean
  products: GQL.ProductNode[]
  inventories: GQL.InventoryNode[]
  scrollIndicator?: any
}

const LoaderWrapper = styled(CenteredLoader)`
  margin: 0px;
`

function InventoryHistory_(props: InventoryHistoryProps, ref: HTMLElementRefOf<'div'>) {
  // Use PlasmicInventoryHistory to render this component as it was
  // designed in Plasmic, by activating the appropriate variants,
  // attaching the appropriate event handlers, etc.  You
  // can also install whatever React hooks you need here to manage state or
  // fetch data.
  //
  // Props you can pass into PlasmicInventoryHistory are:
  // 1. Variants you want to activate,
  // 2. Contents for slots you want to fill,
  // 3. Overrides for any named node in the component to attach behavior and data,
  // 4. Props to set on the root node.
  //
  // By default, we are just piping all InventoryHistoryProps here, but feel free
  // to do whatever works for you.

  const [selectedProduct, setSelectedProduct] = React.useState<GQL.ProductNode | null>(null)
  const [selectedInventory, setSelectedInventory] = React.useState<GQL.InventoryNode | null>(null)

  React.useEffect(() => {
    if (props.products.length === 0 || selectedProduct?.id === props.selectedProductId) return
    if (!props.selectedProductId) {
      return props.setSelectedProductId(props.products[0].id)
    }
    setSelectedProduct(props.products.find(product => product.id === props.selectedProductId) || null)
  }, [props.products, selectedProduct, props.selectedProductId])

  React.useEffect(() => {
    if (props.inventories.length === 0 || selectedInventory?.id === props.selectedInventoryId) return
    if (!props.selectedInventoryId) {
      return props.setSelectedInventoryId(props.inventories[0].id)
    }
    setSelectedInventory(props.inventories.find(inventory => inventory.id === props.selectedInventoryId) || null)
  }, [props.inventories, selectedInventory, props.selectedInventoryId])

  React.useEffect(() => {
    setSelectedInventory(null)
    props.setSelectedInventoryId(null)
  }, [selectedProduct])

  return (
    <PlasmicInventoryHistory
      root={{ ref }}
      inputProductDiv={
        <Select
          // @ts-ignore
          showImage={!!selectedProduct?.image?.image}
          // @ts-ignore
          imagePath={selectedProduct?.image?.image}
          placeholder={
            props.loadingProducts ? (
              <LoaderWrapper>
                <Loader color='white' />
              </LoaderWrapper>
            ) : (
              'Select a product...'
            )
          }
          components={{ Option, Control }}
          styles={reactSelectStylesImgSelect}
          options={props.products.map(product => ({ value: product.id, label: product.displayName }) as SelectOption<string>)}
          onChange={(event: any) => props.setSelectedProductId(event.value)}
          onInputChange={event => props.setSearchProduct(event)}
          value={{ value: selectedProduct?.id || '', label: selectedProduct?.displayName || 'Select a product...' } as SelectOption<string>}
          isSearchable
          isMulti={false}
        />
      }
      inputDepotDiv={
        <Select
          placeholder={
            props.loadingInventories && !props.inventories ? (
              <LoaderWrapper>
                <Loader color='white' />
              </LoaderWrapper>
            ) : (
              'Select a depot...'
            )
          }
          options={props.inventories.map(
            inventory =>
              ({
                value: inventory.id,
                label: inventory.belongsTo?.__typename === 'CylinderGroupNode' ? inventory.belongsTo.customer?.name : inventory.belongsTo?.name,
              }) as SelectOption<string>
          )}
          value={
            {
              value: selectedInventory?.id || '',
              label:
                selectedInventory?.belongsTo?.__typename === 'CylinderGroupNode'
                  ? selectedInventory.belongsTo.customer.name
                  : selectedInventory?.belongsTo?.name || 'Select a depot...',
            } as SelectOption<string>
          }
          onChange={(event: any) => props.setSelectedInventoryId(event.value)}
          components={{ Option, Control }}
          styles={reactSelectStylesImgSelect}
          isSearchable={false}
          isMulti={false}
        />
      }
      rows={
        props.adjustments.length === 0 && props.loadingAdjustments ? (
          <CenteredLoader>
            <Loader color='white' />
          </CenteredLoader>
        ) : props.adjustments.length > 0 ? (
          props.adjustments.map(adjustment => <InventoryHistoryRow key={adjustment?.time} adjustmentNode={adjustment} selectedInventory={selectedInventory} />)
        ) : (
          <NoHits>
            <LabelStatusChip icon='check' title='No inventory adjustments' />
          </NoHits>
        )
      }
      scrollIndicator={
        props.adjustments.length > 0
          ? {
              props: {
                total: props.adjustmentsTotalCount,
                loaded: props.adjustments.length,
                btnLoadMore: {
                  ...(props.loadingAdjustments && { children: <Loader color='white' /> }),
                  onClick: props.loadMoreAdjustments,
                },
              },
            }
          : { render: () => null }
      }
    />
  )
}

const InventoryHistory = React.forwardRef(InventoryHistory_)
export default InventoryHistory
