import { useIntl } from 'react-intl'
import * as GQL from 'generated/graphql'
import UserSubscriptionsToggles from '../components/AlertTogglesStandard'
import UserThresholdSubscriptionsToggles from '../components/AlertTogglesThreshold'
import { DefaultAlertsAccountProps } from 'plasmic/plasmic/solace_components/PlasmicAlertsAccount'
import SettingsContentWrapper from 'plasmic/SettingsContentWrapper'
import AlertsAccount from 'plasmic/AlertsAccount'

export default function SettingsAlerts() {
  const intl = useIntl()
  const t = intl.formatMessage

  const { data: verbData } = GQL.useAllNotificationVerbs({ variables: { subscribableForDistributors: true, first: 100 } })
  const verbs =
    verbData?.allNotificationVerbs?.edges.map(edge => edge?.node).filter((node): node is GQL.NotificationVerbNode => node !== null && node !== undefined) ?? []

  const toggleVerbs = verbs.filter(verb => verb.slug !== 'has-reached')
  const thresholdVerbs = verbs.filter(verb => verb.slug === 'has-reached')

  const { data: channelData } = GQL.useAllChannels()
  const channels = channelData?.allChannels?.edges.map(edge => edge?.node).filter((node): node is GQL.ChannelNode => node !== null && node !== undefined) ?? []

  const { data: subscriptionData } = GQL.useAllPersonalSubscriptions({ variables: { first: 100 } })
  const subscriptions =
    subscriptionData?.allPersonalSubscriptions?.edges
      .map(edge => edge?.node)
      .filter((node): node is GQL.NotificationSubscriptionNode => node !== null && node !== undefined) ?? []

  const toggleSubscriptions = subscriptions.filter(
    subscription => subscription.notifyVerb && toggleVerbs.some(verb => verb.slug === subscription.notifyVerb?.slug)
  )

  const thresholdSubscriptions = subscriptions.filter(
    subscription => subscription.notifyVerb && thresholdVerbs.some(verb => verb.slug === subscription.notifyVerb?.slug)
  )

  interface AlertsAccountProps extends DefaultAlertsAccountProps {
    toggles?: any
    levelTriggerEmail?: any
  }

  const alertsAccountProps: AlertsAccountProps = {
    toggles: (
      <>
        {verbs.length > 0 && channels.length > 0 ? (
          <UserSubscriptionsToggles subscriptions={toggleSubscriptions} verbs={toggleVerbs} channels={channels} />
        ) : null}
      </>
    ),
    levelTriggerEmail: (
      <>
        {verbs.length > 0 && channels.length > 0 ? (
          <UserThresholdSubscriptionsToggles subscriptions={thresholdSubscriptions} verbs={thresholdVerbs} channels={channels} />
        ) : null}
      </>
    ),
  }

  return (
    <SettingsContentWrapper
      settingsTitle={t({ id: 'settings.alerts' })}
      headerButtons={<></>}
      content={
        <>
          <AlertsAccount {...alertsAccountProps} />
        </>
      }
    />
  )
}
