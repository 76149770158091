import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { isBoolean } from 'lodash'
import { useIntl } from 'react-intl'
import * as GQL from 'generated/graphql'
import SettingsContentWrapper from 'plasmic/SettingsContentWrapper'
import GeofenceComponent from 'plasmic/GeofenceComponent'
import GeofenceRows from '../components/GeofenceRows'
import ModalEditGeofence from '../components/ModalEditGeofence'
import ModalCreateGeofence from '../components/ModalCreateGeofence'
import ModalDeleteGeofence from '../components/ModalDeleteGeofence'
import GeofenceMap from '../components/GeofenceMap'
import { useAppContext } from 'util/hooks'
import { CoordinatePair, PolygonPath } from '../types'
import GeofenceChangesBar from '../components/GeofenceChangesBar'

const DEFAULT_MAP_CENTER = { lat: 59.91405618579823, lng: 10.766778580266498 } as CoordinatePair
const LIGHT_MODE_KEY = 'geofence-map-light-mode'

const SettingsGeofences: React.FC = () => {
  const localStorageLightMode = localStorage.getItem(LIGHT_MODE_KEY)
  const intl = useIntl()
  const t = intl.formatMessage

  const { depot } = useAppContext().appContext
  const { data: dataGeofences, loading: loadingGeofences, error: errorGeofences } = GQL.useAllGeofences()
  const geofences = useMemo(() => dataGeofences?.allGeofences?.edges?.map(edge => edge?.node as GQL.GeoFenceNode) || [], [dataGeofences])
  const [polygons, setPolygons] = useState<{ [key: string]: PolygonPath }>({})
  const [lightMode, setLightMode] = useState<boolean>(
    localStorageLightMode && isBoolean(JSON.parse(localStorageLightMode)) ? JSON.parse(localStorageLightMode) : true
  )
  const [selectedGeofence, setSelectedGeofence] = useState<string | null>(null)

  const [editedGeofence, setEditedGeofence] = useState<GQL.GeoFenceNode | null>(null)
  const [deletedGeofence, setDeletedGeofence] = useState<GQL.GeoFenceNode | null>(null)
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false)
  const isEditModalOpen = useMemo(() => !!editedGeofence, [editedGeofence])
  const isDeleteModalOpen = useMemo(() => !!deletedGeofence, [deletedGeofence])

  const [mapCenter, setMapCenter] = useState<CoordinatePair | null>(null)

  const initialMapCenter = useMemo(() => {
    if (depot && depot.address?.latitude && depot.address?.longitude) {
      return { lat: parseFloat(depot.address.latitude), lng: parseFloat(depot.address.longitude) } as CoordinatePair
    }
    // Add defaults from distributor here once it has an address object associated.
    return DEFAULT_MAP_CENTER
  }, [depot])

  const synchronizePolygons = useCallback(
    (geofences: GQL.GeoFenceNode[]) => {
      setPolygons(
        geofences.reduce(
          (prev, geofence) =>
            Object.assign(prev, {
              [geofence.id]: {
                color: geofence.color,
                locked: geofence.locked,
                path: geofence.polygon.coordinates[0].map((coordinate: number[]) => ({ lng: coordinate[0], lat: coordinate[1] })),
              },
            }),
          {}
        )
      )
    },
    [setPolygons]
  )

  const toggleLightMode = (lightMode: boolean) => {
    setLightMode(lightMode)
    localStorage.setItem(LIGHT_MODE_KEY, JSON.stringify(lightMode))
  }

  useEffect(() => {
    synchronizePolygons(geofences)
  }, [geofences, synchronizePolygons])

  return (
    <React.Fragment>
      <SettingsContentWrapper
        showChangesBar
        settingsTitle={t({ id: 'settings.geofences' })}
        headerButtons={<></>}
        info
        paragraphInfo={t({ id: 'settings.geofences.info' })}
        content={
          <GeofenceComponent
            mapWrapper={{
              map: (
                <GeofenceMap
                  initialCenter={initialMapCenter}
                  setCenter={setMapCenter}
                  paths={polygons}
                  setPaths={setPolygons}
                  lightMode={lightMode}
                  setSelectedGeofence={setSelectedGeofence}
                />
              ),
              lightMode: lightMode,
              toggleLightMode: toggleLightMode,
            }}
            btnNewGeofence={{ props: { onClick: () => setIsCreateModalOpen(true) } }}
            rows={
              <GeofenceRows
                geofences={geofences}
                isLoading={loadingGeofences}
                error={errorGeofences}
                setEditedGeofence={setEditedGeofence}
                setDeletedGeofence={setDeletedGeofence}
                selectedGeofence={selectedGeofence}
                setSelectedGeofence={setSelectedGeofence}
              />
            }
          />
        }
        settingChangesBar={<GeofenceChangesBar geofences={geofences} polygons={polygons} discardChanges={synchronizePolygons} />}
      />
      {isCreateModalOpen && (
        <ModalCreateGeofence isOpen={isCreateModalOpen} onClose={() => setIsCreateModalOpen(false)} mapCenter={mapCenter || initialMapCenter} />
      )}
      {isEditModalOpen && <ModalEditGeofence isOpen={isEditModalOpen} onClose={() => setEditedGeofence(null)} geofence={editedGeofence} />}
      {isDeleteModalOpen && <ModalDeleteGeofence isOpen={isDeleteModalOpen} onClose={() => setDeletedGeofence(null)} geofence={deletedGeofence} />}
    </React.Fragment>
  )
}

export default SettingsGeofences
