// This is a skeleton starter React component generated by Plasmic.
// This file is owned by you, feel free to edit as you see fit.
import * as React from 'react'
import { PlasmicModalBulkEditInfo, DefaultModalBulkEditInfoProps } from './plasmic/solace_components/PlasmicModalBulkEditInfo'
import { HTMLElementRefOf } from '@plasmicapp/react-web'

import * as GQL from 'generated/graphql'
import { displayToast } from 'util/toasts'
import { useIntl } from 'react-intl'
import { omit } from 'lodash'
import { useAppContext } from 'util/hooks'
import { SelectOption } from 'types/props'
// Your component props start with props for variants and slots you defined
// in Plasmic, but you can add more here, like event handlers that you can
// attach to named nodes in your component.
//
// If you don't want to expose certain variants or slots as a prop, you can use
// Omit to hide them:
//
// interface ModalBulkEditInfoProps extends Omit<DefaultModalBulkEditInfoProps, "hideProps1"|"hideProp2"> {
//   // etc.
// }
//
// You can also stop extending from DefaultModalBulkEditInfoProps altogether and have
// total control over the props for your component.
export interface ModalBulkEditInfoProps extends DefaultModalBulkEditInfoProps {
  customerIds: string[]
  setCustomerIds: React.Dispatch<React.SetStateAction<Set<string>>>
  onClose: () => void
}

interface CheckboxState {
  isChecked: boolean
  isIndeterminate: boolean
  isDisabled: boolean
  onChange?: (name: string | number) => void
}

type PaymentCheckboxes = { [key: string]: CheckboxState }

function ModalBulkEditInfo_(props: ModalBulkEditInfoProps, ref: HTMLElementRefOf<'div'>) {
  // Use PlasmicModalBulkEditInfo to render this component as it was
  // designed in Plasmic, by activating the appropriate variants,
  // attaching the appropriate event handlers, etc.  You
  // can also install whatever React hooks you need here to manage state or
  // fetch data.
  //
  // Props you can pass into PlasmicModalBulkEditInfo are:
  // 1. Variants you want to activate,
  // 2. Contents for slots you want to fill,
  // 3. Overrides for any named node in the component to attach behavior and data,
  // 4. Props to set on the root node.
  //
  // By default, we are just piping all ModalBulkEditInfoProps here, but feel free
  // to do whatever works for you.
  const intl = useIntl()
  const t = intl.formatMessage
  const { appContext } = useAppContext()

  const [selectedCustomerType, setSelectedCustomerType] = React.useState<string | null>(null)
  const [selectedPricingList, setSelectedPricingList] = React.useState<string | null>(null)
  const [selectedOrderMethod, setSelectedOrderMethod] = React.useState<string | null>(null)
  const [selectedAutomaticTrigger, setSelectedAutomaticTrigger] = React.useState<string | null>(null)
  const [paymentCheckboxStates, setPaymentCheckboxStates] = React.useState<PaymentCheckboxes>({
    checkStripeAdvance: { isChecked: false, isIndeterminate: true, isDisabled: false },
    checkStripeDelivery: { isChecked: false, isIndeterminate: true, isDisabled: false },
    checkStripeCredit: { isChecked: false, isIndeterminate: true, isDisabled: false },
    checkVismaAdvance: { isChecked: false, isIndeterminate: true, isDisabled: true },
    checkVismaDelivery: { isChecked: false, isIndeterminate: true, isDisabled: true },
    checkVismaCredit: { isChecked: false, isIndeterminate: true, isDisabled: false },
  })

  const { data: dataAutomaticTriggers } = GQL.useAllDistributorThresholds()
  const automaticTriggers = React.useMemo(
    () =>
      dataAutomaticTriggers?.allDistributorRefillThresholds?.edges.map(edge => ({ value: edge?.node?.id, label: edge?.node?.name }) as SelectOption<string>) ||
      [],
    [dataAutomaticTriggers]
  )
  const priceCategories = React.useMemo(
    () => appContext?.distributor?.priceCategories?.map(category => ({ value: category?.id, label: category?.name }) as SelectOption<string>),
    [appContext.distributor]
  )

  const [bulkPatchCustomer, { loading: loadingBulkPatchCustomer }] = GQL.useBulkPatchCustomer({
    refetchQueries: ['AllSensorCustomers', 'AllNoSensorCustomers'], // Possibly temp, although might be tricky to implement caching here.
    onCompleted: data => {
      if (!data.bulkPatchCustomer?.ok || data.bulkPatchCustomer?.error) return displayToast(t({ id: 'customers.bulk-edit.error' }), 'error')
      displayToast(t({ id: 'customers.bulk-edit.success' }), 'success')
      props.setCustomerIds(new Set())
      props.onClose()
    },
    onError: () => {
      displayToast(t({ id: 'customers.bulk-edit.error' }), 'error')
    },
  })

  const handleCheckboxChange = (name: keyof typeof paymentCheckboxStates) => {
    setPaymentCheckboxStates(prevState => ({
      ...prevState,
      [name]: {
        ...prevState[name],
        isChecked: !prevState[name].isChecked,
        isIndeterminate: false,
      },
    }))
  }

  const anyCheckboxChanged = React.useMemo(() => Object.values(paymentCheckboxStates).some(checkbox => !checkbox.isIndeterminate), [paymentCheckboxStates])

  const paymentOptions = Object.keys(paymentCheckboxStates).reduce((acc: PaymentCheckboxes, name: keyof typeof paymentCheckboxStates) => {
    acc[name] = {
      ...paymentCheckboxStates[name],
      onChange: () => handleCheckboxChange(name),
    }
    return acc
  }, {})

  const resetCheckboxes = () => {
    const resetState = Object.keys(paymentCheckboxStates).reduce(
      (acc, key) => ({
        ...acc,
        [key]: { ...paymentCheckboxStates[key], isChecked: false, isIndeterminate: true },
      }),
      {}
    )
    setPaymentCheckboxStates(resetState)
  }

  const createResetButtonProps = (stateValue: string | null, resetFunction: React.Dispatch<React.SetStateAction<string | null>>) => ({
    hidden: !stateValue,
    onClick: () => resetFunction(null),
  })

  const onSubmit = () => {
    if (loadingBulkPatchCustomer) return

    bulkPatchCustomer({
      variables: {
        ids: props.customerIds,
        input: {
          customerDomainType: selectedCustomerType as GQL.CustomerDomainType,
          orderMethod: selectedOrderMethod,
          priceCategory: selectedPricingList,
          automaticTrigger: selectedAutomaticTrigger,
          paymentSettings: {
            stripePaymentAlternatives: [
              paymentCheckboxStates.checkStripeAdvance,
              paymentCheckboxStates.checkStripeDelivery,
              paymentCheckboxStates.checkStripeCredit,
            ].every(checkboxState => checkboxState.isIndeterminate)
              ? null
              : {
                  inAdvance: !paymentCheckboxStates.checkStripeAdvance.isIndeterminate ? paymentCheckboxStates.checkStripeAdvance.isChecked : null,
                  onDelivery: !paymentCheckboxStates.checkStripeDelivery.isIndeterminate ? paymentCheckboxStates.checkStripeDelivery.isChecked : null,
                  credit: !paymentCheckboxStates.checkStripeCredit.isIndeterminate ? paymentCheckboxStates.checkStripeCredit.isChecked : null,
                },
            // Using `.every` instead of checking directly for consistency with stripe
            vismaPaymentAlternatives: [paymentCheckboxStates.checkVismaCredit].every(checkboxState => checkboxState.isIndeterminate)
              ? null
              : {
                  credit: !paymentCheckboxStates.checkVismaCredit.isIndeterminate ? paymentCheckboxStates.checkVismaCredit.isChecked : null,
                },
          },
        },
      },
    })
  }

  return (
    <PlasmicModalBulkEditInfo
      root={{ ref }}
      {...omit(props, 'customerIds', 'setCustomerIds', 'onClose')}
      paymentOptions={paymentOptions}
      selectCustomerType={{
        options: [
          { value: GQL.CustomerDomainType.Business, label: 'Business' },
          { value: GQL.CustomerDomainType.Resident, label: 'Resident' },
        ],
        value: selectedCustomerType,
        onChange: setSelectedCustomerType,
      }}
      selectOrderMethod={{
        options: [
          { value: GQL.CustomerOrderMethod.Auto, label: 'Automatic' },
          { value: GQL.CustomerOrderMethod.App, label: 'App' },
          { value: GQL.CustomerOrderMethod.Phone, label: 'Manual' },
        ],
        value: selectedOrderMethod,
        onChange: setSelectedOrderMethod,
      }}
      selectPricingList={{
        options: priceCategories,
        value: selectedPricingList,
        onChange: setSelectedPricingList,
      }}
      selectAutomaticTrigger={{
        options: automaticTriggers,
        value: selectedAutomaticTrigger,
        onChange: setSelectedAutomaticTrigger,
      }}
      btnResetCustomerType={createResetButtonProps(selectedCustomerType, setSelectedCustomerType)}
      btnResetOrderMethod={createResetButtonProps(selectedOrderMethod, setSelectedOrderMethod)}
      btnResetPricingList={createResetButtonProps(selectedPricingList, setSelectedPricingList)}
      btnResetAutomaticTriggers={createResetButtonProps(selectedAutomaticTrigger, setSelectedAutomaticTrigger)}
      btnResetPaymentOptions={{
        hidden: !anyCheckboxChanged,
        onClick: resetCheckboxes,
      }}
      btnSave={{ onClick: () => onSubmit() }}
      selectedCustomersCount={props.customerIds.length.toString()}
      btnCancel={{ onClick: () => props.onClose() }}
      btnClose={{ onClick: () => props.onClose() }}
    />
  )
}

const ModalBulkEditInfo = React.forwardRef(ModalBulkEditInfo_)
export default ModalBulkEditInfo
