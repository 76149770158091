// This is a skeleton starter React component generated by Plasmic.
// This file is owned by you, feel free to edit as you see fit.
import { useState, forwardRef, useCallback, useRef, useMemo } from 'react'
import { useIntl } from 'react-intl'
import { useNavigate } from 'react-router-dom'

import * as GQL from 'generated/graphql'
import styled from 'styled-components'
import { useAppContext, useClickOutside, useModalWithData } from 'util/hooks'
import ViewImageModal from 'modules/orders/AllOrdersTable/ViewImageModal'
import { format, parseISO } from 'date-fns'
import ConfirmModal from 'components/Modal/ConfirmModal'
import { formatPrice, formatTaxRate, updateCylinderGroupOrderCacheOnDelete, updateCylinderGroupOrderCacheOnPatch } from 'modules/orders/util'
import OrderProductRowExtended from './OrderProductRowExtended'

import { PlasmicOrderInfoExtended, DefaultOrderInfoExtendedProps } from './plasmic/solace_components/PlasmicOrderInfoExtended'
import { HTMLElementRefOf } from '@plasmicapp/react-web'
import ActionDropdownLine from './ActionDropdownLine'
import { VerifyExchangeStock } from 'modules/inventory'
import { omit, uniqueId } from 'lodash'
import { paymentMethodLabel, paymentProviderLabel } from '../../src/modules/customers/components/CreateOrder'
import { getOrderPaymentStatus } from 'modules/orders/ActiveOrdersTable/util'
import cylinderPlaceholderFillsvg from './plasmic/solace_components/images/cylinderPlaceholderFillsvg.svg'
import { displayToast } from 'util/toasts'
import { useApolloClient } from '@apollo/client'
import { paymentsEnabled } from 'util/utils'
import { VerifyBulkTankDelivery } from 'modules/orders/types'
import { OrderProductAdjustment } from 'modules/orders/ActiveOrdersTable/OrdersTable'

interface Product {
  id: string | undefined
  name: string | undefined
  image: string | undefined
  quantity: number | undefined
  deposit: string
  price: string
  total: string
  service: boolean
  priceTax: string
  depositTax: string
}

const StatusText = styled.div<{ color?: string }>`
  color: ${props => (props.color ? props.color : 'white')};
`

const CYLINDER_GROUP_ORDER_STATES = {
  [GQL.CylinderGroupOrderStates.Created]: <StatusText color='var(--plasmic-token-sc-purple)'>Order placed</StatusText>,
  [GQL.CylinderGroupOrderStates.AwaitingDelivery]: <StatusText color='var(--plasmic-token-sc-blue)'>Awaiting delivery</StatusText>,
  [GQL.CylinderGroupOrderStates.Delivered]: <StatusText color='var(--plasmic-token-sc-green)'>Delivered</StatusText>,
  [GQL.CylinderGroupOrderStates.Cancelled]: <StatusText color='rgba(225,225,225,0.3)'>Cancelled</StatusText>,
  [GQL.CylinderGroupOrderStates.OrderRequest]: <StatusText color='var(--plasmic-token-sc-purple)'>Order request</StatusText>,
  [GQL.CylinderGroupOrderStates.Paused]: <StatusText color='var(--plasmic-token-sc-gray)'>Paused</StatusText>,
}

export interface OrderInfoExtendedProps extends DefaultOrderInfoExtendedProps {
  order: GQL.CylinderGroupOrderNode | GQL.ReviewableCylinderGroupOrderNode
  initiallyOpened?: boolean
  setVerifyExchangeInfo?: (info: VerifyExchangeStock) => void
  setVerifyBulkTankInfo?: (info: VerifyBulkTankDelivery) => void
  setEditOrder?: (order: GQL.CylinderGroupOrderNode) => void
}

function OrderInfoExtended_(props: OrderInfoExtendedProps, ref: HTMLElementRefOf<'div'>) {
  const [closed, setClosed] = useState<boolean>(!props.initiallyOpened)
  const [moreActions, setMoreActions] = useState(false)
  const navigate = useNavigate()

  const { appContext } = useAppContext()
  const client = useApolloClient()

  const intl = useIntl()
  const t = intl.formatMessage

  const moreActionsHeaderRef = useRef<HTMLDivElement>(null)
  useClickOutside(moreActionsHeaderRef, () => setMoreActions(false))

  if (!props.order) {
    return null
  }

  const [patchCylinderGroupOrder] = GQL.usePatchCylinderGroupOrderLimited({
    update(_, { data }) {
      if (!data?.patchCylinderGroupOrder?.ok || !data.patchCylinderGroupOrder.cylinderGroupOrder) {
        displayToast(t({ id: 'orders.patch-order.error.generic' }), 'error')
        return
      }
      updateCylinderGroupOrderCacheOnPatch(data.patchCylinderGroupOrder.cylinderGroupOrder as GQL.CylinderGroupOrderNode, client)
    },
  })

  const [showViewImageModal, hideViewImageModal] = useModalWithData((order: GQL.CylinderGroupOrderNode) => () => (
    <ViewImageModal isOpen={true} onRequestClose={hideViewImageModal} order={order} />
  ))

  const getDeliveryWindow = (firstPossibleDelivery: string, lastPossibleDelivery: string) => {
    if (!firstPossibleDelivery && !lastPossibleDelivery) {
      return t({ id: 'common.not-available' })
    }

    if (firstPossibleDelivery) {
      firstPossibleDelivery = format(parseISO(firstPossibleDelivery), 'dd. MMM')
    } else {
      firstPossibleDelivery = t({ id: 'common.no-data' })
    }
    if (lastPossibleDelivery) {
      lastPossibleDelivery = format(parseISO(lastPossibleDelivery), 'dd. MMM')
    } else {
      lastPossibleDelivery = t({ id: 'common.no-data' })
    }

    return `${firstPossibleDelivery} - ${lastPossibleDelivery}`
  }

  const [showDeleteConfirmationModal, hideDeleteConfirmationModal] = useModalWithData((order: GQL.CylinderGroupOrderNode) => () => (
    <ConfirmModal
      isOpen
      title={t({ id: 'orders.delete-modal.title' })}
      description={t({ id: 'orders.delete-modal.body' })}
      abortTitle={t({ id: 'common.cancel' })}
      confirmTitle={t({ id: 'common.confirm' })}
      onRequestClose={hideDeleteConfirmationModal}
      onAbort={hideDeleteConfirmationModal}
      onConfirm={() =>
        patchCylinderGroupOrder({
          update(_, { data }) {
            if (!data?.patchCylinderGroupOrder?.ok || !data.patchCylinderGroupOrder.cylinderGroupOrder) {
              displayToast(t({ id: 'orders.patch-order.error.generic' }), 'error')
              return
            }
            updateCylinderGroupOrderCacheOnDelete(data.patchCylinderGroupOrder.cylinderGroupOrder as GQL.CylinderGroupOrderNode, client)
          },
          variables: {
            id: order.id,
            input: {
              state: GQL.CylinderGroupOrderStates.Cancelled,
            },
          },
        })
      }
    ></ConfirmModal>
  ))

  const getOrderSummaryPickups = useCallback(() => {
    if (!props.order.products || props.order.products.length < 1) {
      return {
        toDeliver: props.order.numberOfCylinders,
        toPickup: props.order.numberOfCylinders,
      }
    }

    const initialSum = { toDeliver: 0, toPickup: 0 }
    const summary = props.order.products.reduce((accumulator, orderProduct) => {
      if (!orderProduct?.quantity || !Object.values(GQL.GasTypeEnum).includes(orderProduct?.product?.type as unknown as GQL.GasTypeEnum)) {
        return accumulator
      } else if (!orderProduct?.exchangeCylindersFee) {
        accumulator.toPickup += orderProduct?.quantity
      }
      accumulator.toDeliver += orderProduct?.quantity ? orderProduct.quantity : 0

      return accumulator
    }, initialSum)

    return summary
  }, [props.order])

  const getOrderStatus = useCallback(() => {
    if (!props.order.state) {
      return undefined
    }
    if (props.order.state === GQL.CylinderGroupOrderStates.Delivered) {
      return 'delivered'
    } else if (inDelivery) {
      return 'outForDelivery'
    } else if (props.order.state === GQL.CylinderGroupOrderStates.AwaitingDelivery) {
      return 'awaitingDelivery'
    } else if (props.order.paymentStatus === GQL.CylinderGroupOrderPaymentStates.Paid) {
      return 'orderPaid'
    } else if (props.order.state === GQL.CylinderGroupOrderStates.Created) {
      return 'orderPlaced'
    }
  }, [props.order])

  const getPaymentProvider = useCallback(() => {
    if ('paymentSetting' in props.order) {
      return paymentProviderLabel(props.order.paymentSetting?.provider ? props.order.paymentSetting?.provider : null)
    }
    return '-'
  }, [props.order])

  const getPaymentMethod = useCallback(() => {
    if ('paymentSetting' in props.order) {
      return paymentMethodLabel(props.order.paymentSetting?.method ? props.order.paymentSetting?.method : null)
    }
    return '-'
  }, [props.order])

  const isBulkOrder = useMemo(() => {
    return props.order.__typename === 'CylinderGroupOrderNode' && props.order.orderType === GQL.OrderType.Bulk
  }, [props.order])

  const tripData = useMemo(() => {
    return (props.order.__typename === 'CylinderGroupOrderNode' && props.order.inStops?.find(() => true)) || null
  }, [props.order])

  const inDelivery = useMemo(() => {
    return tripData?.tripStatus?.inProgress || false
  }, [tripData])

  const getDriver = useCallback(() => {
    if (!inDelivery) {
      return '-'
    }
    return tripData?.route?.driver?.user.fullName
  }, [props.order, inDelivery, tripData])

  const images = useMemo(() => {
    return props.order.__typename === 'CylinderGroupOrderNode'
      ? (props.order.inStops?.map((stop, index) => stop?.images?.map((image, n) => image?.url && image)).flat() as GQL.StopImageNode[])
      : []
  }, [props.order])

  const getProducts = (): Product[] => {
    if (!props.order.products || props.order.products.length < 1) {
      return [
        {
          id: props.order.id + uniqueId(props.order.id),
          name: parseFloat(props.order.gasWeight) + ' kg ' + props.order.gasType + ' Cylinder',
          image: undefined,
          quantity: props.order.numberOfCylinders,
          deposit: formatPrice(0, props.order.currency as string),
          price: formatPrice(0, props.order.currency as string),
          total: formatPrice(0, props.order.currency as string),
          service: !Object.values(GQL.GasTypeEnum).includes('Delivery' as unknown as GQL.GasTypeEnum),
          priceTax: `+${formatTaxRate(0, appContext.distributor)}`,
          depositTax: `+${formatTaxRate(0, appContext.distributor)}`,
        },
      ]
    }

    return props.order.products.map(p => ({
      id: p?.id || undefined,
      name: p?.product?.displayName || undefined,
      image: p?.product?.image?.image || cylinderPlaceholderFillsvg,
      quantity: p?.quantity,
      deposit: formatPrice(p?.cylinderDepositPrice, props.order.currency as string),
      price: formatPrice(p?.price, props.order.currency as string),
      total: formatPrice(p?.totalPrice, props.order.currency as string),
      service: !Object.values(GQL.GasTypeEnum).includes(p?.product?.type as unknown as GQL.GasTypeEnum),
      priceTax: `+${formatTaxRate(p?.taxRate, appContext.distributor)}`,
      depositTax: `+${formatTaxRate(p?.depositTaxRate, appContext.distributor)}`,
    }))
  }

  return (
    <PlasmicOrderInfoExtended
      root={{ ref }}
      {...omit(props, 'order', 'initiallyOpened', 'setEditOrder', 'setVerifyExchangeInfo', 'setVerifyBulkTankInfo')}
      closed={closed}
      bulk={isBulkOrder}
      head={{ onClick: () => setClosed(e => !e), style: { cursor: 'pointer' } }}
      paymentStatus={{
        status: getOrderPaymentStatus(props.order as GQL.CylinderGroupOrderNode),
      }}
      paymentStatusHead={{
        status: getOrderPaymentStatus(props.order as GQL.CylinderGroupOrderNode),
      }}
      paymentMethod={getPaymentMethod()}
      paymentProvider={getPaymentProvider()}
      orderId={atob(props.order.id).split(':')[1]}
      orderDateHead={format(parseISO(props.order.createdAt), 'dd. MMM')}
      orderDate={format(parseISO(props.order.createdAt), 'dd. MMMM yyyy')}
      orderedBy={props.order.__typename === 'CylinderGroupOrderNode' ? props.order.orderedBy : 'customer'}
      driver={getDriver() || '-'}
      plannedDelivery={tripData && tripData.expectedArrivalTime ? format(parseISO(tripData.expectedArrivalTime), 'dd. MMMM yyyy') : 'not planned'}
      deliveryWindow={getDeliveryWindow(props.order.firstPossibleDelivery, props.order.lastPossibleDelivery)}
      dateDelivered={
        props.order.__typename === 'CylinderGroupOrderNode' && props.order.deliveredAt
          ? format(parseISO(props.order.deliveredAt), 'dd. MMMM yyyy (HH:mm)')
          : '-'
      }
      status={inDelivery ? <StatusText color='orange'>In delivery</StatusText> : CYLINDER_GROUP_ORDER_STATES[props.order.state!]}
      orderStatusProgress={{
        status: getOrderStatus(),
        awaitingPayment: props.order.paymentStatus !== GQL.CylinderGroupOrderPaymentStates.Paid,
      }}
      products={getProducts().map(p => (
        <OrderProductRowExtended
          key={p.id}
          product={p.name}
          productImage={p.image}
          quantity={isBulkOrder && !p.service ? `${p.quantity} liters` : p.quantity}
          deposit={p.deposit}
          price={p.price}
          total={p.total}
          service={p.service}
          priceTax={p.priceTax}
          depositTax={p.depositTax}
        />
      ))}
      subtotal={formatPrice(props.order.subtotalPrice, props.order.currency as string)}
      tax={formatPrice(props.order.tax, props.order.currency as string)}
      total={formatPrice(props.order.totalPrice, props.order.currency as string)}
      productsToPickup={getOrderSummaryPickups().toPickup.toString()}
      productsToDeliver={getOrderSummaryPickups().toDeliver.toString() + `${isBulkOrder ? ' liters' : ''}`}
      btnOpenRoute={{
        onClick: () => navigate('deliveries/routes', { state: { selectedDay: parseISO(tripData?.expectedArrivalTime) } }),
        notVisible: tripData && tripData.expectedArrivalTime ? false : true,
      }}
      btnDeliveryNote={{
        onClick: () => window.open(`/order/pdf/delivery/${props.order.id}`, '_blank'),
        notVisible: props.order.state !== GQL.CylinderGroupOrderStates.Delivered,
      }}
      labelTotal={
        isBulkOrder && [GQL.CylinderGroupOrderStates.Created, GQL.CylinderGroupOrderStates.AwaitingDelivery].includes(props.order.state!)
          ? 'Estimated total'
          : 'Total'
      }
      moreActions={{
        'data-testid': 'order-more-actions',
        ref: moreActionsHeaderRef ? moreActionsHeaderRef : null,
        open: moreActions,
        onClick: () => setMoreActions(prev => !prev),
        actionDropdown: {
          children: (
            <>
              {props.order.__typename === 'ReviewableCylinderGroupOrderNode' ||
              props.order.state === GQL.CylinderGroupOrderStates.Cancelled ||
              props.order.state === GQL.CylinderGroupOrderStates.Delivered
                ? false
                : true && (
                    <ActionDropdownLine
                      children={
                        props.order.state === GQL.CylinderGroupOrderStates.Paused
                          ? t({ id: 'orders.status.unpause-order' })
                          : t({ id: 'orders.status.pause-order' })
                      }
                      onClick={() =>
                        patchCylinderGroupOrder({
                          variables: {
                            id: props.order.id,
                            input: {
                              state:
                                props.order.state === GQL.CylinderGroupOrderStates.Paused
                                  ? GQL.CylinderGroupOrderStates.Created
                                  : GQL.CylinderGroupOrderStates.Paused,
                            },
                          },
                        })
                      }
                    />
                  )}
              {props.order.__typename === 'ReviewableCylinderGroupOrderNode' ||
              !props.order.state ||
              ![GQL.CylinderGroupOrderStates.AwaitingDelivery, GQL.CylinderGroupOrderStates.Created, GQL.CylinderGroupOrderStates.Paused].includes(
                props.order.state
              )
                ? false
                : true && (
                    <ActionDropdownLine
                      children={
                        props.order.state === GQL.CylinderGroupOrderStates.AwaitingDelivery
                          ? t({ id: 'orders.set-order-placed' })
                          : t({ id: 'orders.set-awaiting-delivery' })
                      }
                      onClick={() =>
                        patchCylinderGroupOrder({
                          variables: {
                            id: props.order.id,
                            input: {
                              state:
                                props.order.state === GQL.CylinderGroupOrderStates.AwaitingDelivery
                                  ? GQL.CylinderGroupOrderStates.Created
                                  : GQL.CylinderGroupOrderStates.AwaitingDelivery,
                            },
                          },
                        })
                      }
                    />
                  )}
              {props.order.__typename === 'ReviewableCylinderGroupOrderNode' || !props.setVerifyExchangeInfo
                ? false
                : props.order.state === GQL.CylinderGroupOrderStates.Cancelled || props.order.state === GQL.CylinderGroupOrderStates.Delivered
                  ? false
                  : true && (
                      <ActionDropdownLine
                        children={'Set as delivered'}
                        onClick={() => {
                          if (props.order.__typename === 'ReviewableCylinderGroupOrderNode' || !props.setVerifyExchangeInfo || !props.setVerifyBulkTankInfo)
                            return
                          if (props.order.__typename === 'CylinderGroupOrderNode' && props.order.orderType === GQL.OrderType.Bulk) {
                            return props.setVerifyBulkTankInfo({
                              callback: (orderProducts: OrderProductAdjustment) => {
                                patchCylinderGroupOrder({
                                  variables: {
                                    id: props.order.id,
                                    input: {
                                      state: GQL.CylinderGroupOrderStates.Delivered,
                                      orderProducts: Object.entries(orderProducts).map(([id, value]) => ({
                                        id: id,
                                        quantity: value.quantity,
                                        productId: value.productId,
                                      })),
                                    },
                                  },
                                })
                              },
                              order: props.order,
                            })
                          }
                          if (appContext.distributor?.inventoryAccess !== GQL.InventoryAccess.NoAccess && props.setVerifyExchangeInfo) {
                            return props.setVerifyExchangeInfo({
                              callback: () =>
                                patchCylinderGroupOrder({
                                  variables: {
                                    id: props.order.id,
                                    input: {
                                      state: GQL.CylinderGroupOrderStates.Delivered,
                                    },
                                  },
                                }),
                              order: props.order as GQL.CylinderGroupOrderNode,
                            })
                          }
                          patchCylinderGroupOrder({
                            variables: {
                              id: props.order.id,
                              input: {
                                state: GQL.CylinderGroupOrderStates.Delivered,
                              },
                            },
                          })
                        }}
                      />
                    )}
              {props.order.__typename !== 'ReviewableCylinderGroupOrderNode' &&
                props.order &&
                props.order.paymentSetting &&
                props.order.paymentSetting.invoiceUrl && (
                  <ActionDropdownLine
                    children={t({ id: 'common.open-invoice' })}
                    onClick={() => {
                      window.open(props.order.paymentSetting?.invoiceUrl!, '_blank')
                    }}
                  />
                )}
              {props.order.__typename !== 'ReviewableCylinderGroupOrderNode' && (
                <ActionDropdownLine
                  children={'Download order'}
                  onClick={() => {
                    window.open(`/order/pdf/${props.order.id}`, '_blank')
                  }}
                />
              )}
              {props.order.__typename !== 'ReviewableCylinderGroupOrderNode' &&
                props.order.state &&
                ![GQL.CylinderGroupOrderStates.Delivered, GQL.CylinderGroupOrderStates.Cancelled].includes(props.order.state) &&
                (paymentsEnabled(appContext) ? props.order.paymentStatus !== GQL.CylinderGroupOrderPaymentStates.Paid : true) &&
                props.setEditOrder && (
                  <ActionDropdownLine
                    children={'Edit order'}
                    onClick={() => (props.order.__typename === 'CylinderGroupOrderNode' && props.setEditOrder ? props.setEditOrder(props.order) : undefined)}
                  />
                )}
              {props.order.__typename !== 'ReviewableCylinderGroupOrderNode' && images && images.length > 0 && (
                <ActionDropdownLine children={'See proof of delivery'} onClick={() => showViewImageModal(props.order)} />
              )}
              {props.order.state === GQL.CylinderGroupOrderStates.Cancelled ||
              props.order.state === GQL.CylinderGroupOrderStates.Delivered ||
              props.order.state === GQL.CylinderGroupOrderStates.OrderRequest
                ? false
                : true && <ActionDropdownLine children={'Delete order'} onClick={() => showDeleteConfirmationModal(props.order)} />}
            </>
          ),
        },
      }}
      externalReference={props.order.externalReference || '-'}
    />
  )
}

const OrderInfoExtended = forwardRef(OrderInfoExtended_)
export default OrderInfoExtended
