import React, { useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { useForm, Controller } from 'react-hook-form'
import Modal from 'components/Modal/Modal'
import Form from 'components/Form'
import FormField from 'components/FormField'
import Button from 'components/Button'
import Margin from 'components/Margin'
import { Calendar } from 'react-date-range'
import { format } from 'date-fns'
import styled from 'styled-components'

const StyledCalendar = styled(Calendar)`
  width: 100%;
`

interface IAddDatesModal {
  title: string
  isOpen: boolean
  onRequestClose: () => void
  selectedDays?: Date[]
  submit: (date: Date) => void
}
interface FormData {
  date: Date
}

const AddDatesModal = ({ submit, selectedDays, onRequestClose, ...props }: IAddDatesModal) => {
  const intl = useIntl()
  const t = intl.formatMessage
  const { handleSubmit, control } = useForm<FormData>()
  const [selectedDate, setSelectedDate] = useState<Date>()

  const onSubmit = () => {
    if (!selectedDate) return
    submit(selectedDate)
  }
  const onRequestCloseInternal = () => {
    onRequestClose()
  }

  const handleCustomDayContent = (date: Date) => {
    if (selectedDays?.map(day => day.setHours(0, 0, 0, 0)).includes(date.setHours(0, 0, 0, 0))) {
      const moreInfo = (
        <>
          <div className='date-info-red-dot' />
          <div className='date-tooltip'>{t({ id: 'common.vehicle-already-unavailable' })}</div>
        </>
      )
      return (
        <div>
          <div className='date-info'>{moreInfo}</div>
          <span>{format(date, 'd')}</span>
        </div>
      )
    }
    return <span>{format(date, 'd')}</span>
  }

  return (
    <Modal onRequestClose={onRequestCloseInternal} {...props} contentStyle={{ width: 450, backgroundColor: '#242c48' }}>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <FormField>
          <Controller
            render={() => (
              <StyledCalendar
                dayContentRenderer={handleCustomDayContent}
                className='rdrMonthCalendar'
                minDate={new Date()}
                date={selectedDate}
                onChange={date => (!selectedDays?.map(day => day.setHours(0, 0, 0, 0)).includes(date.setHours(0, 0, 0, 0)) ? setSelectedDate(date) : null)}
              />
            )}
            name='date'
            control={control}
            defaultValue={selectedDate}
          />
        </FormField>
        <Margin top='1rem'>
          <Button color='green' width='100%' borderRadius='0px'>
            <FormattedMessage id='common.save-changes' />
          </Button>
        </Margin>
      </Form>
    </Modal>
  )
}

export default AddDatesModal
