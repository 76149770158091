// This is a skeleton starter React component generated by Plasmic.
// This file is owned by you, feel free to edit as you see fit.
import * as React from 'react'
import { useIntl } from 'react-intl'
import { PlasmicSettingsSidebar, DefaultSettingsSidebarProps } from './plasmic/solace_components/PlasmicSettingsSidebar'
import { HTMLElementRefOf } from '@plasmicapp/react-web'
import SettingsSidebarGroup from './SettingsSidebarGroup'
import SettingsSidebarLink from './SettingsSidebarLink'
import { useNavigate } from 'react-router'

// Your component props start with props for variants and slots you defined
// in Plasmic, but you can add more here, like event handlers that you can
// attach to named nodes in your component.
//
// If you don't want to expose certain variants or slots as a prop, you can use
// Omit to hide them:
//
// interface SettingsSidebarProps extends Omit<DefaultSettingsSidebarProps, "hideProps1"|"hideProp2"> {
//   // etc.
// }
//
// You can also stop extending from DefaultSettingsSidebarProps altogether and have
// total control over the props for your component.
interface SettingsSidebarProps extends DefaultSettingsSidebarProps {}

function SettingsSidebar_(props: SettingsSidebarProps, ref: HTMLElementRefOf<'div'>) {
  const intl = useIntl()
  const t = intl.formatMessage

  const navigate = useNavigate()

  return (
    <PlasmicSettingsSidebar
      root={{ ref }}
      {...props}
      groups={
        <>
          <SettingsSidebarGroup
            title={t({ id: 'settings.account' })}
            links={
              <>
                <SettingsSidebarLink
                  title={t({ id: 'settings.profile' })}
                  onClick={() => navigate('/settings/profile')}
                  active={window.location.pathname === '/settings/profile'}
                />
                <SettingsSidebarLink
                  title={t({ id: 'settings.alerts' })}
                  onClick={() => navigate('/settings/alerts')}
                  active={window.location.pathname === '/settings/alerts'}
                />
                <SettingsSidebarLink
                  title={t({ id: 'common.password' })}
                  onClick={() => navigate('/settings/password')}
                  active={window.location.pathname === '/settings/password'}
                />
                <SettingsSidebarLink
                  title={t({ id: 'settings.portal' })}
                  onClick={() => navigate('/settings/portal')}
                  active={window.location.pathname === '/settings/portal'}
                />
              </>
            }
          />
          <SettingsSidebarGroup
            title={t({ id: 'common.company' })}
            links={
              <>
                <SettingsSidebarLink
                  title={t({ id: 'common.general' })}
                  onClick={() => navigate('/settings/general')}
                  active={window.location.pathname === '/settings/general'}
                />
                <SettingsSidebarLink
                  title={t({ id: 'settings.depots' })}
                  onClick={() => navigate('/settings/depots')}
                  active={window.location.pathname === '/settings/depots'}
                />
                <SettingsSidebarLink
                  title={t({ id: 'settings.users' })}
                  onClick={() => navigate('/settings/users')}
                  active={window.location.pathname === '/settings/users'}
                />
                <SettingsSidebarLink
                  title={t({ id: 'settings.products' })}
                  onClick={() => navigate('/settings/products')}
                  active={window.location.pathname === '/settings/products'}
                />
                <SettingsSidebarLink
                  title={t({ id: 'settings.services' })}
                  onClick={() => navigate('/settings/services')}
                  active={window.location.pathname === '/settings/services'}
                />
                <SettingsSidebarLink
                  title={t({ id: 'settings.pricing-lists' })}
                  onClick={() => navigate('/settings/pricing-lists')}
                  active={window.location.pathname === '/settings/pricing-lists'}
                />
                <SettingsSidebarLink
                  title={t({ id: 'settings.payments' })}
                  onClick={() => navigate('/settings/payments')}
                  active={window.location.pathname === '/settings/payments'}
                />
              </>
            }
          />
          <SettingsSidebarGroup
            title={t({ id: 'settings.customers' })}
            links={
              <>
                <SettingsSidebarLink
                  title={t({ id: 'settings.automatic-triggers' })}
                  onClick={() => navigate('/settings/automatic-triggers')}
                  active={window.location.pathname === '/settings/automatic-triggers'}
                />
                <SettingsSidebarLink
                  title={t({ id: 'settings.customer-app' })}
                  onClick={() => navigate('/settings/customer-application')}
                  active={window.location.pathname === '/settings/customer-application'}
                />
                <SettingsSidebarLink
                  title={t({ id: 'settings.geofences' })}
                  onClick={() => navigate('/settings/geofences')}
                  active={window.location.pathname === '/settings/geofences'}
                />
                <SettingsSidebarLink
                  title={t({ id: 'settings.customer-notifications' })}
                  onClick={() => navigate('/settings/customer-notifications')}
                  active={window.location.pathname === '/settings/customer-notifications'}
                />
                <SettingsSidebarLink
                  title={t({ id: 'common.tags' })}
                  onClick={() => navigate('/settings/tags')}
                  active={window.location.pathname === '/settings/tags'}
                />
              </>
            }
          />

          <SettingsSidebarGroup
            title='System'
            links={
              <>
                <SettingsSidebarLink
                  title='Changelog'
                  onClick={() => navigate('/settings/changelog')}
                  active={window.location.pathname === '/settings/changelog'}
                />
              </>
            }
          />
        </>
      }
    />
  )
}

const SettingsSidebar = React.forwardRef(SettingsSidebar_)
export default SettingsSidebar
