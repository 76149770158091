import React, { useState } from 'react'
import Select from 'react-select'

import * as GQL from 'generated/graphql'
import { Control, Option, reactSelectStylesImgSelect } from 'plasmic/StyledReactSelect'
import Modal from 'components/Modal/Modal'
import ModalProductCount from 'plasmic/ModalProductCount'
import { displayToast } from 'util/toasts'
import Loader from 'components/Loader'
import { SelectOption } from 'types/props'

interface Props {
  isOpen: boolean
  onClose: () => void
  cylinderGroup?: GQL.CylinderGroupNode
  products: GQL.ProductNode[]
  loadingProducts: boolean
  inventory?: GQL.InventoryNode
}

const AddCylinderGroupInventoryModal: React.FC<Props> = props => {
  const [quantity, setQuantity] = useState('1')
  const [selectedProduct, setSelectedProduct] = React.useState<GQL.ProductNode | null>(
    props.inventory && !!props.inventory.product ? props.inventory.product : null
  )

  const [createInventory, { loading: createInventoryLoading }] = GQL.useAddInventory({
    refetchQueries: ['AllInventories'],
    onCompleted: data => {
      if (!data.addInventory?.ok) {
        return displayToast('Failed to update stock', 'error')
      }
      displayToast('Stock updated successfully', 'success')
      return props.onClose()
    },
    onError: error => {
      displayToast('Failed to update stock', 'error')
    },
  })

  const [patchInventory, { loading: patchInventoryLoading }] = GQL.usePatchInventory({
    refetchQueries: ['AllInventories'],
    onCompleted: data => {
      if (!data.patchInventory?.ok) {
        return displayToast('Failed to update stock', 'error')
      }
      displayToast('Stock updated successfully', 'success')
      return props.onClose()
    },
    onError: error => {
      displayToast('Failed to update stock', 'error')
    },
  })

  const handleOnSubmit = () => {
    if (createInventoryLoading || patchInventoryLoading || !props.cylinderGroup) return
    if (!!props.inventory) {
      return patchInventory({
        variables: {
          inventoryId: props.inventory.id,
          input: {
            stock: {
              state: GQL.InventoryStockState.NotEmpty,
              amount: parseInt(quantity),
            },
            reason: GQL.InventoryAdjustmentReason.ManualCorrection,
            description: '',
            total: true,
          },
        },
      })
    }
    if (!selectedProduct) return
    createInventory({
      variables: {
        input: {
          productId: selectedProduct.id,
          cylinderGroupId: props.cylinderGroup.id,
        },
        stockInput: {
          state: GQL.InventoryStockState.NotEmpty,
          amount: parseInt(quantity),
        },
      },
    })
  }

  return (
    <Modal isOpen={props.isOpen} onRequestClose={props.onClose} stripped>
      <ModalProductCount
        title={!!props.inventory ? 'Adjust quantity' : 'Add product'}
        inputProductDiv={
          <Select
            // @ts-ignore
            showImage={!!selectedProduct?.image?.image}
            // @ts-ignore
            imagePath={selectedProduct?.image?.image || undefined}
            placeholder={props.loadingProducts && props.products.length === 0 ? <Loader color='white' /> : 'Select a product...'}
            components={{ Option, Control }}
            styles={reactSelectStylesImgSelect}
            options={props.products.map(product => ({ value: product, label: product.displayName }) as SelectOption<GQL.ProductNode>)}
            onChange={(event: any) => setSelectedProduct(event.value)}
            // onInputChange={event => props.setSearchProduct(event)}
            value={{ value: selectedProduct || '', label: selectedProduct?.displayName || 'Select a product...' } as SelectOption<GQL.ProductNode>}
            isSearchable
            isMulti={false}
          />
        }
        inputQuantity={{
          undefinedInput: {
            value: quantity,
            name: 'quantity',
            placeholder: 'Product quantity',
            onChange: (event: React.ChangeEvent<HTMLInputElement>) => setQuantity(event.target.value),
          },
        }}
        btnClose={{ onClick: props.onClose }}
        btnCancel={{ onClick: props.onClose }}
        btnSave={{ onClick: handleOnSubmit, label: patchInventoryLoading || createInventoryLoading ? <Loader size={32} color='white' /> : 'Save' }}
      />
    </Modal>
  )
}

export { AddCylinderGroupInventoryModal }
