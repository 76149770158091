import { useState } from 'react'
import { format, parseISO } from 'date-fns'
import { useNavigate } from 'react-router-dom'
import { useIntl } from 'react-intl'

import * as GQL from 'generated/graphql'
import { useAppContext, useCustomerContext } from 'util/hooks'
import { NoHits, ProductLabel, formatPrice, getRowColor } from '../util'
import EstimatedEmpty from 'components/Table/Cells/EstimatedEmpty'
import StockCell from 'components/Table/Cells/StockCell'
import { getOrderPaymentStatus, getOrderMethodForOrder, getAlerts } from './util'
import FlexTable from 'plasmic/FlexTable'
import TableHeadTitle from 'plasmic/TableHeadTitle'
import TableRow from 'plasmic/TableRow'
import CellCustomer from 'plasmic/CellCustomer'
import CellStatus from 'plasmic/CellStatus'
import CellEstimatedEmpty from 'plasmic/CellEstimatedEmpty'
import CellInventory from 'plasmic/CellInventory'
import CellOrderedBy from 'plasmic/CellOrderedBy'
import CellProduct from 'plasmic/CellProduct'
import CellDateOrdered from 'plasmic/CellDateOrdered'
import LabelStatusChip from 'plasmic/LabelStatusChip'
import CellPrice from 'plasmic/CellPrice'
import { VerifyExchangeStock } from 'modules/inventory'
import CellPayment from 'plasmic/CellPayment'
import CellOrderMethod from 'plasmic/CellOrderMethod'
import { StyledOrderMethodButton } from 'modules/customers/CustomersTable'
import {
  ESTIMATED_EMPTY_SORT_KEY,
  FIRST_POSSIBLE_DELIVERY_SORT_KEY,
  ORDERED_BY_SORT_KEY,
  ORDER_METHOD_COLORS,
  ORDER_METHOD_SORT_KEY,
  ORDER_METHOD_TRANSLATIONS,
  PRICE_SORT_KEY,
} from 'modules/customers/consts'
import { CenteredLoader } from 'components/Loader/LoaderWrappers'
import Loader from 'components/Loader'
import { CustomerDrawerListSource } from 'context/CustomerContext'
import { DATE_ORDERED_SORT_KEY } from './consts'
import ScrollIndicator from 'plasmic/ScrollIndicator'
import { VerifyBulkTankDelivery } from '../types'

interface IReadyToRefillTableProps {
  cylinderGroupOrders: GQL.CylinderGroupOrderNode[]
  setVerifyExchangeInfo: (info: VerifyExchangeStock) => void
  setVerifyBulkTankInfo: React.Dispatch<React.SetStateAction<VerifyBulkTankDelivery | undefined>>
  lastMovedRowId: string | undefined
  setLastMovedRowId: (lastMovedRowId?: string) => void
  loading: boolean
  loadingFetchMore: boolean
  loadMore: () => void
  totalCount: number
  onOrderStatusClick: (event: React.MouseEvent<HTMLButtonElement>, fromElement: GQL.CylinderGroupOrderNode | GQL.CylinderGroupNode) => void
  onSortClick: (sortKey: string) => void
  getSortIcon: (sortKey: string) => 'upActive' | 'downActive' | undefined
}

export default function ReadyToRefillTable({
  cylinderGroupOrders,
  lastMovedRowId,
  loading,
  loadingFetchMore,
  loadMore,
  totalCount,
  onOrderStatusClick,
  onSortClick,
  getSortIcon,
}: IReadyToRefillTableProps) {
  const navigate = useNavigate()
  const intl = useIntl()
  const t = intl.formatMessage
  const { appContext } = useAppContext()
  const distributor = appContext.distributor
  const [expanded, setExpanded] = useState(true)

  const { setSource, setCustomersContext } = useCustomerContext()

  let visibleColumns = ['orderedBy', 'product', 'price', 'payment', 'status', 'estimatedEmpty', 'inventory', 'dateOrdered', 'orderMethod']
  if (!distributor?.stockEnabled) {
    visibleColumns = visibleColumns.filter(column => column !== 'inventory')
  }

  return (
    <>
      <FlexTable
        id='awaiting-delivery-table'
        data-testid='awaiting-delivery-table'
        noCheckbox
        collapsed={!expanded}
        children={
          <TableHeadTitle
            style={{ cursor: 'pointer' }}
            onClick={() => setExpanded(oldState => !oldState)}
            color='blue'
            title='Awaiting Delivery'
            count={`(${totalCount || cylinderGroupOrders.length})`}
          />
        }
        headPrice={{ onClick: () => onSortClick(PRICE_SORT_KEY), sorting: getSortIcon(PRICE_SORT_KEY) }}
        headEstimatedEmpty={{ onClick: () => onSortClick(ESTIMATED_EMPTY_SORT_KEY), sorting: getSortIcon(ESTIMATED_EMPTY_SORT_KEY) }}
        headOrderedBy={{ onClick: () => onSortClick(ORDERED_BY_SORT_KEY), sorting: getSortIcon(ORDERED_BY_SORT_KEY) }}
        headDateOrdered={{ onClick: () => onSortClick(DATE_ORDERED_SORT_KEY), sorting: getSortIcon(DATE_ORDERED_SORT_KEY) }}
        headDeliveryWindow={{
          onClick: () => onSortClick(FIRST_POSSIBLE_DELIVERY_SORT_KEY),
          sorting: getSortIcon(FIRST_POSSIBLE_DELIVERY_SORT_KEY),
        }}
        headOrderMethod={{ onClick: () => onSortClick(ORDER_METHOD_SORT_KEY), sorting: getSortIcon(ORDER_METHOD_SORT_KEY) }}
        headProduct={{ hideSorting: true }}
        headStatus={{ hideSorting: true }}
        headPayment={{ hideSorting: true }}
        rows={
          <>
            {cylinderGroupOrders.map(cylinderGroupOrder => (
              <TableRow
                data-testid='order-row'
                noCheckbox
                excite={cylinderGroupOrder.id === lastMovedRowId}
                color={getRowColor(appContext, 'blue', getAlerts(cylinderGroupOrder))}
                key={cylinderGroupOrder.id + 'AwaitingDelivery'}
                sticky={
                  <CellCustomer
                    alerts={getAlerts(cylinderGroupOrder)}
                    onClick={() => {
                      setSource(CustomerDrawerListSource.AWAITING_DELIVERY_ORDERS)
                      setCustomersContext(cylinderGroupOrders.map(cgo => cgo.cylinderGroup.customer))
                      navigate(`?customer=${cylinderGroupOrder.cylinderGroup?.customer.id}`)
                    }}
                    name={cylinderGroupOrder.cylinderGroup?.customer.name}
                    temperature={cylinderGroupOrder.cylinderGroup.customer.temperatureGroup?.temperature?.toString()}
                  />
                }
                scrollCells={
                  <>
                    <CellProduct contentWrapper={<ProductLabel cylinderGroupOrder={cylinderGroupOrder} />} />
                    <CellPrice
                      price={formatPrice(cylinderGroupOrder.totalPrice, cylinderGroupOrder.currency || appContext.distributor?.defaultCurrency || 'USD')}
                    />
                    <CellPayment
                      noInvoice={getOrderPaymentStatus(cylinderGroupOrder) === 'noInvoice' || !cylinderGroupOrder.paymentSetting?.invoiceUrl}
                      paymentStatusChip={{
                        status: getOrderPaymentStatus(cylinderGroupOrder),
                      }}
                      btnInvoice={{
                        onClick: () => {
                          if (cylinderGroupOrder.paymentSetting?.invoiceUrl) {
                            window.open(cylinderGroupOrder.paymentSetting?.invoiceUrl, '_blank')
                          }
                        },
                      }}
                    />
                    <CellStatus fromElement={cylinderGroupOrder} onOrderStatusClick={onOrderStatusClick} editable />
                    <CellEstimatedEmpty content={<EstimatedEmpty cylinderGroup={cylinderGroupOrder.cylinderGroup} displayThreshold={false} />} />
                    {visibleColumns.includes('inventory') && <CellInventory content={<StockCell cylinderGroup={cylinderGroupOrder.cylinderGroup} />} />}
                    <CellOrderedBy
                      content={
                        cylinderGroupOrder.automatic
                          ? t({ id: 'customers.order-type-automatic' })
                          : cylinderGroupOrder.orderedByType === 'clients.customercontact' && cylinderGroupOrder.orderedByContact
                            ? cylinderGroupOrder.orderedByContact?.name
                            : cylinderGroupOrder.orderedByType === 'clients.distributor' && cylinderGroupOrder.addedBy?.fullName
                              ? cylinderGroupOrder.addedBy?.fullName
                              : '-'
                      }
                      title={
                        cylinderGroupOrder.automatic
                          ? t({ id: 'orders.order-method.automatic' })
                          : cylinderGroupOrder.orderedByType === 'clients.customercontact'
                            ? t({ id: 'orders.order-method.customercontact' })
                            : cylinderGroupOrder.orderedByType === 'clients.distributor'
                              ? t({ id: 'orders.order-method.distributor' })
                              : ''
                      }
                    />
                    <CellDateOrdered content={format(parseISO(cylinderGroupOrder.createdAt), 'dd. MMM')} />
                    <CellOrderMethod
                      content={
                        <StyledOrderMethodButton color={ORDER_METHOD_COLORS[getOrderMethodForOrder(cylinderGroupOrder)]}>
                          <div style={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            {t({ id: ORDER_METHOD_TRANSLATIONS[getOrderMethodForOrder(cylinderGroupOrder)] })}
                          </div>
                        </StyledOrderMethodButton>
                      }
                    />
                  </>
                }
              />
            ))}
            {!loading && cylinderGroupOrders.length === 0 && (
              <NoHits>
                <LabelStatusChip icon='check' title='No orders awaiting delivery' />
              </NoHits>
            )}
            {loading && (
              <CenteredLoader sizeAuto>
                <Loader color='gray6' size={50} />
              </CenteredLoader>
            )}
          </>
        }
        visibleColumns={visibleColumns}
      />
      {!loading && cylinderGroupOrders.length > 0 && (
        <ScrollIndicator
          tableRow
          loaded={cylinderGroupOrders.length}
          total={totalCount}
          btnLoadMore={{ ...(loadingFetchMore && { children: <Loader color='white' /> }), onClick: loadMore }}
        />
      )}
    </>
  )
}
