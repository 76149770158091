import React from 'react'
import { useIntl } from 'react-intl'

import SettingsContentWrapper from 'plasmic/SettingsContentWrapper'
import CustomerAppSettings from 'plasmic/CustomerAppSettings'

const SettingsCustomerApplication: React.FC = () => {
  const intl = useIntl()
  const t = intl.formatMessage

  return <SettingsContentWrapper settingsTitle={t({ id: 'settings.customer-app' })} headerButtons={<></>} content={<CustomerAppSettings />} />
}

export default SettingsCustomerApplication
