import React, { useCallback, useRef, useState } from 'react'
import { GoogleMap, Polygon } from '@react-google-maps/api'
import styled from 'styled-components'
import darkModeTheme from 'components/GoogleMap/theme.json'
import lightModeTheme from 'components/GoogleMap/light_theme.json'
import { CoordinatePair, PolygonPath } from '../types'
import { openPolygon } from '../util'

const MapWrapper = styled.div`
  width: 100%;
  max-height: 50vh;
  height: 900px;
`

interface Props {
  initialCenter: CoordinatePair
  setCenter: (center: CoordinatePair) => void
  paths: { [key: string]: PolygonPath }
  setPaths: React.Dispatch<React.SetStateAction<{ [key: string]: PolygonPath }>>
  setSelectedGeofence: React.Dispatch<React.SetStateAction<string | null>>
  lightMode: boolean
}

const GeofenceMap: React.FC<Props> = props => {
  const polygonRef = useRef<{ [key: string]: google.maps.Polygon }>({})
  const listenersRef = useRef<{ [key: string]: google.maps.MapsEventListener[] }>({})
  const [map, setMap] = useState<google.maps.Map | null>()

  const handleMapCenterChange = () => {
    if (!map) return
    const center = map.getCenter()
    if (!center) return
    props.setCenter({ lat: center.lat(), lng: center.lng() })
  }

  const onEdit = useCallback(
    (id: string) => {
      if (polygonRef.current[id]) {
        const nextPath = polygonRef.current[id]
          .getPath()
          .getArray()
          .map(latLng => {
            return { lat: latLng.lat(), lng: latLng.lng() }
          }) as CoordinatePair[]
        props.setPaths(prev => ({ ...prev, [id]: { ...prev[id], path: nextPath } }))
      }
    },
    [props, polygonRef]
  )

  const onLoad = useCallback(
    (polygon: google.maps.Polygon, id: string) => {
      polygonRef.current[id] = polygon
      const path = polygon.getPath()
      listenersRef.current[id] = [
        path.addListener('set_at', () => onEdit(id)),
        path.addListener('insert_at', () => onEdit(id)),
        path.addListener('remove_at', () => onEdit(id)),
      ]
    },
    [onEdit, polygonRef]
  )

  const onUnmount = useCallback(
    (id: string) => {
      delete listenersRef.current[id]
      delete polygonRef.current[id]
    },
    [polygonRef, listenersRef]
  )

  return (
    <MapWrapper>
      <GoogleMap
        mapContainerStyle={{ height: '100%' }}
        center={props.initialCenter}
        zoom={12}
        options={{
          gestureHandling: 'cooperative',
          styles: !props.lightMode ? darkModeTheme : lightModeTheme,
          fullscreenControl: true,
          backgroundColor: 'rgb(20, 25, 38)',
          mapTypeControl: false,
          zoomControl: true,
          streetViewControl: false,
        }}
        onLoad={map => setMap(map)}
        onCenterChanged={handleMapCenterChange}
      >
        {Object.entries(props.paths).map(([key, path]) => (
          <Polygon
            key={key}
            editable={!path.locked}
            draggable={!path.locked}
            path={openPolygon(path.path) || []}
            options={{ fillColor: path.color, strokeColor: path.color }}
            onMouseUp={() => onEdit(key)}
            onDragEnd={() => onEdit(key)}
            onLoad={polygon => onLoad(polygon, key)}
            onUnmount={() => onUnmount(key)}
            onClick={() => props.setSelectedGeofence(key)}
          />
        ))}
      </GoogleMap>
    </MapWrapper>
  )
}

export default GeofenceMap
