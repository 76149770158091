import React, { useCallback, useEffect, useMemo, useState } from 'react'
import Select from 'react-select'

import * as GQL from 'generated/graphql'
import ModalTransferStock from 'plasmic/ModalTransferStock'
import { Control, Option, reactSelectStyles } from 'plasmic/StyledReactSelect'
import Modal from 'components/Modal/Modal'
import { displayToast } from 'util/toasts'
import { TransferStock } from '../types'
import Loader from 'components/Loader'
import { SelectOption } from 'types/props'

interface Props {
  isOpen: boolean
  onClose: () => void
  transferStockInfo: TransferStock
}

const TransferStockModal: React.FC<Props> = props => {
  const SELECT_OPTIONS_SIZE = 150
  const { product, fromInventory, toInventory, fromStockState, toStockState } = props.transferStockInfo
  const [quantity, setQuantity] = useState('0')
  const [description, setDescription] = useState('')
  const [selectedFromInventoryId, setSelectedFromInventoryId] = useState<string | null>(fromInventory?.id || null)
  const [selectedFromInventory, setSelectedFromInventory] = useState<GQL.InventoryNode | null>(fromInventory || null)
  const [selectedToInventoryId, setSelectedToInventoryId] = useState<string | null>(toInventory?.id || null)
  const [selectedToInventory, setSelectedToInventory] = useState<GQL.InventoryNode | null>(toInventory || null)
  const { data: dataInventories } = GQL.useAllInventories({
    variables: { first: SELECT_OPTIONS_SIZE, productId: product.id, forDepot: true },
  })
  const inventories = useMemo(() => dataInventories?.allInventories?.edges.map(edge => edge?.node as GQL.InventoryNode) || [], [dataInventories])

  const [transferStock, { loading: transferStockLoading }] = GQL.useTransferStock({
    refetchQueries: ['AllProductInventories'],
    onCompleted: data => {
      if (!data?.transferStock?.ok) {
        return displayToast('Failed to transfer stock', 'error')
      }
      displayToast('Stock transferred successfully', 'success')
      return props.onClose()
    },
    onError: error => {
      displayToast('Failed to transfer stock', 'error')
    },
  })

  const inventoryInput = useCallback(
    (inventory: GQL.InventoryNode) => {
      const input: GQL.InputAddInventory = { productId: product.id }
      if (inventory.belongsTo?.__typename === 'CylinderGroupNode') {
        input['cylinderGroupId'] = inventory.belongsTo.customer.id
      } else if (inventory.belongsTo?.__typename === 'DepotStorageNode') {
        input['depotId'] = inventory.belongsTo.depot?.id
      } else if (inventory.belongsTo?.__typename === 'VehicleNode') {
        input['vehicleId'] = inventory.belongsTo.id
      }
      return input
    },
    [product]
  )

  const handleOnSubmit = () => {
    if (transferStockLoading || !selectedFromInventory || !selectedToInventory || !product) return
    if (selectedFromInventory.id === selectedToInventory.id)
      return displayToast('You cannot transfer between the same inventories. Use an adjustment.', 'error')

    transferStock({
      variables: {
        input: {
          fromInventory: inventoryInput(selectedFromInventory),
          toInventory: inventoryInput(selectedToInventory),
          fromState: fromStockState,
          toState: toStockState,
          amount: parseInt(quantity),
          description: description,
          reason: GQL.InventoryAdjustmentReason.Other,
        },
      },
    })
  }

  useEffect(() => {
    if (inventories.length === 0 || selectedFromInventory?.id === selectedFromInventoryId) return
    if (!selectedFromInventoryId) {
      return setSelectedFromInventoryId(inventories[0].id)
    }
    setSelectedFromInventory(inventories.find(inventory => inventory.id === selectedFromInventoryId) || null)
  }, [inventories, selectedFromInventory, selectedFromInventoryId])

  useEffect(() => {
    if (inventories.length === 0 || selectedToInventory?.id === selectedToInventoryId) return
    if (!selectedToInventoryId) {
      return setSelectedToInventoryId(inventories[0].id)
    }
    setSelectedToInventory(inventories.find(inventory => inventory.id === selectedToInventoryId) || null)
  }, [inventories, selectedToInventory, selectedToInventoryId])

  return (
    <Modal isOpen={props.isOpen} onRequestClose={props.onClose} stripped>
      <ModalTransferStock
        noImage={!product.image?.image}
        productImage={product.image?.image}
        productTitle={product.displayName}
        selectFromDepotDiv={
          <Select
            options={inventories.map(
              inventory =>
                ({
                  value: inventory.id,
                  label: inventory.belongsTo?.__typename === 'CylinderGroupNode' ? inventory.belongsTo.customer.name : inventory.belongsTo?.name,
                }) as SelectOption<string>
            )}
            value={
              {
                value: selectedFromInventory?.id || '',
                label:
                  selectedFromInventory?.belongsTo?.__typename === 'CylinderGroupNode'
                    ? selectedFromInventory.belongsTo.customer.name
                    : selectedFromInventory?.belongsTo?.name || 'Select from where to transfer...',
              } as SelectOption<string>
            }
            onChange={(event: any) => setSelectedFromInventoryId(event.value)}
            components={{ Option, Control }}
            styles={reactSelectStyles}
            isSearchable={true}
            isMulti={false}
          />
        }
        selectToDepotDiv={
          <Select
            options={inventories.map(
              inventory =>
                ({
                  value: inventory.id,
                  label: inventory.belongsTo?.__typename === 'CylinderGroupNode' ? inventory.belongsTo.customer.name : inventory.belongsTo?.name,
                }) as SelectOption<string>
            )}
            value={
              {
                value: selectedToInventory?.id || '',
                label:
                  selectedToInventory?.belongsTo?.__typename === 'CylinderGroupNode'
                    ? selectedToInventory.belongsTo.customer.name
                    : selectedToInventory?.belongsTo?.name || 'Select where to transfer...',
              } as SelectOption<string>
            }
            onChange={(event: any) => setSelectedToInventoryId(event.value)}
            components={{ Option, Control }}
            styles={reactSelectStyles}
            isSearchable={true}
            isMulti={false}
          />
        }
        inputDescription={{
          props: {
            value: description,
            name: 'description',
            onChange: (event: React.ChangeEvent<HTMLTextAreaElement>) => setDescription(event.target.value),
          },
        }}
        inputQuantity={{
          undefinedInput: {
            value: quantity?.toString(),
            name: 'quantity',
            onChange: (event: React.ChangeEvent<HTMLInputElement>) => setQuantity(event.target.value),
          },
        }}
        btnClose={{ onClick: props.onClose }}
        btnCancel={{ onClick: props.onClose }}
        btnSave={{ onClick: handleOnSubmit, label: transferStockLoading ? <Loader size={32} color='white' /> : 'Transfer stock' }}
      />
    </Modal>
  )
}

export { TransferStockModal }
