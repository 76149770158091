import { Route, Routes, Navigate } from 'react-router-dom'

import SettingsGeneral from './pages/SettingsGeneral'
import SettingsUsers from './pages/SettingsUsers'
import SettingsProducts from './pages/SettingsProducts'
import SettingsServices from './pages/SettingsServices'
import SettingsDepots from './pages/SettingsDepots'
import SettingsProfile from './pages/SettingsProfile'
import SettingsAlerts from './pages/SettingsAlerts'
import SettingsPassword from './pages/SettingsPassword'
import SettingsCustomerNotifications from './pages/SettingsCustomerNotifications'
import SettingsAutomaticTriggers from './pages/SettingsAutomaticTriggers'
import SettingsChangelog from './pages/SettingsChangelog'
import SettingsPortal from './pages/SettingsPortal'
import SettingManageTags from './pages/SettingsManageTags'
import SettingsPricingLists from './pages/SettingsPricingLists'
import SettingsPayments from './pages/SettingsPayments'
import SettingsCustomerApplication from './pages/SettingsCustomerApplication'
import SettingsGeofences from './pages/SettingsGeofences'

export default function Settings() {
  return (
    <Routes>
      <Route path='/general' element={<SettingsGeneral />} />
      <Route path='/depots' element={<SettingsDepots />} />
      <Route path='/users' element={<SettingsUsers />} />
      <Route path='/products' element={<SettingsProducts />} />
      <Route path='/pricing-lists' element={<SettingsPricingLists />} />
      <Route path='/services' element={<SettingsServices />} />
      <Route path='/portal' element={<SettingsPortal />} />
      <Route path='/profile' element={<SettingsProfile />} />
      <Route path='/alerts' element={<SettingsAlerts />} />
      <Route path='/password' element={<SettingsPassword />} />
      <Route path='/customer-application' element={<SettingsCustomerApplication />} />
      <Route path='/customer-notifications' element={<SettingsCustomerNotifications />} />
      <Route path='/automatic-triggers' element={<SettingsAutomaticTriggers />} />
      <Route path='/geofences' element={<SettingsGeofences />} />
      <Route path='/changelog' element={<SettingsChangelog />} />
      <Route path='/tags' element={<SettingManageTags />} />
      <Route path='/payments' element={<SettingsPayments />} />
      <Route path='/' key='*' element={<Navigate to='/settings/profile' />} />
    </Routes>
  )
}
