import { useIntl } from 'react-intl'
import styled from 'styled-components'

import * as GQL from 'generated/graphql'
import LabelStatusChip from 'plasmic/LabelStatusChip'
import OrderInfoExtended from 'plasmic/OrderInfoExtended'
import { VerifyExchangeStock } from 'modules/inventory'
import Loader from 'components/Loader'
import NoteElement from 'plasmic/NoteElement'
import { VerifyBulkTankDelivery } from 'modules/orders/types'

const LoaderWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`

function OrderAccordion({
  active,
  loading,
  orders,
  activeOrders,
  setVerifyExchangeInfo,
  setVerifyBulkTankInfo,
  setEditOrder,
}: {
  active?: boolean
  loading?: boolean
  orders: GQL.CylinderGroupOrderNode[]
  activeOrders: GQL.CylinderGroupOrderNode[]
  setVerifyExchangeInfo?: (info: VerifyExchangeStock) => void
  setVerifyBulkTankInfo?: (info: VerifyBulkTankDelivery) => void
  setEditOrder: (order: GQL.CylinderGroupOrderNode) => void
}) {
  const intl = useIntl()
  const t = intl.formatMessage

  if (!loading && active && !activeOrders) {
    return <LabelStatusChip icon={'check'} title={t({ id: 'common.no-orders' })} />
  }

  return (
    <>
      {loading && (
        <LoaderWrapper>
          <Loader size={32} color='gray8' />
        </LoaderWrapper>
      )}
      {!loading && (active ? activeOrders : orders).length === 0 && (
        <div style={{ display: 'flex', padding: '16px', justifyContent: 'center' }}>
          <LabelStatusChip icon={'check'} title={t({ id: 'common.no-orders' })} />
        </div>
      )}
      {(active ? activeOrders : orders).map((order, i) => {
        if (!order) return null
        return (
          <OrderInfoExtended
            key={order?.id! + i + 'orderInfoCard'}
            data-testid='order-info'
            setVerifyExchangeInfo={setVerifyExchangeInfo}
            setVerifyBulkTankInfo={setVerifyBulkTankInfo}
            closed={i !== 0 || !active}
            order={order as GQL.CylinderGroupOrderNode}
            setEditOrder={setEditOrder}
            hasOrderNote={order.notes ? order.notes.length > 0 : false}
            orderNotes={order.notes?.map(note => <NoteElement key={note?.id} content={note?.content} />)}
            deliveryComment={order.comment}
          />
        )
      })}
    </>
  )
}

export default OrderAccordion
