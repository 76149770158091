/* eslint-disable react/jsx-pascal-case */
import React, { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react'
import { useIntl } from 'react-intl'
import _ from 'lodash'
import Select, { MenuListProps, SingleValue } from 'react-select'
import styled from 'styled-components'

import * as GQL from 'generated/graphql'
import ModalNewContactSignup from 'plasmic/ModalNewContactSignup'
import Modal from 'components/Modal/Modal'
import Map, { IMarker } from 'modules/localization/components/Map'
import PhoneInput from 'components/Input/PhoneInput'
import { displayToast } from 'util/toasts'
import { Control, MenuList, Option, reactSelectStyles } from 'plasmic/StyledReactSelect'
import { useCustomerContext, useDebounce } from 'util/hooks'
import DeleteContactSignupModal from './DeleteContactSignupModal'
import ModalCreateCustomer, { CustomerInfo, OpeningHour } from 'plasmic/ModalCreateCustomer'
import { handleCustomerCreateValidation } from '../util'
import { isValidAddress } from 'modules/localization/utils'
import AddressAutoComplete from 'components/Input/AddressAutoComplete'
import { SelectOption } from 'types/props'

const MapWrapper = styled.div`
  height: 230px;
  width: 740px;
`

interface Props {
  isOpen: boolean
  contact: GQL.CustomerContactSignupNode
  onRequestClose: () => void
}

const FORM_ID = 'assign-customer-contact-form'
const SUGGESTED_THRESHOLD = 0.5
const MATCH_THRESHOLD = 0.1
const PAGE_SIZE = 100

const ContactSignupsModal: React.FC<Props> = props => {
  const intl = useIntl()
  const t = intl.formatMessage

  const [configureCylinderSetup, setConfigureCylinderSetup] = useState(false)
  const [customerInfo, setCustomerInfo] = useState<CustomerInfo>({
    name: props.contact.customerDomainType?.toUpperCase() === GQL.CustomerDomainType.Resident ? props.contact.name || '' : props.contact.businessName || '',
    customerIdentifier: '',
    phoneNumber: props.contact.phone || '',
    address: props.contact.address ? props.contact.address : null,
    depot: {
      value: '',
      label: '',
    },
    orderMethod: GQL.CustomerOrderMethod.Phone,
    customerDomainType: (props.contact.customerDomainType?.toUpperCase() as GQL.CustomerDomainType) || GQL.CustomerDomainType.Business,
    pricingCategory: {
      value: '',
      label: '',
    },
    tags: [] as SelectOption<string>[],
    cylinderMode: GQL.CylinderGroupMode.Standard,
    product: {
      value: '',
      label: '',
    },
    cylinderSides: [
      {
        cylinderCount: 4,
      },
      {
        cylinderCount: 4,
      },
    ],
    trigger: {
      value: '',
      label: '',
    },
    alwaysOpen: true,
    openingHours: [] as OpeningHour[],
  })

  const [name, setName] = useState(props.contact.name || '')
  const [businessName, setBusinessName] = useState(props.contact.businessName || '')
  const [email, setEmail] = useState(props.contact.email || '')
  const [phone, setPhone] = useState(props.contact.phone || '')
  const [address, setAddress] = useState<GQL.AddressNode | null>(props?.contact?.address || null)
  const [search, setSearch] = useState('')
  const [createNewCustomer, setCreateNewCustomer] = useState(true)
  const [deleteSignupModalOpen, setDeleteSignupModalOpen] = useState(false)

  const [selectedCustomer, setSelectedCustomer] = useState<GQL.CustomerNode | null>(null)
  const [selectedCustomerId, setSelectedCustomerId] = useState<string | null>(null)

  const debouncedSearch = useDebounce(search, 500)
  const { customersContext } = useCustomerContext()

  const [assignCustomerContact, { loading: submittingAssignCustomerContact }] = GQL.useAssignCustomerContactMutation({
    refetchQueries: ['AllCustomerContactSignups'],
    onCompleted: response => {
      if (!!response.assignCustomerContact?.warning) {
        displayToast(t({ id: 'customers.contact.create.success-with-warning' }), 'warning')
      } else {
        displayToast(t({ id: 'customers.assign-success' }), 'success')
      }
      props.onRequestClose()
    },
    onError: () => {
      displayToast(t({ id: 'customers.toasts.error-generic' }), 'error')
    },
  })

  const [createCustomer, { loading: submittingCreateCustomer }] = GQL.useCreateCustomer({
    onCompleted: data => {
      if (!data.createCustomer?.customer?.id) return
      displayToast(t({ id: 'customers.toast.create.success' }), 'success')
      assignCustomerContact({
        variables: {
          customerContactSignupId: props.contact.id,
          customerId: data.createCustomer.customer.id || '',
          input: {
            name: name,
            email: email,
            phoneNumber: phone,
          },
        },
      })
    },
    onError: () => {
      displayToast(t({ id: 'customers.toast.create.error' }), 'error')
    },
  })

  const {
    data: customersData,
    fetchMore: fetchMoreCustomers,
    loading: loadingCustomers,
  } = GQL.useAllCustomersCustomersMatchedToContactSignup({
    variables: { after: null, first: PAGE_SIZE, search: debouncedSearch, contact: props.contact.id },
  })
  const customers = useMemo(() => customersData?.allCustomersMatchedToContactSignup?.edges.map(edge => edge?.node as GQL.CustomerNode) || [], [customersData])

  useCallback(() => {
    if (!customersData?.allCustomersMatchedToContactSignup?.pageInfo.hasNextPage) return
    fetchMoreCustomers({
      variables: {
        after: customersData?.allCustomersMatchedToContactSignup?.pageInfo.endCursor,
        first: PAGE_SIZE,
        search: debouncedSearch,
        contact: props.contact.id,
      },
      updateQuery(prev, { fetchMoreResult }) {
        if (!fetchMoreResult) return prev
        return {
          ...fetchMoreResult,
        } as GQL.AllCustomersCustomersMatchedToContactSignup
      },
    })
  }, [fetchMoreCustomers, debouncedSearch, customersData?.allCustomersMatchedToContactSignup?.pageInfo, props.contact.id])

  const formatMatch = (match?: GQL.Maybe<number>) => {
    if (!match || match < MATCH_THRESHOLD) return ''
    return `${Math.round(match * 100)}% match`
  }

  const toggleCreateNewCustomer = () => {
    setCreateNewCustomer(true)
    setSelectedCustomerId(null)
  }

  const handleCreateCustomer = () => {
    createCustomer({
      variables: {
        input: {
          name: customerInfo.name,
          customerIdentifier: customerInfo.customerIdentifier,
          phoneNumber: customerInfo.phoneNumber,
          address: _.omit(customerInfo.address, ['__typename', 'id']) as GQL.CreateAddressInput,
          orderMethod: customerInfo.orderMethod,
          customerDomainType: customerInfo.customerDomainType,
          depot: customerInfo.depot.value,
          tags: customerInfo.tags.map(tag => tag.value),
          priceCategory: customerInfo.pricingCategory.value,
          cylinderGroups: configureCylinderSetup
            ? [
                {
                  product: customerInfo.product.value,
                  thresholdId: customerInfo.trigger.value ? customerInfo.trigger.value : null,
                  cylinderSides: customerInfo.cylinderMode === GQL.CylinderGroupMode.Standard ? customerInfo.cylinderSides : [customerInfo.cylinderSides[0]],
                  cylinderMode: customerInfo.cylinderMode,
                },
              ]
            : null,
          alwaysOpen: customerInfo.alwaysOpen,
          openingHours: customerInfo.openingHours,
        },
      },
    })
  }

  const validateCustomer = () => {
    handleCustomerCreateValidation(customerInfo, configureCylinderSetup, customersContext, handleCreateCustomer, intl)
  }

  useEffect(() => {
    if (customers.length === 0 || createNewCustomer || selectedCustomer?.id === selectedCustomerId) return
    if (!selectedCustomerId) {
      if (customers[0].matchTotal && customers[0].matchTotal >= SUGGESTED_THRESHOLD) {
        setSelectedCustomerId(customers[0].id)
        return
      }
      toggleCreateNewCustomer()
      return
    }
    setSelectedCustomer(customers.find(customer => customer.id === selectedCustomerId) || null)
  }, [customers, selectedCustomer, selectedCustomerId, createNewCustomer])

  const handleSubmit = () => {
    if (submittingCreateCustomer || submittingAssignCustomerContact) return
    if (createNewCustomer) {
      validateCustomer()
    }
    if (!selectedCustomerId) return
    return assignCustomerContact({
      variables: {
        customerContactSignupId: props.contact.id,
        customerId: selectedCustomerId,
        input: {
          name: name,
          email: email,
          phoneNumber: phone,
        },
      },
    })
  }

  const customerMarkers = useMemo(() => {
    if (!selectedCustomer || !selectedCustomer.address.longitude || !selectedCustomer.address.latitude) return undefined
    return [
      {
        longitude: parseFloat(selectedCustomer.address.longitude),
        latitude: parseFloat(selectedCustomer.address.latitude),
      } as IMarker,
    ]
  }, [selectedCustomer])

  const contactMarkers = useMemo(() => {
    if (!props.contact || !address) return undefined
    return [
      {
        longitude: parseFloat(address.longitude),
        latitude: parseFloat(address.latitude),
      } as IMarker,
    ]
  }, [address, props.contact])

  const closeContactSignupModal = () => {
    setDeleteSignupModalOpen(false)
  }

  const handleSelectOnChange = (event: SingleValue<SelectOption<string>>) => {
    setCreateNewCustomer(false)
    setSelectedCustomerId(event?.value || null)
  }

  const handleInputChange = (value: string) => {
    setSearch(value)
  }

  const handleChangeAddress = (suggestion: GQL.GeocodingSuggestionNode) => {
    setAddress(address => {
      if (suggestion.placeName && suggestion.latitude && suggestion.latitude) {
        return {
          firstLine: suggestion.placeName,
          longitude: suggestion.longitude,
          latitude: suggestion.latitude,
        } as GQL.AddressNode
      }
      return address
    })
  }

  const CustomMenuList = (props: MenuListProps<any>) => <MenuList onClickBtnNewCustomer={() => toggleCreateNewCustomer()} form={FORM_ID} {...props} />

  return (
    <Modal
      isOpen={props.isOpen}
      onRequestClose={props.onRequestClose}
      stripped
      overlayStyle={{ alignItems: 'flex-start', overflow: 'auto', padding: '2rem 0rem' }}
    >
      <ModalNewContactSignup
        newCustomer={!selectedCustomerId || createNewCustomer}
        businessCustomer={props.contact.customerDomainType?.toUpperCase() === GQL.CustomerDomainType.Business}
        btnClose={{ onClick: () => props.onRequestClose() }}
        btnDelete={{ onClick: () => setDeleteSignupModalOpen(true) }}
        newCustomerWrapper={
          createNewCustomer ? (
            <ModalCreateCustomer
              customerInfo={customerInfo}
              setCustomerInfo={setCustomerInfo}
              configureCylinderSetup={configureCylinderSetup}
              setConfigureCylinderSetup={setConfigureCylinderSetup}
              inline
              loading={submittingCreateCustomer}
              handleSubmit={() => {}} // this component has his own submit button
            />
          ) : null
        }
        inputContactName={{
          props: {
            fieldValue: name,
            onChange: (event: ChangeEvent<HTMLInputElement>) => setName(event.target.value),
          },
        }}
        inputBusinessName={{
          props: {
            fieldValue: businessName,
            onChange: (event: ChangeEvent<HTMLInputElement>) => setBusinessName(event.target.value),
          },
        }}
        inputContactEmail={{
          props: {
            fieldValue: email,
            onChange: (event: ChangeEvent<HTMLInputElement>) => setEmail(event.target.value),
          },
        }}
        inputContactPhone={{
          render: () => (
            <PhoneInput
              defaultValue={phone}
              onPhoneNumberChange={(...all) => {
                setPhone(all[3].replace(/\s|-/g, ''))
              }}
              onSelectFlag={(...all) => {
                setPhone(all[2].replace(/\s|-/g, ''))
              }}
            />
          ),
        }}
        addressInputForm={{
          inputAddressWrapper: {
            alert: !isValidAddress(address),
            title: t({ id: 'common.address' }),
            noTooltip: true,
            divPhoneNumber: (
              <AddressAutoComplete
                id='input-address'
                name='address'
                placeholder={t({ id: 'common.address' })}
                value={address?.firstLine ? address.firstLine : ''}
                onSelect={handleChangeAddress}
              />
            ),
          },
        }}
        selectCustomerDiv={
          <Select
            id='customer-select'
            placeholder='Search for a customer...'
            components={{ Option, Control, MenuList: CustomMenuList }}
            styles={reactSelectStyles}
            isMulti={false}
            onChange={value => handleSelectOnChange(value)}
            onInputChange={value => handleInputChange(value)}
            isLoading={loadingCustomers}
            value={
              {
                value: selectedCustomer?.id || '',
                label: selectedCustomer?.name || 'Select customer',
                suggested: selectedCustomer?.matchTotal ? selectedCustomer.matchTotal >= SUGGESTED_THRESHOLD : false,
              } as SelectOption<string>
            }
            options={
              customers.map(customer => ({
                value: customer.id,
                label: customer.name,
                suggested: customer.matchTotal ? customer.matchTotal >= SUGGESTED_THRESHOLD : false,
              })) as SelectOption<string>[]
            }
          />
        }
        customerId={selectedCustomer?.customerIdentifier || '-'}
        customerPhone={selectedCustomer?.phoneNumber || '-'}
        customerEmail={selectedCustomer?.contactPersonEmail || '-'}
        matchAddress={{
          props: {
            title: formatMatch(selectedCustomer?.matchAddress),
          },
        }}
        matchEmail={{
          props: {
            title: formatMatch(selectedCustomer?.matchEmail),
          },
        }}
        matchPhone={{
          props: {
            title: formatMatch(selectedCustomer?.matchPhone),
          },
        }}
        btnComplete={{
          props: {
            'data-testid': 'submit',
            disabled: !createNewCustomer && !selectedCustomerId,
            form: FORM_ID,
            onClick: handleSubmit,
            loading: submittingCreateCustomer || submittingAssignCustomerContact,
          },
        }}
        mapCustomerParent={{
          props: {
            children: (
              <MapWrapper>
                <Map
                  options={{ fullscreenControl: false, mapTypeControl: false, zoomControl: false, streetViewControl: false, zoom: 14 }}
                  markers={customerMarkers}
                />
              </MapWrapper>
            ),
          },
        }}
        mapContactParent={{
          props: {
            children: (
              <MapWrapper>
                <Map
                  options={{ fullscreenControl: false, mapTypeControl: false, zoomControl: false, streetViewControl: false, zoom: 14 }}
                  markers={contactMarkers}
                />
              </MapWrapper>
            ),
          },
        }}
      />
      <DeleteContactSignupModal
        isOpen={deleteSignupModalOpen}
        onRequestClose={closeContactSignupModal}
        onRequestCloseParent={props.onRequestClose}
        contactSignup={props.contact}
      />
    </Modal>
  )
}

export default ContactSignupsModal
