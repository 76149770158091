// This is a skeleton starter React component generated by Plasmic.
// This file is owned by you, feel free to edit as you see fit.
import * as React from 'react'
import { format } from 'date-fns'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useIntl } from 'react-intl'
import _, { capitalize, toLower } from 'lodash'
import { Map } from 'immutable'
import styled from 'styled-components'
import { HTMLElementRefOf } from '@plasmicapp/react-web'

import * as GQL from 'generated/graphql'
import { PlasmicRouteTable, DefaultRouteTableProps } from './plasmic/solace_components/PlasmicRouteTable'
import { displayToast } from 'util/toasts'
import Link from 'components/Link'
import Text from 'components/Text'
import { CustomerDrawerListSource } from 'context/CustomerContext'
import DraggableTableRow from 'modules/deliveries/Routes/DraggableTableRow'
import EstimatedEmpty from 'components/Table/Cells/EstimatedEmpty'
import { ProductLabel } from 'modules/orders/util'
import FlexTable from 'plasmic/FlexTable'
import TableRow from 'plasmic/TableRow'
import TableHeadTitle from 'plasmic/TableHeadTitle'
import CellDistance from 'plasmic/CellDistance'
import CellNo from 'plasmic/CellNo'
import CellProduct from 'plasmic/CellProduct'
import CellAddress from 'plasmic/CellAddress'
import CellEstimatedEmpty from 'plasmic/CellEstimatedEmpty'
import CellInventory from 'plasmic/CellInventory'
import CellArrival from 'plasmic/CellArrival'
import { useCustomerContext } from 'util/hooks'

interface ICustomerCell {
  color: 'blue' | 'green' | 'red'
}

enum GroupType {
  MOVEABLE = 'MOVEABLE',
  UNMOVEABLE = 'UNMOVEABLE',
  BREAK = 'BREAK',
}

export const STOP_TABLE_ROW_DRAG_TYPE = 'STOP'

interface StopGroup {
  type: GroupType
  stops: GQL.StopNode[]
}

const CustomerCell = styled.div<ICustomerCell>`
  color: ${props => props.theme.colors[props.color]};
  height: 100%;
  padding-left: 0.5rem;
  justify-content: left;
  display: inline-flex;
  -webkit-box-align: center;
  align-items: center;
  display: flex;
`

const GreenText = styled.b`
  color: ${props => props.theme.colors['green']};
`

export interface RouteTableProps extends DefaultRouteTableProps {
  route: GQL.RouteNode
  routes: GQL.RouteNode[]
  drivers: GQL.DriverNode[]
  setLoading?: React.Dispatch<React.SetStateAction<boolean>>
}

function RouteTable_(props: RouteTableProps, ref: HTMLElementRefOf<'div'>) {
  const intl = useIntl()
  const t = intl.formatMessage

  const [stopGroups, setStopGroups] = useState<StopGroup[]>()

  const { setSource, setCustomersContext } = useCustomerContext()

  const [redoRoute] = GQL.useRedoRoute({
    refetchQueries: ['AllScheduledRoutes', 'RoutingStats', 'RoutingOrdersStats'],
    onCompleted: data => {
      if (data.redoRoute?.error) {
        displayToast(data.redoRoute?.error)
      } else if (!data.redoRoute?.route) {
        displayToast(t({ id: 'routing.edit-route.error' }), 'error')
      } else {
        setStopGroups(undefined)
        displayToast(t({ id: 'routing.edit-route.success' }), 'success')
      }
    },
    onError: () => {
      displayToast(t({ id: 'routing.edit-route.error' }), 'error')
    },
  })

  const timeSpent = useMemo(() => {
    const routeStart = new Date(props.route.startTime).getTime()
    const routeEnd = new Date(props.route.endTime).getTime()
    const routeDiff = Math.round((routeEnd - routeStart) / 1000 / 60)
    const timeSpentDec = routeDiff / 60

    return [
      Math.floor(timeSpentDec).toString(),
      Math.floor((timeSpentDec - Math.floor(timeSpentDec)) * 60)
        .toString()
        .padStart(2, '0'),
    ]
  }, [props.route])

  const insertBreak = useCallback(
    (input: StopGroup[]) => {
      const result: StopGroup[] = []
      let currentArray: GQL.StopNode[] = []
      let isInserted = false

      if (!props.route.breakStartTime) return input

      for (const group of input) {
        let foundCondition = false

        for (const stop of group.stops) {
          if (Date.parse(stop.expectedArrivalTime) > Date.parse(props.route.breakStartTime) && !isInserted) {
            if (currentArray.length > 0) {
              result.push({ type: group.type, stops: [...currentArray] })
            }
            result.push({
              type: GroupType.BREAK,
              stops: [
                {
                  id: 'break',
                  stopType: GQL.StopStopType.Pickup,
                  latitude: 0,
                  longitude: 0,
                  orderInRoute: 0,
                  route: props.route,
                  status: GQL.StopStatus.Started,
                },
              ],
            })
            currentArray = [stop]
            foundCondition = true
            isInserted = true
          } else {
            currentArray.push(stop)
          }
        }

        if (!foundCondition) {
          result.push({ type: group.type, stops: [...currentArray] })
          currentArray = []
        } else {
          result.push({ type: group.type, stops: currentArray })
          currentArray = []
        }
      }

      return result
    },
    [props.route]
  )

  useEffect(() => {
    if (stopGroups || !props.route.stops) return

    const result: StopGroup[] = []
    let currentType: GroupType | null = null
    let currentStops: GQL.StopNode[] = []

    props.route.stops.forEach(stop => {
      if (stop === null) return

      const mappedType = stop.stopType === GQL.StopStopType.Delivery ? GroupType.MOVEABLE : GroupType.UNMOVEABLE

      if (mappedType !== currentType) {
        if (currentType !== null) {
          result.push({ type: currentType, stops: currentStops })
        }
        currentType = mappedType
        currentStops = [stop]
      } else {
        currentStops.push(stop)
      }
    })

    if (currentType !== null) {
      result.push({ type: currentType, stops: currentStops })
    }

    setStopGroups(insertBreak(result))
  }, [props.route, stopGroups, insertBreak])

  const handleStopRowHover = (ref: React.RefObject<HTMLDivElement>, item: any, monitor: any, stop: GQL.StopNode) => {
    if (!ref.current) {
      return
    }

    const hoverIndex = stop.id
    const dragIndex = item.index

    // Don't replace items with themselves
    if (hoverIndex === dragIndex) {
      return
    }

    // Determine rectangle on screen
    const hoverBoundingRect = ref.current?.getBoundingClientRect()

    // Get vertical middle
    const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2

    // Determine mouse position
    const clientOffset = monitor.getClientOffset()

    // Get pixels to the top
    const hoverClientY = (clientOffset as any).y - hoverBoundingRect.top

    // Only perform the move when the mouse has crossed half of the items height
    // When dragging downwards, only move when the cursor is below 50%
    // When dragging upwards, only move when the cursor is above 50%
    // Dragging downwards
    if (hoverIndex > dragIndex && hoverClientY < hoverMiddleY) {
      return
    }

    // Dragging upwards
    if (hoverIndex < dragIndex && hoverClientY > hoverMiddleY) {
      return
    }
    setStopGroups(stopGroups => {
      stopGroups = stopGroups?.map(g => {
        const hoverStopIndex = g.stops.findIndex(s => s.id === hoverIndex)
        const dragStopIndex = g.stops.findIndex(s => s.id === dragIndex)

        if (hoverStopIndex !== -1 && dragStopIndex !== -1 && hoverStopIndex !== dragStopIndex) {
          ;[g.stops[hoverStopIndex], g.stops[dragStopIndex]] = [g.stops[dragStopIndex], g.stops[hoverStopIndex]]
        }

        return g
      })
      return stopGroups
    })
  }

  const handleStopRowDrop = (stop: any, monitor: any) => {
    const sourceRoute = props.routes.find(route => route?.stops?.some(s => s?.id === stop?.id))
    const movedWithinRoute = sourceRoute ? sourceRoute.id === props.route.id : true
    const newStopsOrder = stopGroups?.filter(group => group.type !== GroupType.BREAK).flatMap(group => group.stops.flatMap(s => s.id)) || []

    if (!movedWithinRoute && !newStopsOrder.includes(stop.id)) {
      newStopsOrder.push(stop.id)
    }
    if (props.setLoading) {
      props.setLoading(true)
    }
    redoRoute({
      variables: {
        id: props.route.id,
        newStopsOrder: newStopsOrder,
      },
    }).then(() => {
      if (props.setLoading) {
        props.setLoading(false)
      }
    })
  }

  const visibleColumns = ['no', 'distance', 'address', 'product', 'estimatedEmpty', 'arrival'].filter(column => {
    if (column === 'distance' && props.route.totalDrivingDistance === null) {
      return false
    }

    return true
  })

  return (
    <PlasmicRouteTable
      {..._.omit(props, ['drivers', 'routes', 'route', 'setLoading'])}
      root={{
        ref,
      }}
      flexTable={{
        render: () => (
          <FlexTable
            noCheckbox
            noSorting
            data-testid='route-overview'
            children={<TableHeadTitle title='Customer' count={' (' + (props.route.stops?.length || 0) + ' stops)'} />}
            rows={
              <>
                {stopGroups?.map(group => {
                  if (group.type === GroupType.BREAK) {
                    return (
                      <TableRow
                        noCheckbox
                        key={'break'}
                        data-testid='route-break'
                        sticky={<CustomerCell color='red'>Break</CustomerCell>}
                        scrollCells={
                          <>
                            <CellNo content={'Break'} />
                            {visibleColumns.includes('distance') && <CellDistance content={`${Math.round(props.route.breakDistance! / 1000)} km`} />}
                            <CellProduct content={' '} />
                            <CellAddress noHover address={' '} />
                            <CellEstimatedEmpty content={' '} />
                            <CellInventory content={' '} />
                            <CellArrival
                              content={format(Date.parse(props.route.breakStartTime), 'HH:mm') + ' - ' + format(Date.parse(props.route.breakEndTime), 'HH:mm')}
                            />
                          </>
                        }
                      />
                    )
                  }
                  if (group.type === GroupType.UNMOVEABLE) {
                    return group.stops.map(stop => {
                      let orders = Map<string, number>()
                      if (!props.route.stops) return orders
                      const stopGlobalIndex = props.route.stops.findIndex(_stop => !!_stop && _stop.id === stop.id)
                      let foundNextRefill = false
                      if (stop?.stopType === GQL.StopStopType.Pickup) {
                        const temp = props.route.stops
                          ?.slice(stopGlobalIndex + 1)
                          .filter(s => {
                            if (foundNextRefill) {
                              return false
                            }
                            if (s?.stopType === GQL.StopStopType.Pickup) {
                              foundNextRefill = true
                              return false
                            }
                            return true
                          })
                          .map(s => s?.order)
                        temp?.map(o => {
                          if (!o?.products || o.products.length < 1) {
                            const key =
                              o?.orderType === GQL.OrderType.Bulk
                                ? ' liters of ' + capitalize(toLower((o.products ? o?.products[0]?.product?.displayName : o?.gasType?.toString()) || ''))
                                : Math.trunc(o?.gasWeight || 0).toString() + ' kg ' + capitalize(toLower(o?.gasType?.toString()))
                            return (orders = orders.set(key, orders?.get(key, 0) + o?.numberOfCylinders!))
                          }
                          return o.products.map(p => {
                            const key =
                              o?.orderType === GQL.OrderType.Bulk
                                ? ' liters of ' + capitalize(toLower((o.products ? o?.products[0]?.product?.displayName : o?.gasType?.toString()) || ''))
                                : Math.trunc(p?.product?.weight || 0).toString() + ' kg ' + capitalize(toLower(p?.product?.type.toString()))
                            return (orders = orders.set(key, orders?.get(key, 0) + p?.quantity!))
                          })
                        })
                      }
                      return (
                        <TableRow
                          noCheckbox
                          data-testid={stop?.stopType === GQL.StopStopType.Pickup ? 'route-pickup' : 'route-stop'}
                          multiLine={stop?.stopType === GQL.StopStopType.Pickup ? false : true}
                          key={stop?.order?.id || 'gfdghdf' + stopGlobalIndex}
                          color={stop?.stopType === GQL.StopStopType.Pickup ? '' : 'blue'}
                          sticky={
                            stop?.stopType === GQL.StopStopType.Pickup ? (
                              <CustomerCell color={'green'}>{props.route?.depot?.name} Depot</CustomerCell>
                            ) : (
                              <CustomerCell color={'blue'}>
                                <Link
                                  to={`?customer=${stop?.customer?.id}`}
                                  onClick={() => {
                                    setSource(CustomerDrawerListSource.PLANNED_DELIVERY)
                                    const customers = props.route?.stops ? (props.route.stops.map(stop => stop?.customer) as GQL.CustomerNode[]) : []
                                    if (!!customers) {
                                      setCustomersContext(customers)
                                    }
                                  }}
                                >
                                  {stop?.customer?.name}
                                </Link>
                              </CustomerCell>
                            )
                          }
                          scrollCells={
                            <>
                              <CellNo
                                content={stop?.stopType === GQL.StopStopType.Pickup && stop?.orderInRoute === 0 ? 'Start' : stop?.orderInRoute.toString()}
                              />
                              {visibleColumns.includes('distance') && (
                                <CellDistance
                                  key={stop?.customer?.id + stop?.expectedArrivalTime + 'da'}
                                  content={[
                                    Math.round(stop?.distanceFromPreviousStop! / 100) > 0 ? (
                                      <Text key={stop?.customer?.id + stop?.expectedArrivalTime + 'daDa'} color='white'>
                                        + {Math.round(stop?.distanceFromPreviousStop! / 100) / 10}
                                      </Text>
                                    ) : (
                                      '0'
                                    ),
                                    ' km',
                                  ]}
                                />
                              )}
                              <CellProduct
                                content={
                                  stop?.stopType === GQL.StopStopType.Pickup ? (
                                    props.route?.stops?.some(stop => stop?.order?.orderType === GQL.OrderType.Bulk) ? (
                                      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
                                        {orders
                                          ?.mapEntries(([k, v], i) => [
                                            k,
                                            <span key={i}>
                                              <GreenText>+{v}</GreenText> {k}
                                            </span>,
                                          ])
                                          .toSetSeq()}
                                      </div>
                                    ) : (
                                      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
                                        {orders
                                          ?.mapEntries(([k, v], i) => [
                                            k,
                                            <span key={i}>
                                              <GreenText>+{v}</GreenText> x {k}
                                            </span>,
                                          ])
                                          .toSetSeq()}
                                      </div>
                                    )
                                  ) : null
                                }
                                contentWrapper={stop?.stopType === GQL.StopStopType.Delivery ? <ProductLabel cylinderGroupOrder={stop?.order!} /> : null}
                              />
                              <CellAddress
                                address={
                                  stop?.stopType === GQL.StopStopType.Pickup ? stop?.customer?.depot?.address.firstLine : stop?.customer?.address.firstLine
                                }
                                googleMapsLink={`https://www.google.com/maps/search/?api=1&query=${
                                  stop?.stopType === GQL.StopStopType.Pickup ? props.route.depot?.address.latitude : stop?.customer?.address.latitude
                                },${stop?.stopType === GQL.StopStopType.Pickup ? props.route.depot?.address.longitude : stop?.customer?.address.longitude}`}
                              />
                              <CellEstimatedEmpty
                                content={
                                  stop?.order ? <EstimatedEmpty cylinderGroup={stop?.order?.cylinderGroup} displayThreshold={false} /> : <div>&nbsp;</div>
                                }
                              />
                              <CellArrival content={stop?.expectedArrivalTime ? format(Date.parse(stop?.expectedArrivalTime), 'HH:mm') : 'N/A'} />
                            </>
                          }
                        />
                      )
                    })
                  }
                  if (group.type === GroupType.MOVEABLE) {
                    return group.stops.map((stop, index) => {
                      let orders = Map<string, number>()
                      let foundNextRefill = false
                      if (stop?.stopType === GQL.StopStopType.Pickup) {
                        const temp = props.route.stops
                          ?.slice(index + 1)
                          .filter(s => {
                            if (foundNextRefill) {
                              return false
                            }
                            if (s?.stopType === GQL.StopStopType.Pickup) {
                              foundNextRefill = true
                              return false
                            }
                            return true
                          })
                          .map(s => s?.order)

                        temp?.map(o => {
                          if (!o?.products || o.products.length < 1) {
                            const key = Math.trunc(o?.gasWeight || 0).toString() + ' kg ' + capitalize(toLower(o?.gasType?.toString()))
                            return (orders = orders.set(key, orders.get(key, 0) + o?.numberOfCylinders!))
                          }
                          return o.products.map(p => {
                            const key = Math.trunc(p?.product?.weight || 0).toString() + ' kg ' + capitalize(toLower(p?.product?.type.toString()))
                            return (orders = orders.set(key, orders.get(key, 0) + p?.quantity!))
                          })
                        })
                      }

                      return (
                        <DraggableTableRow
                          stop={stop}
                          type={STOP_TABLE_ROW_DRAG_TYPE}
                          noCheckbox
                          data-testid={stop?.stopType === GQL.StopStopType.Pickup ? 'route-pickup' : 'route-stop'}
                          multiLine={stop?.stopType === GQL.StopStopType.Pickup ? false : true}
                          key={stop?.order?.id}
                          color={stop?.stopType === GQL.StopStopType.Pickup ? '' : 'blue'}
                          sticky={
                            stop?.stopType === GQL.StopStopType.Pickup ? (
                              <CustomerCell color={'green'}>{stop?.customer?.depot?.name} Depot</CustomerCell>
                            ) : (
                              <CustomerCell color={'blue'}>
                                <Link
                                  to={`?customer=${stop?.customer?.id}`}
                                  onClick={() => {
                                    setSource(CustomerDrawerListSource.PLANNED_DELIVERY)
                                    const customers = props.route?.stops ? (props.route.stops.map(stop => stop?.customer) as GQL.CustomerNode[]) : []
                                    if (!!customers) {
                                      setCustomersContext(customers)
                                    }
                                  }}
                                >
                                  {stop?.customer?.name}
                                </Link>
                              </CustomerCell>
                            )
                          }
                          scrollCells={
                            <>
                              <CellNo content={stop?.orderInRoute} />
                              {visibleColumns.includes('distance') && (
                                <CellDistance
                                  key={stop?.customer?.id + stop?.expectedArrivalTime + 'da'}
                                  content={[
                                    Math.round(stop?.distanceFromPreviousStop! / 100) > 0 ? (
                                      <Text key={stop?.customer?.id + stop?.expectedArrivalTime + 'daDa'} color='white'>
                                        + {Math.round(stop?.distanceFromPreviousStop! / 100) / 10}
                                      </Text>
                                    ) : (
                                      '0'
                                    ),
                                    ' km',
                                  ]}
                                />
                              )}
                              <CellProduct
                                content={
                                  stop?.stopType === GQL.StopStopType.Pickup ? (
                                    props.route.stops?.some(stop => stop?.order?.orderType === GQL.OrderType.Bulk) ? (
                                      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
                                        {orders
                                          ?.mapEntries(([k, v], i) => [
                                            k,
                                            <span key={i}>
                                              <GreenText>+{v}</GreenText> liters of {k}
                                            </span>,
                                          ])
                                          .toSetSeq()}
                                      </div>
                                    ) : (
                                      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
                                        {orders
                                          ?.mapEntries(([k, v], i) => [
                                            k,
                                            <span key={i}>
                                              <GreenText>+{v}</GreenText> x {k}
                                            </span>,
                                          ])
                                          .toSetSeq()}
                                      </div>
                                    )
                                  ) : null
                                }
                                contentWrapper={stop?.stopType === GQL.StopStopType.Delivery ? <ProductLabel cylinderGroupOrder={stop?.order!} /> : null}
                              />
                              <CellAddress
                                address={
                                  stop?.stopType === GQL.StopStopType.Pickup ? stop?.customer?.depot?.address.firstLine : stop?.customer?.address.firstLine
                                }
                                googleMapsLink={`https://www.google.com/maps/search/?api=1&query=${
                                  stop?.stopType === GQL.StopStopType.Pickup ? props.route.depot?.address.latitude : stop?.customer?.address.latitude
                                },${stop?.stopType === GQL.StopStopType.Pickup ? props.route.depot?.address.longitude : stop?.customer?.address.longitude}`}
                              />
                              <CellEstimatedEmpty
                                content={
                                  stop?.order ? <EstimatedEmpty cylinderGroup={stop?.order?.cylinderGroup!} displayThreshold={false} /> : <div>&nbsp;</div>
                                }
                              />
                              <CellArrival content={stop?.expectedArrivalTime ? format(Date.parse(stop?.expectedArrivalTime), 'HH:mm') : 'N/A'} />
                            </>
                          }
                          onRowHover={(ref, item, monitor) => handleStopRowHover(ref, item, monitor, stop)}
                          onRowDrop={handleStopRowDrop}
                        />
                      )
                    })
                  }
                  return []
                })}
                <TableRow
                  noCheckbox
                  key={`${props.route?.id}-Ending`}
                  sticky={<CustomerCell color='green'>Return to {props.route.depot?.name}</CustomerCell>}
                  scrollCells={
                    <>
                      <CellNo content={'End'} />
                      {visibleColumns.includes('distance') && (
                        <CellDistance
                          key={props.route.id + props.route?.breakDistance + props.route?.breakEndTime}
                          content={[
                            <Text key={props.route.id + props.route?.breakDistance + props.route?.breakEndTime + 'dadas'} color='white'>
                              +{' '}
                            </Text>,
                            <Text key={props.route.id + props.route?.breakDistance + props.route?.breakEndTime + 'swrev'} color='white'>
                              {Math.round(
                                (props.route.totalDrivingDistance! -
                                  props.route.breakDistance! -
                                  props.route.stops?.map(s => s?.distanceFromPreviousStop!).reduce((a, b) => a + b)!) /
                                  100 /
                                  10
                              )}
                            </Text>,
                            ' km',
                          ]}
                        />
                      )}
                      <CellProduct content={' '} />
                      <CellAddress
                        address={props.route.depot?.address.firstLine}
                        googleMapsLink={
                          'https://www.google.com/maps/search/?api=1&query=' + props.route.depot?.address.latitude + ',' + props.route.depot?.address.longitude
                        }
                      />
                      <CellEstimatedEmpty content={' '} />
                      <CellArrival content={format(Date.parse(props.route.endTime), 'HH:mm')} />
                    </>
                  }
                />
                <TableRow
                  noCheckbox
                  summary
                  key={`${props.route?.id}-Summary`}
                  scrollCells={
                    <>
                      <CellNo content={' '} />
                      {visibleColumns.includes('distance') && (
                        <CellDistance content={t({ id: 'routes.total-km' }, { total: Math.round(props.route.totalDrivingDistance! / 1000) ?? 0 })} />
                      )}
                      <CellProduct
                        content={t(
                          {
                            id: props.route.stops?.some(route => route?.order?.orderType === GQL.OrderType.Bulk)
                              ? 'routes.total-empty-cylinders-bulk'
                              : 'routes.total-empty-cylinders',
                          },
                          { total: props.route.totalCylindersToDeliver ?? 0 }
                        )}
                      />
                      <CellAddress address={' '} noHover />
                      <CellEstimatedEmpty content={' '} />
                      <CellArrival content={`${timeSpent[0]}h ${timeSpent[1]}m`} />
                    </>
                  }
                />
              </>
            }
            visibleColumns={visibleColumns}
          />
        ),
      }}
      routeHead={{
        props: {
          drivers: props.drivers,
          routes: props.routes,
          route: props.route,
        },
      }}
    />
  )
}

const RouteTable = React.forwardRef(RouteTable_)
export default RouteTable
