import * as GQL from 'generated/graphql'

export function routeReadyForDispatch(route: GQL.RouteInfo) {
  return route.driver !== null && route.depot !== null && route.vehicle !== null && route.stops && route.stops?.length > 0
}

export function getRouteIsNotReadyError(route: GQL.RouteInfo) {
  if (route.driver === null) {
    return 'routing.dispatch.errors.no-driver'
  } else if (route.depot === null) {
    return 'routing.dispatch.errors.no-depot'
  } else if (route.vehicle === null) {
    return 'routing.dispatch.errors.no-vehicle'
  } else if (route.stops?.length === 0) {
    return 'routing.dispatch.errors.no-stops'
  } else {
    return 'routing.dispatch.errors.generic'
  }
}

export function getErrorMessageIdFromOptimiseError(errorMessage: string): string {
  if (errorMessage.includes('Unfound route(s)')) {
    return 'routing.errors.optimize-error.unfound-route'
  } else if (errorMessage.includes('time_window')) {
    return 'routing.errors.optimize-error.time-window'
  }
  return 'routing.errors.optimize-error.default'
}

export const getDateOnly = (date: Date) => new Date(date.getFullYear(), date.getMonth(), date.getDate())
