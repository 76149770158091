import React from 'react'
import { Coding, charset7bit, charset7bitText } from './consts'

interface SMSLengthInfo {
  coding: Coding
  parts: number
  charsSms: number
  charsUsed: number
}

const useGetSMSLengthInfo = (singleSMSLength: number) => {
  return React.useCallback(
    (content: string) => {
      const chars_arr = content.split('')
      let coding = Coding._7bit
      let parts = 1
      let part = 1
      let charsUsed = 0
      let charsSms = singleSMSLength

      for (let i = 0; i < chars_arr.length; i++) {
        if (charset7bit.indexOf(chars_arr[i]) >= 0) {
          charsUsed = charsUsed + 1
        } else if (charset7bitText.indexOf(chars_arr[i]) >= 0) {
          charsUsed = charsUsed + 2
        } else {
          coding = Coding._16bit
          charsUsed = chars_arr.length
          break
        }
      }

      if (coding === Coding._7bit) {
        if (charsUsed > singleSMSLength) {
          parts = Math.ceil(charsUsed / 153)
          let partCharsUsed = 7
          charsSms = 153

          for (let i = 0; i < chars_arr.length; i++) {
            if (partCharsUsed + 1 > singleSMSLength) {
              part = part + 1
              partCharsUsed = 7
            }
            if (charset7bitText.indexOf(chars_arr[i]) >= 0 && partCharsUsed + 2 > singleSMSLength) {
              part = part + 1
              partCharsUsed = 7
            }
            if (charset7bitText.indexOf(chars_arr[i]) === -1) {
              partCharsUsed = partCharsUsed + 1
            } else {
              partCharsUsed = partCharsUsed + 2
            }
          }
        } else {
          charsSms = singleSMSLength
        }
      } else {
        if (charsUsed > 70) {
          parts = Math.ceil(charsUsed / 67)
          let partCharsUsed = 3
          charsSms = 67

          for (let i = 0; i < chars_arr.length; i++) {
            if (partCharsUsed + 1 > 70) {
              part = part + 1
              partCharsUsed = 3
            }
            partCharsUsed = partCharsUsed + 1
          }
        } else {
          charsSms = 70
        }
      }

      return {
        coding: coding,
        parts: parts,
        charsSms: charsSms,
        charsUsed: charsUsed,
      } as SMSLengthInfo
    },
    [singleSMSLength]
  )
}

export default useGetSMSLengthInfo
export { type SMSLengthInfo }
