// @ts-nocheck
/* eslint-disable */
/* tslint:disable */
/* prettier-ignore-start */
import React from "react";
import { classNames } from "@plasmicapp/react-web";

export type VehicleBulksvgIconProps = React.ComponentProps<"svg"> & {
  title?: string;
};

export function VehicleBulksvgIcon(props: VehicleBulksvgIconProps) {
  const { className, style, title, ...restProps } = props;
  return (
    <svg
      xmlns={"http://www.w3.org/2000/svg"}
      version={"1.1"}
      x={"0"}
      y={"0"}
      viewBox={"0 0 80 42.4"}
      xmlSpace={"preserve"}
      height={"1em"}
      style={{
        fill: "currentcolor",

        ...(style || {}),
      }}
      className={classNames("plasmic-default__svg", className)}
      {...restProps}
    >
      {title && <title>{title}</title>}

      <path
        d={
          "M6.2 29.3h-.4c-1.6 0-2.9 1.3-2.9 2.9v.7c0 1.6 1.3 2.9 2.9 2.9h3.4c.5 3.7 3.6 6.5 7.4 6.5 3.8 0 6.9-2.8 7.4-6.5h.7c.5 3.7 3.6 6.5 7.4 6.5 3.8 0 6.9-2.8 7.4-6.5H60c.5 3.7 3.6 6.5 7.4 6.5 3.7 0 6.7-2.7 7.4-6.1h1.1c2.1 0 3.9-1.5 3.9-3.4V24c0-.3 0-.7-.1-1.1v-.1c-.1-1.3-.5-2.8-.9-3.9L74.9 7.7c-.6-1.7-2.7-3-4.7-3H59.4c-2.1 0-3.9 1.5-3.9 3.4v21.2H48c3.7-2.3 6.2-6.5 6.2-11.2v-2.6c0-7.3-5.9-13.2-13.2-13.2H22.7V2c0-1.1-.9-2-2-2h-5.5c-1.1 0-2 .9-2 2v.3C5.9 2.4 0 8.3 0 15.5v2.6c0 4.7 2.5 8.9 6.2 11.2zm63.7-11.2c-.4-.2-.7-.7-.7-1.1v-6c0-.2.2-.5.5-.5h4.2l3.4 9.1c.2.5.3 1 .5 1.7l-7.9-3.2zm-6.3 16.8c0-2.2 1.8-4 4-4s4 1.8 4 4-1.8 4-4 4-4-1.8-4-4zm-35.4 0c0-2.2 1.8-4 4-4s4 1.8 4 4-1.8 4-4 4-4-1.8-4-4zm-15.6 0c0-2.2 1.8-4 4-4s4 1.8 4 4-1.8 4-4 4-4-1.8-4-4z"
        }
      ></path>
    </svg>
  );
}

export default VehicleBulksvgIcon;
/* prettier-ignore-end */
