import React from 'react'
import { useIntl } from 'react-intl'
import { ApolloError } from '@apollo/client'
import * as GQL from 'generated/graphql'
import GeofenceRow from 'plasmic/GeofenceRow'
import { CenteredLoader } from 'components/Loader/LoaderWrappers'
import Loader from 'components/Loader'
import { NoHits } from 'modules/orders/util'
import LabelStatusChip from 'plasmic/LabelStatusChip'

interface Props {
  geofences: GQL.GeoFenceNode[]
  isLoading: boolean
  error: ApolloError | undefined
  setEditedGeofence: React.Dispatch<React.SetStateAction<GQL.GeoFenceNode | null>>
  setDeletedGeofence: React.Dispatch<React.SetStateAction<GQL.GeoFenceNode | null>>
  selectedGeofence: string | null
  setSelectedGeofence: React.Dispatch<React.SetStateAction<string | null>>
}

const GeofenceRows: React.FC<Props> = props => {
  const intl = useIntl()
  const t = intl.formatMessage

  if (props.isLoading && !props.geofences) {
    return (
      <CenteredLoader sizeAuto>
        <Loader color='gray6' size={50} />
      </CenteredLoader>
    )
  }

  if (!props.isLoading && props.geofences.length === 0) {
    return (
      <NoHits>
        <LabelStatusChip icon='check' title={t({ id: 'settings.geofences.no-hits' })} />
      </NoHits>
    )
  }

  if (props.error) {
    return (
      <NoHits>
        <LabelStatusChip icon='error' title={t({ id: 'settings.geofences.fetch.error' })} />
      </NoHits>
    )
  }

  return props.geofences.map((geofence, index) => (
    <GeofenceRow
      key={geofence.id}
      title={geofence.name}
      selected={props.selectedGeofence === geofence.id}
      onClick={() => props.setSelectedGeofence(geofence.id)}
      btnEdit={{ props: { onClick: () => props.setEditedGeofence(geofence) } }}
      btnDelete={{ props: { onClick: () => props.setDeletedGeofence(geofence) } }}
      geofence={geofence}
    />
  ))
}

export default GeofenceRows
