import { useMemo, useState } from 'react'
import { useIntl } from 'react-intl'

import * as GQL from 'generated/graphql'
import { TutorialInfoIcon } from 'components/Tooltip/TutorialTooltip'
import { VerifyExchangeStock } from 'modules/inventory'
import OrdersAccordion from '../components/OrdersAccordion'
import CreateOrder from '../components/CreateOrder'

import OrdersTab from 'plasmic/OrdersTab'
import ScrollIndicator from 'plasmic/ScrollIndicator'
import Loader from 'components/Loader'
import { VerifyBulkTankDelivery } from 'modules/orders/types'

interface Orders {
  cylinderGroup: GQL.CylinderGroupNode
  setVerifyExchangeInfo?: (info: VerifyExchangeStock) => void
  setVerifyBulkTankInfo?: (info: VerifyBulkTankDelivery) => void
}

export const ACTIVE_PAST_ORDERS_PAGE_SIZE = 15

export default function Orders({ cylinderGroup, setVerifyExchangeInfo, setVerifyBulkTankInfo }: Orders) {
  const [isCreateOrder, setIsCreateOrder] = useState(window.location.pathname.includes('orders/create'))
  const [editOrder, setEditOrder] = useState<GQL.CylinderGroupOrderNode>()

  const intl = useIntl()
  const t = intl.formatMessage

  const {
    data: activeOrdersData,
    loading: activeOrdersDataLoading,
    fetchMore: fetchMoreActiveOrders,
  } = GQL.useAllActiveCylinderGroupOrders({
    variables: {
      first: ACTIVE_PAST_ORDERS_PAGE_SIZE,
      cylinderGroup: cylinderGroup.id,
      orderBy: '-createdAt',
    },
  })

  const {
    data: pastOrdersData,
    loading: pastOrdersDataLoading,
    fetchMore: fetchMorePastOrders,
  } = GQL.useAllPastCylinderGroupOrders({
    variables: {
      first: ACTIVE_PAST_ORDERS_PAGE_SIZE,
      cylinderGroup: cylinderGroup.id,
      orderBy: '-createdAt',
    },
  })

  const activeOrders = useMemo(() => {
    return activeOrdersData?.activeCylinderGroupOrders
      ? activeOrdersData.activeCylinderGroupOrders.edges.map(order => order?.node as GQL.CylinderGroupOrderNode)
      : []
  }, [activeOrdersData])

  const activeOrdersPageInfo = useMemo(() => {
    return activeOrdersData?.activeCylinderGroupOrders?.pageInfo || { hasNextPage: false, endCursor: '' }
  }, [activeOrdersData])

  const pastOrders = useMemo(() => {
    return pastOrdersData?.pastCylinderGroupOrders ? pastOrdersData.pastCylinderGroupOrders.edges.map(order => order?.node as GQL.CylinderGroupOrderNode) : []
  }, [pastOrdersData])

  const pastOrdersPageInfo = useMemo(() => {
    return pastOrdersData?.pastCylinderGroupOrders?.pageInfo || { hasNextPage: false, endCursor: '' }
  }, [pastOrdersData])

  const onCloseOrderForm = () => {
    setIsCreateOrder(false)
    setEditOrder(undefined)
  }

  return (
    <OrdersTab
      createOrder={isCreateOrder || !!editOrder}
      accordionActiveOrders={{
        'data-testid': 'active-orders',
        accordionContent: (
          <>
            <OrdersAccordion
              active
              loading={activeOrdersDataLoading}
              orders={activeOrders}
              activeOrders={activeOrders}
              setEditOrder={setEditOrder}
              setVerifyExchangeInfo={setVerifyExchangeInfo}
              setVerifyBulkTankInfo={setVerifyBulkTankInfo}
            />
            {activeOrders.length > 0 && activeOrdersPageInfo.hasNextPage && (
              <ScrollIndicator
                loaded={activeOrders.length}
                total={activeOrdersData?.activeCylinderGroupOrders?.totalCount || 0}
                btnLoadMore={{
                  ...(activeOrdersDataLoading && { children: <Loader color='white' /> }),
                  onClick: () =>
                    fetchMoreActiveOrders({
                      variables: {
                        after: activeOrdersPageInfo.endCursor,
                        first: ACTIVE_PAST_ORDERS_PAGE_SIZE,
                        cylinderGroup: cylinderGroup.id,
                        orderBy: '-createdAt',
                      },
                      updateQuery(prev, { fetchMoreResult }) {
                        if (!fetchMoreResult) return prev
                        return {
                          ...fetchMoreResult,
                          activeCylinderGroupOrders: {
                            ...fetchMoreResult.activeCylinderGroupOrders,
                            edges: [...(prev.activeCylinderGroupOrders?.edges || []), ...(fetchMoreResult.activeCylinderGroupOrders?.edges || [])],
                          },
                        } as GQL.AllActiveCylinderGroupOrders
                      },
                    }),
                }}
              />
            )}
          </>
        ),
        helpText: <TutorialInfoIcon content={t({ id: 'tooltips.customers.drawer.tabs.orders.active_orders' })} />,
      }}
      accordionPastOrders={{
        'data-testid': 'past-orders',
        accordionContent: (
          <>
            <OrdersAccordion loading={pastOrdersDataLoading} orders={pastOrders} activeOrders={activeOrders} setEditOrder={setEditOrder} />
            {pastOrders.length > 0 && pastOrdersPageInfo.hasNextPage && (
              <ScrollIndicator
                loaded={pastOrders.length || 0}
                total={pastOrdersData?.pastCylinderGroupOrders?.totalCount || 0}
                btnLoadMore={{
                  ...(pastOrdersDataLoading && { children: <Loader color='white' /> }),
                  onClick: () =>
                    fetchMorePastOrders({
                      variables: {
                        after: pastOrdersPageInfo.endCursor,
                        first: ACTIVE_PAST_ORDERS_PAGE_SIZE,
                        cylinderGroup: cylinderGroup.id,
                        orderBy: '-createdAt',
                      },
                      updateQuery(prev, { fetchMoreResult }) {
                        if (!fetchMoreResult) return prev
                        return {
                          ...fetchMoreResult,
                          pastCylinderGroupOrders: {
                            ...fetchMoreResult.pastCylinderGroupOrders,
                            edges: [...(prev.pastCylinderGroupOrders?.edges || []), ...(fetchMoreResult.pastCylinderGroupOrders?.edges || [])],
                          },
                        } as GQL.AllPastCylinderGroupOrders
                      },
                    }),
                }}
              />
            )}
          </>
        ),
        helpText: <TutorialInfoIcon content={t({ id: 'tooltips.customers.drawer.tabs.orders.past_orders' })} />,
      }}
      btnCreateNewOrder={{
        onClick: () => setIsCreateOrder(true),
      }}
      createOrderModuleExtended={
        <CreateOrder
          cylinderGroup={cylinderGroup}
          orders={pastOrders.concat(activeOrders)}
          activeOrders={activeOrders}
          onClose={onCloseOrderForm}
          order={editOrder}
        />
      }
    />
  )
}
