import * as GQL from 'generated/graphql'
import ToggleContactLine from 'plasmic/ToggleContactLine'
import { displayToast } from 'util/toasts'

interface ISubscriptionProps {
  subscriptions: GQL.NotificationSubscriptionNode[]
  verbs: GQL.NotificationVerbNode[]
  channels: GQL.ChannelNode[]
}

function UserSubscriptionsToggles({ subscriptions, verbs, channels }: ISubscriptionProps) {
  const [createSubscription, createSubscriptionResult] = GQL.useCreateNotificationSubscription({
    onError: err => displayToast(err.message, 'error'),
    refetchQueries: ['AllPersonalSubscriptions'],
  })

  const [patchSubscription, patchSubscriptionResult] = GQL.usePatchNotificationSubscription({
    onError: err => displayToast(err.message, 'error'),
    refetchQueries: ['AllPersonalSubscriptions'],
  })

  const isLoading = createSubscriptionResult.loading || patchSubscriptionResult.loading

  const toggleSubscription = (verb: GQL.NotificationVerbNode, channel: GQL.ChannelNode) => {
    if (!channel || isLoading) return // Early return

    const existingSubscription =
      subscriptions?.find(subscription => subscription?.notifyVerb?.id === verb.id && subscription.notifyChannel?.id === channel?.id) ?? null

    if (!existingSubscription) {
      createSubscription({
        variables: {
          input: {
            notifyVerbId: verb.id,
            notifyChannelId: channel.id,
            isActive: true,
          },
        },
      })
    } else {
      patchSubscription({
        variables: {
          id: existingSubscription.id,
          input: {
            isActive: !existingSubscription.isActive,
          },
        },
      })
    }
  }

  const emailChannel = channels.find(channel => channel.method === 'email')

  return (
    <>
      {verbs.map(verb => {
        const isToggledEmail =
          subscriptions?.some(
            subscription => subscription?.notifyVerb?.id === verb.id && subscription.notifyChannel?.id === emailChannel?.id && subscription.isActive
          ) ?? false

        return (
          <ToggleContactLine
            hide={['sms']}
            key={verb.id}
            title={verb.name}
            subtitle={verb.description || ' '}
            toggleRefilledEmail={{
              isChecked: isToggledEmail,
              onChange: () => emailChannel && toggleSubscription(verb, emailChannel),
            }}
          />
        )
      })}
    </>
  )
}

export default UserSubscriptionsToggles
