// This is a skeleton starter React component generated by Plasmic.
// This file is owned by you, feel free to edit as you see fit.
import * as React from 'react'
import { PlasmicModalGeofence, DefaultModalGeofenceProps } from './plasmic/solace_components/PlasmicModalGeofence'
import { HTMLElementRefOf } from '@plasmicapp/react-web'
import * as p from '@plasmicapp/react-web'
import InputField from './InputField'
import ButtonFill from './ButtonFill'
import SelectTab from './SelectTab'
import SelectTabs from './SelectTabs'

// Your component props start with props for variants and slots you defined
// in Plasmic, but you can add more here, like event handlers that you can
// attach to named nodes in your component.
//
// If you don't want to expose certain variants or slots as a prop, you can use
// Omit to hide them:
//
// interface ModalGeofenceProps extends Omit<DefaultModalGeofenceProps, "hideProps1"|"hideProp2"> {
//   // etc.
// }
//
// You can also stop extending from DefaultModalGeofenceProps altogether and have
// total control over the props for your component.
export interface ModalGeofenceProps extends DefaultModalGeofenceProps {
  inputAreaName?: p.Flex<typeof InputField>
  inputSearch?: p.Flex<'div'>
  btnCreateGeofence?: p.Flex<typeof ButtonFill>
  selectTabs?: p.Flex<typeof SelectTabs>
  tabSearch?: p.Flex<typeof SelectTab>
  tabFreeform?: p.Flex<typeof SelectTab>
  btnClose?: p.Flex<'button'>
}

function ModalGeofence_(props: ModalGeofenceProps, ref: HTMLElementRefOf<'div'>) {
  // Use PlasmicModalGeofence to render this component as it was
  // designed in Plasmic, by activating the appropriate variants,
  // attaching the appropriate event handlers, etc.  You
  // can also install whatever React hooks you need here to manage state or
  // fetch data.
  //
  // Props you can pass into PlasmicModalGeofence are:
  // 1. Variants you want to activate,
  // 2. Contents for slots you want to fill,
  // 3. Overrides for any named node in the component to attach behavior and data,
  // 4. Props to set on the root node.
  //
  // By default, we are just piping all ModalGeofenceProps here, but feel free
  // to do whatever works for you.

  return <PlasmicModalGeofence root={{ ref }} {...props} />
}

const ModalGeofence = React.forwardRef(ModalGeofence_)
export default ModalGeofence
