// This is a skeleton starter React component generated by Plasmic.
// This file is owned by you, feel free to edit as you see fit.
import * as React from 'react'
import { PlasmicWorkspaceDropdown, DefaultWorkspaceDropdownProps } from './plasmic/solace_components/PlasmicWorkspaceDropdown'
import { HTMLElementRefOf } from '@plasmicapp/react-web'
import * as GQL from 'generated/graphql'
import DropdownInstance from './DropdownInstance'
import { useAppContext } from 'util/hooks'
import { useApolloClient } from '@apollo/client'
import Loader from 'components/Loader'
import CacheConfigs from 'util/cacheConfig'
import { useIntl } from 'react-intl'

// Your component props start with props for variants and slots you defined
// in Plasmic, but you can add more here, like event handlers that you can
// attach to named nodes in your component.
//
// If you don't want to expose certain variants or slots as a prop, you can use
// Omit to hide them:
//
// interface WorkspaceDropdownProps extends Omit<DefaultWorkspaceDropdownProps, "hideProps1"|"hideProp2"> {
//   // etc.
// }
//
// You can also stop extending from DefaultWorkspaceDropdownProps altogether and have
// total control over the props for your component.
export interface WorkspaceDropdownProps extends DefaultWorkspaceDropdownProps {}

function WorkspaceDropdown_(props: WorkspaceDropdownProps, ref: HTMLElementRefOf<'div'>) {
  // Use PlasmicWorkspaceDropdown to render this component as it was
  // designed in Plasmic, by activating the appropriate variants,
  // attaching the appropriate event handlers, etc.  You
  // can also install whatever React hooks you need here to manage state or
  // fetch data.
  //
  // Props you can pass into PlasmicWorkspaceDropdown are:
  // 1. Variants you want to activate,
  // 2. Contents for slots you want to fill,
  // 3. Overrides for any named node in the component to attach behavior and data,
  // 4. Props to set on the root node.
  //
  // By default, we are just piping all WorkspaceDropdownProps here, but feel free
  // to do whatever works for you.
  const intl = useIntl()
  const t = intl.formatMessage
  const client = useApolloClient()
  const { appContext, setAppContext } = useAppContext()

  const currentDepot = appContext.depot
  const currentDistributor = appContext.distributor

  const [depotLoading, setDepotLoading] = React.useState(false)
  const [distributorLoading, setDistributorLoading] = React.useState(false)

  const { data: distributorData } = GQL.useAllDistributors({
    context: {
      dropDistributorContext: true,
    },
  })
  const distributors = (distributorData?.allDistributors?.edges.map(e => e?.node) as GQL.DistributorNode[]) || []

  const { data: depotData } = GQL.useAllDepots(CacheConfigs.ACCURATE_ONCE)
  const depots = (depotData?.allDepots?.edges.map(e => e?.node) as GQL.DepotNode[]) || []

  return (
    <PlasmicWorkspaceDropdown
      root={{ ref }}
      {...props}
      dropdownDistributor={{
        title: distributorLoading ? <Loader color='#fff' /> : currentDistributor?.fullName || t({ id: 'common.distributor' }),
        'data-testid': 'distributor-selector',
        dropdown: distributors.map(distributor => (
          <DropdownInstance
            key={distributor.id + 'distributor'}
            data-testid={distributor.name}
            name={distributor.fullName}
            current={currentDistributor?.id === distributor?.id ? true : false}
            onClick={() => {
              setDistributorLoading(true)
              setAppContext(context => ({ ...context, distributor: distributor }))
              localStorage.setItem('distributor', distributor.id)
              client
                .resetStore()
                .catch(error => {
                  console.log('Store reset rejected!', error)
                })
                .finally(() => setDistributorLoading(false))
            }}
          />
        )),
      }}
      dropdownDepot={{
        title: distributorLoading || depotLoading ? <Loader color='#fff' /> : currentDepot?.name || t({ id: 'common.all-depots' }),
        'data-testid': 'depot-selector',
        dropdown: [
          <DropdownInstance
            key='all-dropdownDepot'
            name='All'
            data-testid='all-depots'
            current={currentDepot ? false : true}
            onClick={() => {
              setDepotLoading(true)
              setAppContext(context => ({ ...context, depot: null }))
              localStorage.removeItem('depot')
              client.cache
                .reset()
                .catch(error => {
                  console.log('Store reset rejected!', error)
                })
                .finally(() => setDepotLoading(false))
            }}
          />,
          depots.map(depot => (
            <DropdownInstance
              key={depot.id + 'depot'}
              data-testid={depot.name}
              name={depot.name}
              current={currentDepot?.id === depot?.id ? true : false}
              onClick={() => {
                setDepotLoading(true)
                setAppContext(context => ({ ...context, depot: depot }))
                localStorage.setItem('depot', depot.id)
                client
                  .resetStore()
                  .catch(error => {
                    console.log('Store reset rejected!', error)
                  })
                  .finally(() => setDepotLoading(false))
              }}
            />
          )),
        ],
      }}
    />
  )
}

const WorkspaceDropdown = React.forwardRef(WorkspaceDropdown_)
export default WorkspaceDropdown
