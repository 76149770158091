import React, { useCallback, useMemo, useState } from 'react'
import Select from 'react-select'
import Modal from 'components/Modal/Modal'
import CreateOrder from 'modules/customers/components/CreateOrder'

import * as GQL from 'generated/graphql'
import { Control, Option, reactSelectStyles, IndicatorsContainer } from 'plasmic/StyledReactSelect'
import CacheConfigs from 'util/cacheConfig'
import { useDebounce } from 'util/hooks'
import { SelectOption } from 'types/props'

interface Props {
  isOpen: boolean
  onClose: () => void
  readyToRefillCylinderGroup?: GQL.CylinderGroupNode | undefined
}

const CreateOrderModal: React.FC<Props> = props => {
  const PAGE_SIZE = 15
  const [search, setSearch] = useState('')
  const [selectedCylinderGroup, setSelectedCylinderGroup] = useState<GQL.CylinderGroupNode | undefined>(
    props.readyToRefillCylinderGroup ? props.readyToRefillCylinderGroup : undefined
  )

  const debouncedSearch = useDebounce(search, 500)

  const {
    data: dataAllCylinderGroups,
    fetchMore: fetchMoreCylinderGroups,
    loading,
  } = GQL.useAllCylinderGroups({
    ...CacheConfigs.ACCURATE_ONCE,
    variables: {
      after: null,
      first: PAGE_SIZE,
      search: debouncedSearch,
    },
  })

  const allCylinderGroups = useMemo(
    () => dataAllCylinderGroups?.allCylinderGroups?.edges.map(edge => edge?.node as GQL.CylinderGroupNode) || [],
    [dataAllCylinderGroups]
  )

  const pageInfo = useMemo(() => {
    return dataAllCylinderGroups?.allCylinderGroups?.pageInfo || { hasNextPage: false, endCursor: '' }
  }, [dataAllCylinderGroups])

  const { data: dataOrders, fetchMore: fetchMoreOrders } = GQL.useAllCylinderGroupOrders({
    skip: !selectedCylinderGroup,
    variables: {
      cylinderGroup: selectedCylinderGroup?.id,
      orderBy: '-createdAt',
    },
  })

  const allOrders = useMemo(() => {
    return dataOrders?.allOrders ? dataOrders.allOrders.edges.map(order => order?.node as GQL.CylinderGroupOrderNode) : []
  }, [dataOrders])

  const activeOrders = useMemo(() => {
    return dataOrders?.activeOrders ? dataOrders.activeOrders.edges.map(order => order?.node as GQL.CylinderGroupOrderNode) : []
  }, [dataOrders])

  useCallback(() => {
    if (!selectedCylinderGroup) return
    fetchMoreOrders({
      variables: {
        cylinderGroup: selectedCylinderGroup.id,
      },
      updateQuery(prev, { fetchMoreResult }) {
        if (!fetchMoreResult) return prev
        return {
          ...(fetchMoreResult as GQL.AllCylinderGroupOrders),
        }
      },
    })
  }, [fetchMoreOrders, selectedCylinderGroup])

  useCallback(() => {
    fetchMoreCylinderGroups({
      variables: {
        after: pageInfo.endCursor,
        first: PAGE_SIZE,
        search: debouncedSearch,
      },
      updateQuery(prev, { fetchMoreResult }) {
        if (!fetchMoreResult) return prev
        return {
          ...fetchMoreResult,
          allCylinderGroups: {
            ...fetchMoreResult?.allCylinderGroups,
            edges: [...(prev.allCylinderGroups?.edges || []), ...fetchMoreResult?.allCylinderGroups?.edges!],
          },
        } as GQL.AllCylinderGroups
      },
    })
  }, [fetchMoreCylinderGroups, pageInfo, debouncedSearch])

  return (
    <Modal isOpen={props.isOpen} onRequestClose={props.onClose} stripped overlayStyle={{ alignItems: 'flex-start', overflow: 'auto', padding: '2rem 0rem' }}>
      <CreateOrder
        asModal
        onClose={props.onClose}
        cylinderGroup={selectedCylinderGroup}
        orders={allOrders}
        activeOrders={activeOrders}
        selectCustomerDiv={
          <Select
            placeholder={'Search for a customer...'}
            components={{ Option, Control, IndicatorsContainer }}
            styles={reactSelectStyles}
            isMulti={false}
            onChange={event => setSelectedCylinderGroup(event?.value)}
            onInputChange={value => setSearch(value)}
            isLoading={loading}
            value={
              {
                value: selectedCylinderGroup,
                label: selectedCylinderGroup?.customer?.name || 'Select customer',
              } as SelectOption<GQL.CylinderGroupNode>
            }
            options={
              allCylinderGroups.map(cylinderGroup => ({
                value: cylinderGroup,
                label: cylinderGroup.customer.name,
              })) as SelectOption<GQL.CylinderGroupNode>[]
            }
          />
        }
      />
    </Modal>
  )
}

export { CreateOrderModal }
